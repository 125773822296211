import { toast } from 'react-toastify';
import { all, takeLatest, put } from 'redux-saga/effects';

import { CitiesService } from 'services/api/cities';
import { CitiesResponse } from 'services/api/cities/models';
import { mapErrorToResponseError } from 'services/api/responseErrorHandler';
import { CitiesCreators } from 'store/ducks/cities';

function* fetchCitiesByStateId(
  action: ReturnType<typeof CitiesCreators.getCitiesByStateId.request>,
): Generator<unknown, void, CitiesResponse> {
  try {
    const { stateId } = action.payload;
    const { records } = yield CitiesService.fetchCitiesByStateId(stateId);
    yield put(
      CitiesCreators.getCitiesByStateId.success(
        records.map(city => ({ value: city.id, label: city.name })),
      ),
    );
  } catch (error) {
    const responseError = mapErrorToResponseError(error);
    toast.error(responseError.message);
    yield put(CitiesCreators.getCitiesByStateId.failure(responseError.message));
  }
}

export default all([
  takeLatest(CitiesCreators.getCitiesByStateId.request, fetchCitiesByStateId),
]);
