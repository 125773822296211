import { combineReducers } from 'redux';

import auth, { Types } from './auth';
import cities from './cities';
import dropdownHeader from './dropdownHeader';
import enrollment from './enrollment';
import followUp from './followUp';
import newEnrollment from './newEnrollment';
import schoolFilters from './schoolFilters';
import states from './states';
import stepNavigation from './stepNavigation';
import documentsStudent from './students/documentsStudent';
import studentGeneralInfo from './students/general';
import residentialArea from './students/residentialArea';
import responsableData from './students/responsableData';
import saber from './students/saber';
import studentSchool from './students/school';
import uploadStudentFile from './students/UploadFiles';
import transfer from './transfer';
import uploadingFiles from './uploadingFiles';

const reducers = combineReducers({
  auth,
  documentsStudent,
  studentGeneralInfo,
  stepNavigation,
  states,
  cities,
  responsableData,
  residentialArea,
  uploadingFiles,
  uploadStudentFile,
  schoolFilters,
  enrollment,
  dropdownHeader,
  newEnrollment,
  saber,
  transfer,
  followUp,
  studentSchool,
});

const rootReducer = (state: any, action: any) => {
  // Clear all data in redux store to initial [MA]
  if (action.type === Types.SIGN_OUT) {
    state = undefined;
  }

  return reducers(state, action);
};

export default rootReducer;
