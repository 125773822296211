/* eslint-disable no-restricted-syntax */
/* eslint-disable import/order */
import { toast } from 'react-toastify';
import { all, put, takeLatest, select } from 'redux-saga/effects';

import { Identifier } from 'models/identifier';
import { UploadStudentFiles, Keys } from 'models/student';
import { mapErrorToResponseError } from 'services/api/responseErrorHandler';
import { UploadStudentFileService } from 'services/api/students/uploadStudentFile';
import { UploadStudentFileCreators } from 'store/ducks/students/UploadFiles';
import { StepNavigationCreators } from 'store/ducks/stepNavigation';
import { UploadingFilesCreators } from 'store/ducks/uploadingFiles';
import { RootState } from 'store';
import {
  mapToCreateUploadStudentFile,
  mapToUpdateUploadStudentFile,
  mapUpdateUploadStudentFile,
} from 'services/api/students/uploadStudentFile/mapper';
import { FetchUploadStudentFilesResponse } from 'services/api/students/uploadStudentFile/models';

export const getUserInfoId = ({ auth }: RootState) => auth.userPersonalInfo?.id;

function* createUploadStudentFile(
  action: ReturnType<
    typeof UploadStudentFileCreators.createUploadStudentFile.request
  >,
): Generator<unknown, void, Identifier & number> {
  try {
    const id: number = yield select(getUserInfoId);

    const data = action.payload;
    yield put(
      UploadingFilesCreators.setUploadingFilesRequest({
        status: 'uploading',
        type: data.type as Keys,
      }),
    );
    const response = yield UploadStudentFileService.createUploadStudentFile(
      id,
      mapToCreateUploadStudentFile(data),
    );

    yield put(
      UploadingFilesCreators.setUploadingFilesRequest({
        status: 'uploaded',
        type: data.type as Keys,
      }),
    );

    yield put(
      UploadStudentFileCreators.createUploadStudentFile.success(response),
    );

    yield put(UploadStudentFileCreators.fetchUploadStudentFile.request());

    // yield put(StepNavigationCreators.isCompleted(true));
  } catch (error) {
    const data = action.payload;
    yield put(
      UploadingFilesCreators.setUploadingFilesRequest({
        status: 'error',
        type: data.type as Keys,
      }),
    );
    const responseError = mapErrorToResponseError(error);
    toast.error(responseError.message);
    yield put(
      UploadStudentFileCreators.createUploadStudentFile.failure(
        responseError.message,
      ),
    );
  }
}

function* updateUploadStudentFile(
  action: ReturnType<
    typeof UploadStudentFileCreators.updateUploadStudentFile.request
  >,
): Generator<unknown, void, Identifier & number> {
  try {
    const id: number = yield select(getUserInfoId);

    const data = action.payload;

    const { attachmentsId } = data;

    yield UploadStudentFileService.updateUploadStudentFile(
      id,
      attachmentsId,
      mapToUpdateUploadStudentFile(data),
    );

    yield put(UploadStudentFileCreators.updateUploadStudentFile.success());
    yield put(UploadStudentFileCreators.fetchUploadStudentFile.request());

    // yield put(StepNavigationCreators.isCompleted(true));
  } catch (error) {
    const responseError = mapErrorToResponseError(error);
    toast.error(responseError.message);
    yield put(
      UploadStudentFileCreators.updateUploadStudentFile.failure(
        responseError.message,
      ),
    );
  }
}

function* deleteUploadStudentFile(
  action: ReturnType<
    typeof UploadStudentFileCreators.deleteUploadStudentFile.request
  >,
): Generator<unknown, void, Identifier & number> {
  try {
    const id: number = yield select(getUserInfoId);

    const data = action.payload;

    const { attachmentsId } = data;

    yield UploadStudentFileService.deleteUploadStudentFile(id, attachmentsId);

    yield put(
      UploadingFilesCreators.setUploadingFilesRequest({
        status: 'idle',
        type: data.type as unknown as Keys,
      }),
    );

    yield put(UploadStudentFileCreators.deleteUploadStudentFile.success());
    yield put(UploadStudentFileCreators.fetchUploadStudentFile.request());

    // yield put(StepNavigationCreators.isCompleted(true));
  } catch (error) {
    const responseError = mapErrorToResponseError(error);
    toast.error(responseError.message);
    yield put(
      UploadStudentFileCreators.deleteUploadStudentFile.failure(
        responseError.message,
      ),
    );
  }
}

function* fetchUploadStudentFile(): Generator<
  unknown,
  void,
  FetchUploadStudentFilesResponse & number
> {
  try {
    const id: number = yield select(getUserInfoId);

    const response = yield UploadStudentFileService.fetchUploadStudentFile(id);

    const types = response.records.map(item => item.type);

    for (const type of types) {
      yield put(
        UploadingFilesCreators.setUploadingFilesRequest({
          status: 'uploaded',
          type: type as Keys,
        }),
      );
    }

    yield put(
      UploadStudentFileCreators.fetchUploadStudentFile.success(
        mapUpdateUploadStudentFile(response) as FetchUploadStudentFilesResponse,
      ),
    );
  } catch (error) {
    const responseError = mapErrorToResponseError(error);
    yield put(
      UploadStudentFileCreators.fetchUploadStudentFile.failure(
        responseError.message,
      ),
    );
  }
}

export default all([
  takeLatest(
    UploadStudentFileCreators.createUploadStudentFile.request,
    createUploadStudentFile,
  ),
  takeLatest(
    UploadStudentFileCreators.updateUploadStudentFile.request,
    updateUploadStudentFile,
  ),
  takeLatest(
    UploadStudentFileCreators.fetchUploadStudentFile.request,
    fetchUploadStudentFile,
  ),
  takeLatest(
    UploadStudentFileCreators.deleteUploadStudentFile.request,
    deleteUploadStudentFile,
  ),
]);
