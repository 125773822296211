import { FollowUp } from 'store/ducks/followUp';
import { dateFormatted } from 'utils/dateUtils';

import TimelineItem from '../TimelineItem';
import { Container } from './styles';

interface TimelineProps {
  data: FollowUp[];
}

const Timeline = ({ data }: TimelineProps) => (
  <Container>
    {data.map((item, index) => (
      <TimelineItem
        title={item.action.name}
        date={dateFormatted(item.date)}
        description={item.action.descr}
        code={item.action.code}
        isActived={index === data.length - 1}
        isLastItem={index === data.length - 1}
      />
    ))}
  </Container>
);

export default Timeline;
