import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useRef,
  RefObject,
} from 'react';

import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';

import { RecaptchaService } from 'services/api/recaptcha';

export interface RecaptchaContextData {
  loading: boolean;
  recaptchaSucceed: boolean;
  verifyTokenOnChange: (token: string | null) => void;
  recaptchaRef: RefObject<ReCAPTCHA> | null;
}

const RecaptchaContext = createContext<RecaptchaContextData>(
  {} as RecaptchaContextData,
);

const RecaptchaProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [succeed, setSucceed] = useState(false);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const verifyTokenOnChange = useCallback(async (token: string | null) => {
    try {
      if (token) {
        setLoading(true);
        const response = await RecaptchaService.verifyHumanKey({
          humanKey: token ?? '',
        });
        setSucceed(response.success);
      }
    } catch {
      toast.error(
        'Não foi possível verificar sua conexão! Atualize a página e tente novamente',
      );
      recaptchaRef.current?.reset();
      setSucceed(false);
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <RecaptchaContext.Provider
      value={{
        loading,
        recaptchaSucceed: succeed,
        verifyTokenOnChange,
        recaptchaRef,
      }}
    >
      {children}
    </RecaptchaContext.Provider>
  );
};

function useRecaptcha(): RecaptchaContextData {
  const context = useContext(RecaptchaContext);

  if (!context) {
    throw new Error('useRecaptcha must be used within a RecaptchaProvider');
  }
  return context;
}

export { RecaptchaContext, RecaptchaProvider, useRecaptcha };
