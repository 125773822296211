import { IconFile } from 'assets/icons';
import { BodySemiBold } from 'components/StyledTypography/styles';

import { Container, Column, AttachmentContainer, CaptionLabel } from './styles';

interface DisplayAttachmentProps {
  type: string;
  fileName: string;
}

const DisplayAttachment = ({ type, fileName }: DisplayAttachmentProps) => (
  <Container>
    <AttachmentContainer>
      <IconFile />
    </AttachmentContainer>
    <Column>
      <BodySemiBold>{type}</BodySemiBold>
      <CaptionLabel>{fileName}</CaptionLabel>
    </Column>
  </Container>
);

export default DisplayAttachment;
