import produce, { Draft } from 'immer';
import { ActionType, createAsyncAction, Reducer } from 'typesafe-actions';

export enum Types {
  CREATE_TRANSFER_REQUEST = '@TRANSFER/CREATE_TRANSFER_REQUEST',
  CREATE_TRANSFER_SUCCESS = '@TRANSFER/CREATE_TRANSFER_SUCCESS',
  CREATE_TRANSFER_FAILURE = '@TRANSFER/CREATE_TRANSFER_FAILURE',
  FETCH_TRANSFER_REQUEST = '@TRANSFER/FETCH_TRANSFER_REQUEST',
  FETCH_TRANSFER_SUCCESS = '@TRANSFER/FETCH_TRANSFER_SUCCESS',
  FETCH_TRANSFER_FAILURE = '@TRANSFER/FETCH_TRANSFER_FAILURE',
}

export interface TransferState {
  loading: boolean;
  isCompleted: boolean;
  hasTransfer: boolean;
  hasStateTransfer: boolean;
}

const INITIAL_STATE: TransferState = {
  loading: false,
  isCompleted: false,
  hasTransfer: false,
  hasStateTransfer: false,
};

export const TransferCreators = {
  createTransfer: createAsyncAction(
    Types.CREATE_TRANSFER_REQUEST,
    Types.CREATE_TRANSFER_SUCCESS,
    Types.CREATE_TRANSFER_FAILURE,
  )<{ reason: string; to: string }, { id: number }, string>(),
  fetchTransfers: createAsyncAction(
    Types.FETCH_TRANSFER_REQUEST,
    Types.FETCH_TRANSFER_SUCCESS,
    Types.FETCH_TRANSFER_FAILURE,
  )<void, { hasTransfer: boolean; hasStateTransfer: boolean }, string>(),
};

export type ActionTypes = ActionType<typeof TransferCreators>;

const reducer: Reducer<TransferState, ActionTypes> = (
  state = INITIAL_STATE,
  action: ActionTypes,
) => {
  const { type } = action;

  return produce(state, (draft: Draft<TransferState>) => {
    switch (type) {
      case Types.FETCH_TRANSFER_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.FETCH_TRANSFER_SUCCESS: {
        draft.hasTransfer = action.payload.hasTransfer;
        draft.hasStateTransfer = action.payload.hasStateTransfer;
        draft.loading = false;
        break;
      }
      case Types.FETCH_TRANSFER_FAILURE: {
        draft.loading = false;
        break;
      }

      case Types.CREATE_TRANSFER_REQUEST: {
        draft.loading = true;
        draft.isCompleted = false;
        break;
      }
      case Types.CREATE_TRANSFER_SUCCESS: {
        draft.loading = false;
        draft.isCompleted = true;
        break;
      }
      case Types.CREATE_TRANSFER_FAILURE: {
        draft.loading = false;
        draft.isCompleted = false;
        break;
      }
      default:
    }
  });
};

export default reducer;
