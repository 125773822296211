import base from 'services/api/base';
import { ServiceEndpoints } from 'services/api/types';

import {
  CreateUploadStudentFileRequest,
  UpdateUploadStudentFileRequest,
  UpdateUploadStudentFileResponse,
  FetchUploadStudentFilesResponse,
  DeleteUploadStudentFilesResponse,
} from './models';

const Strings = {
  ERROR_CREATE_UPLOAD_STUDENT_FILE: 'Falha ao carregar arquivos do estudante',
};

export const UploadStudentFileEndpoints: ServiceEndpoints = {
  createUploadStudentFile: {
    path: 'students',
    errorMessages: {
      204: 'Arquivo do estudante salvo com sucesso',
      400: 'Erro ao salvar o arquivo. Formato incorreto',
      401: 'Você precisa estar logado para realizar esta ação',
      403: 'Você não tem permissão para realizar esta ação',
      default: Strings.ERROR_CREATE_UPLOAD_STUDENT_FILE,
    },
  },
};

async function createUploadStudentFile(
  id: number,
  data: CreateUploadStudentFileRequest,
): Promise<CreateUploadStudentFileRequest> {
  const endpoint = UploadStudentFileEndpoints.createUploadStudentFile;
  const response = await base.api.post<CreateUploadStudentFileRequest>(
    `${endpoint.path}/${id}/attachments`,
    data.file,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return response.data;
}

async function updateUploadStudentFile(
  id: number,
  attachmentsId: number,
  data: UpdateUploadStudentFileRequest,
): Promise<UpdateUploadStudentFileResponse> {
  const endpoint = UploadStudentFileEndpoints.createUploadStudentFile;
  const response = await base.api.patch<UpdateUploadStudentFileResponse>(
    `${endpoint.path}/${id}/attachments/${attachmentsId}`,
    data.file,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return response.data;
}

async function deleteUploadStudentFile(
  id: number,
  attachmentsId: number,
): Promise<DeleteUploadStudentFilesResponse> {
  const endpoint = UploadStudentFileEndpoints.createUploadStudentFile;
  const response = await base.api.delete<DeleteUploadStudentFilesResponse>(
    `${endpoint.path}/${id}/attachments/${attachmentsId}`,
  );

  return response.data;
}

async function fetchUploadStudentFile(
  id: number,
): Promise<FetchUploadStudentFilesResponse> {
  const endpoint = UploadStudentFileEndpoints.createUploadStudentFile;
  const response = await base.api.get<FetchUploadStudentFilesResponse>(
    `${endpoint.path}/${id}/attachments`,
    { params: { disablePagination: true } },
  );

  return response.data;
}

export const UploadStudentFileService = {
  createUploadStudentFile,
  updateUploadStudentFile,
  fetchUploadStudentFile,
  deleteUploadStudentFile,
};
