import styled from 'styled-components';

import { H3 } from 'components/StyledTypography/styles';

export const Container = styled.header`
  background: ${({ theme }) => theme.palette.primaryDark};
`;

export const Content = styled.div`
  max-width: ${({ theme }) => theme.breakpoints.xl}px;
  margin: 0 auto;

  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  img {
    z-index: 1;
  }
`;

export const Logo = styled.img`
  display: flex;
  height: 40px;
  z-index: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    height: 36px;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: none;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 56px;
`;

export const EmptyContent = styled.div`
  flex: 1;
  display: flex;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: none;
  }
`;

export const Title = styled(H3)`
  color: ${({ theme }) => theme.palette.white};
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
`;

export const MenuItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16.8px;
    line-height: 20px;
    color: ${({ theme }) => theme.palette.white};
    margin-right: 10px;
  }

  img {
    width: 12px;
  }
`;

export const HeaderRight = styled.div`
  z-index: 2;
`;
