import { isValid } from 'date-fns';

import { Option, OptionValuesTypes } from 'components/Select';

export function unwrapOptionValue<T extends OptionValuesTypes>(
  option: Option,
): T {
  return option && (option.value as T);
}

export function unwrapOptionLabel<T extends OptionValuesTypes>(
  option: Option,
): T {
  return option && (option.label as T);
}

export function wrapOptionValue(
  option: Option[],
  value: OptionValuesTypes,
): Option {
  return (
    option.find(item => JSON.stringify(item.value) === JSON.stringify(value)) ||
    ({} as Option)
  );
}

export function unwrapMultiOptionValue<T extends OptionValuesTypes>(
  option: Option[],
): T[] {
  return option && (option.map(item => item.value) as T[]);
}

export function unwrapDate(date: string): string | undefined {
  return isValid(new Date(date)) ? new Date(date).toString() : undefined;
}
