import produce, { Draft } from 'immer';
import { Reducer } from 'redux';
import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';

import { Identifier } from 'models/identifier';
import { UserPersonalInfo, UserRegisterFormData } from 'models/user';
import {
  SignInRequestData,
  SignInResponseData,
} from 'services/api/auth/models';

export enum Types {
  SIGN_IN_REQUEST = '@auth/SIGN_IN_REQUEST',
  SIGN_IN_SUCCESS = '@auth/SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE = '@auth/SIGN_IN_FAILURE',
  SIGN_UP_REQUEST = '@auth/SIGN_UP_REQUEST',
  SIGN_UP_SUCCESS = '@auth/SIGN_UP_SUCCESS',
  SIGN_UP_FAILURE = '@auth/SIGN_UP_FAILURE',
  USER_REQUEST = '@auth/USER_REQUEST',
  USER_SUCCESS = '@auth/USER_SUCCESS',
  USER_FAILURE = '@auth/USER_FAILURE',
  USER_RESEND_EMAIL_REQUEST = '@auth/USER_RESEND_EMAIL_REQUEST',
  USER_RESEND_EMAIL_SUCCESS = '@auth/USER_RESEND_EMAIL_SUCCESS',
  USER_RESEND_EMAIL_FAILURE = '@auth/USER_RESEND_EMAIL_FAILURE',
  SIGN_OUT = '@auth/SIGN_OUT',
  SET_AUTH_INFO = '@auth/SET_AUTH_INFO',
  SET_PERSONAL_INFO = '@auth/SET_PERSONAL_INFO',
}

export interface UserCredentials extends Omit<SignInRequestData, 'login'> {
  email: string;
}

export type UserAuthInfo = Omit<SignInResponseData, 'user'>;

export interface AuthState {
  loading: boolean;
  userAuthInfo?: UserAuthInfo;
  userPersonalInfo?: UserPersonalInfo;
}

const INITIAL_STATE: AuthState = {
  loading: false,
  userAuthInfo: undefined,
  userPersonalInfo: undefined,
};

export const AuthCreators = {
  setUseAuthInfo: createAction(Types.SET_AUTH_INFO)<UserAuthInfo>(),
  setPersonalInfo: createAction(Types.SET_PERSONAL_INFO)<UserPersonalInfo>(),
  signIn: createAsyncAction(
    Types.SIGN_IN_REQUEST,
    Types.SIGN_IN_SUCCESS,
    Types.SIGN_IN_FAILURE,
  )<UserCredentials, UserAuthInfo, string>(),
  signUp: createAsyncAction(
    Types.SIGN_UP_REQUEST,
    Types.SIGN_UP_SUCCESS,
    Types.SIGN_UP_FAILURE,
  )<UserRegisterFormData, Identifier, string>(),
  fetchUser: createAsyncAction(
    Types.USER_REQUEST,
    Types.USER_SUCCESS,
    Types.USER_FAILURE,
  )<void, UserPersonalInfo, string>(),
  signOut: createAction(Types.SIGN_OUT)<void>(),
  resendEmail: createAsyncAction(
    Types.USER_RESEND_EMAIL_REQUEST,
    Types.USER_RESEND_EMAIL_SUCCESS,
    Types.USER_RESEND_EMAIL_FAILURE,
  )<void, void, void>(),
};

export type ActionTypes = ActionType<typeof AuthCreators>;

const reducer: Reducer<AuthState, ActionTypes> = (
  state = INITIAL_STATE,
  action: ActionTypes,
) => {
  const { type, payload } = action;

  return produce(state, (draft: Draft<AuthState>) => {
    switch (type) {
      case Types.SIGN_OUT: {
        draft.loading = false;
        draft.userAuthInfo = undefined;
        draft.userPersonalInfo = undefined;
        break;
      }

      case Types.SET_PERSONAL_INFO: {
        draft.userPersonalInfo = payload as UserPersonalInfo;
        break;
      }

      case Types.SET_AUTH_INFO: {
        draft.userAuthInfo = payload as UserAuthInfo;
        break;
      }

      case Types.USER_REQUEST:
      case Types.SIGN_IN_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.SIGN_IN_SUCCESS: {
        draft.loading = false;
        draft.userAuthInfo = payload as UserAuthInfo;
        break;
      }

      case Types.USER_SUCCESS: {
        draft.loading = false;
        draft.userPersonalInfo = payload as UserPersonalInfo;
        break;
      }

      case Types.SIGN_UP_REQUEST: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { password, passwordConfirmation, ...userPersonalInfo } =
          payload as UserRegisterFormData;
        draft.loading = false;
        draft.userPersonalInfo = userPersonalInfo;
        break;
      }
      case Types.SIGN_UP_SUCCESS: {
        const userIdentifier = payload as Identifier;
        draft.loading = false;
        draft.userPersonalInfo = {
          ...draft.userPersonalInfo,
          ...userIdentifier,
        };

        break;
      }
      case Types.USER_FAILURE:
      case Types.SIGN_IN_FAILURE: {
        draft.loading = false;
        draft.userAuthInfo = undefined;
        draft.userPersonalInfo = undefined;
        break;
      }

      case Types.USER_RESEND_EMAIL_REQUEST: {
        draft.loading = true;
        break;
      }

      case Types.USER_RESEND_EMAIL_SUCCESS:
      case Types.USER_RESEND_EMAIL_FAILURE: {
        draft.loading = false;
        break;
      }

      default:
    }
  });
};

export default reducer;
