import React from 'react';

import BarLoaderSpinner from 'react-spinners/BarLoader';

import theme from 'styles/theme';

import { StyledContainer } from './styles';

interface BarLoaderProps {
  loading: boolean;
}

const BarLoader = ({ loading }: BarLoaderProps) => {
  return (
    <BarLoaderSpinner
      css={StyledContainer}
      color={theme.palette.primaryLight}
      loading={loading}
    />
  );
};

export default BarLoader;
