import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { IconMatricula, IconTurma, IconEscola } from 'assets/icons';
import {
  MatriculaIllustration,
  MinhaEscolaEnabled,
  MinhaTurmaIllustration,
} from 'assets/images';
import Banner from 'components/Banner';
import { Caption } from 'components/StyledTypography/styles';
import {
  CURRENT_ENROLLMENT_YEAR,
  LAST_ENROLLMENT_YEAR,
} from 'config/constants';
import { RootState } from 'store';
import { AuthCreators } from 'store/ducks/auth';
import { EnrollmentCreators } from 'store/ducks/enrollment';
import { FollowUpCreators } from 'store/ducks/followUp';
import { NewEnrollmentCreators } from 'store/ducks/newEnrollment';
import { DocumentsStudentCreators } from 'store/ducks/students/documentsStudent';
import { GeneralStudentCreators } from 'store/ducks/students/general';
import { ResidentialAreaCreators } from 'store/ducks/students/residentialArea';
import { StudentsCreators } from 'store/ducks/students/responsableData';
import { StudentSchoolCreators } from 'store/ducks/students/school';
import { UploadStudentFileCreators } from 'store/ducks/students/UploadFiles';
import { TransferCreators } from 'store/ducks/transfer';

import ModuleItem from './ModuleItem';
import { Container, Title, Body, ContentBanner } from './styles';

const ModuleSelection = () => {
  const [didTapResendLink, setDidTapResendLink] = useState(false);
  const navigate = useNavigate();

  const { userPersonalInfo, loading: loadingUserResendEmail } = useSelector(
    ({ auth }: RootState) => auth,
  );
  const { overview } = useSelector(({ enrollment }: RootState) => enrollment);

  const { loading: loadingGeneralInfo, studentFormData } = useSelector(
    ({ studentGeneralInfo }: RootState) => studentGeneralInfo,
  );
  const { school, loading: loadingCurrentSchool } = useSelector(
    ({ studentSchool }: RootState) => studentSchool,
  );
  const loadingResidentialArea = useSelector(
    ({ residentialArea }: RootState) => residentialArea.loading,
  );
  const loadingResponsableData = useSelector(
    ({ responsableData }: RootState) => responsableData.loading,
  );
  const loadingEnrollment = useSelector(
    ({ enrollment }: RootState) => enrollment.loading,
  );
  const loadingNewEnrollment = useSelector(
    ({ newEnrollment }: RootState) => newEnrollment.loading,
  );
  const loadingDocuments = useSelector(
    ({ documentsStudent }: RootState) => documentsStudent.loading,
  );
  const loadingAttachments = useSelector(
    ({ uploadStudentFile }: RootState) => uploadStudentFile.loading,
  );
  const loadingFollowUp = useSelector(
    ({ followUp }: RootState) => followUp.loading,
  );
  const followUpRecords = useSelector(
    ({ followUp }: RootState) => followUp.followUp,
  );
  const currentAction = useSelector(
    ({ dropdownHeader }: RootState) => dropdownHeader.currentAction,
  );
  const loadingTransfer = useSelector(
    ({ transfer }: RootState) => transfer.loading,
  );
  const hasTransfer = useSelector(
    ({ transfer }: RootState) => transfer.hasTransfer,
  );

  const isLoading =
    loadingResponsableData ||
    loadingAttachments ||
    loadingResidentialArea ||
    loadingEnrollment ||
    loadingDocuments ||
    loadingGeneralInfo ||
    loadingNewEnrollment ||
    loadingFollowUp ||
    loadingTransfer ||
    loadingUserResendEmail ||
    loadingCurrentSchool;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(EnrollmentCreators.fetchOverviewEnrollment.request());
    dispatch(GeneralStudentCreators.fetchGeneralStudent.request());
    dispatch(StudentsCreators.fetchResponsableData.request());
    dispatch(DocumentsStudentCreators.fetchDocumentsStudent.request());
    dispatch(ResidentialAreaCreators.fetchResidentialArea.request());
    dispatch(EnrollmentCreators.fetchEnrollment.request());
    dispatch(NewEnrollmentCreators.fetchNewEnrollment.request());
    dispatch(UploadStudentFileCreators.fetchUploadStudentFile.request());
    dispatch(FollowUpCreators.getFollowUp.request());
    dispatch(TransferCreators.fetchTransfers.request());
    dispatch(StudentSchoolCreators.getStudentSchool.request());
  }, [dispatch]);

  function onTapEnrollment() {
    const hasLastYearEnroll = overview.find(
      element => element.year === LAST_ENROLLMENT_YEAR,
    );
    const hasCurrentYearEnroll = overview.find(
      element => element.year === CURRENT_ENROLLMENT_YEAR,
    );

    if (hasLastYearEnroll && !hasCurrentYearEnroll && !hasTransfer) {
      navigate('reviewData');
    } else {
      navigate('signinTypes');
    }
  }

  const onTapResendEmail = () => {
    if (didTapResendLink) {
      toast.warn('E-mail de verificação já enviado');
      return;
    }

    dispatch(AuthCreators.resendEmail.request());
    setDidTapResendLink(true);
  };

  const disabled = isLoading;

  return (
    <Container>
      <Title>Escolha um módulo para continuar</Title>
      {userPersonalInfo?.emailVerified === false && (
        <ContentBanner>
          <Banner
            message={`ATENÇÃO: Enviamos um e-mail de validação para o e-mail ${
              userPersonalInfo?.email ?? ''
            }. Clique no link recebido para confirmar sua conta.`}
            variant="warning"
          />
          <Caption>
            Não recebeu o e-mail?{' '}
            <button onClick={onTapResendEmail} type="button">
              <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                Clique aqui
              </span>
            </button>{' '}
            para reenviar.
          </Caption>
        </ContentBanner>
      )}
      <Body>
        <ModuleItem
          imagePath={MatriculaIllustration}
          icon={IconMatricula}
          title="Matrículas"
          description="Renove ou realize uma nova matrícula e acompanhe o andamento do processo."
          buttonTitle="Acessar"
          buttonAction={() => onTapEnrollment()}
          disabled={isLoading || userPersonalInfo?.emailVerified === false}
        />
        <ModuleItem
          imagePath={MinhaEscolaEnabled}
          icon={IconEscola}
          title="Minha escola"
          description="Veja informações gerais, programas e projetos de sua escola."
          buttonTitle="Acessar"
          buttonAction={() => navigate('mySchool')}
          disabled={disabled || !school}
        />
        <ModuleItem
          imagePath={MinhaTurmaIllustration}
          icon={IconTurma}
          title="Minha turma"
          description="Consulte seu quadro de horários, frequência e desempenho acadêmico."
          buttonTitle="Em breve"
          buttonAction={() => false}
          disabled
        />
      </Body>
    </Container>
  );
};

export default ModuleSelection;
