import { toast } from 'react-toastify';
import { all, takeLatest, put, select } from 'redux-saga/effects';

import { Option } from 'components/Select';
import { EnrollmentFormData, TeachingTypeFormData } from 'models/enrollment';
import { StudentFormData } from 'models/student';
import { mapEnrollmentFormToEnrollmentRequest } from 'services/api/enrollment/mapper';
import { mapErrorToResponseError } from 'services/api/responseErrorHandler';
import { SchoolSEEService } from 'services/api/schoolSEE';
import {
  mapSchoolFiltersResponseToSchoolFilters,
  mapSchoolsResponseToSchoolOption,
} from 'services/api/schoolSEE/mapper';
import {
  SchoolFiltersResponse,
  FetchSchoolsResponse,
} from 'services/api/schoolSEE/models';
import { RootState } from 'store';
import { SchoolFiltersCreators } from 'store/ducks/schoolFilters';
import { getGeneralStudentFormData } from 'store/ducks/students/general';
import { unwrapOptionValue } from 'utils/wrapperUtils';

export const getFilterOptions = ({ enrollment }: RootState) =>
  enrollment.educationTypeForm;

export const getStudentSaberCode = ({ studentGeneralInfo }: RootState) =>
  studentGeneralInfo.studentFormData.code;

function* fetchFilters(): Generator<unknown, void, SchoolFiltersResponse> {
  try {
    const filters = yield SchoolSEEService.fetchSchoolFilters();
    const filtersOptions = mapSchoolFiltersResponseToSchoolFilters(filters);
    yield put(SchoolFiltersCreators.getSchoolFilters.success(filtersOptions));
  } catch (error) {
    const responseError = mapErrorToResponseError(error);
    toast.error(responseError.message);
    yield put(
      SchoolFiltersCreators.getSchoolFilters.failure(responseError.message),
    );
  }
}

function* fetchSchoolsSEE(): Generator<
  unknown,
  void,
  TeachingTypeFormData &
    StudentFormData &
    FetchSchoolsResponse &
    Option[] &
    string
> {
  try {
    const filters: TeachingTypeFormData = yield select(getFilterOptions);
    const saberId: string = yield select(getStudentSaberCode);
    const { pplOption } = yield select(getGeneralStudentFormData);
    const {
      mediation,
      step,
      modality,
      educationOption,
      // regime,
      shift,
      temporality,
    } = mapEnrollmentFormToEnrollmentRequest(filters as EnrollmentFormData);

    const schools = yield SchoolSEEService.fetchSchools({
      mediation,
      step,
      modality,
      educationOption,
      // regime,
      shift,
      temporality,
      ppl: unwrapOptionValue(pplOption),
      // saberId,
      disableFilters: false,
    });
    const schoolsOptions = mapSchoolsResponseToSchoolOption(schools);
    yield put(SchoolFiltersCreators.getSchoolsSEE.success(schoolsOptions));
  } catch (error) {
    const responseError = mapErrorToResponseError(error);
    toast.error(responseError.message);
    yield put(
      SchoolFiltersCreators.getSchoolsSEE.failure(responseError.message),
    );
  }
}

function* fetchAllSchoolsSEE(): Generator<
  unknown,
  void,
  FetchSchoolsResponse & Option[]
> {
  try {
    const schools = yield SchoolSEEService.fetchSchools({
      disableFilters: true,
    });
    const schoolsOptions = mapSchoolsResponseToSchoolOption(schools);
    yield put(SchoolFiltersCreators.getAllSchools.success(schoolsOptions));
  } catch (error) {
    const responseError = mapErrorToResponseError(error);
    toast.error(responseError.message);
    yield put(
      SchoolFiltersCreators.getAllSchools.failure(responseError.message),
    );
  }
}

export default all([
  takeLatest(SchoolFiltersCreators.getSchoolFilters.request, fetchFilters),
  takeLatest(SchoolFiltersCreators.getSchoolsSEE.request, fetchSchoolsSEE),
  takeLatest(SchoolFiltersCreators.getAllSchools.request, fetchAllSchoolsSEE),
]);
