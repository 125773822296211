/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  IconAnnex,
  IconClose,
  IconFile,
  IconUploaded,
  IconWarning,
} from 'assets/icons';
import { Status } from 'models/student';
import { RootState } from 'store';
import { UploadStudentFileCreators } from 'store/ducks/students/UploadFiles';

import {
  ButtonContainer,
  Container,
  ProgressBar,
  TitleGroup,
  Typhography,
  ContainerIcon,
  UploadButton,
} from './styles';

interface Document {
  title: string;
  isLoading: boolean;
  status: 'error' | 'uploaded' | 'uploading';
}
interface AttachmentCardProps {
  onChange?: () => void;
  style?: any;
  onClick?: () => void;
  name: string;
  status?: 'error' | 'uploaded' | 'uploading' | 'idle';
  nameFile?: string;
  id?: number;
  obligatory?: boolean;

  type:
    | 'CPF'
    | 'PHOTO'
    | 'SCHOOL_RECORDS'
    | 'BIRTH_CERTIFICATE'
    | 'SCHOLING_STATEMENT'
    | 'ADDRESS'
    | 'SUS'
    | 'VACCINATION_CARD'
    | 'SOCIALNAME'
    | 'ELECTORAL_DISCHARGE'
    | 'MILITARY_DISCHARGE';
}

const AttachmentCard: React.FC<AttachmentCardProps> = ({
  onChange,
  type,
  name,
  nameFile,
  status,
  style,
  id,
  obligatory,
  onClick,
}) => {
  const [state, setState] = useState();
  const [statusError, setStatusError] = useState(status === 'error' && true);
  const { uploadStudentFile } = useSelector((store: RootState) => store);
  const { records } = uploadStudentFile.studentsFiles;

  const [isLoading, setIsLoading] = useState(id !== undefined);
  const dispatch = useDispatch();
  const { loading } = uploadStudentFile;

  useEffect(() => {
    setStatusError(statusError);
  }, [statusError]);

  const handleUploadFile = (data: any) => {
    if (!data) return;

    const newFile = new FormData();
    newFile.append('file', data[0]);
    newFile.append('type', type);

    if ((status === 'uploaded' || status === 'error') && id) {
      dispatch(
        UploadStudentFileCreators.updateUploadStudentFile.request({
          file: newFile,
          attachmentsId: id,
        }),
      );
    } else {
      dispatch(
        UploadStudentFileCreators.createUploadStudentFile.request({
          file: newFile,
          type,
        }),
      );
    }
  };

  const showProgressBar = () => {
    if (isLoading || status === 'uploaded') {
      return true;
    }
    return false;
  };

  const handleChangeStatusError = () => {
    setStatusError(prevState => !prevState);
  };

  return (
    <Container style={style}>
      <TitleGroup>
        <ContainerIcon>
          <IconFile />
        </ContainerIcon>
        <div className="content">
          <Typhography>
            <div className="title">
              {name}
              {status === 'uploaded' ? (
                <IconUploaded />
              ) : (
                !obligatory && <IconWarning />
              )}
            </div>
            {status === 'error' && (
              <div className="message">
                <div className="title">{statusError && 'Erro ao carregar'}</div>
                {statusError && (
                  <IconClose
                    onClick={() => {
                      if (statusError) {
                        handleChangeStatusError();
                        return;
                      }
                      setIsLoading(!isLoading);
                      onClick?.();
                    }}
                  />
                )}
              </div>
            )}
            {showProgressBar() && (
              <div className="message">
                <div className="title">
                  {statusError
                    ? 'Erro ao carregar'
                    : status === 'uploaded'
                    ? nameFile
                    : 'Carregando...'}
                </div>
                <IconClose
                  onClick={() => {
                    if (statusError) {
                      handleChangeStatusError();
                      return;
                    }
                    setIsLoading(!isLoading);
                    onClick?.();
                  }}
                />
              </div>
            )}
          </Typhography>
          {statusError && status !== 'uploaded' && (
            <div className="progressbar">
              <ProgressBar status="error" />
            </div>
          )}
          {isLoading && (
            <div className="progressbar">
              {isLoading && <ProgressBar status={status as Status} />}
            </div>
          )}
        </div>
      </TitleGroup>
      {!showProgressBar() && !statusError && !isLoading && (
        <ButtonContainer>
          <UploadButton className="upload-button">
            <label htmlFor={type}>
              <IconAnnex />
              Escolher arquivo
            </label>
            <input
              type="file"
              name="file"
              id={type}
              onChange={e => handleUploadFile(e.target?.files)}
              accept="application/pdf"
            />
          </UploadButton>
        </ButtonContainer>
      )}
    </Container>
  );
};

export default AttachmentCard;
