import produce, { Draft } from 'immer';
import {
  ActionType,
  createAsyncAction,
  createAction,
  Reducer,
} from 'typesafe-actions';

export enum Types {
  FETCH_SABER_STUDENT_REQUEST = '@saber/FETCH_SABER_STUDENT_REQUEST',
  FETCH_SABER_STUDENT_SUCCESS = '@saber/FETCH_SABER_STUDENT_SUCCESS',
  FETCH_SABER_STUDENT_FAILURE = '@saber/FETCH_SABER_STUDENT_FAILURE',
  SET_ERROR_MESSAGE = '@saber/SET_ERROR_MESSAGE',
  SET_MODAL_OPEN = '@saber/SET_MODAL_OPEN',
}

export interface SaberState {
  modalOpen: boolean;
  loading: boolean;
  studentName?: string;
  errorMessage?: string;
}

const INITIAL_STATE: SaberState = {
  modalOpen: false,
  loading: false,
  errorMessage: undefined,
  studentName: undefined,
};

interface GetStudentRequest {
  code: string;
}

interface GetStudentResponse {
  name: string;
}

export const SaberCreators = {
  getStudent: createAsyncAction(
    Types.FETCH_SABER_STUDENT_REQUEST,
    Types.FETCH_SABER_STUDENT_SUCCESS,
    Types.FETCH_SABER_STUDENT_FAILURE,
  )<GetStudentRequest, GetStudentResponse, string>(),
  setErrorMessage: createAction(Types.SET_ERROR_MESSAGE)<string>(),
  setModalOpen: createAction(Types.SET_MODAL_OPEN)<boolean>(),
};

export type ActionTypes = ActionType<typeof SaberCreators>;

const reducer: Reducer<SaberState, ActionTypes> = (
  state = INITIAL_STATE,
  action: ActionTypes,
) => {
  const { type, payload } = action;

  return produce(state, (draft: Draft<SaberState>) => {
    switch (type) {
      case Types.FETCH_SABER_STUDENT_REQUEST: {
        draft.loading = true;
        draft.errorMessage = undefined;
        draft.studentName = undefined;
        break;
      }
      case Types.FETCH_SABER_STUDENT_SUCCESS: {
        draft.loading = false;
        draft.studentName = (payload as GetStudentResponse)?.name;
        draft.errorMessage = undefined;
        draft.modalOpen = payload as boolean;
        break;
      }
      case Types.FETCH_SABER_STUDENT_FAILURE: {
        draft.loading = false;
        draft.errorMessage = payload as string;
        draft.studentName = undefined;
        break;
      }
      case Types.SET_ERROR_MESSAGE: {
        draft.errorMessage = payload as string;
        break;
      }
      case Types.SET_MODAL_OPEN: {
        draft.modalOpen = payload as boolean;
        break;
      }

      default:
    }
  });
};

export default reducer;
