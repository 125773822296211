import theme from 'styles/theme';

export const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(23, 16, 26, 0.5)',
  },
  content: {
    border: 0,
    borderRadius: 0,
    overflow: 'hidden',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 340,
    padding: 0,
    backgroundColor: 'transparent',
  },
};
