import styled from 'styled-components';

import { BodySemiBold, H2 } from 'components/StyledTypography/styles';

export const Vacination = styled(BodySemiBold)`
  width: 100%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.black};
  margin-top: 24px;
`;

export const FormVacination = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
  margin-top: 24px;
`;

export const Option = styled.div`
  display: flex;
  width: 100%;
  height: 32px;
  background: rgba(51, 171, 255, 0.1);
  opacity: 0.9;
  border-radius: 3px;
  justify-content: space-around;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary};
`;

export const Declaration = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 27px;
  margin-bottom: 27px;
`;

export const ContentFormVacination = styled.div`
  display: flex;
  gap: 20px;
`;

export const CheckboxContainer = styled.div`
  margin-top: 16px;
`;

export const Title = styled(H2)``;
