import { toast } from 'react-toastify';
import { all, takeLatest, put, select } from 'redux-saga/effects';

import { Option } from 'components/Select';
import { CURRENT_ENROLLMENT_YEAR } from 'config/constants';
import {
  EnrollmentData,
  EnrollmentFormData,
  EnrollmentOverview,
} from 'models/enrollment';
import { Identifier } from 'models/identifier';
import { EducationService } from 'services/api/education';
import { EnrollmentService } from 'services/api/enrollment';
import {
  mapEnrollmentResponseToEnrollmentForm,
  mapEnrollmentFormToEnrollmentRequest,
} from 'services/api/enrollment/mapper';
import { FetchEnrollmentResponseData } from 'services/api/enrollment/models';
import { mapErrorToResponseError } from 'services/api/responseErrorHandler';
import { SchoolSEEService } from 'services/api/schoolSEE';
import {
  mapSchoolCoursesResponseToCourseIdOptions,
  mapSchoolFiltersResponseToSchoolFilters,
  mapSchoolResponseToSchoolOption,
} from 'services/api/schoolSEE/mapper';
import {
  FetchSchoolCoursesResponse,
  FetchSchoolResponse,
  SchoolFiltersResponse,
} from 'services/api/schoolSEE/models';
import { TransferService } from 'services/api/transfer';
import { GetTransferResponseData } from 'services/api/transfer/models';
import { RootState } from 'store';
import { EnrollmentCreators } from 'store/ducks/enrollment';
import {
  SchoolFilters,
  SchoolFiltersCreators,
} from 'store/ducks/schoolFilters';
import { StepNavigationCreators } from 'store/ducks/stepNavigation';

export const getUserInfoId = ({ auth }: RootState) => auth.userPersonalInfo?.id;

function* createEnrollment(
  action: ReturnType<typeof EnrollmentCreators.createEnrollment.request>,
): Generator<unknown, void, EnrollmentFormData & number> {
  try {
    const id: number = yield select(getUserInfoId);

    yield EnrollmentService.createEnrollment(
      id,
      mapEnrollmentFormToEnrollmentRequest(action.payload),
    );
    yield put(EnrollmentCreators.createEnrollment.success(action.payload));
    yield put(StepNavigationCreators.isCompleted(true));
  } catch (error) {
    const responseError = mapErrorToResponseError(error);
    toast.error(responseError.message);
    yield put(
      EnrollmentCreators.createEnrollment.failure(responseError.message),
    );
  }
}

function* fetchEnrollment(): Generator<
  unknown,
  void,
  GetTransferResponseData &
    FetchEnrollmentResponseData[] &
    number &
    SchoolFilters &
    SchoolFiltersResponse &
    FetchSchoolResponse &
    FetchSchoolCoursesResponse &
    EnrollmentFormData
> {
  try {
    const id: number = yield select(getUserInfoId);

    const enrollments = yield EnrollmentService.fetchEnrollments(id);
    enrollments.sort(
      (enrollA, enrollB) => Number(enrollA.year) - Number(enrollB.year),
    );

    const response = enrollments.pop();

    if (response === undefined) {
      throw Error('');
    }

    const transfer = yield TransferService.fetchTransfers();

    const hasStateTransfer =
      transfer.records.filter(element => element.to === 'STATE').length !== 0;

    if (hasStateTransfer && response.year !== CURRENT_ENROLLMENT_YEAR) {
      throw Error();
    }

    const filters = yield SchoolSEEService.fetchSchoolFilters();
    const filtersOptions = mapSchoolFiltersResponseToSchoolFilters(filters);
    yield put(SchoolFiltersCreators.getSchoolFilters.success(filters));

    const school = yield SchoolSEEService.fetchSchool({
      id: response.schoolId,
    });
    const schoolMapped = mapSchoolResponseToSchoolOption(school);

    const coursesResponse = yield SchoolSEEService.fetchSchoolCourses({
      id: response.schoolId,
    });
    const coursesIdOption =
      mapSchoolCoursesResponseToCourseIdOptions(coursesResponse);
    const courseIdOption = coursesIdOption.find(
      c => c.value === response.courseId,
    );

    const enrollmentData = mapEnrollmentResponseToEnrollmentForm(
      response,
      filtersOptions,
      schoolMapped,
      courseIdOption,
    ) as EnrollmentData;
    yield put(EnrollmentCreators.fetchEnrollment.success(enrollmentData));
  } catch (error) {
    const responseError = mapErrorToResponseError(error);
    yield put(
      EnrollmentCreators.fetchEnrollment.failure(responseError.message),
    );
  }
}

function* updateEnrollment(
  action: ReturnType<typeof EnrollmentCreators.updateEnrollment.request>,
): Generator<unknown, void, EnrollmentFormData & number> {
  try {
    const id: number = yield select(getUserInfoId);

    yield EnrollmentService.updateEnrollment(
      id,
      mapEnrollmentFormToEnrollmentRequest(action.payload),
    );
    yield put(EnrollmentCreators.updateEnrollment.success(action.payload));
    yield put(StepNavigationCreators.isCompleted(true));
  } catch (error) {
    const responseError = mapErrorToResponseError(error);
    toast.error(responseError.message);
    yield put(
      EnrollmentCreators.updateEnrollment.failure(responseError.message),
    );
  }
}

function* repeatEnrollment(
  action: ReturnType<typeof EnrollmentCreators.repeatEnrollment.request>,
): Generator<unknown, void, EnrollmentFormData & number> {
  try {
    const id: number = yield select(getUserInfoId);

    yield EnrollmentService.repeatEnrollment(id);
    yield put(EnrollmentCreators.repeatEnrollment.success());
  } catch (error) {
    const responseError = mapErrorToResponseError(error);
    toast.error(responseError.message);
    yield put(
      EnrollmentCreators.repeatEnrollment.failure(responseError.message),
    );
  }
}

function* fetchOverviewEnrollment(
  action: ReturnType<typeof EnrollmentCreators.fetchOverviewEnrollment.request>,
): Generator<unknown, void, EnrollmentOverview[] & number> {
  try {
    const id: number = yield select(getUserInfoId);

    const data = yield EnrollmentService.fetchOverviewEnrollment(id);

    data.sort(
      (overviewA, overviewB) => Number(overviewA.year) - Number(overviewB.year),
    );

    yield put(EnrollmentCreators.fetchOverviewEnrollment.success(data));
  } catch (error) {
    const responseError = mapErrorToResponseError(error);
    // toast.error(responseError.message);
    yield put(
      EnrollmentCreators.fetchOverviewEnrollment.failure(responseError.message),
    );
  }
}

export default all([
  takeLatest(EnrollmentCreators.fetchEnrollment.request, fetchEnrollment),
  takeLatest(EnrollmentCreators.createEnrollment.request, createEnrollment),
  takeLatest(EnrollmentCreators.updateEnrollment.request, updateEnrollment),
  takeLatest(EnrollmentCreators.repeatEnrollment.request, repeatEnrollment),
  takeLatest(
    EnrollmentCreators.fetchOverviewEnrollment.request,
    fetchOverviewEnrollment,
  ),
]);
