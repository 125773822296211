import { toast } from 'react-toastify';
import { all, put, select, takeLatest } from 'redux-saga/effects';

import { Identifier } from 'models/identifier';
import { DocumentsStudentData } from 'models/student';
import { mapErrorToResponseError } from 'services/api/responseErrorHandler';
import { DocumentsStudentService } from 'services/api/students/documentsStudent';
import {
  mapToCreateDocumentStudentRequest,
  mapToDocumentsStudentDataToDocumentsStudentForm,
} from 'services/api/students/documentsStudent/mapper';
import { FetchDocumentsStudentRequestData } from 'services/api/students/documentsStudent/models';
import { RootState } from 'store';
import { StepNavigationCreators } from 'store/ducks/stepNavigation';
import { DocumentsStudentCreators } from 'store/ducks/students/documentsStudent';

export const getUserInfoId = ({ auth }: RootState) => auth.userPersonalInfo?.id;
export const getUserBirthDate = ({ auth }: RootState) =>
  auth.userPersonalInfo?.birthdate;

function* createDocumentsStudent(
  action: ReturnType<
    typeof DocumentsStudentCreators.createDocumentsStudent.request
  >,
): Generator<unknown, void, number & string & DocumentsStudentData> {
  try {
    const id: number = yield select(getUserInfoId);
    const birthdate: string = yield select(getUserBirthDate);

    yield DocumentsStudentService.createDocumentsStudent(
      id,
      mapToCreateDocumentStudentRequest({ ...action.payload, birthdate }),
    );
    yield put(
      DocumentsStudentCreators.createDocumentsStudent.success(action.payload),
    );

    yield put(StepNavigationCreators.isCompleted(true));
  } catch (error) {
    const responseError = mapErrorToResponseError(error);
    toast.error(responseError.message);
    yield put(
      DocumentsStudentCreators.createDocumentsStudent.failure(
        responseError.message,
      ),
    );
  }
}

function* fetchDocumentsStudent(): Generator<
  unknown,
  void,
  FetchDocumentsStudentRequestData & number
> {
  try {
    const id: number = yield select(getUserInfoId);

    const response = yield DocumentsStudentService.fetchDocumentsStudent(id);
    yield put(
      DocumentsStudentCreators.fetchDocumentsStudent.success(
        mapToDocumentsStudentDataToDocumentsStudentForm(
          response,
        ) as DocumentsStudentData,
      ),
    );
  } catch (error) {
    const responseError = mapErrorToResponseError(error);

    yield put(
      DocumentsStudentCreators.fetchDocumentsStudent.failure(
        responseError.message,
      ),
    );
  }
}

function* updateDocumentsStudent(
  action: ReturnType<
    typeof DocumentsStudentCreators.updateDocumentsStudent.request
  >,
): Generator<unknown, void, number & string & DocumentsStudentData> {
  try {
    const id: number = yield select(getUserInfoId);
    const birthdate: string = yield select(getUserBirthDate);

    yield DocumentsStudentService.updateDocumentsStudent(
      id,
      mapToCreateDocumentStudentRequest(action.payload),
    );
    yield put(
      DocumentsStudentCreators.updateDocumentsStudent.success({
        ...action.payload,
        birthdate,
      }),
    );

    yield put(StepNavigationCreators.isCompleted(true));
  } catch (error) {
    const responseError = mapErrorToResponseError(error);
    toast.error(responseError.message);
    yield put(
      DocumentsStudentCreators.updateDocumentsStudent.failure(
        responseError.message,
      ),
    );
  }
}

export default all([
  takeLatest(
    DocumentsStudentCreators.createDocumentsStudent.request,
    createDocumentsStudent,
  ),
  takeLatest(
    DocumentsStudentCreators.updateDocumentsStudent.request,
    updateDocumentsStudent,
  ),
  takeLatest(
    DocumentsStudentCreators.fetchDocumentsStudent.request,
    fetchDocumentsStudent,
  ),
]);
