import { useEffect, useMemo, useState } from 'react';

import { BsPatchCheckFill, BsXOctagonFill, BsListCheck } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { BarSpinner, Footer, Header } from 'components';
import { useAccountValidation } from 'hooks/AccountValidationContext';
import { Container as ContainerAuth } from 'pages/Auth/styles';
import { AuthCreators } from 'store/ducks/auth';
import { sleep } from 'utils/timeUtils';

import {
  Container,
  Content,
  Title,
  Information,
  ButtonsDiv,
  StyledLink,
} from './styles';

const messages = {
  success: {
    title: 'Conta Verificada',
    description: 'Sua conta foi verificada com sucesso.',
  },
  failure: {
    title: 'Validação Falhou',
    description:
      'Tente acessar o link novamente ou solicite o reenvio de um novo link fazendo Login',
  },
  loading: {
    title: 'Validando Conta',
    description:
      'Sua conta está sendo validada pelo nosso sistema. Aguarda alguns instantes.',
  },
};

const accountValidationContent = {
  success: (
    <>
      <BsPatchCheckFill size={50} color="#00CB51" />
      <Title>{messages.success.title}</Title>
      <Information>{messages.success.description}</Information>
    </>
  ),
  failure: (
    <>
      <BsXOctagonFill size={50} color="#FF4444" />
      <Title>{messages.failure.title}</Title>
      <Information>{messages.failure.description}</Information>
    </>
  ),
  loading: (
    <>
      <BsListCheck size={50} color="#022859" />
      <Title>{messages.loading.title}</Title>
      <Information>{messages.loading.description}</Information>
    </>
  ),
};

function AccountValidation() {
  const [isSucceed, setIsSucceed] = useState(true);
  const { token } = useParams();
  const { loading, accountVerify } = useAccountValidation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(AuthCreators.signOut());

    const callAccountVerify = async () => {
      await sleep(2000);
      const result = await accountVerify(token ?? '');
      setIsSucceed(result);
    };

    callAccountVerify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentAccountValidationContent = useMemo(() => {
    if (loading) {
      return accountValidationContent.loading;
    }

    return isSucceed
      ? accountValidationContent.success
      : accountValidationContent.failure;
  }, [isSucceed, loading]);

  return (
    <ContainerAuth>
      <BarSpinner loading={loading} />
      <Header menuItemVariant="none" title="Matrícula 2023 - Estudante" />
      <Container>
        <Content>
          {currentAccountValidationContent}
          <ButtonsDiv>
            <StyledLink to="/auth">Voltar para login</StyledLink>
          </ButtonsDiv>
        </Content>
      </Container>
      <Footer />
    </ContainerAuth>
  );
}

export default AccountValidation;
