import Select from 'react-select';
import styled, { css } from 'styled-components';

import { Caption, LabelRegular } from 'components/StyledTypography/styles';

interface SelectContainerProps {
  width: string;
  height: string;
}
interface ErrorProp {
  error: boolean;
}
export const SelectContainer = styled.div<SelectContainerProps>`
  width: ${props => props.width ?? '100%'};
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

export const Label = styled(Caption)`
  color: ${({ theme }) => theme.palette.black};
`;

export const StyledSelect = styled(Select)<ErrorProp>`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.2px;

  .Select__control {
    border: 1px solid ${props => props.theme.palette.grey1};
  }
  .Select__control:hover {
    border-color: ${props => props.theme.palette.primary};
  }
  .Select__control--is-focused {
    box-shadow: none;
    outline: none;
    border: 1px solid ${props => props.theme.palette.primaryDark};
  }
  .Select__indicator-separator {
    display: none;
  }
  .Select__menu {
    border: 1px solid ${props => props.theme.palette.primary};
    width: 100%;
  }
  .Select__option {
    width: 100%;
    :hover {
      background-color: ${props => props.theme.palette.grey3};
      border-radius: 4px;
      color: black;
    }
  }
  .Select__option--is-focused {
    background-color: ${props => props.theme.palette.grey3};
    border-radius: 4px;
    width: 100%;

    color: black;
  }
  .Select__option--is-selected {
    background-color: ${props => props.theme.palette.primaryDark};
    border-radius: 4px;

    color: white;
  }
  .Select__multi-value {
    max-width: 200px;
  }

  ${({ error }) =>
    error &&
    css`
      .Select__control {
        border: 1px solid ${props => props.theme.palette.negative};
        background-color: ${props => props.theme.palette.errorBackground};
      }
      .Select__indicator {
        color: ${props => props.theme.palette.negative};
      }
    `}
`;
export const Message = styled(LabelRegular)<ErrorProp>`
  color: ${props => props.theme.palette.grey1};
  ${({ error }) =>
    error &&
    css`
      color: ${props => props.theme.palette.negative};
    `}
`;
