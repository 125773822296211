import { useSelector } from 'react-redux';

import { IconArrowLeft } from 'assets/icons';
import { binaryOptions, transportTypeOptions } from 'assets/json';
import { Button } from 'components';
import { Caption } from 'components/StyledTypography/styles';
import {
  HeaderSection,
  DisplayField,
  ReviewRow,
} from 'pages/StudentEnrollment/Form/ReviewForm/components';
import { StepsButtonContainer } from 'pages/StudentEnrollment/styles';
import { ReEnrollmentStep } from 'pages/StudentReEnroll';
import { RootState } from 'store';
import { unwrapOptionLabel } from 'utils/wrapperUtils';

import { Container } from './styles';

interface ReviewForm {
  onBack: () => void;
  onNext: () => void;
  onGoToStep: (step: ReEnrollmentStep) => void;
  editable: boolean;
}

const ReviewForm = ({ onBack, onNext, onGoToStep, editable }: ReviewForm) => {
  const { educationTypeForm, educationDataForm } = useSelector(
    ({ enrollment }: RootState) => enrollment,
  );

  return (
    <Container>
      <Caption>Confira se os dados foram preenchidos corretamente</Caption>
      <HeaderSection
        title="Tipo de ensino"
        onEditAction={() => onGoToStep(ReEnrollmentStep.TEACHING_TYPE)}
        editable={editable}
      />
      <>
        <ReviewRow>
          <DisplayField
            question="Mediação"
            answer={unwrapOptionLabel(educationTypeForm.mediationOption)}
          />
          <DisplayField
            question="Etapa"
            answer={unwrapOptionLabel(educationTypeForm.stepOption)}
          />
        </ReviewRow>
        <ReviewRow>
          <DisplayField
            question="Modalidade"
            answer={unwrapOptionLabel(educationTypeForm.modalityOption)}
          />
          <DisplayField
            question="Modalidade Extra"
            answer={unwrapOptionLabel(educationTypeForm.modalitySpecialOption)}
          />
        </ReviewRow>
        <ReviewRow>
          <DisplayField
            question="Regime"
            answer={unwrapOptionLabel(educationTypeForm.regimeOption)}
          />
          <DisplayField
            question="Turno"
            answer={unwrapOptionLabel(educationTypeForm.shiftOption)}
          />
        </ReviewRow>
        <ReviewRow>
          <DisplayField
            question="Temporalidade"
            answer={unwrapOptionLabel(educationTypeForm.temporalityOption)}
          />
        </ReviewRow>
      </>

      <HeaderSection
        title="Dados Educacionais"
        onEditAction={() => onGoToStep(ReEnrollmentStep.EDUCATION_DATA)}
        editable={editable}
      />
      <>
        <ReviewRow>
          <DisplayField
            question="Escola atual"
            answer={unwrapOptionLabel(educationDataForm.school)}
          />
          {educationDataForm.courseOption && (
            <DisplayField
              question="Curso EPT"
              answer={unwrapOptionLabel(educationDataForm.courseOption)}
            />
          )}
        </ReviewRow>
      </>

      <HeaderSection
        title="Transporte"
        onEditAction={() => onGoToStep(ReEnrollmentStep.EDUCATION_DATA)}
        editable={editable}
      />
      <>
        <ReviewRow>
          <DisplayField
            question="Você utiliza transporte escolar público?"
            answer={unwrapOptionLabel(educationDataForm.publicTransport)}
          />
          <DisplayField
            question="Tipo de veículo utilizado"
            answer={unwrapOptionLabel(educationDataForm.transportType)}
          />
        </ReviewRow>
        <ReviewRow>
          <DisplayField
            question={
              unwrapOptionLabel(educationDataForm.transportType) ===
              transportTypeOptions[0].label
                ? 'Rodoviário'
                : 'Aquaviário'
            }
            answer={unwrapOptionLabel(educationDataForm.transportTypeDetail)}
          />
          <DisplayField
            question="Poder público responsável pelo transporte"
            answer={unwrapOptionLabel(
              educationDataForm.publicTransportResponsable,
            )}
          />
        </ReviewRow>
        {educationDataForm.siblingsToEnroll && (
          <ReviewRow>
            <DisplayField
              question="Possui irmãos a serem matriculados na mesma escola?"
              answer={unwrapOptionLabel(educationDataForm.siblingsToEnroll)}
            />
            {educationDataForm.siblingsToEnroll.value ===
              binaryOptions[0].value && (
              <DisplayField
                question="Nome completo do(a) irmão(ã)"
                answer={educationDataForm.siblings.join(', ')}
              />
            )}
          </ReviewRow>
        )}
      </>

      {editable && (
        <StepsButtonContainer>
          <Button
            iconLeft={IconArrowLeft}
            title="Anterior"
            type="button"
            styled="secondary"
            onClick={onBack}
            size="mini"
          />
          <Button
            type="button"
            size="mini"
            title="Finalizar"
            onClick={onNext}
          />
        </StepsButtonContainer>
      )}
    </Container>
  );
};

export default ReviewForm;
