import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CharacterImg } from 'assets/images';
import { Button } from 'components';
import { FollowUpCreators } from 'store/ducks/followUp';

import {
  Container,
  ContainerButtons,
  Content,
  Title,
  SubTitle,
} from './styles';

const StudentReEnrollmentConfirmation = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(FollowUpCreators.getFollowUp.request());
  }, [dispatch]);

  return (
    <Container>
      <Content>
        <img src={CharacterImg} alt="" />
        <Title>Cadastro Realizado com Sucesso!</Title>
        {/* TODO: Change for the next version [MA] */}
        {/* <SubTitle>Agora você já pode realizar a pré-matrícula.</SubTitle> */}
        <SubTitle>
          A próxima etapa da sua matrícula estará disponível em breve! Aguarde
          comunicação da SEECT no seu e-mail.
        </SubTitle>
        <ContainerButtons>
          <Button
            size="medium"
            styled="secondary"
            title="Página inicial"
            onClick={() => navigate('/home/profile')}
          />
          {/* TODO:  Change for the next version [MA] */}
          {/* <Button
            size="medium"
            title="Iniciar pré-matrícula"
            onClick={() => navigate('/home/studentReEnroll')}
          /> */}
        </ContainerButtons>
      </Content>
    </Container>
  );
};

export default StudentReEnrollmentConfirmation;
