import styled from 'styled-components';

export const Container = styled.div`
  max-width: 378px;
  min-width: 302px;
  border: 1px solid #f1edf0;
  border-radius: 8px;
  flex-grow: 1;
  text-color: #b4cd6b;
`;

interface IconProp {
  disabled: boolean;
}

export const Icon = styled.img<IconProp>`
  ${({ disabled }) =>
    disabled
      ? 'filter: invert(54%) sepia(0%) saturate(1%) hue-rotate(163deg) brightness(100%) contrast(92%)'
      : ''};
`;

interface TitleProp {
  disabled: boolean;
}

export const Title = styled.h1<TitleProp>`
  padding: 12px;
  padding: 9px;
  letter-spacing: 0.2px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;

  color: ${({ disabled }) => (disabled ? '#888888' : '#022757')};
`;

export const Head = styled.div`
  border-radius: 8px 8px 0px 0px;
  background: #ffffff;
  display: flex;
  width: 100%;
  justify-content: center;
`;

interface TableProp {
  disabled: boolean;
}

export const Table = styled.table<TableProp>`
  background: #ffffff;
  border-collapse: collapse;
  border: 1px solid #f1edf0;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  border: 0;
  padding: 16px;

  td {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 114%;

    letter-spacing: 0.2px;

    color: ${({ disabled }) => (disabled ? '#888888' : '#1c1413')};
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 100%;
  }

  tbody {
    border-top: 1px solid #f1edf0;
    margin-top: 32px;
    tr:nth-child(odd) {
      background-color: #f8f8f8;
    }
  }

  b {
    font-weight: 700;
  }
`;

interface DotProps {
  color: string;
  disabled: boolean;
}

export const ColorDot = styled.span<DotProps>`
  margin-right: 9px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  background-color: ${({ disabled, color }) => (disabled ? '#c1c1c1' : color)};
  filter: ${({ color }) => (color === '0' ? 'opacity(0%)' : 'opacity(100%)')};
`;
