import styled from 'styled-components';

import { ButtonLabel } from 'components/StyledTypography/styles';

interface MenuItemTextProps {
  isDark: boolean;
}

export const MenuItemText = styled(ButtonLabel)<MenuItemTextProps>`
  color: ${({ theme, isDark }) =>
    isDark ? theme.palette.darkBlue : theme.palette.white};
  margin-right: 24px;
  font-size: 24px;
`;

export const ContainerMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
