import { toast } from 'react-toastify';
import { all, takeLatest, put, select } from 'redux-saga/effects';

import { CURRENT_ENROLLMENT_YEAR } from 'config/constants';
import { mapErrorToResponseError } from 'services/api/responseErrorHandler';
import { TransferService } from 'services/api/transfer';
import { GetTransferResponseData } from 'services/api/transfer/models';
import { RootState } from 'store';
import { TransferCreators } from 'store/ducks/transfer';

export const getUserInfoId = ({ auth }: RootState) => auth.userPersonalInfo?.id;

function* createTransfer(
  action: ReturnType<typeof TransferCreators.createTransfer.request>,
): Generator<unknown, void, string & { id: number }> {
  try {
    const id: string = yield select(getUserInfoId);
    const response = yield TransferService.createTransfer({
      user: id,
      year: CURRENT_ENROLLMENT_YEAR,
      status: 'REQUESTED',
      to: action.payload.to,
      reason: action.payload.reason,
    });
    yield put(TransferCreators.createTransfer.success(response));
  } catch (error) {
    const responseError = mapErrorToResponseError(error);
    toast.error(responseError.message);
    yield put(TransferCreators.createTransfer.failure(responseError.message));
  }
}

function* fetchTransfers(): Generator<
  unknown,
  void,
  GetTransferResponseData & string
> {
  try {
    const response = yield TransferService.fetchTransfers();

    yield put(
      TransferCreators.fetchTransfers.success({
        hasTransfer: response.records.length !== 0,
        hasStateTransfer:
          response.records.filter(item => item.to === 'STATE').length !== 0,
      }),
    );
  } catch (error) {
    const responseError = mapErrorToResponseError(error);
    // toast.error(responseError.message);
    yield put(TransferCreators.fetchTransfers.failure(responseError.message));
  }
}

export default all([
  takeLatest(TransferCreators.createTransfer.request, createTransfer),
  takeLatest(TransferCreators.fetchTransfers.request, fetchTransfers),
]);
