import { isNil, omitBy } from 'lodash';

import {
  UploadStudentFiles,
  UploadStudentFilesUpdateRequest,
} from 'models/student';

import {
  CreateUploadStudentFileRequest,
  UpdateUploadStudentFileRequest,
  UpdateUploadStudentFileResponse,
  FetchUploadStudentFilesResponse,
} from './models';

export function mapToCreateUploadStudentFile(
  data: UploadStudentFiles,
): CreateUploadStudentFileRequest {
  return {
    file: data.file,
    type: data.type,
  };
}

export function mapToUpdateUploadStudentFile(
  data: UploadStudentFilesUpdateRequest,
): UpdateUploadStudentFileRequest {
  return {
    file: data.file,
  };
}

export function mapUpdateUploadStudentFile(
  requestData: FetchUploadStudentFilesResponse,
): Partial<UploadStudentFiles> {
  return omitBy({ ...requestData }, isNil);
}
