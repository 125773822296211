import styled from 'styled-components';

import { H1 } from 'components/StyledTypography/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 64px 20px 0px 20px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 20px;
  }
`;

export const Title = styled(H1)`
  color: ${({ theme }) => theme.palette.primary};
  text-align: center;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 112%;
`;

export const ContentBanner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 650px;
  gap: 8px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 20px;
  margin-top: 47px;
  max-width: ${({ theme }) => theme.breakpoints.xl}px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: column;
    margin-top: 20px;
  }
`;
