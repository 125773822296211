import { all, takeLatest, put, select } from 'redux-saga/effects';

import { mapErrorToResponseError } from 'services/api/responseErrorHandler';
import { StudentSchoolService } from 'services/api/students/school';
import { StudentSchoolResponse } from 'services/api/students/school/models';
import { RootState } from 'store';
import { StudentSchoolCreators } from 'store/ducks/students/school';

export const getUserInfoId = ({ auth }: RootState) => auth.userPersonalInfo?.id;

function* fetchStudentSchool(): Generator<
  unknown,
  void,
  string & StudentSchoolResponse
> {
  try {
    const id: string = yield select(getUserInfoId);
    const response = yield StudentSchoolService.fetchSchool({ id });
    yield put(StudentSchoolCreators.getStudentSchool.success(response));
  } catch (error) {
    if ((error as any)?.statusCode === 404) {
      yield put(
        StudentSchoolCreators.getStudentSchool.failure('Escola não encontrada'),
      );
      return;
    }

    const responseError = mapErrorToResponseError(error);
    yield put(
      StudentSchoolCreators.getStudentSchool.failure(responseError.message),
    );
  }
}

export default all([
  takeLatest(
    StudentSchoolCreators.getStudentSchool.request,
    fetchStudentSchool,
  ),
]);
