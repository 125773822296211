import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CharacterImg } from 'assets/images';
import { Button } from 'components';
import { FollowUpCreators } from 'store/ducks/followUp';

import {
  Container,
  ContainerButtons,
  Content,
  Title,
  SubTitle,
} from './styles';

const StudentEnrollmentConfirmation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(FollowUpCreators.getFollowUp.request());
  }, [dispatch]);

  return (
    <Container>
      <Content>
        <img src={CharacterImg} alt="" />
        <Title>Matrícula Realizada!</Title>
        <SubTitle>
          Agora você já pode acompanhar o andamento da matrícula
        </SubTitle>
        <ContainerButtons>
          <Button
            size="medium"
            styled="secondary"
            title="Página inicial"
            onClick={() => navigate('/home/profile')}
          />
          <Button
            size="medium"
            title="Acompanhamento"
            onClick={() => navigate('/home/followUp')}
          />
        </ContainerButtons>
      </Content>
    </Container>
  );
};

export default StudentEnrollmentConfirmation;
