import { CURRENT_ENROLLMENT_YEAR } from 'config/constants';
import base from 'services/api/base';

import { ServiceEndpoints } from '../types';
import { FollowUpGetRequestData, FollowUpGetResponseData } from './models';

const Strings = {
  DEFAULT_ERROR: 'Erro ao recuperar acompanhamento',
};

export const FollowUpEndpoints: ServiceEndpoints = {
  followUp: {
    path: 'followUp',
    errorMessages: {
      401: 'Não autorizado',
      403: 'Sem permissão para acessar',
      default: Strings.DEFAULT_ERROR,
    },
  },
};

async function fetchFollowUp(
  params: FollowUpGetRequestData,
): Promise<FollowUpGetResponseData[]> {
  const endpoint = FollowUpEndpoints.followUp;
  const response = await base.api.get(`${endpoint.path}/${params.id}`, {
    params: {
      disablePagination: true,
      enrollmentYear: CURRENT_ENROLLMENT_YEAR,
    },
  });
  return response.data;
}

export const FollowUpService = { fetchFollowUp };
