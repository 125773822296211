/* eslint-disable import/no-unresolved */
import { toast } from 'react-toastify';
import { all, put, takeLatest, select } from 'redux-saga/effects';

import { Identifier } from 'models/identifier';
import { ResponsablesData } from 'models/student';
import { mapErrorToResponseError } from 'services/api/responseErrorHandler';
import { ResponsableDataService } from 'services/api/students/responsableData';
import {
  mapResponsableDataResponseToResponsableDataForm,
  mapToCreateResponsableDataRequest,
} from 'services/api/students/responsableData/mapper';
import { FetchResponsableDataRequestData } from 'services/api/students/responsableData/models';
import { RootState, store } from 'store';
import { StepNavigationCreators } from 'store/ducks/stepNavigation';
import { StudentsCreators } from 'store/ducks/students/responsableData';

export const getUserInfoId = ({ auth }: RootState) => auth.userPersonalInfo?.id;

function* createResponsableData(
  action: ReturnType<typeof StudentsCreators.createResponsableData.request>,
): Generator<unknown, void, ResponsablesData & number> {
  try {
    const id: number = yield select(getUserInfoId);

    const data = action.payload;
    const response = yield ResponsableDataService.createResponsableData(
      id,
      mapToCreateResponsableDataRequest(data),
    );
    yield put(StudentsCreators.createResponsableData.success(data));

    yield put(StepNavigationCreators.isCompleted(true));
  } catch (error) {
    const responseError = mapErrorToResponseError(error);
    toast.error(responseError.message);
    yield put(
      StudentsCreators.createResponsableData.failure(responseError.message),
    );
  }
}

function* fetchResponsableData(): Generator<
  unknown,
  void,
  FetchResponsableDataRequestData & number
> {
  try {
    const id: number = yield select(getUserInfoId);

    const response = yield ResponsableDataService.fetchResponsableData(id);

    yield put(
      StudentsCreators.fetchResponsableData.success(
        mapResponsableDataResponseToResponsableDataForm(
          response,
        ) as ResponsablesData,
      ),
    );
  } catch (error) {
    const responseError = mapErrorToResponseError(error);
    yield put(
      StudentsCreators.fetchResponsableData.failure(responseError.message),
    );
  }
}

function* updateResponsableData(
  action: ReturnType<typeof StudentsCreators.updateResponsableData.request>,
): Generator<unknown, void, ResponsablesData & number> {
  try {
    const id: number = yield select(getUserInfoId);

    const data = action.payload;
    yield ResponsableDataService.updateResponsableData(
      id,
      mapToCreateResponsableDataRequest(data),
    );

    yield put(StudentsCreators.updateResponsableData.success(data));

    yield put(StepNavigationCreators.isCompleted(true));
  } catch (error) {
    const responseError = mapErrorToResponseError(error);
    toast.error(responseError.message);
    yield put(
      StudentsCreators.updateResponsableData.failure(responseError.message),
    );
  }
}

export default all([
  takeLatest(
    StudentsCreators.createResponsableData.request,
    createResponsableData,
  ),
  takeLatest(
    StudentsCreators.updateResponsableData.request,
    updateResponsableData,
  ),
  takeLatest(
    StudentsCreators.fetchResponsableData.request,
    fetchResponsableData,
  ),
]);
