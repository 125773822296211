import React from 'react';

import ReactModal from 'react-modal';

import { modalStyles } from './styles';

interface Props extends ReactModal.Props {
  zIndex?: number;
}

const Modal: React.FC<Props> = ({ children, zIndex = 0, ...rest }) => (
  <ReactModal
    style={{
      content: modalStyles.content,
      overlay: { ...modalStyles.overlay, zIndex },
    }}
    {...rest}
  >
    {children}
  </ReactModal>
);

export default Modal;
