import { ReactNode } from 'react';

import { BoyImg } from 'assets/images';

import {
  Container,
  Description,
  Logo,
  FlexBox,
  LastAct,
  Acompanhamento,
} from './styles';

interface CustomContainerProps {
  children: ReactNode;
}

const CustomContainer = ({ children }: CustomContainerProps) => (
  <Container>
    <FlexBox>
      <Logo src={BoyImg} alt="" />
      <Description>
        Aqui você poderá acompanhar as principais ações realizadas no seu
        processo de matrícula e a situação na qual se encontram.
      </Description>
    </FlexBox>
    <LastAct>Últimas atualizações</LastAct>
    <Acompanhamento>{children}</Acompanhamento>
  </Container>
);

export default CustomContainer;
