import React, { createContext, useState, useContext } from 'react';

import { Dialog } from '../components';

enum Strings {
  USE_DIALOG_ERROR = 'useDialogConfirmation must be used within a DialogProvider',
  DEFAULT_TITLE = 'Fechar formulário',
  DEFAULT_DESCRIPTION = 'Ao sair do formulário você perderá todos os dados informados até o momento.',
  DEFAULT_CONFIRMATION_ACTION = 'Deseja confirmar essa ação?',
}

interface Message {
  title: string;
  description: string;
  extraInfo?: string;
}

type Dispatch = () => void;

export interface DialogContextData {
  openDialog(
    fnConfirmation: Dispatch,
    fnDismiss: Dispatch,
    message?: Message,
  ): void;
}

const DEFAULT_MESSAGE: Message = {
  title: Strings.DEFAULT_TITLE,
  description: Strings.DEFAULT_DESCRIPTION,
  extraInfo: Strings.DEFAULT_CONFIRMATION_ACTION,
};

const DialogContext = createContext<DialogContextData>({} as DialogContextData);

const DialogProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState<Message>(DEFAULT_MESSAGE);
  const [handleConfirmation, setHandleConfirmation] = useState<Dispatch>(
    () => () => false,
  );
  const [handleDismiss, setHandleDismiss] = useState<Dispatch>(
    () => () => false,
  );

  function openDialog(
    fnConfirmation: Dispatch,
    fnDismiss: Dispatch,
    msg: Message = DEFAULT_MESSAGE,
  ) {
    setMessage(msg);
    setHandleConfirmation(() => () => {
      fnConfirmation();
      setIsOpen(false);
    });
    setHandleDismiss(() => () => {
      fnDismiss();
      setIsOpen(false);
    });
    setIsOpen(true);
  }

  return (
    <DialogContext.Provider value={{ openDialog }}>
      {children}
      <Dialog
        isOpen={isOpen}
        onConfimartion={handleConfirmation}
        onDismiss={handleDismiss}
        {...message}
      />
    </DialogContext.Provider>
  );
};

function useDialogConfirmation(): DialogContextData {
  const context = useContext(DialogContext);

  if (!context) {
    throw new Error(Strings.USE_DIALOG_ERROR);
  }
  return context;
}

export { DialogContext, DialogProvider, useDialogConfirmation };
