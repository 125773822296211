import axios from 'axios';

import { SERVER_URL } from 'config/envs';

import { Interceptors } from './interceptors';

enum ApiVersion {
  V1 = 'v1',
}

const TIME_OUT = 60000;

export const auth = axios.create({
  baseURL: `${SERVER_URL}/${ApiVersion.V1}`,
  timeout: TIME_OUT,
});

export const api = axios.create({
  baseURL: `${SERVER_URL}/${ApiVersion.V1}`,
  timeout: TIME_OUT,
});
api.interceptors.request.use(Interceptors.request);
api.interceptors.response.use(
  response => response,
  Interceptors.responseFailed,
);

export default { auth, api };
