export enum ActionCodeFollowUp {
  REGISTER_IN_EDITION = 'REGISTER_IN_EDTION',
  REGISTER_COMPLETED = 'REGISTER_COMPLETED',
  PRE_ENROLLMENT_COMPLETED = 'PRE_ENROLLMENT_COMPLETED',
  REQUESTING_CHANGES = 'REQUESTING_CHANGES',
  REQUESTING_CHANGES_WITHOUT_RESERVATION = 'REQUESTING_CHANGES_WITHOUT_RESERVATION',
  PRE_ENROLLMENT_IN_EDITION = 'PRE_ENROLLMENT_IN_EDTION',
  PRE_ENROLLMENT_ACCEPTED = 'PRE_ENROLLMENT_ACCEPTED',
  WAITING_SCHOOL = 'WAITING_SCHOOL',
  WAITING_CLASSES = 'WAITING_CLASSES',
  TRANSFER_REQUESTED = 'TRANSFER_REQUESTED',
}

export interface ActionFollowUp {
  id: number;
  code: ActionCodeFollowUp;
  descr: string;
  name: string;
  created: string;
}

export interface FollowUpGetResponseData {
  id: number;
  date: string;
  action: ActionFollowUp;
  descriptionEnrollmentAccepted?: string;
  descriptionRequestingChanges?: string;
}

export interface FollowUpGetRequestData {
  id: number;
}
