import { toast } from 'react-toastify';
import { all, takeLatest, put } from 'redux-saga/effects';

import { mapErrorToResponseError } from 'services/api/responseErrorHandler';
import { StatesService } from 'services/api/states';
import { StatesResponse } from 'services/api/states/models';
import { StatesCreators } from 'store/ducks/states';

function* fetchStates(): Generator<unknown, void, StatesResponse> {
  try {
    const { records } = yield StatesService.fetchStates();
    yield put(
      StatesCreators.getStates.success(
        records.map(state => ({
          value: state.id,
          label: state.name,
          short: state.short,
        })),
      ),
    );
  } catch (error) {
    const responseError = mapErrorToResponseError(error);
    toast.error(responseError.message);
    yield put(StatesCreators.getStates.failure(responseError.message));
  }
}

export default all([takeLatest(StatesCreators.getStates.request, fetchStates)]);
