import base from 'services/api/base';
import { ServiceEndpoints } from 'services/api/types';

import { SaberResponse } from './models';

const Strings = {
  DEFAULT_ERROR: 'Erro ao recuperar informações do SABER',
};

export const SaberEndpoints: ServiceEndpoints = {
  saberStudent: {
    path: 'students/{code}/saber',
    errorMessages: {
      401: 'Não autorizado',
      403: 'Sem permissão para acessar',
      default: Strings.DEFAULT_ERROR,
    },
  },
};

async function fetchSaberStudentByCode(code: string): Promise<SaberResponse> {
  const endpoint = SaberEndpoints.saberStudent;
  const response = await base.api.get(endpoint.path.replaceAll('{code}', code));
  return response.data;
}

export const SaberService = { fetchSaberStudentByCode };
