import produce, { Draft } from 'immer';
import { Reducer } from 'redux';
import { ActionType, createAsyncAction } from 'typesafe-actions';

import { StudentFormData, StudentType } from 'models/student';
import { RootState } from 'store';

export const getGeneralStudentFormData = ({ studentGeneralInfo }: RootState) =>
  studentGeneralInfo.studentFormData;

export enum Types {
  CREATE_GENERAL_STUDENT_REQUEST = '@student/CREATE_GENERAL_STUDENT_REQUEST',
  CREATE_GENERAL_STUDENT_SUCCESS = '@student/CREATE_GENERAL_STUDENT_SUCCESS',
  CREATE_GENERAL_STUDENT_FAILURE = '@student/CREATE_GENERAL_STUDENT_FAILURE',
  UPDATE_GENERAL_STUDENT_REQUEST = '@student/UPDATE_GENERAL_STUDENT_REQUEST',
  UPDATE_GENERAL_STUDENT_SUCCESS = '@student/UPDATE_GENERAL_STUDENT_SUCCESS',
  UPDATE_GENERAL_STUDENT_FAILURE = '@student/UPDATE_GENERAL_STUDENT_FAILURE',
  FETCH_GENERAL_STUDENT_REQUEST = '@student/FETCH_GENERAL_STUDENT_REQUEST',
  FETCH_GENERAL_STUDENT_SUCCESS = '@student/FETCH_GENERAL_STUDENT_SUCCESS',
  FETCH_GENERAL_STUDENT_FAILURE = '@student/FETCH_GENERAL_STUDENT_FAILURE',
  FINISH_STUDENT_REQUEST = '@student/FINISH_STUDENT_REQUEST',
  FINISH_STUDENT_SUCCESS = '@student/FINISH_STUDENT_SUCCESS',
  FINISH_STUDENT_FAILURE = '@student/FINISH_STUDENT_FAILURE',
}

export interface GeneralStudentState {
  loading: boolean;
  studentId: number;
  studentFormData: StudentFormData;
}

const INITIAL_STATE: GeneralStudentState = {
  loading: false,
  studentId: -1,
  studentFormData: {} as StudentFormData,
};

export const GeneralStudentCreators = {
  createGeneralStudent: createAsyncAction(
    Types.CREATE_GENERAL_STUDENT_REQUEST,
    Types.CREATE_GENERAL_STUDENT_SUCCESS,
    Types.CREATE_GENERAL_STUDENT_FAILURE,
  )<StudentFormData, StudentFormData, string>(),
  updateGeneralStudent: createAsyncAction(
    Types.UPDATE_GENERAL_STUDENT_REQUEST,
    Types.UPDATE_GENERAL_STUDENT_SUCCESS,
    Types.UPDATE_GENERAL_STUDENT_FAILURE,
  )<StudentFormData, StudentFormData, string>(),
  fetchGeneralStudent: createAsyncAction(
    Types.FETCH_GENERAL_STUDENT_REQUEST,
    Types.FETCH_GENERAL_STUDENT_SUCCESS,
    Types.FETCH_GENERAL_STUDENT_FAILURE,
  )<void, StudentFormData, string>(),
  finishStudent: createAsyncAction(
    Types.FINISH_STUDENT_REQUEST,
    Types.FINISH_STUDENT_SUCCESS,
    Types.FINISH_STUDENT_FAILURE,
  )<{ type: StudentType }, void, void>(),
};

export type ActionTypes = ActionType<typeof GeneralStudentCreators>;

const reducer: Reducer<GeneralStudentState, ActionTypes> = (
  state = INITIAL_STATE,
  action: ActionTypes,
) => {
  const { type, payload } = action;

  return produce(state, (draft: Draft<GeneralStudentState>) => {
    switch (type) {
      case Types.FINISH_STUDENT_REQUEST:
        draft.loading = true;
        draft.studentFormData = {
          ...draft.studentFormData,
          type: (payload as { type: StudentType }).type,
        };
        break;
      case Types.FETCH_GENERAL_STUDENT_REQUEST:
        draft.loading = true;
        break;
      case Types.FETCH_GENERAL_STUDENT_SUCCESS:
        draft.studentFormData = payload as StudentFormData;
        draft.loading = false;
        break;
      case Types.UPDATE_GENERAL_STUDENT_REQUEST:
      case Types.CREATE_GENERAL_STUDENT_REQUEST:
        draft.loading = true;
        break;
      case Types.UPDATE_GENERAL_STUDENT_SUCCESS:
      case Types.CREATE_GENERAL_STUDENT_SUCCESS:
        draft.loading = false;
        draft.studentFormData = payload as StudentFormData;
        break;
      case Types.FINISH_STUDENT_SUCCESS:
      case Types.FINISH_STUDENT_FAILURE:
      case Types.UPDATE_GENERAL_STUDENT_FAILURE:
      case Types.FETCH_GENERAL_STUDENT_FAILURE:
      case Types.CREATE_GENERAL_STUDENT_FAILURE:
        draft.loading = false;
        break;
      default:
    }
  });
};

export default reducer;
