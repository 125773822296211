import produce, { Draft } from 'immer';
import { Reducer } from 'redux';
import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';

import { Identifier } from 'models/identifier';
import { DocumentsStudentData } from 'models/student';

export enum Types {
  CREATE_DOCUMENTS_STUDENT_REQUEST = '@student/CREATE_DOCUMENTS_STUDENT_REQUEST',
  CREATE_DOCUMENTS_STUDENT_SUCCESS = '@student/CREATE_DOCUMENTS_STUDENT_SUCCESS',
  CREATE_DOCUMENTS_STUDENT_FAILURE = '@student/CREATE_DOCUMENTS_STUDENT_FAILURE',
  UPDATE_DOCUMENTS_STUDENT_REQUEST = '@student/UPDATE_DOCUMENTS_STUDENT_REQUEST',
  UPDATE_DOCUMENTS_STUDENT_SUCCESS = '@student/UPDATE_DOCUMENTS_STUDENT_SUCCESS',
  UPDATE_DOCUMENTS_STUDENT_FAILURE = '@student/UPDATE_DOCUMENTS_STUDENT_FAILURE',
  FETCH_DOCUMENTS_STUDENT_REQUEST = '@student/FETCH_DOCUMENTS_STUDENT_REQUEST',
  FETCH_DOCUMENTS_STUDENT_SUCCESS = '@student/FETCH_DOCUMENTS_STUDENT_SUCCESS',
  FETCH_DOCUMENTS_STUDENT_FAILURE = '@student/FETCH_DOCUMENTS_STUDENT_FAILURE',
}

export interface State {
  loading: boolean;
  data: DocumentsStudentData;
  studentId: number;
}

const INITIAL_STATE: State = {
  loading: false,
  data: {} as DocumentsStudentData,
  studentId: -1,
};

export const DocumentsStudentCreators = {
  createDocumentsStudent: createAsyncAction(
    Types.CREATE_DOCUMENTS_STUDENT_REQUEST,
    Types.CREATE_DOCUMENTS_STUDENT_SUCCESS,
    Types.CREATE_DOCUMENTS_STUDENT_FAILURE,
  )<DocumentsStudentData, DocumentsStudentData, string>(),
  updateDocumentsStudent: createAsyncAction(
    Types.UPDATE_DOCUMENTS_STUDENT_REQUEST,
    Types.UPDATE_DOCUMENTS_STUDENT_SUCCESS,
    Types.UPDATE_DOCUMENTS_STUDENT_FAILURE,
  )<DocumentsStudentData, DocumentsStudentData, string>(),
  fetchDocumentsStudent: createAsyncAction(
    Types.FETCH_DOCUMENTS_STUDENT_REQUEST,
    Types.FETCH_DOCUMENTS_STUDENT_SUCCESS,
    Types.FETCH_DOCUMENTS_STUDENT_FAILURE,
  )<void, DocumentsStudentData, string>(),
};

export type ActionTypes = ActionType<typeof DocumentsStudentCreators>;

const reducer: Reducer<State, ActionTypes> = (
  state = INITIAL_STATE,
  action: ActionTypes,
) => {
  const { type, payload } = action;

  return produce(state, (draft: Draft<State>) => {
    switch (type) {
      case Types.FETCH_DOCUMENTS_STUDENT_REQUEST:
        draft.loading = true;
        break;
      case Types.FETCH_DOCUMENTS_STUDENT_SUCCESS:
        draft.data = payload as DocumentsStudentData;
        draft.loading = false;
        break;
      case Types.CREATE_DOCUMENTS_STUDENT_REQUEST:
      case Types.UPDATE_DOCUMENTS_STUDENT_REQUEST:
        draft.loading = true;
        break;
      case Types.UPDATE_DOCUMENTS_STUDENT_SUCCESS:
      case Types.CREATE_DOCUMENTS_STUDENT_SUCCESS:
        draft.loading = false;
        draft.data = payload as DocumentsStudentData;
        break;
      case Types.CREATE_DOCUMENTS_STUDENT_FAILURE:
      case Types.UPDATE_DOCUMENTS_STUDENT_FAILURE:
      case Types.FETCH_DOCUMENTS_STUDENT_FAILURE:
        draft.loading = false;
        break;
      default:
    }
  });
};

export default reducer;
