import { Container, Table, Title, Head, Icon, Media } from './styles';

interface NotasProps {
  materia: string;
  nota1: string;
  nota2: string;
  nota3: string;
  nota4: string;
  mediaNotas: string;
  icon: string;
  aproved?: boolean;
}

const CardNotas = ({
  icon,
  materia,
  nota1,
  nota2,
  nota3,
  nota4,
  mediaNotas,
  aproved = false,
}: NotasProps) => {
  return (
    <Container>
      <Head>
        <Icon src={icon} />
        <Title>{materia}</Title>
      </Head>
      <Table>
        <tbody>
          <tr>
            <td>1° Bimestre</td>
            <td>{nota1}</td>
          </tr>
          <tr>
            <td>2° Bimestre</td>
            <td>{nota2}</td>
          </tr>
          <tr>
            <td>3° Bimestre</td>
            <td>{nota3}</td>
          </tr>
          <tr>
            <td>4° Bimestre</td>
            <td>{nota4}</td>
          </tr>
          <tr>
            <td>Média Final</td>
            <td>
              <Media aproved={aproved}>{mediaNotas}</Media>
            </td>
          </tr>
        </tbody>
      </Table>
    </Container>
  );
};

export default CardNotas;
