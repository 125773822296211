import { SERVER_KEY } from 'config/envs';
import { Identifier } from 'models/identifier';
import base from 'services/api/base';

import { ServiceEndpoints } from '../types';
import {
  RefreshTokenRequestData,
  RefreshTokenResponseData,
  SignInRequestData,
  SignInResponseData,
  SignUpRequestData,
  SignUpResponseData,
  FetchUserRequest,
  FetchUserResponse,
  RecoveryPasswordRequestData,
  ResetPasswordRequestData,
  VerifyEmailRequestData,
} from './models';

const Strings = {
  DEFAULT_ERROR_SIGN_IN: 'Erro ao entrar na aplicação',
};

const getResendEmailPath = (id: string) => `/users/${id}/email/resend`;

export const AuthEndpoints: ServiceEndpoints = {
  signIn: {
    path: 'auth',
    errorMessages: {
      400: 'Credenciais inválidas',
      401: 'Login ou senha incorretos',
      404: 'Login ou senha incorretos',
      default: Strings.DEFAULT_ERROR_SIGN_IN,
    },
  },
  signUp: {
    path: 'users',
    errorMessages: {
      400: 'Dados do usuário inválidas',
      422: 'Dados do usuário já existente',
      default: Strings.DEFAULT_ERROR_SIGN_IN,
    },
  },
  fetchUser: {
    path: 'users',
    errorMessages: {
      404: 'Usuário não encontrado',
      default: Strings.DEFAULT_ERROR_SIGN_IN,
    },
  },
  refreshToken: {
    path: '/auth/refresh',
    errorMessages: {
      default: Strings.DEFAULT_ERROR_SIGN_IN,
    },
  },
  recoveryPassword: {
    path: '/users/recovery',
    errorMessages: {
      default: Strings.DEFAULT_ERROR_SIGN_IN,
    },
  },
  resetPassword: {
    path: '/users/recovery/password',
    errorMessages: {
      default: Strings.DEFAULT_ERROR_SIGN_IN,
    },
  },
  resendEmail: {
    path: 'users',
    errorMessages: {
      default: Strings.DEFAULT_ERROR_SIGN_IN,
    },
  },
  verifyEmail: {
    path: '/users/email/verify',
    errorMessages: {
      default: Strings.DEFAULT_ERROR_SIGN_IN,
    },
  },
};

async function signIn(params: SignInRequestData): Promise<SignInResponseData> {
  const endpoint = AuthEndpoints.signIn;
  const response = await base.auth.post<SignInResponseData>(
    endpoint.path,
    params,
  );
  return response.data;
}

async function signUp(params: SignUpRequestData): Promise<SignUpResponseData> {
  const endpoint = AuthEndpoints.signUp;
  const response = await base.auth.post<SignUpResponseData>(
    endpoint.path,
    params,
    {
      headers: {
        Authorization: `Basic ${SERVER_KEY}`,
      },
    },
  );
  return response.data;
}

async function refreshToken(
  params: RefreshTokenRequestData,
): Promise<RefreshTokenResponseData> {
  const endpoint = AuthEndpoints.refreshToken;
  const response = await base.auth.post<RefreshTokenResponseData>(
    endpoint.path,
    params,
  );
  return response.data;
}

async function fetchUser(params: FetchUserRequest): Promise<FetchUserResponse> {
  const endpoint = AuthEndpoints.fetchUser;
  const response = await base.api.get<FetchUserResponse>(
    `${endpoint.path}/${params.id}`,
  );
  return response.data;
}

async function recoveryPassword(
  data: RecoveryPasswordRequestData,
): Promise<void> {
  const endpoint = AuthEndpoints.recoveryPassword;
  await base.api.post(`${endpoint.path}`, data);
}

async function resetPassword(data: ResetPasswordRequestData): Promise<void> {
  const endpoint = AuthEndpoints.resetPassword;
  await base.api.post(`${endpoint.path}`, data);
}

async function resendEmail(data: Identifier): Promise<void> {
  const endpoint = getResendEmailPath(data.id as string);
  await base.api.post(`${endpoint}`);
}

async function verifyEmail(data: VerifyEmailRequestData): Promise<void> {
  const endpoint = AuthEndpoints.verifyEmail;
  await base.api.post(`${endpoint.path}`, data);
}

export const AuthService = {
  signIn,
  signUp,
  refreshToken,
  fetchUser,
  recoveryPassword,
  resetPassword,
  resendEmail,
  verifyEmail,
};
