import { CURRENT_ENROLLMENT_YEAR } from 'config/constants';
import base from 'services/api/base';
import { ServiceEndpoints } from 'services/api/types';

import {
  CreateEnrollmentRequestData,
  FetchEnrollmentResponseData,
  UpdateEnrollmentRequestData,
  UpdateEnrollmentResponseData,
  RepeatEnrollmentResponseData,
  FetchOverviewEnrollmentResponseData,
} from './models';

const Strings = {
  DEFAULT_ERROR_SIGN_IN: 'Erro ao entrar na aplicação',
};

export const EnrollmentEndpoints: ServiceEndpoints = {
  createEnrollment: {
    path: 'enrollments',
    errorMessages: {
      400: 'Dados inválidos. Preencha os campos corretamente',
      default: Strings.DEFAULT_ERROR_SIGN_IN,
    },
  },
  updateEnrollment: {
    path: 'enrollments',
    errorMessages: {
      400: 'Dados inválidos. Preencha os campos corretamente',
      404: 'Matrícula não encontrada',
      default: Strings.DEFAULT_ERROR_SIGN_IN,
    },
  },
  fetchEnrollment: {
    path: 'enrollments',
    errorMessages: {
      404: 'Matrícula não encontrada',
      default: Strings.DEFAULT_ERROR_SIGN_IN,
    },
  },
  repeatEnrollment: {
    path: 'enrollments',
    errorMessages: {
      404: 'Matrícula não encontrada',
      default: Strings.DEFAULT_ERROR_SIGN_IN,
    },
  },
};

async function createEnrollment(
  id: number,
  body: CreateEnrollmentRequestData,
): Promise<CreateEnrollmentRequestData> {
  const endpoint = EnrollmentEndpoints.createEnrollment;
  const response = await base.api.post<CreateEnrollmentRequestData>(
    `${endpoint.path}/${id}`,
    body,
  );
  return response.data;
}

async function updateEnrollment(
  id: number,
  body: UpdateEnrollmentRequestData,
): Promise<UpdateEnrollmentResponseData> {
  const endpoint = EnrollmentEndpoints.updateEnrollment;
  await base.api.patch<UpdateEnrollmentResponseData>(
    `${endpoint.path}/${id}`,
    body,
  );
}

async function fetchEnrollments(
  id: number,
): Promise<FetchEnrollmentResponseData[]> {
  const endpoint = EnrollmentEndpoints.fetchEnrollment;
  const response = await base.api.get<FetchEnrollmentResponseData[]>(
    `${endpoint.path}/${id}`,
  );
  return response.data;
}

async function repeatEnrollment(
  id: number,
): Promise<RepeatEnrollmentResponseData> {
  const endpoint = EnrollmentEndpoints.repeatEnrollment;

  const response = await base.api.post<RepeatEnrollmentResponseData>(
    `${endpoint.path}/${id}/repeat`,
    { year: CURRENT_ENROLLMENT_YEAR },
  );
  return response.data;
}

async function fetchOverviewEnrollment(
  id: number,
): Promise<FetchOverviewEnrollmentResponseData[]> {
  const endpoint = EnrollmentEndpoints.fetchEnrollment;

  const response = await base.api.get<FetchOverviewEnrollmentResponseData[]>(
    `${endpoint.path}/${id}/overview`,
  );
  return response.data;
}

export const EnrollmentService = {
  createEnrollment,
  updateEnrollment,
  fetchEnrollments,
  fetchOverviewEnrollment,
  repeatEnrollment,
};
