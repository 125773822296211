import { useCallback, useEffect } from 'react';

import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { Button, Input, CheckBox, ReCaptcha } from 'components';
import InputKeyboardDate from 'components/InputKeyboardDate';
import { useDialogConfirmation } from 'hooks/DialogContext';
import { useRecaptcha } from 'hooks/ReCaptchaContext';
import { UserRegisterFormData } from 'models/user';
import { RootState } from 'store';
import { AuthCreators } from 'store/ducks/auth';
import {
  checkFormikErrorsAndSetFieldTouched,
  getFormikMessageError,
} from 'utils/formikUtils';
import { normalizeCpfValue } from 'utils/maskUtils';
import {
  dateRequiredSchema,
  cpfRequiredSchema,
  emailSchema,
  personNameRequiredSchema,
  textRequiredSchema,
} from 'utils/validations';

import {
  HorizontalDivider,
  Container,
  Content,
  Title,
  Form,
  LabelRegister,
  TermContainer,
  CheckboxContainer,
  ContainerButtons,
  FormRow,
  Subtitle,
} from './styles';

const schema = Yup.object().shape({
  fullname: personNameRequiredSchema,
  email: emailSchema,
  cpf: cpfRequiredSchema,
  birthdate: dateRequiredSchema,
  motherName: personNameRequiredSchema,
  password: textRequiredSchema,
  passwordConfirmation: textRequiredSchema.oneOf(
    [Yup.ref('password'), null],
    'As senhas devem ser iguais',
  ),
  // TODO: Melhorar o conteúdo da mensagem de validação para os campos booleanos [MA]
  knowledge: Yup.boolean().isTrue('É necessário concordar com a declaração'),
  justification: Yup.boolean().isTrue(
    'É necessário concordar com a justificativa',
  ),
});

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modal = useDialogConfirmation();
  const { loading } = useSelector(({ auth }: RootState) => auth);
  const { verifyTokenOnChange, recaptchaRef, recaptchaSucceed } =
    useRecaptcha();

  const onSubmit = useCallback(
    formData => {
      dispatch(AuthCreators.signUp.request(formData));
    },
    [dispatch],
  );

  const formik = useFormik({
    initialValues: {
      justification: false,
      knowledge: false,
    } as UserRegisterFormData,
    validationSchema: schema,
    onSubmit,
  });

  useEffect(() => {
    checkFormikErrorsAndSetFieldTouched(formik);
  }, [formik.isSubmitting]);

  const onCancel = useCallback(() => {
    if (formik.dirty) {
      modal.openDialog(
        () => navigate(-1),
        () => false,
      );
    } else {
      navigate(-1);
    }
  }, [modal, formik, navigate]);

  return (
    <Container>
      <Content>
        <Title>Cadastro</Title>
        <Subtitle>
          Preencha os campos para criar seu perfil no sistema de matrículas
        </Subtitle>
        <LabelRegister>Dados pessoais e Acesso</LabelRegister>
        <Form id="signup-form-id">
          <FormRow>
            <Input
              id="fullname"
              label="Nome Completo"
              type="text"
              placeholder="Informe seu nome completo"
              required
              onChange={formik.handleChange}
              value={formik.values.fullname}
              onBlur={formik.handleBlur}
              messageError={getFormikMessageError(formik, 'fullname')}
            />
            <Input
              id="cpf"
              label="CPF"
              type="text"
              placeholder="Digite seu CPF"
              required
              value={formik.values.cpf}
              onChange={evt =>
                formik.setFieldValue('cpf', normalizeCpfValue(evt.target.value))
              }
              onBlur={formik.handleBlur}
              maxLength={14}
              messageError={getFormikMessageError(formik, 'cpf')}
            />
          </FormRow>
          <FormRow>
            <InputKeyboardDate
              id="birthdate"
              label="Data de Nascimento"
              required
              onChange={date =>
                formik.setFieldValue('birthdate', date ?? undefined)
              }
              value={
                formik.values.birthdate
                  ? new Date(formik.values.birthdate)
                  : null
              }
              onBlur={formik.handleBlur}
              messageError={getFormikMessageError(formik, 'birthdate')}
            />
            <Input
              id="email"
              label="Email"
              type="email"
              placeholder="Digite seu email"
              required
              onChange={formik.handleChange}
              value={formik.values.email}
              onBlur={formik.handleBlur}
              messageError={getFormikMessageError(formik, 'email')}
            />
          </FormRow>
          <FormRow>
            <Input
              id="motherName"
              label="Nome da Mãe"
              type="text"
              placeholder="Informe o nome da sua mãe"
              required
              onChange={formik.handleChange}
              value={formik.values.motherName}
              onBlur={formik.handleBlur}
              messageError={getFormikMessageError(formik, 'motherName')}
            />
          </FormRow>
          <HorizontalDivider />
          <FormRow>
            <Input
              id="password"
              label="Senha"
              type="password"
              placeholder="Informe sua senha de acesso"
              required
              onChange={formik.handleChange}
              value={formik.values.password}
              onBlur={formik.handleBlur}
              messageError={getFormikMessageError(formik, 'password')}
            />

            <Input
              id="passwordConfirmation"
              label="Confirme Sua Senha"
              type="password"
              placeholder="Insira novamente sua senha"
              required
              onChange={formik.handleChange}
              value={formik.values.passwordConfirmation}
              onBlur={formik.handleBlur}
              messageError={getFormikMessageError(
                formik,
                'passwordConfirmation',
              )}
            />
          </FormRow>
          <HorizontalDivider />
          <TermContainer>
            <CheckboxContainer>
              <CheckBox
                id="knowledge"
                onChange={() =>
                  formik.setFieldValue('knowledge', !formik.values.knowledge)
                }
                checked={formik.values.knowledge}
                label="Declaro, ainda, que estou ciente de que, em caso de utilização de
                qualquer meio ilícito ou imoral ou declaração falsa para a
                participação do Credenciamento supramencionado, incorro nas
                penalidades previstas nos artigos 171 e 299 do Decreto Lei nº
                2.848, de 07 de dezembro de 1940 (Código Penal)*"
                messageError={getFormikMessageError(formik, 'knowledge')}
              />
            </CheckboxContainer>
            <CheckboxContainer>
              <CheckBox
                id="knowledge"
                onChange={() =>
                  formik.setFieldValue(
                    'justification',
                    !formik.values.justification,
                  )
                }
                checked={formik.values.justification}
                label="Declaro ter conhecimento da Lei de n.º 13.709 de 14 de agosto de 2018 - Lei Geral de Proteção de Dados e autorizo expressamente a coleta, processamento, compartilhamento e divulgação de meus dados pessoais nos termos da referida Lei, especificamente para fins relacionados às atividades da Secretaria de Estado da Educação e da Ciência e Tecnologia do Estado da Paraíba (SEECT - PB)"
                messageError={getFormikMessageError(formik, 'justification')}
              />
            </CheckboxContainer>
          </TermContainer>
          <ReCaptcha ref={recaptchaRef} onChange={verifyTokenOnChange} />
        </Form>
        <ContainerButtons>
          <Button
            title="Cancelar"
            size="small"
            styled="secondary"
            onClick={onCancel}
          />
          <Button
            title="Criar conta"
            size="small"
            type="submit"
            form="signup-form-id"
            onClick={formik.submitForm}
            disabled={loading || !recaptchaSucceed}
          />
        </ContainerButtons>
      </Content>
    </Container>
  );
};

export default SignUp;
