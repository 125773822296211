import styled from 'styled-components';

import { BodyRegular, Caption, H2 } from 'components/StyledTypography/styles';

export const Container = styled.div`
  display: flex;
  padding: 40px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0px 2px 14px -2px rgba(0, 0, 0, 0.15);
  width: 100%;
  min-height: 588px;
  border-radius: 8px;
  max-width: 786px;
  padding: 32px;
`;

export const Box = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
  margin: 16px 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    grid-template-columns: 1fr;
  }
`;

export const ContainerText = styled.div`
  display: flex;
  gap: 40px;
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SchoolImage = styled.img`
  height: 100px;
  width: 150px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: none;
  }
`;

export const Title = styled(H2)`
  color: ${({ theme }) => theme.palette.black};
`;

export const SubTitle = styled(BodyRegular)`
  color: ${({ theme }) => theme.palette.black};
`;

export const TagContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TagTitle = styled(Caption)``;

export const TagList = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 4px;
`;

export const Tag = styled(BodyRegular)`
  padding: 8px 16px;
  background: rgba(33, 33, 33, 0.08);
  border-radius: 24px;
`;
