import styled, { css, keyframes } from 'styled-components';

import { BodyRegular } from 'components/StyledTypography/styles';

export const Container = styled.div`
  width: 100%;
  height: 15%;
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
  padding: 15px;
  margin-top: 21px;
  margin-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey3};
`;

export const Typhography = styled(BodyRegular)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .title {
    display: flex;
    align-items: center;
    svg {
      margin-left: 4px;
    }
  }

  .message {
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      cursor: pointer;
      margin-left: 13px;
    }
    .title {
      display: block;
      text-align: right;
      max-width: 10rem;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`;
export const TitleGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }

  .progressbar {
    width: 100%;
    margin-top: 12px;
    /* background-color: ${({ theme }) => theme.palette.grey3}; */
    overflow: hidden;

    @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
      display: none;
    }
  }
`;

export const ButtonContainer = styled.div``;

interface IProgressBar {
  status: 'uploading' | 'uploaded' | 'error' | 'idle';
}

export const ProgressBar = styled.div<IProgressBar>`
  width: 25%;
  height: 4px;

  /* transition: width 10s ease-in-out; */
  background-color: ${({ theme }) => theme.palette.primary};
  transform: translateX(-100%);
  z-index: 2;

  ${({ status }) => {
    switch (status) {
      case 'uploading':
        return css`
          animation: progress 2s linear infinite;
        `;

      case 'uploaded':
        return css`
          width: 100%;
          transform: translateX(0);
          background-color: ${({ theme }) => theme.palette.primary};
        `;

      default:
        return css`
          width: 100%;
          transform: translateX(0);
          background-color: ${({ theme }) => theme.palette.negative};
        `;
    }
  }}

  @keyframes progress {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(400%);
    }
  }
`;

export const UploadButton = styled.div`
  input[type='file'] {
    display: none;
  }
  label {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 12px 16px;
    width: 200px;
    border-radius: 100px;
    font: 500 15px 'Inter';
    border: 1px solid ${({ theme }) => theme.palette.grey1};
    color: ${({ theme }) => theme.palette.grey1};
    text-align: center;
    cursor: pointer;

    span {
      white-space: nowrap;
      max-width: 100%;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    :hover {
      background: ${({ theme }) => theme.palette.grey1};
      color: #fff;
      svg path {
        fill: ${({ theme }) => theme.palette.white};
      }
    }
  }
`;

export const ContainerIcon = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    display: none;
  }
`;
