import styled from 'styled-components';

import { BodyBold, Caption, H3 } from 'components/StyledTypography/styles';

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
`;

export const HeaderContent = styled.div`
  max-width: ${({ theme }) => theme.breakpoints.xl}px;
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
`;

export const Header = styled.div`
  background: ${({ theme }) => theme.palette.white};
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  padding: 16px;

  img {
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export const Content = styled.div`
  max-width: ${({ theme }) => theme.breakpoints.sm}px;
  width: 100%;
  margin-top: 95px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
`;

export const Title = styled(H3)`
  color: ${({ theme }) => theme.palette.primaryDark};
`;

export const BoxQuestion = styled.div`
  background: ${({ theme }) => theme.palette.white};
  border: 1px solid ${({ theme }) => theme.palette.grey3};
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 16px;
  padding: 16px 14px;
`;

export const Question = styled(BodyBold)`
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme.palette.black};
`;

export const Answer = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  margin-top: 4px;
  /* or 20px */
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme.palette.black};
`;

export const FooterText = styled(Caption)`
  color: ${({ theme }) => theme.palette.grey1};
`;

export const Footer = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: inherit;
  width: inherit;
`;

export const ContainerAccordion = styled.div`
  width: 100%;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 4px;

  h2 {
    border-radius: 4px;
    border: 0.5px solid rgba(173, 173, 173, 0.5);
    border-bottom: 0.5px solid rgba(173, 173, 173, 0.5);
    font-weight: bold;
  }
  .css-1eziwv {
    font-weight: bold;
  }
`;
