/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-lone-blocks */

import {
  ArrowRight,
  CoverFigures,
  CoverGirl,
  LogoPbGovImg,
  GirlImg,
  Matricula2022Img,
  SaberLogo,
} from 'assets/images';

import { useNavigate } from 'react-router-dom';
import { DropdownMenu } from 'components';

import {
  Container,
  Header,
  Content,
  GirlImage,
  EnrollmentImage,
  EnrollmentButton,
  WelcomeArea,
  CoverFiguresImage,
  CoverGirlImage,
  LogoPBGovImage,
  CoverGirlDiv,
  ContainerBackground,
  ContainerLogoGov,
} from './styles';

const Cover = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <ContainerBackground>
        <img src={CoverFigures} alt="" />
      </ContainerBackground>
      <ContainerLogoGov>
        <LogoPBGovImage src={LogoPbGovImg} alt="Logo do Estado da Paraíba" />
      </ContainerLogoGov>
      <Content>
        <WelcomeArea>
          <h1>Bem-vindo(a) ao e-Saber!</h1>
          <span>
            Clique no botão abaixo para acessar o sistema de matrículas do
            Governo do Estado da Paraíba.
          </span>
          <EnrollmentButton onClick={() => navigate('auth')}>
            <span>
              Realizar matrícula do estudante{' '}
              <img src={ArrowRight} alt="Logo do Governo da Paraíba" />
            </span>
          </EnrollmentButton>
        </WelcomeArea>
        <CoverGirlDiv>
          <span />
          <CoverGirlImage src={CoverGirl} alt="Test" />
        </CoverGirlDiv>
      </Content>
    </Container>
  );
};

export default Cover;
