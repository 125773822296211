import base from 'services/api/base';
import { ServiceEndpoints } from 'services/api/types';

import {
  CreateResponsableDataRequestData,
  CreateResponsableDataResponseData,
  FetchResponsableDataResponseData,
  UpdateResponsableDataRequestData,
  UpdateResponsableDataResponseData,
} from './models';

const Strings = {
  ERROR_CREATE_RESPONSABLE_DATA: 'Falha ao cadastrar dados do responsável',
};

export const ResponsableDataEndpoints: ServiceEndpoints = {
  createResponsableData: {
    path: 'students',
    errorMessages: {
      204: 'Dados do responsável cadastrados com sucesso',
      400: 'Dados inválidos. Preencha os campos de maneira correta',
      401: 'Você precisa estar logado para realizar esta ação',
      403: 'Você não tem permissão para realizar esta ação',
      default: Strings.ERROR_CREATE_RESPONSABLE_DATA,
    },
  },
  updateResponsableData: {
    path: 'students',
    errorMessages: {
      204: 'Dados do responsável cadastrados com sucesso',
      400: 'Dados inválidos. Preencha os campos de maneira correta',
      401: 'Você precisa estar logado para realizar esta ação',
      403: 'Você não tem permissão para realizar esta ação',
      default: Strings.ERROR_CREATE_RESPONSABLE_DATA,
    },
  },
  fetchResponsableData: {
    path: 'students',
    errorMessages: {
      204: 'Dados do responsável cadastrados com sucesso',
      400: 'Dados inválidos. Preencha os campos de maneira correta',
      401: 'Você precisa estar logado para realizar esta ação',
      403: 'Você não tem permissão para realizar esta ação',
      default: Strings.ERROR_CREATE_RESPONSABLE_DATA,
    },
  },
};

async function createResponsableData(
  id: number,
  data: CreateResponsableDataRequestData,
): Promise<CreateResponsableDataRequestData> {
  const endpoint = ResponsableDataEndpoints.createResponsableData;
  const response = await base.api.post<CreateResponsableDataRequestData>(
    `${endpoint.path}/${id}/responsables`,
    data,
  );
  return response.data;
}

async function updateResponsableData(
  id: number,
  data: UpdateResponsableDataRequestData,
): Promise<UpdateResponsableDataResponseData> {
  const endpoint = ResponsableDataEndpoints.updateResponsableData;
  await base.api.patch<UpdateResponsableDataResponseData>(
    `${endpoint.path}/${id}/responsables`,
    data,
  );
}

async function fetchResponsableData(
  id: number,
): Promise<FetchResponsableDataResponseData> {
  const endpoint = ResponsableDataEndpoints.fetchResponsableData;
  const response = await base.api.get<FetchResponsableDataResponseData>(
    `${endpoint.path}/${id}/responsables`,
  );

  return response.data;
}

export const ResponsableDataService = {
  createResponsableData,
  updateResponsableData,
  fetchResponsableData,
};
