import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0px 2px 14px -2px rgba(0, 0, 0, 0.15);
  width: 100%;
  min-height: 588px;
  border-radius: 8px;
  max-width: 786px;
`;

export const FlexBox = styled.div`
  display: flex;
  width: 100%;
`;

export const Acompanhamento = styled.div`
  display: flex;
  width: 100%;
  padding: 26px;
`;

export const TimeLine = styled.div`
  display: flex;
  flex-direction: column;
  width: 16px;
  align-content: start;
  margin-left: 15px;
  @media screen and (max-width: 420px) {
    margin-left: 28px;
  }
`;

export const Line = styled.span`
  border: 1px solid #1a4f75;
  background: #1a4f75;
  height: 125px;
  margin-right: auto;
  margin-left: auto;
`;

export const Atoms = styled.div`
  margin-left: auto;
  margin-right: auto;
  height: 12px;
  width: 12px;
  background: #1a4f75;
  border-radius: 90px;
`;

export const ActiveAtoms = styled.div`
  margin-left: auto;
  margin-right: auto;
  height: 16px;
  width: 16px;
  border: 4px solid #1a4f75;
  border-radius: 90px;
`;

export const Datas = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  padding-left: 24px;
  gap: 120px;
  @media screen and (max-width: 420px) {
    order: 3;
  }
`;

export const PastDate = styled.h2`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #888888;
  opacity: 0.9;
`;

export const ActualDate = styled.h2`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #121212;
`;

export const Description = styled.h2`
  padding-left: 18px;
  padding-top: 39px;
  max-width: 650px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #121212;
  @media screen and (max-width: 420px) {
    padding-top: 28px;
    padding-left: 28px;
    padding-right: 28px;
  }
`;

export const LastAct = styled.h2`
  padding-top: 20px;
  padding-left: 24px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #888888;
  @media screen and (max-width: 420px) {
    padding-left: 28px;
  }
`;

export const Logo = styled.img`
  padding-top: 24px;
  padding-left: 24px;
  @media screen and (max-width: 420px) {
    display: none;
  }
`;
