import { useCallback, useState } from 'react';

import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { Button, Input } from 'components';
import { useForgotPassword } from 'hooks/ForgotPassword';
import { getFormikMessageError } from 'utils/formikUtils';
import { emailSchema } from 'utils/validations';

import {
  Container,
  Content,
  Title,
  Information,
  Form,
  ButtonsDiv,
  StyledLink,
} from './styles';

const FORGOT_PASSWORD_SCHEMA = Yup.object().shape({
  email: emailSchema,
});

const FORM_ID = 'forgot-form';

const ForgotPassword = () => {
  const [success, setSuccess] = useState(false);
  const { onRecoveryPassword, loading } = useForgotPassword();
  const navigate = useNavigate();

  const onSubmit = useCallback(
    async formData => {
      const succeed = await onRecoveryPassword(formData);
      setSuccess(succeed);
    },
    [onRecoveryPassword],
  );

  const formik = useFormik({
    initialValues: { email: undefined },
    validationSchema: FORGOT_PASSWORD_SCHEMA,
    onSubmit,
  });

  if (success) {
    return (
      <Container>
        <Content>
          <Title>Recuperação de senha</Title>
          <Information>
            E-mail enviado com sucesso. Por favor, verifique a sua caixa de
            entrada ou spam.
          </Information>
          <Form>
            <ButtonsDiv>
              <Button
                title="Voltar para o login"
                size="large"
                type="button"
                onClick={() => navigate('/auth')}
                disabled={loading}
              />
            </ButtonsDiv>
          </Form>
        </Content>
      </Container>
    );
  }

  return (
    <Container>
      <Content>
        <Title>Recuperação de senha</Title>
        <Information>
          Informe o e-mail cadastrado para receber o link de redefinição de
          senha
        </Information>
        <Form id={FORM_ID}>
          <Input
            label="E-mail"
            type="email"
            placeholder="Digite seu email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            name="email"
            messageError={getFormikMessageError(formik, 'email')}
          />
          <ButtonsDiv>
            <Button
              title="Enviar link de recuperação"
              size="large"
              type="button"
              form={FORM_ID}
              onClick={formik.submitForm}
              disabled={loading}
            />
            <StyledLink to="/auth">voltar para login</StyledLink>
          </ButtonsDiv>
        </Form>
      </Content>
    </Container>
  );
};

export default ForgotPassword;
