import { toast } from 'react-toastify';
import { all, takeLatest, put, select } from 'redux-saga/effects';

import { Option } from 'components/Select';
import { PARAIBA_ID } from 'config/constants';
import { NewEnrollmentFormData } from 'models/newEnrollment';
import { CitiesService } from 'services/api/cities';
import { CitiesResponse } from 'services/api/cities/models';
import { EducationService } from 'services/api/education';
import { EducationResponseData } from 'services/api/education/models';
import { NewEnrollmentService } from 'services/api/newEnrollment';
import {
  mapNewEnrollmentFormToNewEnrollmentRequest,
  mapNewEnrollmentResponseToNewEnrollmentFormData,
} from 'services/api/newEnrollment/mapper';
import { FetchNewEnrollmentResponseData } from 'services/api/newEnrollment/models';
import { mapErrorToResponseError } from 'services/api/responseErrorHandler';
import { SchoolSEEService } from 'services/api/schoolSEE';
import {
  mapSchoolCoursesResponseToCourseIdOptions,
  mapSchoolFiltersResponseToSchoolFilters,
  mapSchoolItineraiesResponseToItineraryIdOptions,
  mapSchoolItineraryResponseToItineraryIdOption,
  mapSchoolResponseToSchoolOption,
} from 'services/api/schoolSEE/mapper';
import {
  FetchSchoolCoursesResponse,
  FetchSchoolItineraiesResponse,
  FetchSchoolResponse,
  SchoolFiltersResponse,
} from 'services/api/schoolSEE/models';
import { RootState } from 'store';
import { NewEnrollmentCreators } from 'store/ducks/newEnrollment';
import {
  SchoolFilters,
  SchoolFiltersCreators,
} from 'store/ducks/schoolFilters';
import { StepNavigationCreators } from 'store/ducks/stepNavigation';

export const getUserInfoId = ({ auth }: RootState) => auth.userPersonalInfo?.id;

function* createNewEnrollment(
  action: ReturnType<typeof NewEnrollmentCreators.createNewEnrollment.request>,
): Generator<unknown, void, NewEnrollmentFormData & number> {
  try {
    const id: number = yield select(getUserInfoId);
    yield NewEnrollmentService.createNewEnrollment(
      id,
      mapNewEnrollmentFormToNewEnrollmentRequest(action.payload),
    );
    yield put(
      NewEnrollmentCreators.createNewEnrollment.success(action.payload),
    );
    yield put(StepNavigationCreators.isCompleted(true));
  } catch (error) {
    const responseError = mapErrorToResponseError(error);
    toast.error(responseError.message);
    yield put(
      NewEnrollmentCreators.createNewEnrollment.failure(responseError.message),
    );
  }
}

function* updateNewEnrollment(
  action: ReturnType<typeof NewEnrollmentCreators.updateNewEnrollment.request>,
): Generator<unknown, void, NewEnrollmentFormData & number> {
  try {
    const id: number = yield select(getUserInfoId);

    yield NewEnrollmentService.updateNewEnrollment(
      id,
      mapNewEnrollmentFormToNewEnrollmentRequest(action.payload),
    );
    yield put(
      NewEnrollmentCreators.updateNewEnrollment.success(action.payload),
    );
    yield put(StepNavigationCreators.isCompleted(true));
  } catch (error) {
    const responseError = mapErrorToResponseError(error);
    toast.error(responseError.message);
    yield put(
      NewEnrollmentCreators.updateNewEnrollment.failure(responseError.message),
    );
  }
}

function getCityOptionFromResponse(
  cities: CitiesResponse,
  cityId: number | undefined,
): Option | undefined {
  if (!cityId) {
    return undefined;
  }

  const city = cities.records.find(c => c.id === cityId);

  if (!city) {
    return undefined;
  }

  return {
    label: city.name,
    value: city.id,
  };
}

function* fetchNewEnrollment(): Generator<
  unknown,
  void,
  FetchNewEnrollmentResponseData[] &
    EducationResponseData[] &
    Option[] &
    number &
    CitiesResponse &
    FetchSchoolItineraiesResponse &
    SchoolFiltersResponse &
    FetchSchoolResponse &
    FetchSchoolCoursesResponse &
    NewEnrollmentFormData
> {
  try {
    let previousSchoolIdOption;
    const id: number = yield select(getUserInfoId);
    const cities = yield CitiesService.fetchCitiesByStateId(PARAIBA_ID);
    const enrollments = yield NewEnrollmentService.fetchNewEnrollment(id);
    enrollments.sort(
      (enrollA, enrollB) => Number(enrollA.year) - Number(enrollB.year),
    );

    const response = enrollments.pop();

    if (response === undefined) {
      throw Error('');
    }

    const educationOptions = (
      (yield EducationService.fetchEducationOptions()) as EducationResponseData[]
    ).map(item => ({ value: item.id, label: item.nome }));
    const educationOptionsSelected = educationOptions.find(
      item => item.value === response.modalitySpecial,
    )?.value;

    const mediations = (
      (yield EducationService.fetchMediations({
        educationOption: educationOptionsSelected ?? '',
      })) as EducationResponseData[]
    ).map(item => ({
      value: item.id,
      label: item.nome,
    }));
    const mediationSelected = mediations.find(
      item => item.value === response.mediation,
    )?.value;

    const modalities = (
      (yield EducationService.fetchModalities({
        mediation: mediationSelected ?? '',
      })) as EducationResponseData[]
    ).map(item => ({
      value: item.id,
      label: item.nome,
    }));

    const modalitySelected = modalities.find(
      item => item.value === response.modality,
    )?.value;

    const steps = (
      (yield EducationService.fetchSteps({
        modality: modalitySelected ?? '',
      })) as EducationResponseData[]
    ).map(item => ({ value: item.id, label: item.nome }));
    const stepsSelected = steps.find(
      item => item.value === response.step,
    )?.value;

    const shifts = (
      (yield EducationService.fetchShifts({
        step: stepsSelected ?? '',
      })) as EducationResponseData[]
    ).map(item => ({ value: item.id, label: item.nome }));

    const temporalities = (
      (yield EducationService.fetchTemporalities({
        step: stepsSelected ?? '',
      })) as EducationResponseData[]
    ).map(item => ({ value: item.id, label: item.nome }));

    if (response.previousSchoolId) {
      const previousSchool = yield SchoolSEEService.fetchSchool({
        id: response.previousSchoolId,
      });
      previousSchoolIdOption = mapSchoolResponseToSchoolOption(previousSchool);
    }

    // cities
    const cityId1Option = getCityOptionFromResponse(
      cities,
      Number(response.cityId1),
    ) as Option;

    const cityId2Option = getCityOptionFromResponse(
      cities,
      Number(response.cityId2),
    );
    const cityId3Option = getCityOptionFromResponse(
      cities,
      Number(response.cityId3),
    );
    const cityId4Option = getCityOptionFromResponse(
      cities,
      Number(response.cityId4),
    );
    const cityId5Option = getCityOptionFromResponse(
      cities,
      Number(response.cityId5),
    );

    // schools and courses
    const school1 = yield SchoolSEEService.fetchSchool({
      id: response.schoolId1,
    });
    const schoolId1Option = mapSchoolResponseToSchoolOption(school1);
    const courses1Response = yield SchoolSEEService.fetchSchoolCourses({
      id: response.schoolId1,
    });
    const coursesId1Option =
      mapSchoolCoursesResponseToCourseIdOptions(courses1Response);
    const courseId1Option = coursesId1Option.find(
      c => c.value === response.courseId1,
    );
    const itinerary1Response = yield SchoolSEEService.fetchSchoolItineraries({
      id: response.schoolId1,
    });
    const itinerariesId1Option =
      mapSchoolItineraiesResponseToItineraryIdOptions(itinerary1Response);
    const itineraryId1Option = itinerariesId1Option.find(
      c => c.value === response.itineraryId1,
    );

    let schoolId2Option: Option | undefined;
    let courseId2Option: Option | undefined;
    let itineraryId2Option: Option | undefined;
    if (response.schoolId2) {
      const school2 = yield SchoolSEEService.fetchSchool({
        id: response.schoolId2,
      });
      schoolId2Option = mapSchoolResponseToSchoolOption(school2);
      const courses2Response = yield SchoolSEEService.fetchSchoolCourses({
        id: response.schoolId2,
      });
      const coursesId2Option =
        mapSchoolCoursesResponseToCourseIdOptions(courses2Response);
      courseId2Option = coursesId2Option.find(
        c => c.value === response.courseId2,
      );
      const itinerary2Response = yield SchoolSEEService.fetchSchoolItineraries({
        id: response.schoolId2,
      });
      const itinerariesId2Option =
        mapSchoolItineraiesResponseToItineraryIdOptions(itinerary2Response);
      itineraryId2Option = itinerariesId2Option.find(
        c => c.value === response.itineraryId2,
      );
    }

    let schoolId3Option: Option | undefined;
    let courseId3Option: Option | undefined;
    let itineraryId3Option: Option | undefined;
    if (response.schoolId3) {
      const school3 = yield SchoolSEEService.fetchSchool({
        id: response.schoolId3,
      });
      schoolId3Option = mapSchoolResponseToSchoolOption(school3);
      const courses3Response = yield SchoolSEEService.fetchSchoolCourses({
        id: response.schoolId3,
      });
      const coursesId3Option =
        mapSchoolCoursesResponseToCourseIdOptions(courses3Response);
      courseId3Option = coursesId3Option.find(
        c => c.value === response.courseId3,
      );
      const itinerary3Response = yield SchoolSEEService.fetchSchoolItineraries({
        id: response.schoolId3,
      });
      const itinerariesId3Option =
        mapSchoolItineraiesResponseToItineraryIdOptions(itinerary3Response);
      itineraryId3Option = itinerariesId3Option.find(
        c => c.value === response.itineraryId3,
      );
    }

    let schoolId4Option: Option | undefined;
    let courseId4Option: Option | undefined;
    let itineraryId4Option: Option | undefined;
    if (response.schoolId4) {
      const school4 = yield SchoolSEEService.fetchSchool({
        id: response.schoolId4,
      });
      schoolId4Option = mapSchoolResponseToSchoolOption(school4);
      const courses4Response = yield SchoolSEEService.fetchSchoolCourses({
        id: response.schoolId4,
      });
      const coursesId4Option =
        mapSchoolCoursesResponseToCourseIdOptions(courses4Response);
      courseId4Option = coursesId4Option.find(
        c => c.value === response.courseId4,
      );
      const itinerary4Response = yield SchoolSEEService.fetchSchoolItineraries({
        id: response.schoolId4,
      });
      const itinerariesId4Option =
        mapSchoolItineraiesResponseToItineraryIdOptions(itinerary4Response);
      itineraryId4Option = itinerariesId4Option.find(
        c => c.value === response.itineraryId4,
      );
    }

    let schoolId5Option: Option | undefined;
    let courseId5Option: Option | undefined;
    let itineraryId5Option: Option | undefined;
    if (response.schoolId5) {
      const school5 = yield SchoolSEEService.fetchSchool({
        id: response.schoolId5,
      });
      schoolId5Option = mapSchoolResponseToSchoolOption(school5);
      const courses5Response = yield SchoolSEEService.fetchSchoolCourses({
        id: response.schoolId5,
      });
      const coursesId5Option =
        mapSchoolCoursesResponseToCourseIdOptions(courses5Response);
      courseId5Option = coursesId5Option.find(
        c => c.value === response.courseId5,
      );
      const itinerary5Response = yield SchoolSEEService.fetchSchoolItineraries({
        id: response.schoolId5,
      });
      const itinerariesId5Option =
        mapSchoolItineraiesResponseToItineraryIdOptions(itinerary5Response);
      itineraryId5Option = itinerariesId5Option.find(
        c => c.value === response.itineraryId5,
      );
    }

    const enrollmentData = mapNewEnrollmentResponseToNewEnrollmentFormData(
      response,
      {
        mediation: mediations,
        modality: modalities,
        educationOption: educationOptions,
        step: steps,
        shift: shifts,
        temporality: temporalities,
      },
      previousSchoolIdOption,
      {
        schoolId1Option,
        schoolId2Option,
        schoolId3Option,
        schoolId4Option,
        schoolId5Option,
      },
      {
        courseId1Option,
        courseId2Option,
        courseId3Option,
        courseId4Option,
        courseId5Option,
      },
      {
        cityId1Option,
        cityId2Option,
        cityId3Option,
        cityId4Option,
        cityId5Option,
      },
      {
        itineraryId1Option,
        itineraryId2Option,
        itineraryId3Option,
        itineraryId4Option,
        itineraryId5Option,
      },
    ) as NewEnrollmentFormData;
    yield put(NewEnrollmentCreators.fetchNewEnrollment.success(enrollmentData));
  } catch (error) {
    const responseError = mapErrorToResponseError(error);

    yield put(
      NewEnrollmentCreators.fetchNewEnrollment.failure(responseError.message),
    );
  }
}

export default all([
  takeLatest(
    NewEnrollmentCreators.createNewEnrollment.request,
    createNewEnrollment,
  ),
  takeLatest(
    NewEnrollmentCreators.updateNewEnrollment.request,
    updateNewEnrollment,
  ),
  takeLatest(
    NewEnrollmentCreators.fetchNewEnrollment.request,
    fetchNewEnrollment,
  ),
]);
