interface RouteOtptions {
  title?: string;
  navBackTitle?: string;
  hidden: boolean;
  hiddenBackButton?: boolean;
  // FIXME: Remove any type [MA]
  navigateTo?: any;
}

function extractRouteOptions(location: string): RouteOtptions {
  switch (location) {
    case '/home/registerStudent':
      return {
        title: 'Cadastro do estudante',
        navBackTitle: 'Página Inicial',
        hidden: false,
        hiddenBackButton: false,
        navigateTo: '/home/profile',
      };
    case '/home':
      return {
        title: '',
        navBackTitle: 'Sair',
        hidden: true,
        hiddenBackButton: true,
        navigateTo: '/auth',
      };
    case '/home/attendence':
      return {
        title: 'Frequência',
        navBackTitle: 'Voltar',
        hidden: false,
        hiddenBackButton: false,
        navigateTo: '/home',
      };
    case '/home/mySchool':
      return {
        title: 'Minha Escola',
        navBackTitle: 'Voltar',
        hidden: false,
        hiddenBackButton: false,
        navigateTo: '/home',
      };
    case '/home/studentReEnroll':
      return {
        title: 'Matrícula',
        navBackTitle: 'Página Inicial',
        hidden: false,
        hiddenBackButton: false,
        navigateTo: '/home/profile',
      };
    case '/home/followUp':
      return {
        title: 'Acompanhamento',
        navBackTitle: 'Página Inicial',
        hidden: false,
        hiddenBackButton: false,
        navigateTo: '/home/profile',
      };
    case '/home/studentNewEnrollment':
      return {
        title: 'Nova Matrícula',
        navBackTitle: 'Página Inicial',
        hidden: false,
        hiddenBackButton: false,
        navigateTo: '/home/profile',
      };
    case '/home/grades':
      return {
        title: 'Minhas notas',
        navBackTitle: 'Voltar',
        hidden: false,
        hiddenBackButton: false,
        navigateTo: '/home/profile',
      };
    case '/home/studentEnrollmentConfirmation':
      return { hidden: true };
    default:
      return { hidden: true };
  }
}

export function getHeaderTitle(location: string): string {
  switch (location) {
    case '/home/profile':
      return 'Matrículas - 2023';
    case '/home/studentReEnrollmentConfirmation':
    case '/home/newEnrollmentConfirmation':
      return 'Matrícula 2023 - Estudante';
    default:
      return '';
  }
}

export function getTitlePage(location: string): string {
  return extractRouteOptions(location).title || '';
}

export function getNavBackTitle(location: string): string {
  return extractRouteOptions(location).navBackTitle || '';
}

export function isHidden(location: string): boolean {
  return extractRouteOptions(location).hidden;
}

export function isHiddenBackButton(location: string): boolean {
  return extractRouteOptions(location).hiddenBackButton || false;
}

export function getNavigateTo(location: string): any {
  return extractRouteOptions(location).navigateTo;
}

export default {
  getTitlePage,
  getNavBackTitle,
  isHidden,
  getNavigateTo,
  isHiddenBackButton,
};
