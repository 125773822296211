import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components';
import Banner from 'components/Banner';
import { RootState } from 'store';
import {
  DropdownHeaderActions,
  DropdownHeaderCreators,
} from 'store/ducks/dropdownHeader';

import {
  Container,
  ContainerButtons,
  Content,
  Title,
  BannerContainer,
  BannerContent,
} from './styles';

const SigninTypes = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navToProfile = () => navigate('/home/profile');

  // TODO: remover logica quando a proxima etapa tiver aberta [MA]
  const educationDataFormEnrollment = useSelector(
    ({ enrollment }: RootState) => enrollment.educationDataForm,
  );

  const { hasTransfer } = useSelector(({ transfer }: RootState) => transfer);

  return (
    <>
      <BannerContainer>
        <BannerContent>
          <Banner
            message="ATENÇÃO: caso você seja um aluno VETERANO, clique no botão “renovação de matrícula” para seguir. Caso você seja um aluno NOVATO, clique no botão “nova matrícula” para seguir."
            variant="warning"
          />
        </BannerContent>
      </BannerContainer>
      <Container>
        <Content>
          <Title>Qual a ação desejada?</Title>
          <ContainerButtons>
            <Button
              size="large"
              styled="secondary"
              title="Renovação de matrícula (VETERANO)"
              onClick={() => {
                dispatch(
                  DropdownHeaderCreators.setCurrentDropdownHeader(
                    DropdownHeaderActions.RE_ENROLLMENT,
                  ),
                );
                navToProfile();
              }}
              disabled={!hasTransfer && isEmpty(educationDataFormEnrollment)}
            />
            <Button
              size="large"
              styled="secondary"
              title="Nova matrícula (NOVATO)"
              onClick={() => {
                dispatch(
                  DropdownHeaderCreators.setCurrentDropdownHeader(
                    DropdownHeaderActions.NEW_ENROLLMENT,
                  ),
                );
                navToProfile();
              }}
              disabled={hasTransfer || !isEmpty(educationDataFormEnrollment)}
            />
          </ContainerButtons>
        </Content>
      </Container>
    </>
  );
};

export default SigninTypes;
