import styled from 'styled-components';

import { BodyRegular, Caption, H3 } from 'components/StyledTypography/styles';

export const Title = styled(H3)`
  color: ${({ theme }) => theme.palette.black};
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const BodyText = styled(Caption)`
  color: ${({ theme }) => theme.palette.black};
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 12px 8px 12px 16px;

  background-color: ${({ theme }) => theme.palette.grey4};
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
`;

export const InputText = styled(BodyRegular)`
  color: ${({ theme }) => theme.palette.black};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;
