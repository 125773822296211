import styled from 'styled-components';

export const Container = styled.div`
  max-width: 378px;
  min-width: 302px;
  border: 1px solid #f1edf0;
  border-radius: 8px;
  flex-grow: 1;
`;

interface MediaFinalProp {
  aproved: boolean;
}

export const Media = styled.h2<MediaFinalProp>`
  font-weight: 700;
  color: ${({ aproved }) => (aproved ? '#07AB39' : '#DB4040')};
`;

export const Icon = styled.img``;

export const Title = styled.h1`
  padding: 12px;
  padding: 9px;
  letter-spacing: 0.2px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;

  color: #022757;
`;

export const Head = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const Table = styled.table`
  background: #ffffff;
  border-collapse: collapse;
  border: 1px solid #f1edf0;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  border: 0;
  padding: 16px;

  td {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 114%;

    letter-spacing: 0.2px;

    color: #1c1413;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 100%;
  }

  tbody {
    border-top: 1px solid #f1edf0;
    margin-top: 32px;
    tr:nth-child(odd) {
      background-color: #f8f8f8;
    }
  }
`;
