import styled, { css } from 'styled-components';

import { Caption, LabelRegular } from 'components/StyledTypography/styles';

interface InputContainerProps {
  width: string;
  height: string;
  error: boolean;
}

interface MessageProps {
  error: boolean;
}

interface LabelProps {
  disabled: boolean;
}

export const InputContainer = styled.div<InputContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: ${props => props.width ?? '100%'};
`;

export const Label = styled(Caption)<LabelProps>`
  color: ${({ theme, disabled }) =>
    disabled ? theme.palette.grey2 : theme.palette.black};
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

export const InputText = styled.input<InputContainerProps>`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.2px;

  width: 100%;
  height: 100%;
  border: 1px solid ${props => props.theme.palette.grey1};
  border-radius: 4px;
  padding: 8px 16px;

  :hover {
    border: 1px solid ${props => props.theme.palette.primary};
  }

  ::placeholder {
    color: ${props => props.theme.palette.grey1};
  }

  :focus-visible {
    outline: none;
    border: 1px solid ${props => props.theme.palette.primary};
  }

  ::disabled {
    background-color: ${props => props.theme.palette.grey1};
    border: none;
  }

  &:disabled {
    border: none;
  }

  ${({ error }) =>
    error &&
    css`
      border-color: ${props => props.theme.palette.negative};
      background-color: ${props => props.theme.palette.errorBackground};

      :hover {
        border-color: ${props => props.theme.palette.negative};
      }
    `}
`;
export const Message = styled(LabelRegular)<MessageProps>`
  color: ${props => props.theme.palette.grey1};

  ${({ error }) =>
    error &&
    css`
      color: ${props => props.theme.palette.negative};
    `}
`;
