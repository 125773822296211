import base from 'services/api/base';
import { ServiceEndpoints } from 'services/api/types';

import {
  CreateDocumentStudentRequestData,
  CreateDocumentStudentResponseData,
  FetchDocumentsStudentResponseData,
  UpdateDocumenStudentRequestData,
  UpdateDocumenStudentResponseData,
} from './models';

const Strings = {
  DEFAULT_ERROR_SET_DOCUMENTS: 'Erro ao salvar documentos',
};

export const DocumentsStudentEndpoints: ServiceEndpoints = {
  createDocumentsStudent: {
    path: 'students',
    errorMessages: {
      204: 'Documentos salvos com sucesso',
      400: 'Dados inválidos',
      401: 'Não autorizado',
      403: 'Você não tem permissão para realizar esta ação',
      404: 'Aluno não encontrado',
      409: 'Conflito',
      default: Strings.DEFAULT_ERROR_SET_DOCUMENTS,
    },
  },
  updateDocumentsStudent: {
    path: 'students',
    errorMessages: {
      204: 'Documentos salvos com sucesso',
      400: 'Dados inválidos',
      401: 'Não autorizado',
      403: 'Você não tem permissão para realizar esta ação',
      default: Strings.DEFAULT_ERROR_SET_DOCUMENTS,
    },
  },
  fetchDocumentsStudent: {
    path: 'students',
    errorMessages: {
      204: 'Documentos salvos com sucesso',
      400: 'Dados inválidos',
      401: 'Não autorizado',
      403: 'Você não tem permissão para realizar esta ação',
      default: Strings.DEFAULT_ERROR_SET_DOCUMENTS,
    },
  },
};

async function createDocumentsStudent(
  id: number,
  data: CreateDocumentStudentRequestData,
): Promise<CreateDocumentStudentResponseData> {
  const endpoint = DocumentsStudentEndpoints.createDocumentsStudent;
  const response = await base.api.post<CreateDocumentStudentResponseData>(
    `${endpoint.path}/${id}/docs`,
    data,
  );

  return response.data;
}

async function updateDocumentsStudent(
  id: number,
  data: UpdateDocumenStudentRequestData,
): Promise<UpdateDocumenStudentResponseData> {
  const endpoint = DocumentsStudentEndpoints.updateDocumentsStudent;
  const response = await base.api.patch<UpdateDocumenStudentResponseData>(
    `${endpoint.path}/${id}/docs`,
    data,
  );

  return response.data;
}

async function fetchDocumentsStudent(
  id: number,
): Promise<FetchDocumentsStudentResponseData> {
  const endpoint = DocumentsStudentEndpoints.fetchDocumentsStudent;
  const response = await base.api.get<FetchDocumentsStudentResponseData>(
    `${endpoint.path}/${id}/docs`,
  );
  return response.data;
}

export const DocumentsStudentService = {
  createDocumentsStudent,
  updateDocumentsStudent,
  fetchDocumentsStudent,
};
