import styled from 'styled-components';

import { BodyRegular, H2 } from 'components/StyledTypography/styles';

export const Container = styled.div`
  width: 100%;
  flex: 1;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 54px;
  padding: 32px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 32px;
  max-width: 600px;

  background-color: ${({ theme }) => theme.palette.white};
  box-shadow: 0px 2px 14px -2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px; */

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    /* grid-template-columns: 1fr; */
    button {
      width: 100%;
    }
  }
`;

export const Title = styled(H2)`
  margin-top: 24px;
  color: ${({ theme }) => theme.palette.black};
  text-align: center;
`;

export const SubTitle = styled(BodyRegular)`
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 56px;
  color: ${({ theme }) => theme.palette.black};
`;
