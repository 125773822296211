import produce, { Draft } from 'immer';
import { ActionType, createAsyncAction, Reducer } from 'typesafe-actions';

export enum Types {
  STATES_REQUEST = '@states/STATES_REQUEST',
  STATES_SUCCESS = '@states/STATES_SUCCESS',
  STATES_FAILURE = '@states/STATES_FAILURE',
}

export interface State {
  value: number;
  label: string;
  short: string;
}

export interface StatesState {
  loading: boolean;
  states: State[];
}

const INITIAL_STATE: StatesState = {
  loading: false,
  states: [],
};

export const StatesCreators = {
  getStates: createAsyncAction(
    Types.STATES_REQUEST,
    Types.STATES_SUCCESS,
    Types.STATES_FAILURE,
  )<void, State[], string>(),
};

export type ActionTypes = ActionType<typeof StatesCreators>;

const reducer: Reducer<StatesState, ActionTypes> = (
  state = INITIAL_STATE,
  action: ActionTypes,
) => {
  const { type, payload } = action;

  return produce(state, (draft: Draft<StatesState>) => {
    switch (type) {
      case Types.STATES_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.STATES_SUCCESS: {
        draft.loading = false;
        draft.states = payload as State[];
        break;
      }
      case Types.STATES_FAILURE: {
        draft.loading = false;
        break;
      }

      default:
    }
  });
};

export default reducer;
