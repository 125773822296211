import produce, { Draft } from 'immer';
import { Reducer } from 'redux';
import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';

import { Keys, Status } from 'models/student';

export enum Types {
  SET_UPLOADING_FILES_REQUEST = '@uploadingFiles/SET_UPLOADING_FILES_REQUEST',
  SET_UPLOADING_FILES_SUCCESS = '@uploadingFiles/SET_UPLOADING_FILES_SUCCESS',
  SET_UPLOADING_FILES_FAILURE = '@uploadingFiles/SET_UPLOADING_FILES_FAILURE',
}

export interface UploadRequest {
  type: Keys;
  status: Status;
}

export type UploadingFilesState = {
  files: Record<Keys, Status>;
};

const INITIAL_STATE: UploadingFilesState = {
  files: {
    CPF: 'idle',
    PHOTO: 'idle',
    SCHOOL_RECORDS: 'idle',
    BIRTH_CERTIFICATE: 'idle',
    SCHOLING_STATEMENT: 'idle',
    ADDRESS: 'idle',
    SUS: 'idle',
    VACCINATION_CARD: 'idle',
    SOCIALNAME: 'idle',
    ELECTORAL_DISCHARGE: 'idle',
    MILITARY_DISCHARGE: 'idle',
  },
};

export const UploadingFilesCreators = {
  setUploadingFilesRequest: createAction(
    Types.SET_UPLOADING_FILES_REQUEST,
  )<UploadRequest>(),
};

export type ActionTypes = ActionType<typeof UploadingFilesCreators>;

const reducer: Reducer<UploadingFilesState, ActionTypes> = (
  state = INITIAL_STATE,
  action: ActionTypes,
) => {
  const { type, payload } = action;
  return produce(state, (draft: Draft<UploadingFilesState>) => {
    const uploadRequest = payload as UploadRequest;
    switch (type) {
      case Types.SET_UPLOADING_FILES_REQUEST:
        draft.files[uploadRequest.type] = uploadRequest.status;
        break;
      default:
    }
  });
};

export default reducer;
