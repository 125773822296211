import { useCallback, useState } from 'react';

import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { IconConfirmation } from 'assets/icons';
import { NewPasswordImage } from 'assets/images';
import { Button, Input } from 'components';
import { useForgotPassword } from 'hooks/ForgotPassword';
import { getFormikMessageError } from 'utils/formikUtils';
import { textRequiredSchema } from 'utils/validations';

import {
  Container,
  Content,
  Title,
  Information,
  Form,
  ButtonsDiv,
} from '../ForgotPassword/styles';

const FORM_ID = 'recover-password-form';

const RECOVER_PASSWORD_SCHEMA = Yup.object().shape({
  password: textRequiredSchema,
  passwordConfirmation: textRequiredSchema.oneOf(
    [Yup.ref('password'), null],
    'As senhas devem ser iguais',
  ),
});

const RecoverPassword = () => {
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const { onResetPassword, loading } = useForgotPassword();
  const { token } = useParams();

  const onSubmit = useCallback(
    async formData => {
      const succeed = await onResetPassword({
        token: token ?? '',
        newPassword: formData.password,
      });
      setSuccess(succeed);
    },
    [onResetPassword, token],
  );

  const formik = useFormik({
    initialValues: { password: undefined, passwordConfirmation: undefined },
    validationSchema: RECOVER_PASSWORD_SCHEMA,
    onSubmit,
  });

  if (success) {
    return (
      <Container>
        <Content>
          <Title>Senha Atualizada</Title>
          <IconConfirmation style={{ margin: 24 }} />
          <Button
            title="Acessar sistema"
            size="medium"
            type="button"
            onClick={() => navigate('/auth')}
          />
        </Content>
      </Container>
    );
  }

  return (
    <Container>
      <Content>
        <Title>Nova senha</Title>
        <NewPasswordImage style={{ marginTop: 24 }} />
        <Information>Insira uma nova senha</Information>
        <Form id={FORM_ID}>
          <Input
            id="password"
            label="Senha"
            type="password"
            placeholder="Informe sua senha"
            onChange={formik.handleChange}
            value={formik.values.password}
            onBlur={formik.handleBlur}
            messageError={getFormikMessageError(formik, 'password')}
          />
          <Input
            id="passwordConfirmation"
            label="Confirme Sua Senha"
            type="password"
            placeholder="Insira novamente sua senha"
            onChange={formik.handleChange}
            value={formik.values.passwordConfirmation}
            onBlur={formik.handleBlur}
            messageError={getFormikMessageError(formik, 'passwordConfirmation')}
          />
          <ButtonsDiv>
            <Button
              title="Atualizar senha"
              size="medium"
              type="button"
              form={FORM_ID}
              onClick={formik.submitForm}
              disabled={loading}
            />
          </ButtonsDiv>
        </Form>
      </Content>
    </Container>
  );
};

export default RecoverPassword;
