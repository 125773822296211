import styled, { css } from 'styled-components';

import { LabelRegular } from 'components/StyledTypography/styles';

interface MessageProps {
  error: boolean;
}
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Content = styled.label`
  display: block;
  position: relative;
  padding-left: 36px;
  margin-bottom: 12px;
  cursor: cursor;
  user-select: none;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 114%;
  letter-spacing: 0.2px;

  span {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background: ${({ theme }) => theme.palette.white};
    border-radius: 4px;
    border: 2px solid ${({ theme }) => theme.palette.grey3};
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  input:checked ~ span {
    background-color: ${({ theme }) => theme.palette.primary};
  }
  span:after {
    content: '';
    position: absolute;
    display: none;
  }
  input:checked ~ span:after {
    display: block;
  }
  span:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
export const Message = styled(LabelRegular)<MessageProps>`
  color: ${props => props.theme.palette.grey1};

  ${({ error }) =>
    error &&
    css`
      color: ${props => props.theme.palette.negative};
    `}
`;
