import styled from 'styled-components';

import { H2 } from 'components/StyledTypography/styles';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 64px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 34px 102px;
  background-color: ${({ theme }) => theme.palette.white};
  box-shadow: 0px 2px 14px -2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 34px 32px;
  }
`;

export const BannerContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 90%;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 95%;
  }
`;

export const BannerContainer = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  margin-top: 40px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  button {
    width: 100%;
  }
`;

export const Title = styled(H2)`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  /* identical to box height, or 24px */

  letter-spacing: 0.2px;

  color: #000000;
`;
