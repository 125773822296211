import React, { createContext, useContext, useState } from 'react';

import { toast } from 'react-toastify';

import { Option } from 'components/Select';
import { EducationService } from 'services/api/education';
import { mapErrorToResponseError } from 'services/api/responseErrorHandler';

type MediationsParams = { educationOptionId: string };
type ModalitiesParams = { mediationId: string };
type StepsParams = { modalityId: string };
type TemporalitiesParams = { stepId: string };
type ShiftParams = { stepId: string };

export interface EducationFilterContextData {
  loading: boolean;
  educationOptions: Option[];
  mediations: Option[];
  steps: Option[];
  modalities: Option[];
  temporalities: Option[];
  shifts: Option[];

  getEducationOptions: () => Promise<void>;
  getMediations: (filter: MediationsParams) => Promise<void>;
  getModalities: (filter: ModalitiesParams) => Promise<void>;
  getSteps: (filter: StepsParams) => Promise<void>;
  getTemporalities: (filter: TemporalitiesParams) => Promise<void>;
  getShifts: (filter: ShiftParams) => Promise<void>;
}

const EducationFilterContext = createContext<EducationFilterContextData>(
  {} as EducationFilterContextData,
);

const EducationFilterProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [mediations, setMediations] = useState<Option[]>([]);
  const [steps, setSteps] = useState<Option[]>([]);
  const [modalities, setModalities] = useState<Option[]>([]);
  const [educationOptions, setEducationOptions] = useState<Option[]>([]);
  const [shifts, setShifts] = useState<Option[]>([]);
  const [temporalities, setTemporalities] = useState<Option[]>([]);

  const showErrorMessage = (error: unknown) => {
    // const responseError = mapErrorToResponseError(error);
    // toast.error(responseError.message);
  };

  const getEducationOptions = async () => {
    setLoading(true);
    try {
      const response = await EducationService.fetchEducationOptions();
      setEducationOptions(
        response
          .sort((itemA, itemB) => (itemA.nome < itemB.nome ? 1 : -1))
          .map(item => ({ value: item.id, label: item.nome })),
      );
      setMediations([]);
      setModalities([]);
      setSteps([]);
      setTemporalities([]);
      setShifts([]);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const getMediations = async (filter: MediationsParams) => {
    setLoading(true);
    try {
      const response = await EducationService.fetchMediations({
        educationOption: filter.educationOptionId,
      });
      setMediations(
        response.map(item => ({ value: item.id, label: item.nome })),
      );
      setModalities([]);
      setSteps([]);
      setTemporalities([]);
      setShifts([]);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const getModalities = async (filter: ModalitiesParams) => {
    setLoading(true);
    try {
      const response = await EducationService.fetchModalities({
        mediation: filter.mediationId,
      });
      setModalities(
        response.map(item => ({ value: item.id, label: item.nome })),
      );
      setSteps([]);
      setTemporalities([]);
      setShifts([]);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const getSteps = async (filter: StepsParams) => {
    setLoading(true);
    try {
      const response = await EducationService.fetchSteps({
        modality: filter.modalityId,
      });
      setSteps(response.map(item => ({ value: item.id, label: item.nome })));
      setTemporalities([]);
      setShifts([]);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const getShifts = async (filter: ShiftParams) => {
    setLoading(true);
    try {
      const response = await EducationService.fetchShifts({
        step: filter.stepId,
      });
      setShifts(response.map(item => ({ value: item.id, label: item.nome })));
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const getTemporalities = async (filter: TemporalitiesParams) => {
    setLoading(true);
    try {
      const response = await EducationService.fetchTemporalities({
        step: filter.stepId,
      });
      setTemporalities(
        response.map(item => ({ value: item.id, label: item.nome })),
      );
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <EducationFilterContext.Provider
      value={{
        loading,
        mediations,
        steps,
        modalities,
        educationOptions,
        shifts,
        temporalities,
        getMediations,
        getModalities,
        getEducationOptions,
        getSteps,
        getShifts,
        getTemporalities,
      }}
    >
      {children}
    </EducationFilterContext.Provider>
  );
};

function useEducationFilter(): EducationFilterContextData {
  const context = useContext(EducationFilterContext);

  if (!context) {
    throw new Error(
      'useEducationFilter must be used within a EducationFilterContext',
    );
  }
  return context;
}

export { useEducationFilter, EducationFilterContext, EducationFilterProvider };
