import produce, { Draft } from 'immer';
import { ActionType, createAsyncAction, Reducer } from 'typesafe-actions';

import { Option } from 'components/Select';

export enum Types {
  SCHOOL_FILTERS_REQUEST = '@schoolFilters/SCHOOL_FILTERS_REQUEST',
  SCHOOL_FILTERS_SUCCESS = '@schoolFilters/SCHOOL_FILTERS_SUCCESS',
  SCHOOL_FILTERS_FAILURE = '@schoolFilters/SCHOOL_FILTERS_FAILURE',
  SCHOOL_SEE_REQUEST = '@schoolFilters/SCHOOL_SEE_REQUEST',
  SCHOOL_SEE_SUCCESS = '@schoolFilters/SCHOOL_SEE_SUCCESS',
  SCHOOL_SEE_FAILURE = '@schoolFilters/SCHOOL_SEE_FAILURE',
  FETCH_ALL_SCHOOLS_REQUEST = '@schoolFilters/FETCH_ALL_SCHOOLS_REQUEST',
  FETCH_ALL_SCHOOLS_SUCCESS = '@schoolFilters/FETCH_ALL_SCHOOLS_SUCCESS',
  FETCH_ALL_SCHOOLS_FAILURE = '@schoolFilters/FETCH_ALL_SCHOOLS_FAILURE',
}

export interface SchoolFilters {
  gerencialRegionalEnsino: Option[];
  situacaoFuncionamento: Option[];
  autorizacaoFuncionamento: Option[];
  zonaEscolar: Option[];
  localizacaoDiferenciada: Option[];
  localFuncionamento: Option[];
  tipoSalaUtilizada: Option[];
  tipoMediacao: Option[];
  modalidade: Option[];
  modalidadeExtra: Option[];
  etapa: Option[];
  regime: Option[];
  turno: Option[];
  temporalidade: Option[];
  temporalidadeEpt: Option[];
  temporalidadeEptFic: Option[];
  ofertaEpt: Option[];
  etapaEpt: Option[];
  tipoAtendimento: Option[];
  oferta: Option[];
  turma: Option[];
  statusEscola: Option[];
  localFuncionamentoDiferenciado: Option[];
}

export interface StatesState {
  loading: boolean;
  filters: SchoolFilters;
  schools: Option[];
  allSchools: Option[];
}

const INITIAL_STATE: StatesState = {
  loading: false,
  filters: {} as SchoolFilters,
  schools: [],
  allSchools: [],
};

export const SchoolFiltersCreators = {
  getSchoolFilters: createAsyncAction(
    Types.SCHOOL_FILTERS_REQUEST,
    Types.SCHOOL_FILTERS_SUCCESS,
    Types.SCHOOL_FILTERS_FAILURE,
  )<void, SchoolFilters, string>(),
  getSchoolsSEE: createAsyncAction(
    Types.SCHOOL_SEE_REQUEST,
    Types.SCHOOL_SEE_SUCCESS,
    Types.SCHOOL_SEE_FAILURE,
  )<void, Option[], string>(),
  getAllSchools: createAsyncAction(
    Types.FETCH_ALL_SCHOOLS_REQUEST,
    Types.FETCH_ALL_SCHOOLS_SUCCESS,
    Types.FETCH_ALL_SCHOOLS_FAILURE,
  )<void, Option[], string>(),
};

export type ActionTypes = ActionType<typeof SchoolFiltersCreators>;

const reducer: Reducer<StatesState, ActionTypes> = (
  state = INITIAL_STATE,
  action: ActionTypes,
) => {
  const { type, payload } = action;

  return produce(state, (draft: Draft<StatesState>) => {
    switch (type) {
      case Types.FETCH_ALL_SCHOOLS_REQUEST:
      case Types.SCHOOL_SEE_REQUEST:
      case Types.SCHOOL_FILTERS_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.SCHOOL_FILTERS_SUCCESS: {
        draft.loading = false;
        draft.filters = payload as SchoolFilters;
        break;
      }
      case Types.FETCH_ALL_SCHOOLS_SUCCESS: {
        draft.loading = false;
        draft.allSchools = payload as Option[];
        break;
      }
      case Types.SCHOOL_SEE_SUCCESS: {
        draft.loading = false;
        draft.schools = payload as Option[];
        break;
      }
      case Types.SCHOOL_SEE_FAILURE:
      case Types.FETCH_ALL_SCHOOLS_FAILURE:
      case Types.SCHOOL_FILTERS_FAILURE: {
        draft.loading = false;
        break;
      }

      default:
    }
  });
};

export default reducer;
