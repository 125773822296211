import produce, { Draft } from 'immer';
import { Reducer } from 'redux';
import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';

import { Identifier } from 'models/identifier';
import { ResponsablesData } from 'models/student';

export enum Types {
  CREATE_RESPONSABLE_DATA_REQUEST = '@responsableData/CREATE_RESPONSABLE_DATA_REQUEST',
  CREATE_RESPONSABLE_DATA_SUCCESS = '@responsableData/CREATE_RESPONSABLE_DATA_SUCCESS',
  CREATE_RESPONSABLE_DATA_FAILURE = '@responsableData/CREATE_RESPONSABLE_DATA_FAILURE',
  UPDATE_RESPONSABLE_DATA_REQUEST = '@responsableData/UPDATE_RESPONSABLE_DATA_REQUEST',
  UPDATE_RESPONSABLE_DATA_SUCCESS = '@responsableData/UPDATE_RESPONSABLE_DATA_SUCCESS',
  UPDATE_RESPONSABLE_DATA_FAILURE = '@responsableData/UPDATE_RESPONSABLE_DATA_FAILURE',
  FETCH_RESPONSABLE_DATA_REQUEST = '@responsableData/FETCH_RESPONSABLE_DATA_REQUEST',
  FETCH_RESPONSABLE_DATA_SUCCESS = '@responsableData/FETCH_RESPONSABLE_DATA_SUCCESS',
  FETCH_RESPONSABLE_DATA_FAILURE = '@responsableData/FETCH_RESPONSABLE_DATA_FAILURE',
}

export type ResponsablesDataResponse = ResponsablesData;

export interface StudentsState {
  loading: boolean;
  responsableStudentData?: ResponsablesDataResponse;
  studentId: number;
}

const INITIAL_STATE: StudentsState = {
  loading: false,
  responsableStudentData: {} as ResponsablesDataResponse,
  studentId: -1,
};

export const StudentsCreators = {
  createResponsableData: createAsyncAction(
    Types.CREATE_RESPONSABLE_DATA_REQUEST,
    Types.CREATE_RESPONSABLE_DATA_SUCCESS,
    Types.CREATE_RESPONSABLE_DATA_FAILURE,
  )<ResponsablesDataResponse, ResponsablesDataResponse, string>(),
  updateResponsableData: createAsyncAction(
    Types.UPDATE_RESPONSABLE_DATA_REQUEST,
    Types.UPDATE_RESPONSABLE_DATA_SUCCESS,
    Types.UPDATE_RESPONSABLE_DATA_FAILURE,
  )<ResponsablesDataResponse, ResponsablesDataResponse, string>(),
  fetchResponsableData: createAsyncAction(
    Types.FETCH_RESPONSABLE_DATA_REQUEST,
    Types.FETCH_RESPONSABLE_DATA_SUCCESS,
    Types.FETCH_RESPONSABLE_DATA_FAILURE,
  )<void, ResponsablesDataResponse, string>(),
};

export type ActionTypes = ActionType<typeof StudentsCreators>;

const reducer: Reducer<StudentsState, ActionTypes> = (
  state = INITIAL_STATE,
  action: ActionTypes,
) => {
  const { type, payload } = action;

  return produce(state, (draft: Draft<StudentsState>) => {
    switch (type) {
      case Types.FETCH_RESPONSABLE_DATA_REQUEST:
        draft.loading = true;
        break;

      case Types.FETCH_RESPONSABLE_DATA_SUCCESS:
        draft.loading = false;
        draft.responsableStudentData = payload as ResponsablesDataResponse;
        break;
      case Types.CREATE_RESPONSABLE_DATA_REQUEST:
      case Types.UPDATE_RESPONSABLE_DATA_REQUEST:
        draft.loading = true;
        break;
      case Types.UPDATE_RESPONSABLE_DATA_SUCCESS:
      case Types.CREATE_RESPONSABLE_DATA_SUCCESS:
        draft.loading = false;
        draft.responsableStudentData = payload as ResponsablesDataResponse;
        break;
      case Types.FETCH_RESPONSABLE_DATA_FAILURE:
      case Types.CREATE_RESPONSABLE_DATA_FAILURE:
      case Types.UPDATE_RESPONSABLE_DATA_FAILURE:
        draft.loading = false;
        break;
      default:
    }
  });
};

export default reducer;
