import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from 'components';
import { Caption, H2 } from 'components/StyledTypography/styles';

export const Container = styled.div`
  width: 100%;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-top: 32px;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px;

  background-color: ${({ theme }) => theme.palette.white};
  box-shadow: 0px 2px 14px -2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`;

export const Title = styled(H2)`
  color: ${({ theme }) => theme.palette.black};
`;

export const Subtitle = styled(Caption)`
  color: ${({ theme }) => theme.palette.grey1};
  margin: 16px 0px;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  margin-top: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const RecoveryPassword = styled(Caption)`
  display: flex;
  margin-top: 16px;
  align-items: center;
  justify-content: center;

  text {
    color: ${({ theme }) => theme.palette.primaryDark};
    font-weight: 600;
  }
`;

export const ForgotPassword = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.grey1};

  text {
    text-decoration: underline;
    color: ${({ theme }) => theme.palette.primaryDark};
    font-weight: bold;
  }
`;

export const OrContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const OrLine = styled.div`
  border-bottom: 0.5px solid #cbd2d9;
  width: 100%;
`;

export const OrText = styled(Caption)`
  color: ${({ theme }) => theme.palette.grey1};
  margin: 0px 8px;
`;

export const SignInButton = styled(Button)`
  width: 100%;
`;

export const SignUpButton = styled(Button)`
  border: ${({ theme }) => `1px solid ${theme.palette.primary}`};
  color: ${({ theme }) => theme.palette.primary};
  width: 100%;
`;
