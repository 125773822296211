import packageJson from '../../../package.json';
import { Container, FooterText } from './styles';

const Footer = () => (
  <Container>
    <FooterText>{`2023 | SEECT- Governo da Paraíba | v${packageJson.version}`}</FooterText>
  </Container>
);

export default Footer;
