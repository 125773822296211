const ONLY_NUMBERS_REGEX = /\D/g;

export function normalizeCpfValue(value: string): string {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');

  if (onlyNums.length <= 3) {
    return onlyNums;
  }

  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3)}`;
  }

  if (onlyNums.length <= 9) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
      6,
    )}`;
  }

  return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
    6,
    9,
  )}-${onlyNums.slice(9, 11)}`;
}

export function normalizeNisValue(value: string): string {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');

  if (onlyNums.length <= 3) {
    return onlyNums;
  }

  if (onlyNums.length <= 8) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3)}`;
  }

  if (onlyNums.length <= 10) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 8)}.${onlyNums.slice(
      8,
    )}`;
  }

  return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 8)}.${onlyNums.slice(
    8,
    10,
  )}-${onlyNums.slice(10, 11)}`;
}

export function normalizeOnlyNumbers(value: string): string {
  return value ? value.replace(ONLY_NUMBERS_REGEX, '') : value;
}

export function normalizePhoneValue(value: string): string {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');

  if (onlyNums.length <= 2) {
    return `(${onlyNums}`;
  }

  if (onlyNums.length <= 6) {
    return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2)}`;
  }

  if (onlyNums.length <= 10) {
    return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 6)}-${onlyNums.slice(
      6,
    )}`;
  }

  return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}-${onlyNums.slice(
    7,
    11,
  )}`;
}

export function normalizeCEPValue(value: string): string {
  if (!value) {
    return '';
  }

  const parsed = normalizeOnlyNumbers(value);
  return parsed.length <= 5
    ? parsed
    : `${parsed.substr(0, 5)}-${parsed.substr(5, 3)}`;
}

export function normalizeCurrency(value: string): string {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(ONLY_NUMBERS_REGEX, '');

  return onlyNums
    .replace(/(\d)(\d{2})$/g, '$1,$2')
    .replace(/(?=(\d{3})+(\D))\B/g, '.');
}

export function normalizeDate(value: string): string {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');

  if (onlyNums.length <= 4) {
    return onlyNums;
  }

  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4)}`;
  }

  return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 6)}-${onlyNums.slice(
    6,
    8,
  )}`;
}

export const cpfMask = (value: string) => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
};

export const cepMask = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1');
};
