import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Outlet } from 'react-router-dom';

import { Header, HeaderNavigation, BarSpinner } from 'components';
import {
  getTitlePage,
  isHidden,
  getNavBackTitle,
  getNavigateTo,
  isHiddenBackButton,
  getHeaderTitle,
} from 'routes/helpers';
import { RootState } from 'store';
import { AuthCreators } from 'store/ducks/auth';
import { DropdownHeaderActions } from 'store/ducks/dropdownHeader';

import { Container } from './styles';

const Home = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const loadingGeneralInfo = useSelector(
    ({ studentGeneralInfo }: RootState) => studentGeneralInfo.loading,
  );
  const loadingResidentialArea = useSelector(
    ({ residentialArea }: RootState) => residentialArea.loading,
  );
  const loadingResponsableData = useSelector(
    ({ responsableData }: RootState) => responsableData.loading,
  );
  const loadingEnrollment = useSelector(
    ({ enrollment }: RootState) => enrollment.loading,
  );
  const loadingDocuments = useSelector(
    ({ documentsStudent }: RootState) => documentsStudent.loading,
  );
  const loadingAttachments = useSelector(
    ({ uploadStudentFile }: RootState) => uploadStudentFile.loading,
  );
  const loadingFollowUp = useSelector(
    ({ followUp }: RootState) => followUp.loading,
  );
  const currentAction = useSelector(
    ({ dropdownHeader }: RootState) => dropdownHeader.currentAction,
  );
  const isNewEnrollment =
    currentAction === DropdownHeaderActions.NEW_ENROLLMENT;

  const isLoading =
    loadingResponsableData ||
    loadingAttachments ||
    loadingResidentialArea ||
    loadingEnrollment ||
    loadingDocuments ||
    loadingGeneralInfo ||
    loadingFollowUp;

  useEffect(() => {
    dispatch(AuthCreators.fetchUser.request());
  }, [dispatch]);

  return (
    <Container>
      <BarSpinner loading={isLoading} />
      <Header
        menuItemVariant="user"
        title={getHeaderTitle(location.pathname)}
      />
      <HeaderNavigation
        title={
          getTitlePage(location.pathname) &&
          `${getTitlePage(location.pathname)} - ${
            isNewEnrollment ? 'Novato' : 'Veterano'
          }`
        }
        hidden={isHidden(location.pathname)}
        navBackTitle={getNavBackTitle(location.pathname)}
        navigateTo={getNavigateTo(location.pathname)}
        hiddenBackButton={isHiddenBackButton(location.pathname)}
      />
      <Outlet />
    </Container>
  );
};

export default Home;
