import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';

import { store } from 'store';
import { AuthCreators } from 'store/ducks/auth';

import { AuthService } from './auth';
import { responseErrorHandler } from './responseErrorHandler';

const request = async (config: AxiosRequestConfig) => {
  const accessToken = store.getState().auth.userAuthInfo?.accessToken;
  const headers: AxiosRequestHeaders = { ...config.headers };

  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }
  headers['Content-Type'] = 'application/json';

  return { ...config, headers };
};

const responseFailed = async (error: AxiosCustomError) => {
  const originalRequest = error.config;
  if (error.response?.status === 401 && !originalRequest.retry) {
    originalRequest.retry = true;
    const refreshToken = store.getState().auth.userAuthInfo?.refreshToken || '';
    try {
      const config = await AuthService.refreshToken({ refreshToken });
      store.dispatch(AuthCreators.setUseAuthInfo(config));
      originalRequest.headers = {
        ...originalRequest.headers,
        Authorization: `Bearer ${config.accessToken} `,
      };
      return Promise.resolve(axios(originalRequest));
    } catch {
      return Promise.resolve(responseErrorHandler(error));
    }
  }
  return Promise.reject(responseErrorHandler(error));
};

export const Interceptors = {
  responseFailed,
  request,
};
