import { isNil, omitBy } from 'lodash';

import {
  schoolRegionOptions,
  binaryOptions,
  countryOptions,
  sexOptions,
  racialOptions,
  nationalityOptions,
  studentAdditionalResourceOptions,
  communityOptions,
  clothingSize,
  disabilityOptions,
} from 'assets/json';
import { Option } from 'components/Select';
import { StudentFormData } from 'models/student';
import {
  unwrapMultiOptionValue,
  unwrapOptionValue,
  wrapOptionValue,
} from 'utils/wrapperUtils';

import {
  CreateGeneralStudentRequestData,
  FetchGeneralStudentResponseData,
} from './models';

export function mapGeneralStudentFormToGeneralStudentRequest(
  form: StudentFormData,
): CreateGeneralStudentRequestData {
  return {
    code: form.code || null,
    birthCityId: unwrapOptionValue(form.birthCityOption),
    birthStateId: unwrapOptionValue(form.birthStateOption),
    fullname: form.fullname,
    socialName: form.socialName || null,
    network: unwrapOptionValue(form.networkOption),
    sex: unwrapOptionValue(form.sexOption),
    race: unwrapOptionValue(form.raceOption),
    nationality: unwrapOptionValue(form.nationalityOption),
    nationalityCountry: unwrapOptionValue(form.nationalityCountryOption),
    passport: form.passport,
    phone: form.phone,
    email: form.email,
    affectiveName: form.affectiveName || null,
    handicapped: unwrapOptionValue(form.handicappedOption),
    handicappedType: unwrapOptionValue(form.handicappedTypeOption),
    // TODO: Verificar significado desse campo [MA]
    extraType: undefined,
    itinerant: unwrapOptionValue(form.itinerantOption),
    specialCommunity: unwrapOptionValue(form.specialCommunityOption),
    shirtSize: unwrapOptionValue(form.shirtSizeOption),
    shortsSize: unwrapOptionValue(form.shortsSizeOption),
    extraResource: unwrapMultiOptionValue(form.extraResourceOption),
    ppl: unwrapOptionValue(form.pplOption),
    hasSocialName: unwrapOptionValue(form.hasSocialNameInSchoolRecordsOption),
    birthdate: form.birthdate,
    type: form.type,
  };
}

export function mapGeneralStudentResponseToGeneralStudentForm(
  requestData: FetchGeneralStudentResponseData,
): Partial<StudentFormData> {
  const stateOptions: Option[] = [
    {
      label:
        requestData.city &&
        requestData.city.state &&
        requestData.city.state.name,
      value:
        requestData.city && requestData.city.state && requestData.city.state.id,
    },
  ];
  const cityOptions: Option[] = [
    {
      label: requestData.city && requestData.city.name,
      value: requestData.city && requestData.city.id,
    },
  ];
  return omitBy(
    {
      birthStateOption: wrapOptionValue(stateOptions, requestData.birthStateId),
      birthCityOption: wrapOptionValue(cityOptions, requestData.birthCityId),
      code: requestData.code,
      networkOption: wrapOptionValue(schoolRegionOptions, requestData.network),
      fullname: requestData.fullname,
      hasSocialNameOption: wrapOptionValue(
        binaryOptions,
        !!requestData.socialName,
      ),
      socialName: requestData.socialName,
      hasSocialNameInSchoolRecordsOption: wrapOptionValue(
        binaryOptions,
        requestData.hasSocialName,
      ),
      sexOption: wrapOptionValue(sexOptions, requestData.sex),
      raceOption: wrapOptionValue(racialOptions, requestData.race),
      nationalityOption: wrapOptionValue(
        nationalityOptions,
        requestData.nationality,
      ),
      affectiveName: requestData.affectiveName,
      nationalityCountryOption: wrapOptionValue(
        countryOptions,
        requestData.nationalityCountry,
      ),
      passport: requestData.passport,
      phone: requestData.phone,
      email: requestData.email,
      handicappedOption: wrapOptionValue(
        binaryOptions,
        requestData.handicapped,
      ),
      handicappedTypeOption: wrapOptionValue(
        disabilityOptions,
        requestData.handicappedType,
      ),
      pplOption: wrapOptionValue(binaryOptions, requestData.ppl),
      extraResourceOption: requestData.extraResource?.map(item =>
        wrapOptionValue(studentAdditionalResourceOptions, item),
      ),
      itinerantOption: wrapOptionValue(binaryOptions, requestData.itinerant),
      specialCommunityOption: wrapOptionValue(
        communityOptions,
        requestData.specialCommunity,
      ),
      shirtSizeOption: wrapOptionValue(clothingSize, requestData.shirtSize),
      shortsSizeOption: wrapOptionValue(clothingSize, requestData.shortsSize),
      type: requestData.type,
      birthdate: requestData.user.birthdate,
    },
    isNil,
  );
}
