/* eslint-disable @typescript-eslint/no-unused-vars */
import produce, { Draft } from 'immer';
import { Reducer } from 'redux';
import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';

import { Identifier } from 'models/identifier';
import { ResidentalArea } from 'models/student';
import { SignInRequestData } from 'services/api/auth/models';

export enum Types {
  CREATE_RESIDENTIAL_AREA_REQUEST = '@residentialArea/RESIDENTIAL_AREA_REQUEST',
  CREATE_RESIDENTIAL_AREA_SUCCESS = '@residentialArea/RESIDENTIAL_AREA_SUCCESS',
  CREATE_RESIDENTIAL_AREA_FAILURE = '@residentialArea/RESIDENTIAL_AREA_FAILURE',
  UPDATE_RESIDENTIAL_AREA_REQUEST = '@residentialArea/UPDATE_RESIDENTIAL_AREA_REQUEST',
  UPDATE_RESIDENTIAL_AREA_SUCCESS = '@residentialArea/UPDATE_RESIDENTIAL_AREA_SUCCESS',
  UPDATE_RESIDENTIAL_AREA_FAILURE = '@residentialArea/UPDATE_RESIDENTIAL_AREA_FAILURE',
  FETCH_RESIDENTIAL_AREA_REQUEST = '@residentialArea/FETCH_RESIDENTIAL_AREA_REQUEST',
  FETCH_RESIDENTIAL_AREA_SUCCESS = '@residentialArea/FETCH_RESIDENTIAL_AREA_SUCCESS',
  FETCH_RESIDENTIAL_AREA_FAILURE = '@residentialArea/FETCH_RESIDENTIAL_AREA_FAILURE',
}

export type ResidentalAreaRequest = ResidentalArea;

export interface ResidentalAreaState {
  loading: boolean;
  residentialArea: ResidentalAreaRequest;
  studentId: number;
}

const INITIAL_STATE: ResidentalAreaState = {
  loading: false,
  residentialArea: {} as ResidentalAreaRequest,
  studentId: -1,
};

export const ResidentialAreaCreators = {
  createResidentialArea: createAsyncAction(
    Types.CREATE_RESIDENTIAL_AREA_REQUEST,
    Types.CREATE_RESIDENTIAL_AREA_SUCCESS,
    Types.CREATE_RESIDENTIAL_AREA_FAILURE,
  )<ResidentalAreaRequest, ResidentalArea, string>(),
  updateResidentialArea: createAsyncAction(
    Types.UPDATE_RESIDENTIAL_AREA_REQUEST,
    Types.UPDATE_RESIDENTIAL_AREA_SUCCESS,
    Types.UPDATE_RESIDENTIAL_AREA_FAILURE,
  )<ResidentalAreaRequest, ResidentalArea, string>(),
  fetchResidentialArea: createAsyncAction(
    Types.FETCH_RESIDENTIAL_AREA_REQUEST,
    Types.FETCH_RESIDENTIAL_AREA_SUCCESS,
    Types.FETCH_RESIDENTIAL_AREA_FAILURE,
  )<void, ResidentalAreaRequest, string>(),
};

export type ActionTypes = ActionType<typeof ResidentialAreaCreators>;

const reducer: Reducer<ResidentalAreaState, ActionTypes> = (
  state = INITIAL_STATE,
  action: ActionTypes,
) => {
  const { type, payload } = action;

  return produce(state, (draft: Draft<ResidentalAreaState>) => {
    switch (type) {
      case Types.FETCH_RESIDENTIAL_AREA_REQUEST:
        draft.loading = true;
        break;
      case Types.FETCH_RESIDENTIAL_AREA_SUCCESS:
        draft.loading = false;
        draft.residentialArea = payload as ResidentalAreaRequest;
        break;
      case Types.CREATE_RESIDENTIAL_AREA_REQUEST:
      case Types.UPDATE_RESIDENTIAL_AREA_REQUEST:
        draft.loading = true;
        break;
      case Types.UPDATE_RESIDENTIAL_AREA_SUCCESS:
      case Types.CREATE_RESIDENTIAL_AREA_SUCCESS:
        draft.residentialArea = payload as ResidentalAreaRequest;
        draft.loading = false;
        break;
      case Types.CREATE_RESIDENTIAL_AREA_FAILURE:
      case Types.UPDATE_RESIDENTIAL_AREA_FAILURE:
      case Types.FETCH_RESIDENTIAL_AREA_FAILURE:
        draft.loading = false;
        break;

      default:
    }
  });
};

export default reducer;
