import styled from 'styled-components';

import { Caption } from 'components/StyledTypography/styles';

export const OneLine = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 24px;
`;

export const SelectLabel = styled(Caption)`
  margin-bottom: 12px;
`;

export const SelectContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    grid-template-columns: 1fr;
  }
`;
