/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';

import { Tooltip } from '@chakra-ui/react';

import { IconInputInfo } from 'assets/icons';
import { SchoolOptionValue } from 'models/enrollment';

import {
  SelectContainer,
  StyledSelect,
  Label,
  Message,
  RowContainer,
} from './styles';

export type OptionValuesTypes =
  | string
  | boolean
  | number
  | SchoolOptionValue
  | undefined;

export interface Option {
  value: OptionValuesTypes;
  label: string;
}

export interface SelectProps {
  options: Option[];
  placeholder?: string;
  label?: string;
  width?: string;
  height?: string;
  name?: string;
  defaultValue?: Option | null;
  value?: Option | Option[] | null;
  onChange?: (inputValue: any, actionMeta: any) => void;
  hint?: string;
  messageError?: string;
  disabled?: boolean;
  info?: string;
  isMulti?: boolean;
  required?: boolean;
}

const Select: React.FC<SelectProps> = ({
  options,
  placeholder = 'Selecione uma opção',
  width = '100%',
  height = '40px',
  label = '',
  onChange,
  defaultValue,
  name,
  value,
  hint = '',
  messageError = '',
  disabled = false,
  info = '',
  isMulti = false,
  required = false,
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const error = !!messageError;

  const handleOnScroll = () => {
    setMenuIsOpen(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleOnScroll);
    return () => window.removeEventListener('scroll', handleOnScroll);
  }, []);

  return (
    <SelectContainer width={width} height={height}>
      {label && (
        <RowContainer>
          <Label>
            {label}
            {required ? '*' : ''}
          </Label>
          {info && (
            <Tooltip
              label={info}
              fontFamily="Inter"
              fontStyle="normal"
              fontSize={13}
              fontWeight="normal"
              placement="top"
              backgroundColor="#121212"
              borderRadius={8}
            >
              <IconInputInfo />
            </Tooltip>
          )}
        </RowContainer>
      )}
      <StyledSelect
        name={name}
        options={options}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={onChange}
        classNamePrefix="Select"
        value={value}
        error={error}
        isDisabled={disabled}
        noOptionsMessage={() => 'Sem opções'}
        isMulti={isMulti}
        menuPosition="fixed"
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
        menuIsOpen={menuIsOpen}
      />
      {!error ? (
        <Message error={error}>{hint}</Message>
      ) : (
        <Message error={error}>{messageError}</Message>
      )}
    </SelectContainer>
  );
};

export default Select;
