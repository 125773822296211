import styled from 'styled-components';

import { Caption } from 'components/StyledTypography/styles';

export const Container = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
`;

export const FooterText = styled(Caption)`
  color: ${({ theme }) => theme.palette.grey1};
`;
