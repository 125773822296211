import styled from 'styled-components';

import {
  H2,
  LabelExtraBold,
  Caption,
} from 'components/StyledTypography/styles';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 32px;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 650px;
  box-shadow: 0px 2px 14px -2px rgba(0, 0, 0, 0.15);
  background: ${({ theme }) => theme.palette.white};
  padding: 32px;
  width: 100%;
`;

export const Title = styled(H2)`
  color: ${({ theme }) => theme.palette.black};
`;

export const Subtitle = styled(Caption)`
  margin-top: 8px;
  color: ${({ theme }) => theme.palette.black};
  text-align: center;
`;

export const LabelRegister = styled(LabelExtraBold)`
  width: 100%;
  margin-top: 24px;
`;

export const Form = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 32px;
`;

export const HorizontalDivider = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.grey3};
  margin: 8px 0px;
`;

export const FormRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
  margin: 16px 0px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    grid-template-columns: 1fr;
  }
`;

export const TermContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex: 1;
  margin-bottom: 24px;
  width: 100%;
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 24px;
`;
