import base from 'services/api/base';
import { ServiceEndpoints } from 'services/api/types';

import {
  FetchModalitiesRequest,
  FetchShiftsRequest,
  FetchStepsRequest,
  FetchTemporalitiesRequest,
  EducationResponseData,
  FetchEducationData,
  FetchMediationsRequest,
} from './models';

const Strings = {
  DEFAULT_ERROR: 'Algo deu errado',
};

export const EducationEndpoints: ServiceEndpoints = {
  fetchMediations: {
    path: 'education/mediation',
    errorMessages: {
      404: 'Mediação não encontrada',
      default: Strings.DEFAULT_ERROR,
    },
  },
  fetchModalities: {
    path: 'education/modality',
    errorMessages: {
      404: 'Modalidade não encontrada',
      default: Strings.DEFAULT_ERROR,
    },
  },
  fetchEducationOptions: {
    path: 'education/educationOption',
    errorMessages: {
      404: 'Modalidade extra não encontrada',
      default: Strings.DEFAULT_ERROR,
    },
  },
  fetchSteps: {
    path: 'education/step',
    errorMessages: {
      404: 'Etapa não encontrada',
      default: Strings.DEFAULT_ERROR,
    },
  },
  fetchShifts: {
    path: 'education/shift',
    errorMessages: {
      404: 'Turno não encontrado',
      default: Strings.DEFAULT_ERROR,
    },
  },
  fetchTemporalities: {
    path: 'education/temporality',
    errorMessages: {
      404: 'Temporalidade não encontrado',
      default: Strings.DEFAULT_ERROR,
    },
  },
};

async function fetchMediations(
  params: FetchMediationsRequest,
): Promise<EducationResponseData[]> {
  const endpoint = EducationEndpoints.fetchMediations;
  const response = await base.api.get<FetchEducationData>(endpoint.path, {
    params: {
      disablePagination: true,
      ...params,
    },
  });
  return response.data.records;
}

async function fetchModalities(
  params: FetchModalitiesRequest,
): Promise<EducationResponseData[]> {
  const endpoint = EducationEndpoints.fetchModalities;
  const response = await base.api.get<FetchEducationData>(endpoint.path, {
    params: {
      disablePagination: true,
      ...params,
    },
  });
  return response.data.records;
}

async function fetchEducationOptions(): Promise<EducationResponseData[]> {
  const endpoint = EducationEndpoints.fetchEducationOptions;
  const response = await base.api.get<FetchEducationData>(endpoint.path, {
    params: {
      disablePagination: true,
    },
  });
  return response.data.records;
}

async function fetchSteps(
  params: FetchStepsRequest,
): Promise<EducationResponseData[]> {
  const endpoint = EducationEndpoints.fetchSteps;
  const response = await base.api.get<FetchEducationData>(endpoint.path, {
    params: {
      disablePagination: true,
      ...params,
    },
  });
  return response.data.records;
}

async function fetchShifts(
  params: FetchShiftsRequest,
): Promise<EducationResponseData[]> {
  const endpoint = EducationEndpoints.fetchShifts;
  const response = await base.api.get<FetchEducationData>(endpoint.path, {
    params: {
      disablePagination: true,
      ...params,
    },
  });
  return response.data.records;
}

async function fetchTemporalities(
  params: FetchTemporalitiesRequest,
): Promise<EducationResponseData[]> {
  const endpoint = EducationEndpoints.fetchTemporalities;
  const response = await base.api.get<FetchEducationData>(endpoint.path, {
    params: {
      disablePagination: true,
      ...params,
    },
  });
  return response.data.records;
}

export const EducationService = {
  fetchMediations,
  fetchModalities,
  fetchEducationOptions,
  fetchSteps,
  fetchShifts,
  fetchTemporalities,
};
