import base from 'services/api/base';

import { ServiceEndpoints } from '../types';
import {
  SchoolFiltersResponse,
  FetchSchoolsResponse,
  FetchSchoolResponse,
  FetchSchoolRequest,
  FetchSchoolsRequest,
  FetchSchoolCoursesResponse,
  FetchSchoolItineraiesResponse,
} from './models';

const Strings = {
  DEFAULT_ERROR: 'Algo deu errado',
};

export const SchoolSEEEndpoints: ServiceEndpoints = {
  filters: {
    path: 'schools/seepb/filters',
    errorMessages: {
      401: 'Não autorizado',
      403: 'Sem permissão para acessar',
      default: 'Erro ao recuperar filtros',
    },
  },
  schools: {
    path: 'schools/seepb',
    errorMessages: {
      401: 'Não autorizado',
      403: 'Sem permissão para acessar',
      default: 'Erro ao recuperar as escolas',
    },
  },
  school: {
    path: 'schools/seepb',
    errorMessages: {
      401: 'Não autorizado',
      403: 'Sem permissão para acessar',
      404: 'Escola não encontrada',
      default: 'Erro ao recuperar as escolas',
    },
  },
  schoolCourses: {
    path: 'schools/seepb/{id}/courses',
    errorMessages: {
      401: 'Não autorizado',
      403: 'Sem permissão para acessar',
      404: 'Curso não encontrado',
      default: 'Erro ao recuperar cursos',
    },
  },
  itineraries: {
    path: 'schools/seepb/{id}/itineraries',
    errorMessages: {
      401: 'Não autorizado',
      403: 'Sem permissão para acessar',
      404: 'Curso não encontrado',
      default: 'Erro ao recuperar cursos',
    },
  },
};

async function fetchSchoolFilters(): Promise<SchoolFiltersResponse> {
  const endpoint = SchoolSEEEndpoints.filters;
  const response = await base.api.get<SchoolFiltersResponse>(endpoint.path);

  return response.data;
}

async function fetchSchools(
  params: FetchSchoolsRequest,
): Promise<FetchSchoolsResponse> {
  const endpoint = SchoolSEEEndpoints.schools;
  const response = await base.api.get<FetchSchoolsResponse>(endpoint.path, {
    params: {
      disablePagination: true,
      ...params,
    },
  });
  return response.data;
}

async function fetchSchool(
  params: FetchSchoolRequest,
): Promise<FetchSchoolResponse> {
  const endpoint = SchoolSEEEndpoints.schools;
  const response = await base.api.get<FetchSchoolResponse>(
    `${endpoint.path}/${params.id}`,
  );
  return response.data;
}

async function fetchSchoolCourses(params: {
  id: string;
}): Promise<FetchSchoolCoursesResponse> {
  const endpoint = SchoolSEEEndpoints.schoolCourses;
  const response = await base.api.get<FetchSchoolCoursesResponse>(
    endpoint.path.replace('{id}', params.id),
  );
  return response.data;
}

async function fetchSchoolItineraries(params: { id: string }) {
  const endpoint = SchoolSEEEndpoints.itineraries;
  const response = await base.api.get<FetchSchoolItineraiesResponse>(
    endpoint.path.replace('{id}', params.id),
  );
  return response.data;
}

export const SchoolSEEService = {
  fetchSchoolFilters,
  fetchSchools,
  fetchSchool,
  fetchSchoolCourses,
  fetchSchoolItineraries,
};
