import produce, { Draft } from 'immer';
import {
  ActionType,
  createAsyncAction,
  Reducer,
  createAction,
} from 'typesafe-actions';

export enum Types {
  CITIES_REQUEST = '@cities/CITIES_REQUEST',
  CITIES_SUCCESS = '@cities/CITIES_SUCCESS',
  CITIES_FAILURE = '@cities/CITIES_FAILURE',
  CITIES_CLEAN = '@cities/CITIES_CLEAN',
}

export interface CityOption {
  value: number;
  label: string;
}

export interface CitiesState {
  loading: boolean;
  cityOptions: CityOption[];
}

const INITIAL_STATE: CitiesState = {
  loading: false,
  cityOptions: [],
};

export const CitiesCreators = {
  getCitiesByStateId: createAsyncAction(
    Types.CITIES_REQUEST,
    Types.CITIES_SUCCESS,
    Types.CITIES_FAILURE,
  )<{ stateId: number }, CityOption[], string>(),
  cleanCities: createAction(Types.CITIES_CLEAN)<void>(),
};

export type ActionTypes = ActionType<typeof CitiesCreators>;

const reducer: Reducer<CitiesState, ActionTypes> = (
  state = INITIAL_STATE,
  action: ActionTypes,
) => {
  const { type, payload } = action;

  return produce(state, (draft: Draft<CitiesState>) => {
    switch (type) {
      case Types.CITIES_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.CITIES_SUCCESS: {
        draft.loading = false;
        draft.cityOptions = payload as CityOption[];
        break;
      }
      case Types.CITIES_FAILURE: {
        draft.loading = false;
        break;
      }
      case Types.CITIES_CLEAN: {
        draft.loading = false;
        draft.cityOptions = [];
        break;
      }

      default:
    }
  });
};

export default reducer;
