import { useCallback, useEffect, useMemo } from 'react';

import { useFormik } from 'formik';
import { isEmpty, isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { IconArrowLeft, IconArrowRight } from 'assets/icons';
import responsableRelation from 'assets/json/responsableRelation.json';
import { Button, Input, Select } from 'components';
import InputKeyboardDate from 'components/InputKeyboardDate';
import { Option } from 'components/Select';
import { ResponsablesData } from 'models/student';
import {
  ChildrenFormContainer,
  ChildrenFormRow,
  GrayLine,
  StepsButtonContainer,
} from 'pages/StudentEnrollment/styles';
import { RootState } from 'store';
import { StudentsCreators } from 'store/ducks/students/responsableData';
import {
  checkFormikErrorsAndSetFieldTouched,
  getFormikMessageError,
} from 'utils/formikUtils';
import { normalizeCpfValue, normalizePhoneValue } from 'utils/maskUtils';
import {
  dateRequiredSchema,
  cpfRequiredSchema,
  emailSchema,
  phoneSchema,
  personNameRequiredSchema,
  textRequiredSchema,
  personNameOptionalSchema,
  cpfSchema,
  dateSchema,
  emailSchemaOptional,
  phoneSchemaOptional,
} from 'utils/validations';

enum responsablesDataType {
  GRANDMOTHER = 'Avó',
  GRANDFATHER = 'Avô',
  SISTER = 'Irmã',
  BROTHER = 'Irmão',
  MOTHER = 'Mãe',
  FATHER = 'Pai',
  UNCLE = 'Tio',
  AUNT = 'Tia',
  OTHER = 'Outros',
}

interface ResponsibleFormProps {
  onBack: () => void;
  onNext: () => void;
  editing: boolean;
}

const RESPONSABLES_DATA_FORM_SCHEMA = Yup.object().shape({
  fatherName: personNameOptionalSchema,
  motherName: personNameRequiredSchema,
  responsableName: personNameRequiredSchema,
  responsableRelation: Yup.string()
    .oneOf([
      'GRANDMOTHER',
      'GRANDFATHER',
      'SISTER',
      'BROTHER',
      'MOTHER',
      'FATHER',
      'UNCLE',
      'AUNT',
      'OTHER',
    ])
    .required('Campo obrigatório'),
  responsableCPF: cpfRequiredSchema,
  responsableBirthdate: dateRequiredSchema,
  responsableProfession: textRequiredSchema,
  responsableOccupation: textRequiredSchema,
  responsablePhone: phoneSchema,
  responsableEmail: emailSchema,
});

const PPL_RESPONSABLES_DATA_FORM_SCHEMA = Yup.object().shape({
  fatherName: personNameOptionalSchema,
  motherName: personNameOptionalSchema,
  responsableName: personNameOptionalSchema,
  responsableRelation: Yup.string().oneOf([
    'GRANDMOTHER',
    'GRANDFATHER',
    'SISTER',
    'BROTHER',
    'MOTHER',
    'FATHER',
    'UNCLE',
    'AUNT',
    'OTHER',
  ]),
  responsableCPF: cpfSchema,
  responsableBirthdate: dateSchema,
  responsableProfession: Yup.string(),
  responsableOccupation: Yup.string(),
  responsablePhone: phoneSchemaOptional,
  responsableEmail: emailSchemaOptional,
});

const ResponsibleForm = ({ onBack, onNext, editing }: ResponsibleFormProps) => {
  const dispatch = useDispatch();
  const { loading, responsableStudentData } = useSelector(
    ({ responsableData }: RootState) => responsableData,
  );
  const { cpf: studentCPF } = useSelector(
    ({ documentsStudent }: RootState) => documentsStudent.data,
  );
  const { studentFormData } = useSelector(
    ({ studentGeneralInfo }: RootState) => studentGeneralInfo,
  );
  const isPPL = !!studentFormData?.pplOption?.value;

  const onSubmit = useCallback(
    (formData: ResponsablesData) => {
      if (editing) {
        onNext();
      }

      if (
        studentCPF &&
        studentCPF.replace(/\D/g, '') ===
          formData.responsableCPF?.replace(/\D/g, '')
      ) {
        toast.error('O CPF do responsável não pode ser igual ao do estudante');
        return;
      }

      if (isEqual(responsableStudentData, formData)) {
        onNext();
      } else if (isEmpty(responsableStudentData)) {
        dispatch(StudentsCreators.createResponsableData.request(formData));
      } else {
        dispatch(StudentsCreators.updateResponsableData.request(formData));
      }
    },
    [dispatch, onNext, responsableStudentData],
  );

  const schema = useMemo(
    () =>
      isPPL ? PPL_RESPONSABLES_DATA_FORM_SCHEMA : RESPONSABLES_DATA_FORM_SCHEMA,
    [isPPL],
  );

  const formik = useFormik({
    initialValues: responsableStudentData || ({} as ResponsablesData),
    validationSchema: schema,
    onSubmit,
  });

  useEffect(() => {
    checkFormikErrorsAndSetFieldTouched(formik);
  }, [formik.isSubmitting]);

  return (
    <ChildrenFormContainer id="form-responsables">
      <ChildrenFormRow>
        <Input
          label="Filiação - Pai"
          placeholder="Digite o nome"
          name="fatherName"
          onChange={formik.handleChange}
          value={formik.values?.fatherName}
          messageError={getFormikMessageError(formik, 'fatherName')}
          onBlur={formik.handleBlur}
          disabled={formik.values.noAffiliation}
        />
        <Input
          label="Filiação - Mãe"
          name="motherName"
          placeholder="Digite o nome"
          required={!isPPL}
          onChange={formik.handleChange}
          value={formik.values?.motherName}
          messageError={getFormikMessageError(formik, 'motherName')}
          onBlur={formik.handleBlur}
          disabled={formik.values.noAffiliation}
        />
      </ChildrenFormRow>

      <GrayLine />

      <ChildrenFormRow>
        <Input
          label="Nome do responsável"
          name="responsableName"
          placeholder="Digite o nome"
          required={!isPPL}
          onChange={formik.handleChange}
          value={formik.values.responsableName}
          messageError={getFormikMessageError(formik, 'responsableName')}
          onBlur={formik.handleBlur}
        />
        <Select
          label="Parentesco"
          name="responsableRelation"
          options={responsableRelation}
          onChange={(option: Option, _) =>
            formik.setFieldValue('responsableRelation', option.value)
          }
          value={
            formik.values.responsableRelation
              ? {
                  label:
                    responsablesDataType[formik.values.responsableRelation],
                  value: formik.values.responsableRelation,
                }
              : undefined
          }
          messageError={getFormikMessageError(formik, 'responsableRelation')}
          required={!isPPL}
        />
      </ChildrenFormRow>
      <ChildrenFormRow>
        <Input
          name="responsableCPF"
          label="CPF do responsável"
          placeholder="Digite o CPF"
          required={!isPPL}
          onChange={evt =>
            formik.setFieldValue(
              'responsableCPF',
              normalizeCpfValue(evt.target.value),
            )
          }
          value={formik.values.responsableCPF}
          onBlur={formik.handleBlur}
          messageError={getFormikMessageError(formik, 'responsableCPF')}
          maxLength={14}
        />
        <InputKeyboardDate
          label="Data do nascimento do responsável"
          name="responsableBirthdate"
          required={!isPPL}
          onChange={date =>
            formik.setFieldValue(
              'responsableBirthdate',
              date?.toDateString() ?? undefined,
            )
          }
          value={
            formik.values.responsableBirthdate
              ? new Date(`${formik.values.responsableBirthdate} 06:00`)
              : null
          }
          onBlur={formik.handleBlur}
          messageError={getFormikMessageError(formik, 'responsableBirthdate')}
        />
      </ChildrenFormRow>

      <GrayLine />

      <ChildrenFormRow>
        <Input
          label="Profissão"
          name="responsableProfession"
          placeholder="Digite a profissão"
          required={!isPPL}
          onChange={formik.handleChange}
          value={formik.values.responsableProfession}
          onBlur={formik.handleBlur}
          messageError={getFormikMessageError(formik, 'responsableProfession')}
        />
        <Input
          label="Ocupação"
          name="responsableOccupation"
          placeholder="Digite a ocupação"
          required={!isPPL}
          onChange={formik.handleChange}
          value={formik.values.responsableOccupation}
          onBlur={formik.handleBlur}
          messageError={getFormikMessageError(formik, 'responsableOccupation')}
        />
      </ChildrenFormRow>
      <ChildrenFormRow>
        <Input
          label="Contato Telefônico"
          placeholder="(83) 0000-0000"
          name="responsablePhone"
          required={!isPPL}
          value={formik.values.responsablePhone}
          onChange={evt =>
            formik.setFieldValue(
              'responsablePhone',
              normalizePhoneValue(evt.target.value),
            )
          }
          maxLength={15}
          onBlur={formik.handleBlur}
          messageError={getFormikMessageError(formik, 'responsablePhone')}
        />
        <Input
          label="E-mail do responsável"
          placeholder="Digite o E-mail"
          name="responsableEmail"
          required={!isPPL}
          onChange={formik.handleChange}
          value={formik.values.responsableEmail}
          onBlur={formik.handleBlur}
          messageError={getFormikMessageError(formik, 'responsableEmail')}
        />
      </ChildrenFormRow>

      <StepsButtonContainer>
        <Button
          iconLeft={editing ? undefined : IconArrowLeft}
          title={editing ? 'Cancelar' : 'Anterior'}
          type="button"
          styled="secondary"
          size="mini"
          onClick={onBack}
        />

        <Button
          iconRight={editing ? undefined : IconArrowRight}
          type="button"
          form="form-responsables"
          size="mini"
          title={editing ? 'Atualizar' : 'Próximo'}
          onClick={formik.submitForm}
          disabled={loading}
        />
      </StepsButtonContainer>
    </ChildrenFormContainer>
  );
};
export default ResponsibleForm;
