import base from 'services/api/base';

import { ServiceEndpoints } from '../types';
import { StatesResponse } from './models';

const Strings = {
  DEFAULT_ERROR: 'Erro ao recuperar estados',
};

export const StatesEndpoints: ServiceEndpoints = {
  states: {
    path: 'states',
    errorMessages: {
      401: 'Não autorizado',
      403: 'Sem permissão para acessar',
      default: Strings.DEFAULT_ERROR,
    },
  },
};

async function fetchStates(): Promise<StatesResponse> {
  const endpoint = StatesEndpoints.states;
  const response = await base.api.get(endpoint.path, {
    params: { disablePagination: true },
  });
  return response.data;
}

export const StatesService = { fetchStates };
