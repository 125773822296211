import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { AccountValidationProvider } from 'hooks/AccountValidationContext';
import { EducationFilterProvider } from 'hooks/EducationFilterContext';
import {
  Auth,
  Attendence,
  Landing,
  Login,
  SignUp,
  ForgotPassword,
  Home,
  StudentEnrollment,
  SigninTypes,
  StudentEnrollmentConfirmation,
  UserProfile,
  StudentReEnroll,
  StudentReEnrollmentConfirmation,
  FollowUp,
  MySchool,
  NewEnrollment,
  NewEnrollmentConfirmation,
  RecoverPassword,
  ModuleSelection,
  ReviewData,
  ReEnrollConfirmation,
  TransferConfirmation,
  Grades,
  AccountValidation,
} from 'pages';
import { RedirectToRecoverPassword } from 'pages/Auth/RecoverPassword/RedirectToRecoverPassword';

import { RequireAuth } from './RequireAuth';

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route
          path="/auth/accountValidation/:token"
          element={
            <AccountValidationProvider>
              <AccountValidation />
            </AccountValidationProvider>
          }
        />
        <Route
          path="/forgotPassword/:token"
          element={<RedirectToRecoverPassword />}
        />
        <Route path="/auth" element={<Auth />}>
          <Route index element={<Login />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="forgotPassword" element={<ForgotPassword />} />
          <Route path="forgotPassword/:token" element={<RecoverPassword />} />
        </Route>
        <Route
          path="/home"
          element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }
        >
          <Route index element={<ModuleSelection />} />
          <Route path="reviewData" element={<ReviewData />} />
          <Route path="signinTypes" element={<SigninTypes />} />
          <Route path="profile" element={<UserProfile />} />
          <Route path="registerStudent" element={<StudentEnrollment />} />
          <Route
            path="studentEnrollmentConfirmation"
            element={<StudentEnrollmentConfirmation />}
          />
          <Route
            path="studentReEnroll"
            element={
              <EducationFilterProvider>
                <StudentReEnroll />
              </EducationFilterProvider>
            }
          />
          <Route
            path="studentReEnrollmentConfirmation"
            element={<StudentReEnrollmentConfirmation />}
          />
          <Route path="followUp" element={<FollowUp />} />
          <Route path="attendence" element={<Attendence />} />
          <Route path="mySchool" element={<MySchool />} />
          <Route
            path="studentNewEnrollment"
            element={
              <EducationFilterProvider>
                <NewEnrollment />
              </EducationFilterProvider>
            }
          />
          <Route
            path="studentNewEnrollmentConfirmation"
            element={<NewEnrollmentConfirmation />}
          />
          <Route
            path="reEnrollConfirmation"
            element={<ReEnrollConfirmation />}
          />
          <Route
            path="transferConfirmation"
            element={<TransferConfirmation />}
          />
          <Route path="grades" element={<Grades />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
