import { Button } from 'components';

import {
  Container,
  TextContainer,
  Step,
  Title,
  Divider,
  DisabledText,
  ContainerIcon,
  Icon,
} from './styles';

interface CardProps {
  step: string;
  title: string;
  state:
    | 'update'
    | 'continue'
    | 'active'
    | 'disabled'
    | 'view'
    | 'blocked'
    | 'closed';
  onClick: () => void;
  isLoading: boolean;
  icon: string;
}

// <Button title="Iniciar" onClick={onClick} />

const Card = ({ step, title, state, onClick, isLoading, icon }: CardProps) => {
  const renderButton = () => {
    switch (state) {
      case 'active':
        return (
          <Button
            title="Iniciar"
            onClick={onClick}
            size="small"
            disabled={isLoading}
          />
        );
      case 'continue':
        return (
          <Button
            title="Continuar"
            onClick={onClick}
            size="small"
            disabled={isLoading}
          />
        );
      case 'update':
        return (
          <Button
            styled="secondary"
            title="Atualizar"
            onClick={onClick}
            size="small"
            disabled={isLoading}
          />
        );
      case 'view':
        return (
          <Button
            title="Visualizar"
            onClick={onClick}
            size="small"
            disabled={isLoading}
          />
        );
      case 'disabled':
        return <DisabledText>Finalize a etapa anterior</DisabledText>;
      case 'blocked':
        return <DisabledText>Etapa disponível em breve</DisabledText>;
      case 'closed':
        return <DisabledText>Etapa indisponível</DisabledText>;
      default:
        return <div />;
    }
  };

  const disabled = state === 'disabled' || state === 'blocked';

  return (
    <Container disabled={disabled}>
      <ContainerIcon>
        <Icon src={icon} disabled={disabled} />
        <Divider />
      </ContainerIcon>
      <TextContainer>
        <Step disabled={disabled}>{step}</Step>
        <Title disabled={disabled}>{title}</Title>
      </TextContainer>
      {renderButton()}
    </Container>
  );
};

export default Card;
