// eslint-disable-next-line import/no-duplicates
import { format, parse, parseISO, isDate } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { ptBR } from 'date-fns/locale';

export const DATE_FORMAT = 'dd/MM/yyyy';

const locales = { locale: ptBR };

export function formatDate(value: string): string {
  if (isDate(value)) {
    try {
      return format(value as unknown as Date, 'P', locales);
    } catch {
      return '';
    }
  }

  const date = parseISO(value);
  try {
    return format(new Date(date), 'P', locales);
  } catch {
    return '';
  }
}
export function dateFormatted(value: string): string {
  try {
    return format(new Date(`${value}`), DATE_FORMAT, { locale: ptBR });
  } catch {
    return '';
  }
}

export function dateDashFormatted(value: string): string {
  try {
    return format(new Date(value), 'yyyy-MM-dd', { locale: ptBR });
  } catch {
    return '';
  }
}

export function parseDateString(value: any) {
  return isDate(value) ? value : parse(value, DATE_FORMAT, new Date());
}

export function getAge(dateString: string) {
  const today = new Date();
  const birthDate = new Date(`${dateString}T00:00`);
  const age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();

  return m < 0 || (m === 0 && today.getDate() < birthDate.getDate())
    ? age - 1
    : age;
}
