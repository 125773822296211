export const palette = {
  primary: '#022859',
  primaryDark: '#022859',
  primaryLight: '#33ABFF',
  primaryLighter: 'rgba(51, 171, 255, 0.1)',
  secondary: '#0b4b75',
  accent: '#99ff66',
  black: '#121212',
  darkBlue: '#032859',
  darkBlue2: '#022859',
  grey1: '#888888',
  grey2: '#C1C1C1',
  grey3: '#E0E0E0',
  grey4: '#F8F8F8',
  white: '#FFFFFF',
  white2: '#EFEFEF',
  positive: '#07AB39',
  negative: '#EC5C52',
  warning: '#FDE900',
  errorBackground: '#FFF6F6',
};

export type ColorVariantsTypes = keyof typeof palette;

const theme = {
  palette,
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
} as const;

export default theme;
