import {
  IconCheckGreen,
  IconCheckBlue,
  IconWarningYellow,
  IconWarningRed,
} from 'assets/icons';

import { Container, Title } from './styles';

export type StatusFollowUpType =
  | 'request_changes'
  | 'completed'
  | 'in_edition'
  | 'effective';

export interface StatusFolloUpProps {
  status: StatusFollowUpType;
}

interface StatusFollowUpData {
  title: string;
  image: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

function getDataByStatus(status: StatusFollowUpType): StatusFollowUpData {
  switch (status) {
    case 'request_changes':
      return { title: 'Solicitação de ajuste', image: IconWarningRed };
    case 'completed':
      return { title: 'Concluído', image: IconCheckGreen };
    case 'in_edition':
      return { title: 'Em edição', image: IconWarningYellow };
    case 'effective':
      return { title: 'Efetivada', image: IconCheckBlue };
    default:
      return {} as StatusFollowUpData;
  }
}

const StatusFollowUp = ({ status }: StatusFolloUpProps) => {
  const { title, image: Icon } = getDataByStatus(status);

  return (
    <Container status={status}>
      <Icon />
      <Title status={status}>{title}</Title>
    </Container>
  );
};

export default StatusFollowUp;
