import { Frequencia } from 'assets/images';
import AttendanceTable from 'components/AttendanceTable';
import { GrayLine } from 'pages/StudentEnrollment/styles';

import DropdownMenu from './DropdownMenuSubject/DropdownMenu';
import { MenuItemText, ContainerMenu } from './DropdownMenuSubject/styles';
import {
  Container,
  Content,
  ContainerText,
  AttendenceImage,
  HeaderContent,
  Title,
  SubTitle,
  CardsBox,
} from './styles';

const Attendence = () => {
  return (
    <Container>
      <Content>
        <ContainerText>
          <AttendenceImage src={Frequencia} alt="" />
          <HeaderContent>
            <Title>Acompanhe sua frequência escolar</Title>
            <SubTitle>
              Selecione uma disciplina abaixo e visualize sua <br /> frequência
              em cada mês
            </SubTitle>
          </HeaderContent>
        </ContainerText>
        <GrayLine />
        <Container>
          <DropdownMenu
            title="Português"
            items={[
              { title: 'Português', handler: () => false },
              { title: 'Matemática', handler: () => false },
              { title: 'História', handler: () => false },
              { title: 'Geografia', handler: () => false },
              { title: 'Filosofia', handler: () => false },
              { title: 'Sociologia', handler: () => false },
            ]}
            isDark
          />
        </Container>
        <CardsBox>
          <AttendanceTable
            mes="Janeiro"
            presente="5"
            ausente="5"
            ausenteJustificado="-"
            naoRegistrado="-"
            frequenciaTotal="70%"
          />
          <AttendanceTable
            mes="Fevereiro"
            presente="5"
            ausente="5"
            ausenteJustificado="-"
            naoRegistrado="-"
            frequenciaTotal="70%"
          />
          <AttendanceTable
            mes="Março"
            presente="5"
            ausente="5"
            ausenteJustificado="-"
            naoRegistrado="-"
            frequenciaTotal="70%"
          />
          <AttendanceTable
            mes="Abril"
            presente="5"
            ausente="5"
            ausenteJustificado="-"
            naoRegistrado="-"
            frequenciaTotal="70%"
          />
          <AttendanceTable
            mes="Maio"
            presente="5"
            ausente="5"
            ausenteJustificado="-"
            naoRegistrado="-"
            frequenciaTotal="70%"
          />
          <AttendanceTable
            mes="Junho"
            presente="5"
            ausente="5"
            ausenteJustificado="-"
            naoRegistrado="-"
            frequenciaTotal="70%"
          />
          <AttendanceTable
            mes="Julho"
            presente="5"
            ausente="5"
            ausenteJustificado="-"
            naoRegistrado="-"
            frequenciaTotal="70%"
          />
          <AttendanceTable
            mes="Agosto"
            presente="5"
            ausente="5"
            ausenteJustificado="-"
            naoRegistrado="-"
            frequenciaTotal="70%"
          />
          <AttendanceTable
            mes="Setembro"
            presente="5"
            ausente="5"
            ausenteJustificado="-"
            naoRegistrado="-"
            frequenciaTotal="70%"
          />
          <AttendanceTable
            mes="Outubro"
            presente="5"
            ausente="5"
            ausenteJustificado="-"
            naoRegistrado="-"
            frequenciaTotal="70%"
          />
          <AttendanceTable
            disabled
            mes="Novembro"
            presente="-"
            ausente="-"
            ausenteJustificado="-"
            naoRegistrado="-"
            frequenciaTotal="-"
          />
          <AttendanceTable
            disabled
            mes="Dezembro"
            presente="-"
            ausente="-"
            ausenteJustificado="-"
            naoRegistrado="-"
            frequenciaTotal="-"
          />
        </CardsBox>
      </Content>
    </Container>
  );
};

export default Attendence;
