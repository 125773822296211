/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/order */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import cepPromise from 'cep-promise';

import { ResidentialAreaCreators } from 'store/ducks/students/residentialArea';
import { IconArrowLeft, IconArrowRight } from 'assets/icons';
import {
  differentiatedLocationOptions,
  residentialAreaOptions,
  countryOptions,
} from 'assets/json';
import { Input, Select, Button } from 'components';
import { Option } from 'components/Select';
import { ResidentalArea } from 'models/student';
import {
  ChildrenFormContainer,
  StepsButtonContainer,
  ChildrenFormRow,
  GrayLine,
} from 'pages/StudentEnrollment/styles';
import { RootState } from 'store';
import { CitiesCreators } from 'store/ducks/cities';
import {
  cepSchema,
  Strings as ValidationStrings,
  numberSelectOptionRequiredSchema,
  stringSelectOptionRequiredSchema,
  textRequiredSchema,
  numberSelectOptionSchema,
  stringSelectOptionSchema,
  cepSchemaOptional,
} from 'utils/validations';
import { StatesCreators } from 'store/ducks/states';
import { isEmpty, isEqual } from 'lodash';
import { normalizeCEPValue, normalizeOnlyNumbers } from 'utils/maskUtils';
import {
  checkFormikErrorsAndSetFieldTouched,
  getFormikMessageError,
} from 'utils/formikUtils';

const RESIDENTIAL_FORM_SCHEMA = Yup.object().shape({
  zipcode: cepSchema.required(ValidationStrings.REQUIRED_FIELD),
  stateId: numberSelectOptionRequiredSchema,
  cityId: numberSelectOptionRequiredSchema,
  country: stringSelectOptionRequiredSchema,
  neighborhood: textRequiredSchema,
  street: Yup.string().required(ValidationStrings.REQUIRED_FIELD),
  district: Yup.string(),
  zone: Yup.mixed()
    .oneOf(['COUNTRYSIDE', 'URBAN'])
    .required(ValidationStrings.REQUIRED_FIELD),
  differentiatedLocation: stringSelectOptionRequiredSchema,
  complement: Yup.string(),
  number: Yup.string().required(ValidationStrings.REQUIRED_FIELD),
});

const PPL_RESIDENTIAL_FORM_SCHEMA = Yup.object().shape({
  zipcode: cepSchemaOptional,
  stateId: numberSelectOptionSchema,
  cityId: numberSelectOptionSchema,
  country: stringSelectOptionSchema,
  neighborhood: Yup.string(),
  street: Yup.string(),
  district: Yup.string(),
  zone: Yup.mixed().oneOf(['COUNTRYSIDE', 'URBAN']),
  differentiatedLocation: stringSelectOptionSchema,
  complement: Yup.string(),
  number: Yup.string(),
});

interface ResidentialAreaFormProps {
  onBack: () => void;
  onNext: () => void;
  editing: boolean;
}

enum zone {
  COUNTRYSIDE = 'Rural',
  URBAN = 'Urbana',
}

interface CepResponse {
  street: string;
  city: string;
  neighborhood: string;
  state: string;
}

const ResidentialAreaForm = ({
  onBack,
  onNext,
  editing,
}: ResidentialAreaFormProps) => {
  const [cepResponse, setCepResponse] = useState<CepResponse | undefined>(
    undefined,
  );
  const dispatch = useDispatch();
  const { residentialArea, loading } = useSelector(
    (store: RootState) => store.residentialArea,
  );
  const { loading: statesLoading, states: stateOptions } = useSelector(
    ({ states }: RootState) => states,
  );
  const { loading: citiesLoading, cityOptions } = useSelector(
    ({ cities }: RootState) => cities,
  );
  const { studentFormData } = useSelector(
    ({ studentGeneralInfo }: RootState) => studentGeneralInfo,
  );
  const isPPL = !!studentFormData?.pplOption?.value;

  useEffect(() => {
    dispatch(CitiesCreators.cleanCities());
    dispatch(StatesCreators.getStates.request());
  }, [dispatch]);

  const onSubmit = useCallback(
    formData => {
      if (editing) {
        onNext();
      }

      if (isEqual(residentialArea, formData)) {
        onNext();
      } else if (isEmpty(residentialArea)) {
        dispatch(
          ResidentialAreaCreators.createResidentialArea.request({
            ...formData,
            cityId: formData.cityId,
            country: formData.country,
            neighborhood: formData.neighborhood,
            stateId: formData.stateId,
            differentiatedLocation: formData.differentiatedLocation,
          }),
        );
      } else {
        dispatch(
          ResidentialAreaCreators.updateResidentialArea.request({
            ...formData,
            cityId: formData.cityId,
            country: formData.country,
            neighborhood: formData.neighborhood,
            differentiatedLocation: formData.differentiatedLocation,
          }),
        );
      }
    },
    [dispatch, onNext, residentialArea, editing],
  );

  const schema = useMemo(
    () => (isPPL ? PPL_RESIDENTIAL_FORM_SCHEMA : RESIDENTIAL_FORM_SCHEMA),
    [isPPL],
  );

  const formik = useFormik({
    initialValues: residentialArea || ({ zone: 'URBAN' } as ResidentalArea),
    validationSchema: schema,
    onSubmit,
  });

  useEffect(() => {
    checkFormikErrorsAndSetFieldTouched(formik);
  }, [formik.isSubmitting]);

  useEffect(() => {
    async function fetchCEP() {
      try {
        const cepResponseData = await cepPromise(formik.values.zipcode);
        setCepResponse(cepResponseData);
      } catch {
        setCepResponse(undefined);
      }
    }

    if (
      formik.values?.zipcode?.length === 9 &&
      !formik.values.neighborhood &&
      !formik.values.street
    ) {
      fetchCEP();
    } else {
      setCepResponse(undefined);
    }
  }, [setCepResponse, formik.values.zipcode]);

  useEffect(() => {
    if (!cepResponse) {
      return;
    }

    const { street, neighborhood, state } = cepResponse;

    formik.setFieldValue('neighborhood', neighborhood);
    formik.setFieldValue('street', street);
    formik.setFieldValue(
      'stateId',
      stateOptions.find(s => s.short === state),
    );
  }, [stateOptions, cepResponse]);

  useEffect(() => {
    if (!cityOptions || !cepResponse) {
      return;
    }

    formik.setFieldValue(
      'cityId',
      cityOptions.find(c => c.label === cepResponse.city),
    );
  }, [cityOptions, cepResponse]);

  const oldState = useRef(residentialArea?.stateId);

  useEffect(() => {
    if (oldState.current !== formik.values.stateId) {
      formik.setFieldValue('cityId', undefined);
    }
  }, [formik.values.stateId]);

  useEffect(() => {
    if (formik.values.stateId !== undefined) {
      dispatch(
        CitiesCreators.getCitiesByStateId.request({
          stateId: formik.values.stateId.value as number,
        }),
      );
    }
  }, [dispatch, formik.values.stateId]);

  return (
    <ChildrenFormContainer id="student-form-residential">
      <ChildrenFormRow>
        <Select
          label="Pais de residência"
          options={countryOptions}
          onChange={(option, _) => formik.setFieldValue('country', option)}
          value={formik.values.country}
          messageError={getFormikMessageError(formik, 'country')}
          required={!isPPL}
        />
        <Input
          label="CEP"
          name="zipcode"
          placeholder="Digite o Número"
          required={!isPPL}
          onBlur={formik.handleBlur}
          value={formik.values.zipcode}
          onChange={evt =>
            formik.setFieldValue('zipcode', normalizeCEPValue(evt.target.value))
          }
          messageError={getFormikMessageError(formik, 'zipcode')}
          maxLength={9}
        />
      </ChildrenFormRow>
      <ChildrenFormRow>
        <Select
          label="Estado"
          options={stateOptions}
          onChange={(option, _) => formik.setFieldValue('stateId', option)}
          value={formik.values.stateId}
          messageError={getFormikMessageError(formik, 'stateId')}
          required={!isPPL}
          disabled={statesLoading}
        />
        <Select
          label="Municipio"
          options={cityOptions}
          onChange={(option, _) => formik.setFieldValue('cityId', option)}
          value={formik.values.cityId}
          messageError={getFormikMessageError(formik, 'cityId')}
          required={!isPPL}
          disabled={citiesLoading}
        />
      </ChildrenFormRow>
      <ChildrenFormRow>
        <Input
          label="Bairro"
          name="neighborhood"
          placeholder="Digite seu bairro"
          required={!isPPL}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.neighborhood}
          messageError={getFormikMessageError(formik, 'neighborhood')}
        />
        <Input
          label="Rua"
          name="street"
          placeholder="Digite sua Rua"
          required={!isPPL}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.street}
          messageError={getFormikMessageError(formik, 'street')}
        />
      </ChildrenFormRow>
      <ChildrenFormRow>
        <Input
          label="Número"
          name="number"
          placeholder="Digite seu número"
          onBlur={formik.handleBlur}
          value={formik.values.number}
          onChange={evt =>
            formik.setFieldValue(
              'number',
              normalizeOnlyNumbers(evt.target.value),
            )
          }
          messageError={getFormikMessageError(formik, 'number')}
          required={!isPPL}
        />
        <Input
          label="Complemento"
          name="complement"
          placeholder="Digite o complemento"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.complement}
          messageError={getFormikMessageError(formik, 'complement')}
        />
      </ChildrenFormRow>

      <GrayLine />

      <ChildrenFormRow>
        <Input
          label="Distrito"
          placeholder="Digite seu distrito"
          name="district"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.district}
          messageError={getFormikMessageError(formik, 'district')}
        />
        <Select
          label="Zona de residencia"
          options={residentialAreaOptions}
          onChange={(option: Option, _) =>
            formik.setFieldValue('zone', option.value)
          }
          value={
            formik.values.zone
              ? {
                  label: zone[formik.values.zone],
                  value: formik.values.zone,
                }
              : undefined
          }
          messageError={getFormikMessageError(formik, 'zone')}
          required={!isPPL}
        />
      </ChildrenFormRow>
      <ChildrenFormRow>
        <Select
          label="Localização diferenciada de residência"
          options={differentiatedLocationOptions}
          onChange={(option, _) =>
            formik.setFieldValue('differentiatedLocation', option)
          }
          value={formik.values.differentiatedLocation}
          messageError={getFormikMessageError(formik, 'differentiatedLocation')}
          required={!isPPL}
        />
      </ChildrenFormRow>

      <StepsButtonContainer>
        <Button
          iconLeft={editing ? undefined : IconArrowLeft}
          title={editing ? 'Cancelar' : 'Anterior'}
          type="button"
          styled="secondary"
          size="mini"
          onClick={onBack}
        />

        <Button
          iconRight={editing ? undefined : IconArrowRight}
          type="button"
          size="mini"
          title={editing ? 'Atualizar' : 'Próximo'}
          form="student-form-residential"
          onClick={formik.submitForm}
          disabled={loading}
        />
      </StepsButtonContainer>
    </ChildrenFormContainer>
  );
};

export default ResidentialAreaForm;
