import {
  Container,
  FullButton,
  HeaderImage,
  TitleContainer,
  IconImage,
  Title,
  Description,
} from './styles';

interface ModuleItemProps {
  imagePath: string;
  icon: string;
  title: string;
  description: string;
  buttonTitle: string;
  buttonAction: () => void;
  disabled?: boolean;
}

const ModuleItem = ({
  imagePath,
  icon,
  title,
  description,
  buttonTitle,
  buttonAction,
  disabled = false,
}: ModuleItemProps) => {
  return (
    <Container>
      <HeaderImage src={imagePath} disabled={disabled} />
      <TitleContainer>
        <IconImage src={icon} />
        <Title disabled={disabled}>{title}</Title>
      </TitleContainer>
      <Description disabled={disabled}>{description}</Description>
      <FullButton
        title={buttonTitle}
        onClick={buttonAction}
        disabled={disabled}
      />
    </Container>
  );
};

export default ModuleItem;
