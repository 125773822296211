/* eslint-disable react/prop-types */
import { ButtonHTMLAttributes } from 'react';

import { ButtonStyle, LinkS, ButtonContainer } from './styles';

export type ButtonType = 'primary' | 'secondary' | 'accent';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  styled?: ButtonType;
  size?: 'mini' | 'small' | 'medium' | 'large' | 'bigger';
  title: string;
  link?: boolean;
  href?: string;
  iconLeft?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  iconRight?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const Button: React.FC<ButtonProps> = ({
  styled = 'primary',
  title,
  link,
  href,
  size = 'default',
  iconLeft,
  iconRight,
  ...rest
}) => {
  const IconLeft = iconLeft || undefined;
  const IconRight = iconRight || undefined;
  return link === undefined ? (
    <ButtonStyle buttonType={styled} size={size} {...rest}>
      <ButtonContainer>{IconLeft && <IconLeft />}</ButtonContainer>

      {title}
      <ButtonContainer>{IconRight && <IconRight />}</ButtonContainer>
    </ButtonStyle>
  ) : (
    <>
      <LinkS href={href}>{title}</LinkS>
    </>
  );
};

export default Button;
