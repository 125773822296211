import React from 'react';

import { IconCloseModal } from 'assets/icons';
import { H3, Caption } from 'components/StyledTypography/styles';

import Button from '../Button';
import Modal from '../Modal';
import { Container, ButtonContainer, Content, Header } from './styles';

interface DialogProps {
  isOpen: boolean;
  title: string;
  description: string;
  extraInfo?: string;
  onConfimartion: () => void;
  onDismiss: () => void;
}

const ZINDEX_PRIORITY = 2;

const Dialog: React.FC<DialogProps> = ({
  isOpen,
  title,
  description,
  extraInfo = '',
  onConfimartion,
  onDismiss,
}) => (
  <Modal isOpen={isOpen} zIndex={ZINDEX_PRIORITY}>
    <Container>
      <Header>
        <H3>{title}</H3>
        <button type="button" onClick={onDismiss}>
          <IconCloseModal />
        </button>
      </Header>
      <Content>
        <Caption>{description}</Caption>
        {extraInfo ? <Caption>{extraInfo}</Caption> : null}
      </Content>
      <ButtonContainer>
        <Button
          title="Não"
          type="button"
          styled="secondary"
          size="mini"
          onClick={onDismiss}
        />
        <Button
          type="button"
          size="mini"
          title="Sim"
          onClick={onConfimartion}
        />
      </ButtonContainer>
    </Container>
  </Modal>
);

export default Dialog;
