/* eslint-disable react/destructuring-assignment */
import { useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { IconArrowLeft, IconArrowRight } from 'assets/icons';
import AttachmentCard from 'components/AttachmentCard';
import Button from 'components/Button';
import { Keys } from 'models/student';
import {
  ChildrenFormContainer,
  StepsButtonContainer,
} from 'pages/StudentEnrollment/styles';
import { RootState } from 'store';
import { UploadStudentFileCreators } from 'store/ducks/students/UploadFiles';
import { getAge } from 'utils/dateUtils';

interface AttachmentsFormProps {
  onBack: () => void;
  onNext: () => void;
  editing: boolean;
}

export enum documentTypes {
  BIRTH_CERTIFICATE = 'Certidão de nascimento civil',
  SCHOOL_RECORDS = 'Histórico escolar',
  SCHOLING_STATEMENT = 'Declaração de escolaridade',
  PHOTO = 'Foto 3x4',
  ADDRESS = 'Comprovante de Residência',
  CPF = 'CPF',
  SUS = 'Cartão Nacional de Saúde (SUS)',
  VACCINATION_CARD = 'Cartão de vacinação',
  SOCIALNAME = 'Declaração de nome social',
  ELECTORAL_DISCHARGE = 'Comprovante de quitação eleitoral',
  MILITARY_DISCHARGE = 'Comprovante de quitação militar',
}

const AttachmentsForm = ({ onBack, onNext, editing }: AttachmentsFormProps) => {
  const { hasSocialNameInSchoolRecordsOption, sexOption } = useSelector(
    (store: RootState) => store.studentGeneralInfo.studentFormData,
  );
  const { sus } = useSelector(
    (store: RootState) => store.documentsStudent.data,
  );

  const userPersonalInfo = useSelector(
    ({ auth }: RootState) => auth.userPersonalInfo,
  );
  const { studentFormData } = useSelector(
    ({ studentGeneralInfo }: RootState) => studentGeneralInfo,
  );
  const isPPL = !!studentFormData?.pplOption?.value;

  const documents: {
    type:
      | 'BIRTH_CERTIFICATE'
      | 'SCHOOL_RECORDS'
      | 'SCHOLING_STATEMENT'
      | 'PHOTO'
      | 'ADDRESS'
      | 'CPF'
      | 'SUS'
      | 'VACCINATION_CARD'
      | 'SOCIALNAME'
      | 'ELECTORAL_DISCHARGE'
      | 'MILITARY_DISCHARGE';
  }[] = [
    { type: 'BIRTH_CERTIFICATE' },
    { type: 'SCHOOL_RECORDS' },
    { type: 'SCHOLING_STATEMENT' },
    { type: 'PHOTO' },
    { type: 'ADDRESS' },
    { type: 'CPF' },
    { type: 'SUS' },
    { type: 'VACCINATION_CARD' },
    { type: 'SOCIALNAME' },
    { type: 'ELECTORAL_DISCHARGE' },
    { type: 'MILITARY_DISCHARGE' },
  ];

  const dispatch = useDispatch();

  const handleDeleteFiles = (attachmentsId: number, type: Keys) => {
    dispatch(
      UploadStudentFileCreators.deleteUploadStudentFile.request({
        attachmentsId,
        type: type as Keys,
      }),
    );
  };

  const { uploadStudentFile, uploadingFiles } = useSelector(
    (store: RootState) => store,
  );
  const { records } = uploadStudentFile.studentsFiles;

  const socialNameRequired = hasSocialNameInSchoolRecordsOption?.value;
  const milataryDischargeRequired =
    sexOption?.value === 'M' &&
    getAge(userPersonalInfo?.birthdate || new Date().toDateString()) >= 18;
  const electoralRequired =
    getAge(userPersonalInfo?.birthdate || new Date().toDateString()) >= 18;

  const disabledButton = useMemo(() => {
    if (isPPL) {
      return false;
    }

    const disabled =
      Object.entries(uploadingFiles.files).filter(item => {
        if (!socialNameRequired && item[0] === 'SOCIALNAME') {
          return false;
        }
        if (!milataryDischargeRequired && item[0] === 'MILITARY_DISCHARGE') {
          return false;
        }
        if (!electoralRequired && item[0] === 'ELECTORAL_DISCHARGE') {
          return false;
        }
        if (!sus && item[0] === 'SUS') {
          return false;
        }
        if (item[0] === 'CPF' || item[0] === 'SCHOOL_RECORDS') {
          return false;
        }

        return item[1] !== 'uploaded';
      }).length > 0;
    return disabled;
  }, [
    isPPL,
    sus,
    uploadingFiles,
    socialNameRequired,
    milataryDischargeRequired,
    electoralRequired,
  ]);

  return (
    <ChildrenFormContainer>
      {documents.map(document => {
        const { type } = document;
        const status = uploadingFiles.files[type as Keys];
        const record = records.find(item => item.type === type);
        if (!record) {
          // if (type === 'PHOTO') return null;
          if (type === 'SOCIALNAME') {
            return socialNameRequired ? (
              <AttachmentCard
                type={type}
                key={type}
                name={documentTypes[type]}
                status={status}
              />
            ) : null;
          }
          if (type === 'MILITARY_DISCHARGE') {
            return milataryDischargeRequired ? (
              <AttachmentCard
                type={type}
                key={type}
                name={documentTypes[type]}
                status={status}
              />
            ) : null;
          }
          if (type === 'ELECTORAL_DISCHARGE') {
            return electoralRequired ? (
              <AttachmentCard
                type={type}
                key={type}
                name={documentTypes[type]}
                status={status}
              />
            ) : null;
          }
          return (
            <AttachmentCard
              type={type}
              key={type}
              obligatory={type === 'SUS'}
              name={documentTypes[type]}
              status={status}
            />
          );
        }
        return (
          <AttachmentCard
            status={status}
            type={record.type}
            key={record.id}
            nameFile={record.name || record.url}
            onClick={() => handleDeleteFiles(record.id, record.type)}
            name={documentTypes[record.type]}
            id={record.id}
          />
        );
      })}

      <StepsButtonContainer>
        <Button
          iconLeft={editing ? undefined : IconArrowLeft}
          title={editing ? 'Cancelar' : 'Anterior'}
          type="button"
          styled="secondary"
          size="mini"
          onClick={onBack}
        />
        <Button
          iconRight={editing ? undefined : IconArrowRight}
          type="button"
          size="mini"
          title={editing ? 'Atualizar' : 'Próximo'}
          onClick={onNext}
          disabled={disabledButton}
        />
      </StepsButtonContainer>
    </ChildrenFormContainer>
  );
};

export default AttachmentsForm;
