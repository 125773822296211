import { useCallback, useEffect, useState } from 'react';

import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { IconArrowLeft, IconArrowRight } from 'assets/icons';
import { binaryOptions } from 'assets/json';
import { Button, Input, Select } from 'components';
import { Option } from 'components/Select';
import { BodyRegular } from 'components/StyledTypography/styles';
import { PARAIBA_ID } from 'config/constants';
import { SchoolOptionValue } from 'models/enrollment';
import {
  NewEnrollmentFormData,
  NewEnrollmentSchoolOffersFormData,
} from 'models/newEnrollment';
import {
  ChildrenFormContainer,
  ChildrenFormRow,
  GrayLine,
  StepsButtonContainer,
} from 'pages/StudentEnrollment/styles';
import {
  AddSiblingButton,
  RemoveSiblingButton,
} from 'pages/StudentReEnroll/Form/EducationData/styles';
import { mapNewEnrollmentFormToNewEnrollmentRequest } from 'services/api/newEnrollment/mapper';
import { SchoolSEEService } from 'services/api/schoolSEE';
import {
  mapSchoolCoursesResponseToCourseIdOptions,
  mapSchoolItineraiesResponseToItineraryIdOptions,
  mapSchoolsResponseToSchoolOption,
} from 'services/api/schoolSEE/mapper';
import { RootState } from 'store';
import { CitiesCreators } from 'store/ducks/cities';
import { NewEnrollmentCreators } from 'store/ducks/newEnrollment';
import {
  checkFormikErrorsAndSetFieldTouched,
  getFormikMessageError,
} from 'utils/formikUtils';
import {
  booleanSelectOptionRequiredSchema,
  stringSelectOptionRequiredSchema,
  Strings as ValidationsStrings,
  stringSelectOptionSchema,
  schoolSelectOptionRequiredSchema,
  schoolSelectOptionSchema,
  booleanSelectOptionSchema,
} from 'utils/validations';

import { AddOptionButton, RemoveOptionButton } from './styles';

const FORM_ID = 'new-enrollment-school-offers-form';
const SCHOOLS_OPTIONS_MAX_COUNT = 5;

interface SchoolOffersProps {
  onBack: () => void;
  onNext: () => void;
}

const SchoolOffers = ({ onBack, onNext }: SchoolOffersProps) => {
  const dispatch = useDispatch();
  const { loading: citiesLoading, cityOptions } = useSelector(
    ({ cities }: RootState) => cities,
  );
  const {
    educationDataFormData,
    teachingTypeFormData,
    schoolOffersFormData,
    loading,
  } = useSelector(({ newEnrollment }: RootState) => newEnrollment);
  const { pplOption } = useSelector(
    ({ studentGeneralInfo }: RootState) => studentGeneralInfo.studentFormData,
  );
  const [loadingData, setLoadingData] = useState(false);

  const ppl = !!pplOption?.value;
  const [siblingsIndex, setSiblingsIndex] = useState(
    (schoolOffersFormData.siblings && schoolOffersFormData.siblings.length) ||
      1,
  );
  const [schoolsCount, setSchoolsCount] = useState(() => {
    if (
      schoolOffersFormData.schoolId5Option &&
      schoolOffersFormData.schoolId5Option.value
    ) {
      return 5;
    }
    if (
      schoolOffersFormData.schoolId4Option &&
      schoolOffersFormData.schoolId4Option.value
    ) {
      return 4;
    }
    if (
      schoolOffersFormData.schoolId3Option &&
      schoolOffersFormData.schoolId3Option.value
    ) {
      return 3;
    }
    if (
      schoolOffersFormData.schoolId2Option &&
      schoolOffersFormData.schoolId2Option.value
    ) {
      return 2;
    }

    return 1;
  });
  const [schoolOptions1, setSchoolOptions1] = useState<Option[]>([]);
  const [schoolOptions2, setSchoolOptions2] = useState<Option[]>([]);
  const [schoolOptions3, setSchoolOptions3] = useState<Option[]>([]);
  const [schoolOptions4, setSchoolOptions4] = useState<Option[]>([]);
  const [schoolOptions5, setSchoolOptions5] = useState<Option[]>([]);
  const [courseIdOption1, setCourseIdOption1] = useState<Option[]>([]);
  const [courseIdOption2, setCourseIdOption2] = useState<Option[]>([]);
  const [courseIdOption3, setCourseIdOption3] = useState<Option[]>([]);
  const [courseIdOption4, setCourseIdOption4] = useState<Option[]>([]);
  const [courseIdOption5, setCourseIdOption5] = useState<Option[]>([]);
  const [itineraryIdOption1, setItineraryIdOption1] = useState<Option[]>([]);
  const [itineraryIdOption2, setItineraryIdOption2] = useState<Option[]>([]);
  const [itineraryIdOption3, setItineraryIdOption3] = useState<Option[]>([]);
  const [itineraryIdOption4, setItineraryIdOption4] = useState<Option[]>([]);
  const [itineraryIdOption5, setItineraryIdOption5] = useState<Option[]>([]);

  const setSchoolOptions = [
    setSchoolOptions1,
    setSchoolOptions2,
    setSchoolOptions3,
    setSchoolOptions4,
    setSchoolOptions5,
  ];
  const setCourseIdOptions = [
    setCourseIdOption1,
    setCourseIdOption2,
    setCourseIdOption3,
    setCourseIdOption4,
    setCourseIdOption5,
  ];
  const setItineraryIdOptions = [
    setItineraryIdOption1,
    setItineraryIdOption2,
    setItineraryIdOption3,
    setItineraryIdOption4,
    setItineraryIdOption5,
  ];

  const {
    mediation,
    step,
    modality,
    modalitySpecial,
    regime,
    shift,
    temporality,
  } = mapNewEnrollmentFormToNewEnrollmentRequest(
    teachingTypeFormData as NewEnrollmentFormData,
  );

  const SCHOOL_OFFERS_SCHEMA = Yup.object().shape({
    schoolId1Option: schoolSelectOptionRequiredSchema.nullable(),
    courseId1Option: stringSelectOptionSchema
      .when('eptOrFic1Option', {
        is: (option: Option) => option.value,
        then: stringSelectOptionRequiredSchema,
      })
      .nullable(),
    eptOrFic1Option: booleanSelectOptionRequiredSchema.nullable(),
    cityId1Option: stringSelectOptionRequiredSchema.nullable(),
    itineraryId1Option: stringSelectOptionSchema.nullable(),
    schoolId2Option: schoolSelectOptionSchema.nullable(),
    courseId2Option: stringSelectOptionSchema.nullable(),
    eptOrFic2Option: booleanSelectOptionSchema.nullable(),
    cityId2Option: stringSelectOptionSchema.nullable(),
    itineraryId2Option: stringSelectOptionSchema.nullable(),
    schoolId3Option: schoolSelectOptionSchema.nullable(),
    courseId3Option: stringSelectOptionSchema.nullable(),
    eptOrFic3Option: booleanSelectOptionSchema.nullable(),
    cityId3Option: stringSelectOptionSchema.nullable(),
    itineraryId3Option: stringSelectOptionSchema.nullable(),
    schoolId4Option: schoolSelectOptionSchema.nullable(),
    courseId4Option: stringSelectOptionSchema.nullable(),
    eptOrFic4Option: booleanSelectOptionSchema.nullable(),
    cityId4Option: stringSelectOptionSchema.nullable(),
    itineraryId4Option: stringSelectOptionSchema.nullable(),
    schoolId5Option: schoolSelectOptionSchema.nullable(),
    courseId5Option: stringSelectOptionSchema.nullable(),
    eptOrFic5Option: booleanSelectOptionSchema.nullable(),
    cityId5Option: stringSelectOptionSchema.nullable(),
    itineraryId5Option: stringSelectOptionSchema.nullable(),
    siblingsToEnrollOption: booleanSelectOptionRequiredSchema,
    siblings: Yup.array()
      .of(Yup.string())
      .when('siblingsToEnrollOption', {
        is: (option: Option) => option.value,
        then: rule => rule.required(ValidationsStrings.REQUIRED_FIELD),
      }),
  });

  const fetchSchools = async (
    schoolIndex: number,
    city: string,
    eptOrFic: boolean,
    formik: any,
    cleanFormikField: boolean,
  ) => {
    setLoadingData(true);
    try {
      const schoolsResponse = await SchoolSEEService.fetchSchools({
        mediation,
        step,
        modality,
        educationOption: modalitySpecial,
        //  regime,
        shift,
        temporality,
        ppl,
        city,
        eptOrFic,
      });
      const schools = mapSchoolsResponseToSchoolOption(schoolsResponse);
      setSchoolOptions[schoolIndex](schools);

      if (cleanFormikField) {
        formik.setFieldValue(`schoolId${schoolIndex + 1}Option`, null);
        formik.setFieldValue(`courseId${schoolIndex + 1}Option`, null);
        formik.setFieldValue(`itineraryId${schoolIndex + 1}Option`, null);
      }
    } catch (error) {
      // empty error
    } finally {
      setLoadingData(false);
    }
  };

  const fetchSchoolCourses = async (
    schoolId: string,
    schoolIndex: number,
    formik: any,
    cleanFormikField: boolean,
  ) => {
    const schoolCoursesResponse = await SchoolSEEService.fetchSchoolCourses({
      id: schoolId,
    });
    const coursesOptions = mapSchoolCoursesResponseToCourseIdOptions(
      schoolCoursesResponse,
    );

    setCourseIdOptions[schoolIndex](coursesOptions);

    if (cleanFormikField) {
      formik.setFieldValue(`itineraryId${schoolIndex + 1}Option`, null);
      formik.setFieldValue(`courseId${schoolIndex + 1}Option`, null);
    }
  };

  const fetchSchoolItineraries = async (
    schoolId: string,
    schoolIndex: number,
  ) => {
    const schoolItinerariesResponse =
      await SchoolSEEService.fetchSchoolItineraries({
        id: schoolId,
      });
    const itineraryOptions = mapSchoolItineraiesResponseToItineraryIdOptions(
      schoolItinerariesResponse,
    );

    setItineraryIdOptions[schoolIndex](itineraryOptions);
  };

  useEffect(() => {
    dispatch(
      CitiesCreators.getCitiesByStateId.request({
        stateId: PARAIBA_ID,
      }),
    );
  }, [dispatch]);

  const onSubmit = useCallback(
    (formData: NewEnrollmentSchoolOffersFormData) => {
      if (
        formData.siblingsToEnrollOption &&
        formData.siblings &&
        formData.siblings.filter(e => !e).length !== 0
      ) {
        toast.error('Preencha o nome de todos os irmãos');
        return;
      }

      const schoolOptionsValue = [
        formData.schoolId1Option.value,
        formData.schoolId2Option?.value,
        formData.schoolId3Option?.value,
        formData.schoolId4Option?.value,
        formData.schoolId5Option?.value,
      ]
        .filter(s => !!s)
        .map(s => (s as SchoolOptionValue)?.schoolId);

      if (schoolOptionsValue.length !== new Set(schoolOptionsValue).size) {
        toast.error('Não é possível repetir a opção de escola');
        return;
      }

      if (formData.schoolId1Option.value)
        dispatch(
          NewEnrollmentCreators.setNewEnrollmentSchoolOffersForm(formData),
        );

      if (isEmpty(schoolOffersFormData)) {
        dispatch(
          NewEnrollmentCreators.createNewEnrollment.request({
            ...formData,
            ...educationDataFormData,
            ...teachingTypeFormData,
          }),
        );
      } else {
        dispatch(
          NewEnrollmentCreators.updateNewEnrollment.request({
            ...formData,
            ...educationDataFormData,
            ...teachingTypeFormData,
          }),
        );
      }
    },
    [
      dispatch,
      schoolOffersFormData,
      educationDataFormData,
      teachingTypeFormData,
    ],
  );

  const formik = useFormik({
    initialValues: isEmpty(schoolOffersFormData)
      ? ({
          eptOrFic1Option: ppl ? binaryOptions[1] : undefined,
        } as NewEnrollmentSchoolOffersFormData)
      : schoolOffersFormData,
    validationSchema: SCHOOL_OFFERS_SCHEMA,
    onSubmit,
  });

  useEffect(() => {
    const schoolsIdValues = [
      (formik.values.schoolId1Option?.value as SchoolOptionValue)?.schoolId,
      (formik.values.schoolId2Option?.value as SchoolOptionValue)?.schoolId,
      (formik.values.schoolId3Option?.value as SchoolOptionValue)?.schoolId,
      (formik.values.schoolId4Option?.value as SchoolOptionValue)?.schoolId,
      (formik.values.schoolId5Option?.value as SchoolOptionValue)?.schoolId,
    ];
    const eptOrFicValues = [
      formik.values.eptOrFic1Option?.value,
      formik.values.eptOrFic2Option?.value,
      formik.values.eptOrFic3Option?.value,
      formik.values.eptOrFic4Option?.value,
      formik.values.eptOrFic5Option?.value,
    ];
    const citiesIdValues = [
      formik.values.cityId1Option?.label,
      formik.values.cityId2Option?.label,
      formik.values.cityId3Option?.label,
      formik.values.cityId4Option?.label,
      formik.values.cityId5Option?.label,
    ];
    const itineraryIdValues = [
      formik.values.itineraryId1Option?.label,
      formik.values.itineraryId2Option?.label,
      formik.values.itineraryId3Option?.label,
      formik.values.itineraryId4Option?.label,
      formik.values.itineraryId5Option?.label,
    ];

    for (let i = 0; i < citiesIdValues.length; i += 1) {
      const cityId = citiesIdValues[i];
      const eptOrFic = eptOrFicValues[i];
      const schoolId = schoolsIdValues[i];

      if (cityId) {
        fetchSchools(i, cityId as string, eptOrFic as boolean, formik, false);
      }

      if (schoolId) {
        fetchSchoolCourses(schoolId, i, formik, false);
        fetchSchoolItineraries(schoolId, i);
      }
    }
  }, []);

  useEffect(() => {
    checkFormikErrorsAndSetFieldTouched(formik);
  }, [formik.isSubmitting]);

  useEffect(() => {
    if (
      formik.values?.siblingsToEnrollOption !== undefined &&
      !formik.values?.siblingsToEnrollOption.value &&
      formik.values?.siblings !== undefined
    ) {
      formik.setFieldValue('siblings', undefined);
      setSiblingsIndex(1);
    }
  }, [formik, formik.values?.siblingsToEnrollOption]);

  return (
    <ChildrenFormContainer id={FORM_ID}>
      <ChildrenFormRow>
        <BodyRegular>Selecione até 05 opções de sua preferência</BodyRegular>
      </ChildrenFormRow>

      {Array.from({ length: schoolsCount }, (__, index) => {
        const schoolOption = index + 1;
        const cityValues = [
          formik.values.cityId1Option,
          formik.values.cityId2Option,
          formik.values.cityId3Option,
          formik.values.cityId4Option,
          formik.values.cityId5Option,
        ];
        const cityValue = cityValues[index];
        const cityField = `cityId${schoolOption}Option`;

        const eptOrFicValues = [
          formik.values.eptOrFic1Option,
          formik.values.eptOrFic2Option,
          formik.values.eptOrFic3Option,
          formik.values.eptOrFic4Option,
          formik.values.eptOrFic5Option,
        ];
        const eptOrFicValue = eptOrFicValues[index];
        const eptOrFicField = `eptOrFic${schoolOption}Option`;

        const schoolIdValues = [
          formik.values.schoolId1Option,
          formik.values.schoolId2Option,
          formik.values.schoolId3Option,
          formik.values.schoolId4Option,
          formik.values.schoolId5Option,
        ];
        const schoolIdValue = schoolIdValues[index];
        const schoolIdField = `schoolId${schoolOption}Option`;

        const courseIdValues = [
          formik.values.courseId1Option,
          formik.values.courseId2Option,
          formik.values.courseId3Option,
          formik.values.courseId4Option,
          formik.values.courseId5Option,
        ];
        const courseIdValue = courseIdValues[index];
        const courseIdField = `courseId${schoolOption}Option`;

        const itineraryIdValues = [
          formik.values.itineraryId1Option,
          formik.values.itineraryId2Option,
          formik.values.itineraryId3Option,
          formik.values.itineraryId4Option,
          formik.values.itineraryId5Option,
        ];
        const itineraryIdValue = itineraryIdValues[index];
        const itineraryIdField = `itineraryId${schoolOption}Option`;

        const schoolOptions = [
          schoolOptions1,
          schoolOptions2,
          schoolOptions3,
          schoolOptions4,
          schoolOptions5,
        ];
        const courseIdOptions = [
          courseIdOption1,
          courseIdOption2,
          courseIdOption3,
          courseIdOption4,
          courseIdOption5,
        ];
        const itineraryIdOptions = [
          itineraryIdOption1,
          itineraryIdOption2,
          itineraryIdOption3,
          itineraryIdOption4,
          itineraryIdOption5,
        ];

        const removeSchoolOption = () => {
          for (let i = schoolsCount - 1; i > index; i -= 1) {
            setSchoolOptions[i - 1](schoolOptions[i]);
            setCourseIdOptions[i - 1](courseIdOptions[i]);
            setItineraryIdOptions[i - 1](itineraryIdOptions[i]);
            formik.setFieldValue(`cityId${i}Option`, cityValues[i]);
            formik.setFieldValue(`eptOrFic${i}Option`, eptOrFicValues[i]);
            formik.setFieldValue(`schoolId${i}Option`, schoolIdValues[i]);
            formik.setFieldValue(`courseId${i}Option`, courseIdValues[i]);
            formik.setFieldValue(`itineraryId${i}Option`, itineraryIdValues[i]);
          }

          setSchoolOptions[schoolsCount - 1]([]);
          setCourseIdOptions[schoolsCount - 1]([]);
          setItineraryIdOptions[schoolsCount - 1]([]);
          formik.setFieldValue(`cityId${schoolsCount}Option`, undefined);
          formik.setFieldValue(`eptOrFic${schoolsCount}Option`, undefined);
          formik.setFieldValue(`schoolId${schoolsCount}Option`, undefined);
          formik.setFieldValue(`courseId${schoolsCount}Option`, undefined);
          formik.setFieldValue(`itineraryId${schoolsCount}Option`, undefined);

          setSchoolsCount(Math.max(1, schoolsCount - 1));
        };
        const addSchoolOption = () => {
          setSchoolsCount(
            Math.min(SCHOOLS_OPTIONS_MAX_COUNT, schoolsCount + 1),
          );
        };

        return (
          <div key={index}>
            <ChildrenFormRow>
              <BodyRegular>{`${schoolOption}ª Opção`}</BodyRegular>
            </ChildrenFormRow>

            <ChildrenFormRow>
              <Select
                label="Cidade"
                options={cityOptions}
                disabled={citiesLoading}
                required
                value={cityValue}
                onChange={(option, _) => {
                  formik.setFieldValue(cityField, option);

                  if (option && option.label && eptOrFicValue) {
                    fetchSchools(
                      index,
                      option.label,
                      !!eptOrFicValue?.value,
                      formik,
                      true,
                    );
                  }
                }}
                messageError={getFormikMessageError(formik, cityField)}
              />
              {!ppl && (
                <Select
                  label="Pretende cursar EPT?"
                  info="Educação Profissional Técnica - EPT"
                  options={binaryOptions}
                  required
                  value={eptOrFicValue}
                  onChange={(option, _) => {
                    formik.setFieldValue(eptOrFicField, option);

                    if (option?.value === false) {
                      formik.setFieldValue(courseIdField, undefined);
                    }

                    if (cityValue && option) {
                      fetchSchools(
                        index,
                        cityValue.label,
                        !!option?.value,
                        formik,
                        true,
                      );
                    }
                  }}
                  messageError={getFormikMessageError(formik, eptOrFicField)}
                />
              )}
            </ChildrenFormRow>
            <ChildrenFormRow>
              <Select
                label="Escola"
                options={schoolOptions[index]}
                required
                value={schoolIdValue}
                onChange={(option, _) => {
                  formik.setFieldValue(schoolIdField, option);
                  fetchSchoolCourses(
                    (option.value as SchoolOptionValue).schoolId,
                    index,
                    formik,
                    true,
                  );
                  fetchSchoolItineraries(
                    (option.value as SchoolOptionValue).schoolId,
                    index,
                  );
                }}
                messageError={getFormikMessageError(formik, schoolIdField)}
                disabled={loadingData}
              />
              {itineraryIdOptions[index] &&
                itineraryIdOptions[index].length !== 0 && (
                  <Select
                    label="Itinerário formativo"
                    options={itineraryIdOptions[index]}
                    value={itineraryIdValue}
                    onChange={(option, _) =>
                      formik.setFieldValue(itineraryIdField, option)
                    }
                    messageError={getFormikMessageError(
                      formik,
                      itineraryIdField,
                    )}
                    disabled={loadingData}
                  />
                )}

              {eptOrFicValue && eptOrFicValue.value && (
                <Select
                  label="Curso EPT"
                  options={courseIdOptions[index]}
                  required
                  value={courseIdValue}
                  onChange={(option, _) =>
                    formik.setFieldValue(courseIdField, option)
                  }
                  messageError={getFormikMessageError(formik, courseIdField)}
                  disabled={loadingData}
                />
              )}
            </ChildrenFormRow>

            <ChildrenFormRow>
              {schoolOption !== 1 ? (
                <>
                  <RemoveOptionButton
                    type="button"
                    onClick={removeSchoolOption}
                  >
                    {`- Remover ${schoolOption}ª Opção`}
                  </RemoveOptionButton>
                  {schoolOption !== SCHOOLS_OPTIONS_MAX_COUNT &&
                    schoolOption === schoolsCount && (
                      <AddOptionButton type="button" onClick={addSchoolOption}>
                        + Adicionar mais uma opção
                      </AddOptionButton>
                    )}
                </>
              ) : (
                schoolsCount === 1 &&
                schoolOption === 1 && (
                  <AddOptionButton type="button" onClick={addSchoolOption}>
                    + Adicionar mais uma opção
                  </AddOptionButton>
                )
              )}
            </ChildrenFormRow>

            <GrayLine />
          </div>
        );
      })}

      <ChildrenFormRow>
        <Select
          label="Possui irmãos a serem matriculados na mesma escola?"
          options={binaryOptions}
          required
          value={formik.values.siblingsToEnrollOption}
          onChange={(option, _) =>
            formik.setFieldValue('siblingsToEnrollOption', option)
          }
          messageError={getFormikMessageError(formik, 'siblingsToEnrollOption')}
        />
      </ChildrenFormRow>
      {formik.values?.siblingsToEnrollOption?.value &&
        Array.from({ length: siblingsIndex }, (_, key) => {
          return (
            <ChildrenFormRow>
              <Input
                label="Nome completo do irmão"
                placeholder="Digite o nome do irmão"
                required
                onChange={e => {
                  if (
                    key === 0 &&
                    e.target.value === '' &&
                    siblingsIndex === 1
                  ) {
                    return formik.setFieldValue('siblings', undefined);
                  }
                  return formik.setFieldValue(
                    `siblings[${key}]`,
                    e.target.value,
                  );
                }}
                onBlur={formik.handleBlur}
                value={formik.values?.siblings?.[key]}
                name="siblings"
                messageError={getFormikMessageError(formik, 'siblings')}
              />

              {key !== siblingsIndex - 1 ? (
                <RemoveSiblingButton
                  type="button"
                  onClick={() => {
                    const siblings = formik.values.siblings
                      ? [...formik.values.siblings]
                      : [];
                    siblings.splice(key, 1);
                    formik.setFieldValue('siblings', siblings);
                    setSiblingsIndex(siblingsIndex - 1);
                  }}
                >
                  - Remover irmão
                </RemoveSiblingButton>
              ) : (
                <AddSiblingButton
                  type="button"
                  onClick={() => {
                    const siblings = formik.values.siblings
                      ? [...formik.values.siblings]
                      : [];
                    siblings.push('');
                    formik.setFieldValue('siblings', siblings);
                    setSiblingsIndex(siblingsIndex + 1);
                  }}
                >
                  + Adicionar mais um irmão
                </AddSiblingButton>
              )}
            </ChildrenFormRow>
          );
        })}

      <StepsButtonContainer>
        <Button
          iconLeft={IconArrowLeft}
          title="Anterior"
          type="button"
          styled="secondary"
          size="mini"
          form={FORM_ID}
          onClick={onBack}
          disabled={loading}
        />
        <Button
          iconRight={IconArrowRight}
          type="button"
          size="mini"
          title="Finalizar"
          form={FORM_ID}
          onClick={formik.submitForm}
          disabled={loading}
        />
      </StepsButtonContainer>
    </ChildrenFormContainer>
  );
};

export default SchoolOffers;
