import base from 'services/api/base';

import { ServiceEndpoints } from '../types';
import { CitiesResponse } from './models';

const Strings = {
  DEFAULT_ERROR: 'Erro ao recuperar cidades',
};

export const CitiesEndpoints: ServiceEndpoints = {
  cities: {
    path: 'cities',
    errorMessages: {
      401: 'Não autorizado',
      403: 'Sem permissão para acessar',
      default: Strings.DEFAULT_ERROR,
    },
  },
};

async function fetchCitiesByStateId(stateId: number): Promise<CitiesResponse> {
  const endpoint = CitiesEndpoints.cities;
  const response = await base.api.get(endpoint.path, {
    params: { disablePagination: true, stateId },
  });
  return response.data;
}

export const CitiesService = { fetchCitiesByStateId };
