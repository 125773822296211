import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  gap: 32px;
  flex-direction: column;
  width: 62%;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 65px;
  padding: 32px;

  background: #ffffff;
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    width: auto;
    margin: 24px 15px 103px 15px;
    padding: 13px;
  }
`;

export const Text = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  color: #121212;
  max-width: 444px;
  h1 {
    font-size: 25px;
    margin-bottom: 23px;
  }

  p {
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.2px;
  }
`;
export const TextBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    h1 {
      display: none;
    }

`;
export const CardsBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 32px;
  align-items: center;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    gap: 24px;
  }
`;
export const Line = styled.div`
  height: 0px;
  border: 1px solid #e0e0e0;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    display: none;
  }
`;
export const Image = styled.img`
  width: 110.16px;
  height: 99.86px;
`;
