/* eslint-disable react/require-default-props */
import React from 'react';

import { Tooltip } from '@chakra-ui/react';

import {
  IconError,
  IconErrorWarning,
  IconInputInfo,
  IconUploaded,
} from 'assets/icons';

import {
  InputContainer,
  InputText,
  Label,
  Message,
  RowContainer,
} from './styles';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  width?: string;
  height?: string;
  placeholder?: string;
  disabled?: boolean;
  type?: string;
  label?: string;
  messageError?: string | undefined;
  hint?: string;
  hintSuccess?: string;
  hintFailure?: string;
  info?: string;
  required?: boolean;
}

const renderExtraInfo = (
  error: boolean,
  disabled: boolean,
  hint: string,
  messageError: string,
  hintSuccess: string,
  hintFailure: string,
) => {
  if (disabled) {
    return (
      <RowContainer>
        <IconErrorWarning />
        <Message error={false}>Campo desativado</Message>
      </RowContainer>
    );
  }

  if (hintSuccess || hintFailure) {
    return (
      <RowContainer>
        {hintSuccess ? (
          <IconUploaded style={{ width: 12, height: 12 }} />
        ) : (
          <IconError style={{ width: 12, height: 12 }} />
        )}
        <Message error={!hintSuccess}>{hintSuccess || hintFailure}</Message>
      </RowContainer>
    );
  }

  return !error ? (
    <Message error={error}>{hint}&#8203;</Message>
  ) : (
    <Message error={error}>{messageError}</Message>
  );
};

const Input: React.FC<InputProps> = ({
  width = '100%',
  height = '40px',
  placeholder = '',
  disabled = false,
  type = 'text',
  label = '',
  messageError = '',
  hint = '',
  info = '',
  hintSuccess = '',
  hintFailure = '',
  required = false,
  ...rest
}) => {
  const error = !!messageError;
  return (
    <InputContainer
      width={width}
      height={height}
      className={type}
      error={error}
    >
      {label && (
        <RowContainer>
          <Label disabled={disabled}>
            {label}
            {required ? '*' : ''}
          </Label>
          {info && (
            <Tooltip
              label={info}
              fontFamily="Inter"
              fontStyle="normal"
              fontSize={13}
              fontWeight="normal"
              placement="top"
              backgroundColor="#121212"
              borderRadius={8}
            >
              <IconInputInfo />
            </Tooltip>
          )}
        </RowContainer>
      )}
      <InputText
        width={width}
        height={height}
        type={type}
        placeholder={placeholder}
        className={type}
        disabled={disabled}
        error={error}
        {...rest}
      />
      {renderExtraInfo(
        error,
        disabled,
        hint,
        messageError,
        hintSuccess,
        hintFailure,
      )}
    </InputContainer>
  );
};

export default Input;
