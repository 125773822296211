import React, { useState, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import { DoingIcon, DoneIcon, ToDoIcon } from 'assets/icons';
import { RecaptchaProvider } from 'hooks/ReCaptchaContext';
import { ReviewForm } from 'pages/StudentEnrollment/Form';
import {
  Container,
  ProgressContainer,
  ProgressContent,
  Content,
  SideStepsContainer,
  StepsTitle,
  FormContainer,
  FormTitle,
  StepIcon,
  StepLabel,
  CurrentStepLabel,
  StepContainer,
  ContainerForm,
} from 'pages/StudentEnrollment/styles';

import RenewEnrollment from './Form/RenewEnrollment';

const REVIEW_DATA_STEPS = ['Atualização de dados', 'Renovação de matrícula'];

export enum ReviewDataStep {
  UPDATE_PERSONAL_DATA,
  UPDATE_ENROLLMENT_DATA,
}

interface StepProps {
  current: number;
}

const Step = ({ current }: StepProps) => {
  const renderStepLabel = (step: string, index: number) => {
    if (index < current) {
      return (
        <>
          <StepIcon src={DoneIcon} />
          <StepLabel>{step}</StepLabel>
        </>
      );
    }

    if (current === index) {
      return (
        <>
          <StepIcon src={DoingIcon} />
          <CurrentStepLabel>{step}</CurrentStepLabel>
        </>
      );
    }

    return (
      <>
        <StepIcon src={ToDoIcon} />
        <StepLabel>{step}</StepLabel>
      </>
    );
  };

  return (
    <>
      {REVIEW_DATA_STEPS.map((step, index) => (
        <StepContainer>{renderStepLabel(step, index)}</StepContainer>
      ))}
    </>
  );
};

export default function ReviewData() {
  const [currentStepIndex, setCurrentStepIndex] = useState(
    ReviewDataStep.UPDATE_PERSONAL_DATA,
  );

  const navigate = useNavigate();

  const CurrentForm = useMemo(() => {
    switch (currentStepIndex) {
      case ReviewDataStep.UPDATE_PERSONAL_DATA:
        return (
          <ReviewForm
            onBack={() => navigate('/home')}
            onNext={() => setCurrentStepIndex(currentStepIndex + 1)}
            onGoToStep={() => false}
            description="Antes de renovar sua matrícula, confira se seus dados pessoais estão atualizados."
            editable="inplace"
          />
        );
      case ReviewDataStep.UPDATE_ENROLLMENT_DATA:
        return (
          <RenewEnrollment
            onBack={() => setCurrentStepIndex(currentStepIndex - 1)}
            onNext={() => false}
          />
        );
      default:
        return <></>;
    }
  }, [currentStepIndex, navigate]);

  return (
    <RecaptchaProvider>
      <Container>
        <ProgressContainer>
          <ProgressContent
            progress={(currentStepIndex * 100) / REVIEW_DATA_STEPS.length}
          />
        </ProgressContainer>
        <ContainerForm>
          <Content>
            <SideStepsContainer>
              <StepsTitle>Etapas</StepsTitle>
              <Step current={currentStepIndex} />
            </SideStepsContainer>
            <FormContainer>
              <FormTitle>{REVIEW_DATA_STEPS[currentStepIndex]}</FormTitle>
              {CurrentForm}
            </FormContainer>
          </Content>
        </ContainerForm>
      </Container>
    </RecaptchaProvider>
  );
}
