import base from 'services/api/base';
import { ServiceEndpoints } from 'services/api/types';

import {
  CreateGeneralStudentRequestData,
  FetchGeneralStudentResponseData,
  FinishStudentRequest,
  FinishStudentResponse,
  UpdateGeneralStudentRequestData,
  UpdateGeneralStudentResponseData,
} from './models';

const Strings = {
  DEFAULT_ERROR_SIGN_IN: 'Erro ao entrar na aplicação',
};

export const GeneralStudentEndpoints: ServiceEndpoints = {
  createGeneralStudent: {
    path: 'students',
    errorMessages: {
      400: 'Dados inválidos. Preencha os campos de maneira incorreta',
      422: 'Dados do usuário já existente',
      default: Strings.DEFAULT_ERROR_SIGN_IN,
    },
  },
  updateGeneralStudent: {
    path: 'students',
    errorMessages: {
      400: 'Dados inválidos. Preencha os campos de maneira incorreta',
      404: 'Usuário não encontrado',
      422: 'Dados do usuário já existente',
      default: Strings.DEFAULT_ERROR_SIGN_IN,
    },
  },
  fetchGeneralStudent: {
    path: 'students',
    errorMessages: {
      404: 'Usuário não encontrado',
      default: Strings.DEFAULT_ERROR_SIGN_IN,
    },
  },
  finishStudent: {
    path: 'students/{user}/finish',
    errorMessages: {
      default: Strings.DEFAULT_ERROR_SIGN_IN,
    },
  },
};

async function createGeneralStudent(
  id: number,
  body: CreateGeneralStudentRequestData,
): Promise<CreateGeneralStudentRequestData> {
  const endpoint = GeneralStudentEndpoints.createGeneralStudent;
  const response = await base.api.post<CreateGeneralStudentRequestData>(
    `${endpoint.path}/${id}`,
    body,
  );
  return response.data;
}

async function updateGeneralStudent(
  id: number,
  body: UpdateGeneralStudentRequestData,
): Promise<UpdateGeneralStudentResponseData> {
  const endpoint = GeneralStudentEndpoints.updateGeneralStudent;
  await base.api.patch<UpdateGeneralStudentResponseData>(
    `${endpoint.path}/${id}`,
    body,
  );
}

async function fetchGeneralStudent(
  id: number,
): Promise<FetchGeneralStudentResponseData> {
  const endpoint = GeneralStudentEndpoints.fetchGeneralStudent;
  const response = await base.api.get<FetchGeneralStudentResponseData>(
    `${endpoint.path}/${id}`,
  );
  return response.data;
}

async function finishStudent(
  params: FinishStudentRequest,
): Promise<FinishStudentResponse> {
  const endpoint = GeneralStudentEndpoints.finishStudent;
  await base.api.post(endpoint.path.replace('{user}', String(params.id)), {
    type: params.type,
  });
}

export const StudentGeneralService = {
  createGeneralStudent,
  updateGeneralStudent,
  fetchGeneralStudent,
  finishStudent,
};
