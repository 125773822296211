export function chunckJoinString(
  str: string,
  separator: string,
  size: number,
): string {
  try {
    return str.split(separator).slice(0, size).join(separator);
  } catch {
    return str;
  }
}
