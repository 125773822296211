import React, { createContext, useContext, useState } from 'react';

import { AuthService } from 'services/api/auth';

export interface AccountValidationContextData {
  loading: boolean;

  accountVerify: (token: string) => Promise<boolean>;
}

const AccountValidationContext = createContext<AccountValidationContextData>(
  {} as AccountValidationContextData,
);

const AccountValidationProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(true);

  const accountVerify = async (token: string) => {
    try {
      await AuthService.verifyEmail({ token });
      return true;
    } catch {
      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <AccountValidationContext.Provider value={{ loading, accountVerify }}>
      {children}
    </AccountValidationContext.Provider>
  );
};

function useAccountValidation(): AccountValidationContextData {
  const context = useContext(AccountValidationContext);

  if (!context) {
    throw new Error(
      'useAccountValidation must be used within an AccountValidationProvider',
    );
  }
  return context;
}

export {
  AccountValidationProvider,
  AccountValidationContext,
  useAccountValidation,
};
