import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Caption, H2 } from 'components/StyledTypography/styles';

export const Container = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 24px;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 24px 40px;
  max-width: 400px;
  width: 100%;

  background-color: ${({ theme }) => theme.palette.white};
  box-shadow: 0px 2px 14px -2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 24px;
  }
`;

export const Information = styled(Caption)`
  margin-top: 16px;
  text-align: center;
  color: ${({ theme }) => theme.palette.grey1};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 12px;
  }
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 16px;
  gap: 16px;
`;

export const ButtonsDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    button {
      width: 100%;
      font-size: 14px;
    }
  }
`;

export const StyledLink = styled(Link)`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 114%;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.palette.primaryDark};
`;

export const Title = styled(H2)`
  color: ${({ theme }) => theme.palette.black};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 18px;
  }
`;
