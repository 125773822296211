import produce, { Draft } from 'immer';
import {
  ActionType,
  createAction,
  createAsyncAction,
  Reducer,
} from 'typesafe-actions';

import { Option } from 'components/Select';
import {
  NewEnrollmentTeachingTypeFormData,
  NewEnrollmentEducationalDataFormData,
  NewEnrollmentSchoolOffersFormData,
  NewEnrollmentFormData,
} from 'models/newEnrollment';

export enum Types {
  SET_TEACHING_TYPE_FORM_DATA = '@newEnrollment/SET_TEACHING_TYPE_FORM_DATA',
  SET_EDUCATION_DATA_FORM_DATA = '@newEnrollment/SET_EDUCATION_DATA_FORM_DATA',
  SET_SCHOOL_OFFERS_FORM_DATA = '@newEnrollment/SET_SCHOOL_OFFERS_FORM_DATA',
  FETCH_NEW_ENROLLMENT_REQUEST = '@newEnrollment/FETCH_NEW_ENROLLMENT_REQUEST',
  FETCH_NEW_ENROLLMENT_SUCCESS = '@newEnrollment/FETCH_NEW_ENROLLMENT_SUCCESS',
  FETCH_NEW_ENROLLMENT_FAILURE = '@newEnrollment/FETCH_NEW_ENROLLMENT_FAILURE',
  CREATE_NEW_ENROLLMENT_REQUEST = '@newEnrollment/CREATE_NEW_ENROLLMENT_REQUEST',
  CREATE_NEW_ENROLLMENT_SUCCESS = '@newEnrollment/CREATE_NEW_ENROLLMENT_SUCCESS',
  CREATE_NEW_ENROLLMENT_FAILURE = '@newEnrollment/CREATE_NEW_ENROLLMENT_FAILURE',
  UPDATE_NEW_ENROLLMENT_REQUEST = '@newEnrollment/UPDATE_NEW_ENROLLMENT_REQUEST',
  UPDATE_NEW_ENROLLMENT_SUCCESS = '@newEnrollment/UPDATE_NEW_ENROLLMENT_SUCCESS',
  UPDATE_NEW_ENROLLMENT_FAILURE = '@newEnrollment/UPDATE_NEW_ENROLLMENT_FAILURE',
}

export interface NewEnrollmentState {
  loading: boolean;
  teachingTypeFormData: NewEnrollmentTeachingTypeFormData;
  educationDataFormData: NewEnrollmentEducationalDataFormData;
  schoolOffersFormData: NewEnrollmentSchoolOffersFormData;
}

const INITIAL_STATE: NewEnrollmentState = {
  loading: false,
  teachingTypeFormData: {} as NewEnrollmentTeachingTypeFormData,
  educationDataFormData: {} as NewEnrollmentEducationalDataFormData,
  schoolOffersFormData: {} as NewEnrollmentSchoolOffersFormData,
};

export const NewEnrollmentCreators = {
  setNewEnrollmentTeachingTypeForm: createAction(
    Types.SET_TEACHING_TYPE_FORM_DATA,
  )<NewEnrollmentTeachingTypeFormData>(),
  setNewEnrollmentEducationDataForm: createAction(
    Types.SET_EDUCATION_DATA_FORM_DATA,
  )<NewEnrollmentEducationalDataFormData>(),
  setNewEnrollmentSchoolOffersForm: createAction(
    Types.SET_SCHOOL_OFFERS_FORM_DATA,
  )<NewEnrollmentSchoolOffersFormData>(),
  createNewEnrollment: createAsyncAction(
    Types.CREATE_NEW_ENROLLMENT_REQUEST,
    Types.CREATE_NEW_ENROLLMENT_SUCCESS,
    Types.CREATE_NEW_ENROLLMENT_FAILURE,
  )<NewEnrollmentFormData, NewEnrollmentFormData, string>(),
  updateNewEnrollment: createAsyncAction(
    Types.UPDATE_NEW_ENROLLMENT_REQUEST,
    Types.UPDATE_NEW_ENROLLMENT_SUCCESS,
    Types.UPDATE_NEW_ENROLLMENT_FAILURE,
  )<NewEnrollmentFormData, NewEnrollmentFormData, string>(),
  fetchNewEnrollment: createAsyncAction(
    Types.FETCH_NEW_ENROLLMENT_REQUEST,
    Types.FETCH_NEW_ENROLLMENT_SUCCESS,
    Types.FETCH_NEW_ENROLLMENT_FAILURE,
  )<void, NewEnrollmentFormData, string>(),
};

export type ActionTypes = ActionType<typeof NewEnrollmentCreators>;

function unwrapTeachingTypeFromNewEnrollmentForm(
  enroll: NewEnrollmentFormData,
): NewEnrollmentTeachingTypeFormData {
  const {
    mediationOption,
    stepOption,
    modalityOption,
    modalitySpecialOption,
    regimeOption,
    temporalityOption,
    shiftOption,
  } = enroll;
  return {
    mediationOption,
    stepOption,
    modalityOption,
    modalitySpecialOption,
    regimeOption,
    temporalityOption,
    shiftOption,
  };
}

function unwrapEducationDataFromNewEnrollmentForm(
  enroll: NewEnrollmentFormData,
): NewEnrollmentEducationalDataFormData {
  const {
    previousSchoolIdOption,
    disableGrade2020Option,
    portugueseGrade2020,
    mathGrade2020,
    disableGrade2021Option,
    portugueseGrade2021,
    mathGrade2021,
    publicTransportOption,
    previousSchool,
  } = enroll;
  return {
    previousSchoolIdOption,
    disableGrade2020Option,
    portugueseGrade2020,
    mathGrade2020,
    disableGrade2021Option,
    portugueseGrade2021,
    mathGrade2021,
    publicTransportOption,
    previousSchool,
  };
}

function unwrapSchoolOffersFromNewEnrollmentForm(
  enroll: NewEnrollmentFormData,
): NewEnrollmentSchoolOffersFormData {
  const {
    schoolId1Option,
    schoolId2Option,
    schoolId3Option,
    schoolId4Option,
    schoolId5Option,
    courseId1Option,
    courseId2Option,
    courseId3Option,
    courseId4Option,
    courseId5Option,
    cityId1Option,
    cityId2Option,
    cityId3Option,
    cityId4Option,
    cityId5Option,
    eptOrFic1Option,
    eptOrFic2Option,
    eptOrFic3Option,
    eptOrFic4Option,
    eptOrFic5Option,
    itineraryId1Option,
    itineraryId2Option,
    itineraryId3Option,
    itineraryId4Option,
    itineraryId5Option,
    siblingsToEnrollOption,
    siblings,
  } = enroll;
  return {
    schoolId1Option,
    schoolId2Option,
    schoolId3Option,
    schoolId4Option,
    schoolId5Option,
    courseId1Option,
    courseId2Option,
    courseId3Option,
    courseId4Option,
    courseId5Option,
    cityId1Option,
    cityId2Option,
    cityId3Option,
    cityId4Option,
    cityId5Option,
    eptOrFic1Option,
    eptOrFic2Option,
    eptOrFic3Option,
    eptOrFic4Option,
    eptOrFic5Option,
    itineraryId1Option,
    itineraryId2Option,
    itineraryId3Option,
    itineraryId4Option,
    itineraryId5Option,
    siblingsToEnrollOption,
    siblings,
  };
}

const reducer: Reducer<NewEnrollmentState, ActionTypes> = (
  state = INITIAL_STATE,
  action: ActionTypes,
) => {
  const { type, payload } = action;

  return produce(state, (draft: Draft<NewEnrollmentState>) => {
    switch (type) {
      case Types.SET_TEACHING_TYPE_FORM_DATA: {
        draft.loading = false;
        draft.teachingTypeFormData =
          payload as NewEnrollmentTeachingTypeFormData;
        break;
      }
      case Types.SET_EDUCATION_DATA_FORM_DATA: {
        draft.loading = false;
        draft.educationDataFormData =
          payload as NewEnrollmentEducationalDataFormData;
        break;
      }
      case Types.SET_SCHOOL_OFFERS_FORM_DATA: {
        draft.loading = false;
        draft.schoolOffersFormData =
          payload as NewEnrollmentSchoolOffersFormData;
        break;
      }
      case Types.CREATE_NEW_ENROLLMENT_REQUEST:
      case Types.UPDATE_NEW_ENROLLMENT_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.FETCH_NEW_ENROLLMENT_REQUEST: {
        draft.teachingTypeFormData = {} as NewEnrollmentTeachingTypeFormData;
        draft.educationDataFormData =
          {} as NewEnrollmentEducationalDataFormData;
        draft.schoolOffersFormData = {} as NewEnrollmentSchoolOffersFormData;
        draft.loading = true;
        break;
      }
      case Types.UPDATE_NEW_ENROLLMENT_FAILURE:
      case Types.CREATE_NEW_ENROLLMENT_FAILURE:
      case Types.FETCH_NEW_ENROLLMENT_FAILURE: {
        draft.loading = false;
        break;
      }
      case Types.CREATE_NEW_ENROLLMENT_SUCCESS:
      case Types.UPDATE_NEW_ENROLLMENT_SUCCESS:
      case Types.FETCH_NEW_ENROLLMENT_SUCCESS: {
        const newEnrollmentData = payload as NewEnrollmentFormData;
        draft.teachingTypeFormData =
          unwrapTeachingTypeFromNewEnrollmentForm(newEnrollmentData);
        draft.educationDataFormData =
          unwrapEducationDataFromNewEnrollmentForm(newEnrollmentData);
        draft.schoolOffersFormData =
          unwrapSchoolOffersFromNewEnrollmentForm(newEnrollmentData);
        draft.loading = false;
        break;
      }

      default:
    }
  });
};

export default reducer;
