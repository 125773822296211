import base from 'services/api/base';
import { ServiceEndpoints } from 'services/api/types';

import {
  CreateNewEnrollmentRequestData,
  FetchNewEnrollmentResponseData,
  UpdateNewEnrollmentRequestData,
  UpdateNewEnrollmentResponseData,
} from './models';

const Strings = {
  DEFAULT_ERROR: 'Algo deu errado',
};

export const NewEnrollmentEndpoints: ServiceEndpoints = {
  createNewEnrollment: {
    path: 'newEnrollments',
    errorMessages: {
      400: 'Dados inválidos. Preencha os campos corretamente',
      default: Strings.DEFAULT_ERROR,
    },
  },
  updateNewEnrollment: {
    path: 'newEnrollments',
    errorMessages: {
      400: 'Dados inválidos. Preencha os campos corretamente',
      404: 'Matrícula não encontrada',
      default: Strings.DEFAULT_ERROR,
    },
  },
  fetchNewEnrollment: {
    path: 'newEnrollments',
    errorMessages: {
      404: 'Matrícula não encontrada',
      default: Strings.DEFAULT_ERROR,
    },
  },
};

async function createNewEnrollment(
  id: number,
  body: CreateNewEnrollmentRequestData,
): Promise<CreateNewEnrollmentRequestData> {
  const endpoint = NewEnrollmentEndpoints.createNewEnrollment;
  const response = await base.api.post<CreateNewEnrollmentRequestData>(
    `${endpoint.path}/${id}`,
    body,
  );
  return response.data;
}

async function updateNewEnrollment(
  id: number,
  body: UpdateNewEnrollmentRequestData,
): Promise<UpdateNewEnrollmentResponseData> {
  const endpoint = NewEnrollmentEndpoints.updateNewEnrollment;
  await base.api.patch<UpdateNewEnrollmentResponseData>(
    `${endpoint.path}/${id}`,
    body,
  );
}

async function fetchNewEnrollment(
  id: number,
): Promise<FetchNewEnrollmentResponseData> {
  const endpoint = NewEnrollmentEndpoints.fetchNewEnrollment;
  const response = await base.api.get<FetchNewEnrollmentResponseData>(
    `${endpoint.path}/${id}`,
  );

  return response.data;
}

export const NewEnrollmentService = {
  createNewEnrollment,
  updateNewEnrollment,
  fetchNewEnrollment,
};
