import { useSelector } from 'react-redux';

import { IconArrowLeft } from 'assets/icons';
import { Button } from 'components';
import { Option } from 'components/Select';
import { Caption } from 'components/StyledTypography/styles';
import { NewEnrollmentSchoolOffersFormData } from 'models/newEnrollment';
import { NewEnrollmentStep } from 'pages/NewEnrollment';
import {
  HeaderSection,
  DisplayField,
  ReviewRow,
  SubHeader,
} from 'pages/StudentEnrollment/Form/ReviewForm/components';
import { StepsButtonContainer } from 'pages/StudentEnrollment/styles';
import { RootState } from 'store';
import { unwrapOptionLabel } from 'utils/wrapperUtils';

import { Container } from './styles';

interface ReviewForm {
  onBack: () => void;
  onNext: () => void;
  onGoToStep: (step: NewEnrollmentStep) => void;
  editable: boolean;
}

const ReviewForm = ({ onBack, onNext, onGoToStep, editable }: ReviewForm) => {
  const { teachingTypeFormData, educationDataFormData, schoolOffersFormData } =
    useSelector(({ newEnrollment }: RootState) => newEnrollment);

  const { studentFormData } = useSelector(
    ({ studentGeneralInfo }: RootState) => studentGeneralInfo,
  );

  const isPPL = !!studentFormData?.pplOption?.value;

  return (
    <Container>
      <Caption>Confira se os dados foram preenchidos corretamente</Caption>
      <HeaderSection
        title="Tipo de ensino"
        onEditAction={() => onGoToStep(NewEnrollmentStep.TEACHING_TYPE)}
        editable={editable}
      />
      <>
        <ReviewRow>
          <DisplayField
            question="Mediação"
            answer={unwrapOptionLabel(teachingTypeFormData.mediationOption)}
          />
          <DisplayField
            question="Etapa"
            answer={unwrapOptionLabel(teachingTypeFormData.stepOption)}
          />
        </ReviewRow>
        <ReviewRow>
          <DisplayField
            question="Modalidade"
            answer={unwrapOptionLabel(teachingTypeFormData.modalityOption)}
          />
          <DisplayField
            question="Modalidade Extra"
            answer={unwrapOptionLabel(
              teachingTypeFormData.modalitySpecialOption,
            )}
          />
        </ReviewRow>
        <ReviewRow>
          <DisplayField
            question="Temporalidade"
            answer={unwrapOptionLabel(teachingTypeFormData.temporalityOption)}
          />
          <DisplayField
            question="Turno"
            answer={unwrapOptionLabel(teachingTypeFormData.shiftOption)}
          />
        </ReviewRow>
      </>

      <HeaderSection
        title="Dados Educacionais"
        onEditAction={() => onGoToStep(NewEnrollmentStep.EDUCATIONAL_DATA)}
        editable={editable}
      />
      <>
        <ReviewRow>
          <DisplayField
            question="Escola de Origem"
            answer={
              unwrapOptionLabel(educationDataFormData.previousSchoolIdOption) ??
              educationDataFormData.previousSchool ??
              ''
            }
          />

          {!isPPL && (
            <DisplayField
              question="Possui necessidade de transporte escolar público?"
              answer={unwrapOptionLabel(
                educationDataFormData.publicTransportOption,
              )}
            />
          )}
        </ReviewRow>

        <SubHeader>Média Ano Letivo 2021</SubHeader>
        <ReviewRow>
          <DisplayField
            question="Língua Portuguesa"
            answer={educationDataFormData.portugueseGrade2020}
          />

          <DisplayField
            question="Língua Matemática"
            answer={educationDataFormData.mathGrade2020}
          />
        </ReviewRow>

        <SubHeader>Média Ano Letivo 2022</SubHeader>
        <ReviewRow>
          <DisplayField
            question="Língua Portuguesa"
            answer={educationDataFormData.portugueseGrade2021}
          />

          <DisplayField
            question="Língua Matemática"
            answer={educationDataFormData.mathGrade2021}
          />
        </ReviewRow>
      </>

      <HeaderSection
        title="Oferta de Escolas"
        onEditAction={() => onGoToStep(NewEnrollmentStep.SCHOOL_OFFERS)}
        editable={editable}
      />
      {[1, 2, 3, 4, 5].map(item => {
        const cityIdOption = schoolOffersFormData[
          `cityId${item}Option` as keyof NewEnrollmentSchoolOffersFormData
        ] as Option;
        const eptOrFicOption = schoolOffersFormData[
          `eptOrFic${item}Option` as keyof NewEnrollmentSchoolOffersFormData
        ] as Option;
        const schoolIdOption = schoolOffersFormData[
          `schoolId${item}Option` as keyof NewEnrollmentSchoolOffersFormData
        ] as Option;
        const courseIdOption = schoolOffersFormData[
          `courseId${item}Option` as keyof NewEnrollmentSchoolOffersFormData
        ] as Option;
        const itineraryIdOption = schoolOffersFormData[
          `itineraryId${item}Option` as keyof NewEnrollmentSchoolOffersFormData
        ] as Option;

        if (!cityIdOption) {
          return null;
        }

        return (
          <>
            <SubHeader>{`${item}ª Opção`}</SubHeader>
            <ReviewRow>
              <DisplayField
                question="Cidade"
                answer={unwrapOptionLabel(cityIdOption)}
              />

              <DisplayField
                question="Pretende cursar EPT?"
                answer={unwrapOptionLabel(eptOrFicOption)}
              />
            </ReviewRow>
            <ReviewRow>
              <DisplayField
                question="Escola"
                answer={unwrapOptionLabel(schoolIdOption)}
              />
              {courseIdOption && (
                <DisplayField
                  question="Curso EPT"
                  answer={unwrapOptionLabel(courseIdOption)}
                />
              )}
              {itineraryIdOption && (
                <DisplayField
                  question="Itinerário formativo"
                  answer={unwrapOptionLabel(itineraryIdOption)}
                />
              )}
            </ReviewRow>
          </>
        );
      })}

      {editable && (
        <StepsButtonContainer>
          <Button
            iconLeft={IconArrowLeft}
            title="Anterior"
            type="button"
            styled="secondary"
            onClick={onBack}
            size="mini"
          />
          <Button
            type="button"
            size="mini"
            title="Finalizar"
            onClick={onNext}
          />
        </StepsButtonContainer>
      )}
    </Container>
  );
};

export default ReviewForm;
