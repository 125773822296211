import styled from 'styled-components';

import { H1 } from 'components/StyledTypography/styles';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.white};
`;

export const Content = styled.div`
  display: flex;
  max-width: ${({ theme }) => theme.breakpoints.xl}px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 24px 16px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  span {
    color: ${({ theme }) => theme.palette.grey1};
    margin-left: 8px;
  }
`;

export const Title = styled(H1)`
  color: ${({ theme }) => theme.palette.grey1};
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 20px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    font-size: 16px;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
`;

export const ItemSide = styled.div`
  display: flex;
  flex: 1;
  z-index: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: none;
  }
`;

export const ItemCenter = styled.div``;
