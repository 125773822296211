import styled from 'styled-components';

import { Label } from 'components/Select/styles';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 24px;
`;

export const Separator = styled.div`
  margin: 8px 0px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey3};
`;

export const FormSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LabelForm = styled(Label)`
  margin: 24px 0;
`;

export const RadioRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;

  span {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 114%;
    letter-spacing: 0.2px;
  }
`;
