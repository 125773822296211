import { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { SaberLogo } from 'assets/images';
import { DropdownMenu } from 'components';
import { RootState } from 'store';
import { AuthCreators } from 'store/ducks/auth';
import { chunckJoinString } from 'utils/stringsUtils';

import {
  Container,
  Content,
  Title,
  TitleContainer,
  LogoContainer,
  HeaderRight,
  EmptyContent,
} from './styles';

type MenuItemVariant = 'none' | 'special-access' | 'user';

interface HeaderProps {
  menuItemVariant: MenuItemVariant;
  title?: string;
}

const Header = ({ menuItemVariant, title }: HeaderProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userPersonalInfo } = useSelector(({ auth }: RootState) => auth);

  const renderMenu = useCallback(
    (variant: MenuItemVariant) => {
      switch (variant) {
        case 'none':
          return <EmptyContent />;
        case 'special-access':
          return (
            <HeaderRight>
              {/* <DropdownMenu
                title="Acesso Especial"
                items={[
                  { title: 'Professor', handler: () => false },
                  { title: 'Escola', handler: () => false },
                ]}
              /> */}
            </HeaderRight>
          );
        case 'user':
          return (
            <HeaderRight>
              <DropdownMenu
                title={chunckJoinString(
                  userPersonalInfo?.fullname || '',
                  ' ',
                  2,
                )}
                items={[
                  {
                    title: 'Sair da Aplicação',
                    handler: () => {
                      dispatch(AuthCreators.signOut());
                    },
                  },
                ]}
              />
            </HeaderRight>
          );
        default:
          return <EmptyContent />;
      }
    },
    [dispatch, userPersonalInfo],
  );

  const onLogoTap = () => {
    switch (menuItemVariant) {
      case 'user':
        navigate('/home');
        break;
      case 'special-access':
        navigate('/');
        break;
      case 'none':
        navigate('/');
        break;
      default:
        break;
    }
  };

  return (
    <Container>
      <Content>
        <LogoContainer onClick={onLogoTap}>
          <img src={SaberLogo} alt="Logo do Saber PB" />
        </LogoContainer>
        <TitleContainer>
          <Title>{title}</Title>
        </TitleContainer>
        {renderMenu(menuItemVariant)}
      </Content>
    </Container>
  );
};

Header.defaultProps = {
  title: undefined,
};

export default Header;
