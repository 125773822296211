import React from 'react';

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  AccordionIcon,
} from '@chakra-ui/react';

import { ESaberLogo2 } from 'assets/images';
import { Cover, Footer } from 'components';

import {
  Container,
  Header,
  Content,
  Title,
  ContainerAccordion,
  Column,
  HeaderContent,
} from './styles';

const QUESTIONS = [
  {
    question: 'Posso realizar a matrícula diretamente na secretaria da escola?',
    response:
      'Não, todo o processo deverá ser realizado pelo sistema de matrículas. Contudo, as escolas da rede estadual deverão atuar como polos de matrícula para aqueles estudantes que não tiverem acesso à internet para a utilização do sistema de matrículas. ',
  },
  {
    question: 'Quem já estuda na rede estadual precisa renovar a matrícula?',
    response:
      'Sim, todo estudante deve renovar sua matrícula para dar continuidade a sua rotina escolar.',
  },
  {
    question:
      'Quem vai mudar de escola, deve renovar ou fazer uma nova matrícula?',
    response:
      'Quem precisa mudar de escola para o ano seguinte deverá solicitar transferência voluntária no site de matrícula e, a seguir, realizar o processo como nova matrícula, respeitando os prazos estabelecidos.',
  },
  {
    question:
      'Após criar a conta no sistema de matrículas é preciso fazer mais alguma coisa?',
    response:
      'Sim, a criação da conta permite apenas o acesso ao sistema. Todo estudante ou responsável precisará preencher a ficha de cadastro, o formulário de matrícula e acompanhar o andamento do processo até a confirmação da sua matrícula.',
  },
  {
    question:
      'Posso acessar o formulário mais de uma vez para preencher todas as informações?',
    response:
      'Sim, o sistema poderá ser acessado quantas vezes forem necessárias para o preenchimento das informações. É importante apenas ficar atento para completar todas as etapas dentro do prazo estabelecido.',
  },
  {
    question:
      'É possível fazer a matrícula de mais de um estudante ao mesmo tempo?',
    response:
      'Não, deverá ser criado uma conta para cada estudante que for se matricular na rede, preenchendo seu cadastro individualmente.',
  },
  {
    question: 'O estudante pode escolher em qual escola vai estudar?',
    response:
      'O estudante que irá renovar sua matrícula permanecerá na mesma escola do Ano Letivo em curso. Para os estudantes novatos e estudantes que desejam mudar de escola da rede estadual, haverá a opção de escolher até cinco escolas de sua preferência. A SEECT-PB fará o possível para atender a preferência do estudante levando em consideração a modalidade de ensino e proximidade da residência.',
  },
  {
    question: 'O que fazer após cadastro e solicitação de matrícula?',
    response:
      'Após a realização do cadastro e a matrícula, o estudante deverá aguardar a confirmação da mesma pela escola. O processo pode ser acompanhado na área "Acompanhamento" do site.',
  },
];

const Landing = () => {
  return (
    <Container>
      <Header>
        <HeaderContent>
          <img src={ESaberLogo2} alt="Logo do eSaber" />
        </HeaderContent>
      </Header>
      <Cover />
      <Column>
        <Content>
          <Title>Dúvidas frequentes</Title>
          <ContainerAccordion>
            {QUESTIONS.map(item => (
              <Accordion allowToggle allowMultiple>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        {item.question}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel
                    style={{ border: '0.5px solid rgba(173, 173, 173, 0.5)' }}
                    pb={4}
                  >
                    {item.response}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            ))}
          </ContainerAccordion>
        </Content>
        <Footer />
      </Column>
    </Container>
  );
};

export default Landing;
