import { useCallback, useEffect } from 'react';

import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { IconArrowRight } from 'assets/icons';
import { Button, Select } from 'components';
import { useEducationFilter } from 'hooks/EducationFilterContext';
import { TeachingTypeFormData } from 'models/enrollment';
import {
  ChildrenFormContainer,
  ChildrenFormRow,
  GrayLine,
  StepsButtonContainer,
} from 'pages/StudentEnrollment/styles';
import { RootState } from 'store';
import { EnrollmentCreators } from 'store/ducks/enrollment';
import {
  checkFormikErrorsAndSetFieldTouched,
  getFormikMessageError,
} from 'utils/formikUtils';
import { stringSelectOptionRequiredSchema } from 'utils/validations';

const TEACHING_TYPE_SCHEMA = Yup.object().shape({
  mediationOption: stringSelectOptionRequiredSchema,
  stepOption: stringSelectOptionRequiredSchema,
  modalityOption: stringSelectOptionRequiredSchema,
  modalitySpecialOption: stringSelectOptionRequiredSchema,
  temporalityOption: stringSelectOptionRequiredSchema,
  shiftOption: stringSelectOptionRequiredSchema,
});

interface TeachingTypeProps {
  onNext: () => void;
}

const TeachingType = ({ onNext }: TeachingTypeProps) => {
  const {
    loading,
    mediations,
    steps,
    modalities,
    educationOptions,
    shifts,
    temporalities,
    getMediations,
    getSteps,
    getModalities,
    getEducationOptions,
    getShifts,
    getTemporalities,
  } = useEducationFilter();

  const dispatch = useDispatch();
  const { educationTypeForm } = useSelector(
    ({ enrollment }: RootState) => enrollment,
  );

  const onSubmit = useCallback(
    (formData: TeachingTypeFormData) => {
      dispatch(EnrollmentCreators.setEnrollmentEducationTypeForm(formData));
      onNext();
    },
    [onNext, dispatch],
  );

  const formik = useFormik({
    initialValues: educationTypeForm,
    validationSchema: TEACHING_TYPE_SCHEMA,
    onSubmit,
  });

  useEffect(() => {
    checkFormikErrorsAndSetFieldTouched(formik);
  }, [formik.isSubmitting]);

  useEffect(() => {
    async function loadFilters() {
      await getEducationOptions();

      if (!isEmpty(educationTypeForm)) {
        const mediationId = String(educationTypeForm.mediationOption.value);
        const modalityId = String(educationTypeForm.modalityOption.value);
        const educationOptionId = String(
          educationTypeForm.modalitySpecialOption.value,
        );
        const stepId = String(educationTypeForm.stepOption.value);

        await getMediations({ educationOptionId });
        await getModalities({ mediationId });
        await getSteps({ modalityId });
        await getShifts({ stepId });
        await getTemporalities({ stepId });
      }
    }

    loadFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      formik.values.modalitySpecialOption &&
      formik.values.modalitySpecialOption !== undefined &&
      formik.dirty
    ) {
      getMediations({
        educationOptionId: formik.values.modalitySpecialOption.value as string,
      });

      formik.setFieldValue('mediationOption', null);
      formik.setFieldValue('modalityOption', null);
      formik.setFieldValue('stepOption', null);
      formik.setFieldValue('shiftOption', null);
      formik.setFieldValue('temporalityOption', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.modalitySpecialOption]);

  useEffect(() => {
    if (
      formik.values.mediationOption &&
      formik.values.mediationOption !== undefined &&
      formik.dirty
    ) {
      getModalities({
        mediationId: formik.values.mediationOption.value as string,
      });

      formik.setFieldValue('modalityOption', null);
      formik.setFieldValue('stepOption', null);
      formik.setFieldValue('shiftOption', null);
      formik.setFieldValue('temporalityOption', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.mediationOption]);

  useEffect(() => {
    if (
      formik.values.modalityOption &&
      formik.values.modalityOption !== undefined &&
      formik.dirty
    ) {
      getSteps({
        modalityId: formik.values.modalityOption.value as string,
      });

      formik.setFieldValue('stepOption', null);
      formik.setFieldValue('shiftOption', null);
      formik.setFieldValue('temporalityOption', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.modalityOption]);

  useEffect(() => {
    if (
      formik.values.stepOption &&
      formik.values.stepOption !== undefined &&
      formik.dirty
    ) {
      getShifts({
        stepId: formik.values.stepOption.value as string,
      });
      getTemporalities({
        stepId: formik.values.stepOption.value as string,
      });

      formik.setFieldValue('shiftOption', null);
      formik.setFieldValue('temporalityOption', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.stepOption]);

  return (
    <ChildrenFormContainer id="teaching-form-id">
      <ChildrenFormRow>
        <Select
          label="Modalidade Extra"
          options={educationOptions}
          required
          value={formik.values.modalitySpecialOption}
          onChange={(option, _) =>
            formik.setFieldValue('modalitySpecialOption', option)
          }
          messageError={getFormikMessageError(formik, 'modalitySpecialOption')}
          disabled={loading || isEmpty(educationOptions)}
        />
        <Select
          label="Mediação"
          options={mediations}
          required
          value={formik.values.mediationOption}
          onChange={(option, _) =>
            formik.setFieldValue('mediationOption', option)
          }
          messageError={getFormikMessageError(formik, 'mediationOption')}
          disabled={loading || isEmpty(mediations)}
        />
      </ChildrenFormRow>

      <ChildrenFormRow>
        <Select
          label="Modalidade"
          options={modalities}
          required
          value={formik.values.modalityOption}
          onChange={(option, _) =>
            formik.setFieldValue('modalityOption', option)
          }
          messageError={getFormikMessageError(formik, 'modalityOption')}
          disabled={loading || isEmpty(modalities)}
        />
        <Select
          label="Etapa"
          options={steps}
          required
          value={formik.values.stepOption}
          onChange={(option, _) => formik.setFieldValue('stepOption', option)}
          messageError={getFormikMessageError(formik, 'stepOption')}
          disabled={loading || isEmpty(steps)}
        />
      </ChildrenFormRow>

      <GrayLine />

      <ChildrenFormRow>
        <Select
          label="Temporalidade"
          options={temporalities}
          required
          value={formik.values.temporalityOption}
          onChange={(option, _) =>
            formik.setFieldValue('temporalityOption', option)
          }
          messageError={getFormikMessageError(formik, 'temporalityOption')}
          disabled={loading || isEmpty(temporalities)}
        />
        <Select
          label="Turno"
          options={shifts}
          required
          value={formik.values.shiftOption}
          onChange={(option, _) => formik.setFieldValue('shiftOption', option)}
          messageError={getFormikMessageError(formik, 'shiftOption')}
          disabled={loading || isEmpty(shifts)}
        />
      </ChildrenFormRow>
      <StepsButtonContainer>
        <Button
          iconRight={IconArrowRight}
          type="button"
          size="mini"
          title="Próximo"
          form="teaching-form-id"
          onClick={formik.submitForm}
          disabled={loading}
        />
      </StepsButtonContainer>
    </ChildrenFormContainer>
  );
};

export default TeachingType;
