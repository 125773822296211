import produce, { Draft } from 'immer';
import { ActionType, createAsyncAction, Reducer } from 'typesafe-actions';

import { StudentSchoolResponse } from 'services/api/students/school/models';

export enum Types {
  FETCH_USER_SCHOOL_REQUEST = '@studends/school/FETCH_USER_SCHOOL_REQUEST',
  FETCH_USER_SCHOOL_FAILURE = '@studends/school/FETCH_USER_SCHOOL_REQUEST_FAILURE',
  FETCH_USER_SCHOOL_SUCCESS = '@studends/school/FETCH_USER_SCHOOL_REQUEST_SUCCESS',
}

export interface StudentSchoolState {
  school?: StudentSchoolResponse;
  loading: boolean;
}

const INITIAL_STATE: StudentSchoolState = {
  school: undefined,
  loading: false,
};

export const StudentSchoolCreators = {
  getStudentSchool: createAsyncAction(
    Types.FETCH_USER_SCHOOL_REQUEST,
    Types.FETCH_USER_SCHOOL_SUCCESS,
    Types.FETCH_USER_SCHOOL_FAILURE,
  )<void, StudentSchoolResponse, string>(),
};

export type ActionTypes = ActionType<typeof StudentSchoolCreators>;

const reducer: Reducer<StudentSchoolState, ActionTypes> = (
  state = INITIAL_STATE,
  action: ActionTypes,
) => {
  const { type, payload } = action;

  return produce(state, (draft: Draft<StudentSchoolState>) => {
    switch (type) {
      case Types.FETCH_USER_SCHOOL_REQUEST: {
        draft.loading = true;
        draft.school = undefined;
        break;
      }
      case Types.FETCH_USER_SCHOOL_SUCCESS: {
        draft.loading = false;
        draft.school = payload as StudentSchoolResponse;
        break;
      }
      case Types.FETCH_USER_SCHOOL_FAILURE: {
        draft.loading = false;
        draft.school = undefined;
        break;
      }

      default:
    }
  });
};

export default reducer;
