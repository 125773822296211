import { AxiosError } from 'axios';

import { store } from 'store';
import { AuthCreators } from 'store/ducks/auth';

import { AuthEndpoints } from './auth';
import { CitiesEndpoints } from './cities';
import { EnrollmentEndpoints } from './enrollment';
import { StatesEndpoints } from './states';
import { GeneralStudentEndpoints } from './students/general';
import { ResponsableDataEndpoints } from './students/responsableData';
import { Endpoint, ResponseError, ServiceEndpoints } from './types';

export const ErrorStrings = {
  DEFAULT_ERROR: 'Algo deu errado',
  CONNECTION_ERROR:
    'Erro de conexão com a Internet. Por favor, verifique sua conexão',
  SESSION_EXPIRED: 'Sua sessão expirou, Por favor, faça login novamente',
};

const ALL_SERVICES_ENDPOINTS: ServiceEndpoints[] = [
  CitiesEndpoints,
  StatesEndpoints,
  GeneralStudentEndpoints,
  ResponsableDataEndpoints,
  EnrollmentEndpoints,
];

function getResponseErrorMessage(error: AxiosError): string {
  const pathRequested = error.config.url;
  const status = error.response?.status;

  const serverErrorMessage = error.response?.data?.body?.errorMessage;

  if (serverErrorMessage) {
    return serverErrorMessage;
  }

  let serviceEndpointRequested: Endpoint | undefined;

  [...ALL_SERVICES_ENDPOINTS, AuthEndpoints].find(serviceEndpoints => {
    const serviceEndpointKey = Object.keys(serviceEndpoints).find(
      key => serviceEndpoints[key].path === pathRequested,
    );

    if (serviceEndpointKey) {
      serviceEndpointRequested = serviceEndpoints[serviceEndpointKey];
      return true;
    }

    return false;
  });

  if (!serviceEndpointRequested) {
    return ErrorStrings.DEFAULT_ERROR;
  }

  const { errorMessages } = serviceEndpointRequested;

  if (status) {
    return errorMessages[status];
  }

  return errorMessages.default ?? ErrorStrings.DEFAULT_ERROR;
}

export function responseErrorHandler(error: AxiosError): ResponseError {
  if (error.response) {
    const { status } = error.response;
    const { url } = error.config;
    const authPaths = Object.values(AuthEndpoints).map(item => item.path);

    // Auth paths don't have session expired error [MA]
    if (status === 401 && !authPaths.includes(url || '')) {
      // At this point the refreshToken failed so we'll signout the user [ML]
      store.dispatch(AuthCreators.signOut());

      return {
        statusCode: status,
        message: ErrorStrings.SESSION_EXPIRED,
      };
    }

    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx [ML]
    return { statusCode: status, message: getResponseErrorMessage(error) };
  }

  if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser
    // and an instance of http.ClientRequest in node.js [ML]
    return {
      statusCode: error.request.status,
      message: ErrorStrings.CONNECTION_ERROR,
    };
  }

  // Something happened in setting up the request that triggered an Error [ML]
  return {
    statusCode: -1,
    message: ErrorStrings.DEFAULT_ERROR,
  };
}

export function mapErrorToResponseError(error: unknown): ResponseError {
  const responseError = error as ResponseError;

  if (responseError?.statusCode && responseError?.message) {
    return responseError;
  }

  if (error as AxiosError) {
    return responseErrorHandler(error as AxiosError);
  }

  return {
    statusCode: -1,
    message: ErrorStrings.DEFAULT_ERROR,
  };
}
