import styled from 'styled-components';

import { ButtonType } from '.';

interface TypeButton {
  buttonType?: ButtonType;
  size?: string;
  icon?: string;
}

export const ButtonStyle = styled.button<TypeButton>`
  background: ${({ buttonType, theme }) =>
    buttonType === 'secondary'
      ? theme.palette.white
      : theme.palette.primaryDark};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 100px;
  border: ${({ buttonType, theme }) =>
    buttonType === 'secondary' ? `1px solid ${theme.palette.grey3}` : 'none'};

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  color: ${({ buttonType, theme }) =>
    buttonType === 'secondary' ? theme.palette.grey1 : theme.palette.white};

  width: ${props => {
    switch (props.size) {
      case 'mini':
        return '118px';
      case 'small':
        return '140px';
      case 'medium':
        return '200px';
      case 'large':
        return '240px';
      case 'bigger':
        return '300px';
      default:
        return '100%';
    }
  }};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 118px;
  }

  &:hover {
    background: ${({ theme }) => theme.palette.primaryDark};
    color: ${({ theme }) => theme.palette.white};
  }

  &:active {
    background: ${({ theme }) => theme.palette.primaryDark};
    color: ${({ theme }) => theme.palette.white};
  }

  &:visited {
    color: ${({ buttonType, theme }) =>
      buttonType === 'secondary' ? theme.palette.grey1 : theme.palette.white};
  }

  &:disabled {
    cursor: not-allowed;
    background: ${({ buttonType, theme }) =>
      buttonType === 'secondary' ? theme.palette.white : theme.palette.grey2};
    color: ${({ buttonType, theme }) =>
      buttonType === 'secondary' ? theme.palette.grey1 : theme.palette.white};
  }
`;

export const LinkS = styled.a`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.2px;

  color: ${({ theme }) => theme.palette.primaryDark};

  &:hover {
    text-decoration-line: underline;
    color: ${({ theme }) => theme.palette.primaryDark};
  }

  &:active {
    color: ${({ theme }) => theme.palette.primaryLight};
  }

  &:visited {
    color: #4e00f3;
  }

  &:focus {
    color: #121212;
    outline: 1px solid red;
  }
`;

export const IconStyle = styled.img<TypeButton>``;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 8px;
  margin-right: 8px;
`;
