import { all, takeLatest, put } from 'redux-saga/effects';

import { mapErrorToResponseError } from 'services/api/responseErrorHandler';
import { SaberService } from 'services/api/students/saber';
import { SaberResponse } from 'services/api/students/saber/models';
import { SaberCreators } from 'store/ducks/students/saber';

function* fetchSaberStudentByCode(
  action: ReturnType<typeof SaberCreators.getStudent.request>,
): Generator<unknown, void, SaberResponse> {
  try {
    const { code } = action.payload;
    const { name } = yield SaberService.fetchSaberStudentByCode(code);
    yield put(SaberCreators.getStudent.success({ name }));
  } catch (error) {
    if ((error as any)?.statusCode === 404) {
      yield put(
        SaberCreators.getStudent.failure('Por favor, insira um número válido'),
      );
      return;
    }

    const responseError = mapErrorToResponseError(error);
    yield put(SaberCreators.getStudent.failure(responseError.message));
  }
}

export default all([
  takeLatest(SaberCreators.getStudent.request, fetchSaberStudentByCode),
]);
