import base from 'services/api/base';

import { ServiceEndpoints } from '../types';
import {
  RequestVerifyHumanKeyData,
  ResponseVerifyHumanKeyData,
} from './models';

const Strings = {
  DEFAULT_ERROR_SIGN_IN: 'Erro ao entrar na aplicação',
};

export const RecaptchaEndpoints: ServiceEndpoints = {
  signIn: {
    path: 'auth/recaptcha',
    errorMessages: {
      default: Strings.DEFAULT_ERROR_SIGN_IN,
    },
  },
};

async function verifyHumanKey(
  params: RequestVerifyHumanKeyData,
): Promise<ResponseVerifyHumanKeyData> {
  const endpoint = RecaptchaEndpoints.signIn;
  const response = await base.auth.post<ResponseVerifyHumanKeyData>(
    endpoint.path,
    params,
  );
  return response.data;
}

export const RecaptchaService = {
  verifyHumanKey,
};
