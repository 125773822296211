import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { IconArrowLeft, IconArrowRight } from 'assets/icons';
import { binaryOptions, loremOptions, schoolRegionOptions } from 'assets/json';
import { Button, CheckBox, Input, Select } from 'components';
import { Option } from 'components/Select';
import { BodySemiBold } from 'components/StyledTypography/styles';
import { NewEnrollmentEducationalDataFormData } from 'models/newEnrollment';
import {
  ChildrenFormContainer,
  ChildrenFormRow,
  GrayLine,
  StepsButtonContainer,
} from 'pages/StudentEnrollment/styles';
import { RootState } from 'store';
import { NewEnrollmentCreators } from 'store/ducks/newEnrollment';
import { SchoolFiltersCreators } from 'store/ducks/schoolFilters';
import { GeneralStudentCreators } from 'store/ducks/students/general';
import {
  checkFormikErrorsAndSetFieldTouched,
  getFormikMessageError,
} from 'utils/formikUtils';
import {
  booleanSelectOptionRequiredSchema,
  booleanSelectOptionSchema,
  gradeRequiredSchema,
  numberSchema,
  schoolSelectOptionRequiredSchema,
  schoolSelectOptionSchema,
  textRequiredSchema,
} from 'utils/validations';

const FORM_ID = 'new-enrollment-educational-data';

interface EducationalDataProps {
  onBack: () => void;
  onNext: () => void;
}

const EducationalData = ({ onNext, onBack }: EducationalDataProps) => {
  const dispatch = useDispatch();
  const { studentFormData, loading: studentFormDataLoading } = useSelector(
    ({ studentGeneralInfo }: RootState) => studentGeneralInfo,
  );
  const { educationDataFormData } = useSelector(
    ({ newEnrollment }: RootState) => newEnrollment,
  );
  const { allSchools, loading } = useSelector(
    ({ schoolFilters }: RootState) => schoolFilters,
  );
  const [networkOption, setNetworkOption] = useState<Option>();
  const isPPL = !!studentFormData?.pplOption?.value;

  const onSubmit = useCallback(
    formData => {
      dispatch(
        NewEnrollmentCreators.setNewEnrollmentEducationDataForm(formData),
      );

      if (
        networkOption &&
        networkOption?.value !== studentFormData?.networkOption?.value
      ) {
        // this dispatch will execute `onNext()` once finished [ML]
        dispatch(
          GeneralStudentCreators.updateGeneralStudent.request({
            ...studentFormData,
            networkOption,
          }),
        );
      } else {
        onNext();
      }
    },
    [dispatch, networkOption, studentFormData, onNext],
  );

  useEffect(() => {
    setNetworkOption(studentFormData?.networkOption);
  }, [studentFormData, setNetworkOption]);

  const isNetworkState = networkOption?.value === schoolRegionOptions[2].value;
  const isNetworkNone = networkOption?.value === schoolRegionOptions[0].value;

  const educationSchema = useMemo(
    () =>
      Yup.object().shape({
        previousSchoolIdOption: isNetworkState
          ? schoolSelectOptionRequiredSchema
          : schoolSelectOptionSchema,
        disableGrade2020Option: booleanSelectOptionSchema,
        portugueseGrade2020: gradeRequiredSchema.when(
          'disableGrade2020Option',
          {
            is: (option: Option) => option.value,
            then: () => numberSchema,
          },
        ),
        mathGrade2020: gradeRequiredSchema.when('disableGrade2020Option', {
          is: (option: Option) => option.value,
          then: () => numberSchema,
        }),
        disableGrade2021Option: booleanSelectOptionSchema,
        portugueseGrade2021: gradeRequiredSchema.when(
          'disableGrade2021Option',
          {
            is: (option: Option) => option.value,
            then: () => numberSchema,
          },
        ),
        mathGrade2021: gradeRequiredSchema.when('disableGrade2021Option', {
          is: (option: Option) => option.value,
          then: () => numberSchema,
        }),
        publicTransportOption: booleanSelectOptionRequiredSchema,
        previousSchool:
          isNetworkState || isNetworkNone ? Yup.string() : textRequiredSchema,
      }),
    [isNetworkState, isNetworkNone],
  );

  const formik = useFormik({
    initialValues: isEmpty(educationDataFormData)
      ? ({
          publicTransportOption: isPPL ? binaryOptions[1] : undefined,
          disableGrade2020Option: binaryOptions[1],
          disableGrade2021Option: binaryOptions[1],
        } as NewEnrollmentEducationalDataFormData)
      : educationDataFormData,
    validationSchema: educationSchema,
    onSubmit,
  });

  useEffect(() => {
    dispatch(SchoolFiltersCreators.getAllSchools.request());
  }, [dispatch]);

  useEffect(() => {
    checkFormikErrorsAndSetFieldTouched(formik);
  }, [formik.isSubmitting]);

  useEffect(() => {
    if (
      formik.values.disableGrade2020Option !== undefined &&
      formik.values.disableGrade2020Option.value &&
      (formik.values.portugueseGrade2020 !== undefined ||
        formik.values.mathGrade2020 !== undefined)
    ) {
      formik.setFieldValue('portugueseGrade2020', undefined);
      formik.setFieldValue('mathGrade2020', undefined);
    }
  }, [formik, formik.values.disableGrade2020Option]);

  useEffect(() => {
    if (
      formik.values.disableGrade2021Option !== undefined &&
      formik.values.disableGrade2021Option.value &&
      (formik.values.portugueseGrade2021 !== undefined ||
        formik.values.mathGrade2021 !== undefined)
    ) {
      formik.setFieldValue('portugueseGrade2021', undefined);
      formik.setFieldValue('mathGrade2021', undefined);
    }
  }, [formik, formik.values.disableGrade2021Option]);

  useEffect(() => {
    if (
      formik.values.publicTransportOption !== undefined &&
      formik.values.publicTransportOption.value &&
      isPPL
    ) {
      formik.setFieldValue('publicTransportOption', binaryOptions[1]);
    }
  }, [formik, formik.values.publicTransportOption, isPPL]);

  return (
    <ChildrenFormContainer id={FORM_ID}>
      <ChildrenFormRow>
        <Select
          label="Estudantes matriculados na rede (2022 ou anos anteriores)"
          options={schoolRegionOptions}
          required
          value={networkOption}
          onChange={(option, _) => {
            if (option === schoolRegionOptions[0]) {
              // schoolRegionOptions === NONE
              formik.setFieldValue('previousSchoolIdOption', undefined);
              formik.setFieldValue('previousSchool', undefined);
            } else if (option === schoolRegionOptions[2]) {
              // schoolRegionOptions === STATE
              formik.setFieldValue('previousSchool', undefined);
            } else {
              formik.setFieldValue('previousSchoolIdOption', undefined);
            }
            setNetworkOption(option);
          }}
        />
      </ChildrenFormRow>
      {!isNetworkNone && (
        <ChildrenFormRow>
          {isNetworkState ? (
            <Select
              label="Escola de Origem"
              options={allSchools}
              required
              value={formik.values.previousSchoolIdOption}
              onChange={(option, _) =>
                formik.setFieldValue('previousSchoolIdOption', option)
              }
              messageError={getFormikMessageError(
                formik,
                'previousSchoolIdOption',
              )}
            />
          ) : (
            <Input
              label="Escola de Origem"
              placeholder="Digite a escola de origem"
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={
                formik.values.previousSchool ? formik.values.previousSchool : ''
              }
              name="previousSchool"
              messageError={getFormikMessageError(formik, 'previousSchool')}
            />
          )}
        </ChildrenFormRow>
      )}

      <GrayLine />

      <BodySemiBold>Média Ano Letivo 2021</BodySemiBold>
      <ChildrenFormRow>
        <CheckBox
          label="Não se aplica"
          name="disableGrade2020Option"
          checked={!!formik.values.disableGrade2020Option?.value}
          onChange={evt =>
            formik.setFieldValue(
              'disableGrade2020Option',
              binaryOptions[evt.target.checked ? 0 : 1],
            )
          }
        />
      </ChildrenFormRow>
      <ChildrenFormRow>
        <Input
          label="Língua Portuguesa"
          placeholder="Digite a média"
          required
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={
            formik.values.portugueseGrade2020
              ? formik.values.portugueseGrade2020
              : ''
          }
          name="portugueseGrade2020"
          messageError={getFormikMessageError(formik, 'portugueseGrade2020')}
          disabled={!!formik.values.disableGrade2020Option?.value}
        />
        <Input
          label="Matemática"
          placeholder="Digite a média"
          required
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.mathGrade2020 ? formik.values.mathGrade2020 : ''}
          name="mathGrade2020"
          messageError={getFormikMessageError(formik, 'mathGrade2020')}
          disabled={!!formik.values.disableGrade2020Option?.value}
        />
      </ChildrenFormRow>

      <GrayLine />

      <BodySemiBold>Média Ano Letivo 2022</BodySemiBold>
      <ChildrenFormRow>
        <CheckBox
          label="Não se aplica"
          name="disableGrade2021Option"
          checked={!!formik.values.disableGrade2021Option?.value}
          onChange={evt =>
            formik.setFieldValue(
              'disableGrade2021Option',
              binaryOptions[evt.target.checked ? 0 : 1],
            )
          }
        />
      </ChildrenFormRow>

      <ChildrenFormRow>
        <Input
          label="Língua Portuguesa"
          placeholder="Digite a média"
          required
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={
            formik.values.portugueseGrade2021
              ? formik.values.portugueseGrade2021
              : ''
          }
          name="portugueseGrade2021"
          messageError={getFormikMessageError(formik, 'portugueseGrade2021')}
          disabled={!!formik.values.disableGrade2021Option?.value}
        />
        <Input
          label="Matemática"
          placeholder="Digite a média"
          required
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.mathGrade2021 ? formik.values.mathGrade2021 : ''}
          name="mathGrade2021"
          messageError={getFormikMessageError(formik, 'mathGrade2021')}
          disabled={!!formik.values.disableGrade2021Option?.value}
        />
      </ChildrenFormRow>

      {!isPPL && (
        <>
          <GrayLine />
          <BodySemiBold>Transporte</BodySemiBold>
          <ChildrenFormRow>
            <Select
              label="Possui necessidade de transporte escolar público?"
              options={binaryOptions}
              required
              value={formik.values.publicTransportOption}
              onChange={(option, _) =>
                formik.setFieldValue('publicTransportOption', option)
              }
              messageError={getFormikMessageError(
                formik,
                'publicTransportOption',
              )}
            />
          </ChildrenFormRow>
        </>
      )}

      <StepsButtonContainer>
        <Button
          iconLeft={IconArrowLeft}
          title="Anterior"
          type="button"
          styled="secondary"
          size="mini"
          form={FORM_ID}
          onClick={onBack}
          disabled={loading || studentFormDataLoading}
        />
        <Button
          iconRight={IconArrowRight}
          type="button"
          size="mini"
          title="Próximo"
          form={FORM_ID}
          onClick={formik.submitForm}
          disabled={loading || studentFormDataLoading}
        />
      </StepsButtonContainer>
    </ChildrenFormContainer>
  );
};

export default EducationalData;
