import { isNil, omitBy } from 'lodash';

import {
  binaryOptions,
  publicTransportResponsableOptions,
  transportDetailOptions,
  transportTypeOptions,
} from 'assets/json';
import { Option } from 'components/Select';
import { CURRENT_ENROLLMENT_YEAR } from 'config/constants';
import {
  EnrollmentData,
  EnrollmentFormData,
  SchoolOptionValue,
} from 'models/enrollment';
import { SchoolFilters } from 'store/ducks/schoolFilters';
import { unwrapOptionValue, wrapOptionValue } from 'utils/wrapperUtils';

import {
  CreateEnrollmentRequestData,
  FetchEnrollmentResponseData,
} from './models';

export function mapEnrollmentFormToEnrollmentRequest(
  form: EnrollmentFormData,
): CreateEnrollmentRequestData {
  const schoolOption = unwrapOptionValue(form.school) as SchoolOptionValue;

  return {
    mediation: unwrapOptionValue(form.mediationOption),
    year: CURRENT_ENROLLMENT_YEAR,
    step: unwrapOptionValue(form.stepOption),
    modality: unwrapOptionValue(form.modalityOption),
    educationOption: unwrapOptionValue(form.modalitySpecialOption),
    regime: 'DEPRECATED',
    temporality: unwrapOptionValue(form.temporalityOption),
    shift: unwrapOptionValue(form.shiftOption),
    schoolId: schoolOption && schoolOption.schoolId,
    courseId:
      (form.courseOption && (unwrapOptionValue(form.courseOption) as string)) ??
      null,
    schoolInep: schoolOption && schoolOption.schoolInep,
    publicTransport: unwrapOptionValue(form.publicTransport),
    publicTransportResponsable: unwrapOptionValue(
      form.publicTransportResponsable,
    ),
    transporteType: unwrapOptionValue(form.transportType),
    transporteTypeDetail: unwrapOptionValue(form.transportTypeDetail),
    siblingsToEnroll: unwrapOptionValue(form.siblingsToEnroll),
    siblings: form.siblings,
  };
}

export function mapEnrollmentResponseToEnrollmentForm(
  requestData: FetchEnrollmentResponseData,
  options: SchoolFilters,
  school: Option,
  courseOption?: Option,
): Partial<EnrollmentData> {
  return omitBy(
    {
      year: requestData.year,
      mediationOption: wrapOptionValue(
        options.tipoMediacao,
        requestData.mediation,
      ),
      stepOption: wrapOptionValue(options.etapa, requestData.step),
      modalityOption: wrapOptionValue(options.modalidade, requestData.modality),
      educationOptionOption: wrapOptionValue(
        options.modalidadeExtra,
        requestData.modalitySpecial,
      ),
      regimeOption: wrapOptionValue(options.regime, requestData.regime),
      temporalityOption: wrapOptionValue(
        options.temporalidade,
        requestData.temporality,
      ),
      shiftOption: wrapOptionValue(options.turno, requestData.shift),
      school,
      courseOption,
      publicTransport: wrapOptionValue(
        binaryOptions,
        requestData.publicTransport,
      ),
      publicTransportResponsable: wrapOptionValue(
        publicTransportResponsableOptions,
        requestData.publicTransportResponsable,
      ),
      transportType: wrapOptionValue(
        transportTypeOptions,
        requestData.transporteType,
      ),
      transportTypeDetail: wrapOptionValue(
        transportDetailOptions,
        requestData.transporteTypeDetail,
      ),
      siblingsToEnroll: wrapOptionValue(
        binaryOptions,
        requestData.siblingsToEnroll,
      ),
      siblings: requestData.siblings,
    },
    isNil,
  );
}
