import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { IconInf, IconLocation, IconHome, IconHat } from 'assets/icons';
import { MinhaEscola } from 'assets/images';
import { DisplayField } from 'pages/StudentEnrollment/Form/ReviewForm/components';
import { RootState } from 'store';
import { StudentSchoolCreators } from 'store/ducks/students/school';

import HeaderSectionIcon from './HeaderSectionIcon';
import {
  Container,
  Content,
  Title,
  SubTitle,
  SchoolImage,
  HeaderContent,
  ContainerText,
  Box,
  TagTitle,
  TagContainer,
  TagList,
  Tag,
} from './styles';

const textOrEmpty = (text?: string) => text || '-';
const phoneFormatted = (ddd?: string, phone?: string) =>
  ddd && phone ? `(${ddd}) ${phone}` : '-';

const MySchool = () => {
  const dispatch = useDispatch();
  const currentSchool = useSelector(
    ({ studentSchool }: RootState) => studentSchool.school,
  );
  const { educationTypeForm } = useSelector(
    ({ enrollment }: RootState) => enrollment,
  );

  useEffect(() => {
    if (!currentSchool) {
      dispatch(StudentSchoolCreators.getStudentSchool.request());
    }
  }, [dispatch]); // not adding `currentSchool` to avoid infinite recursion [ML]

  return (
    <Container>
      <Content>
        <ContainerText>
          <SchoolImage src={MinhaEscola} alt="" />
          <HeaderContent>
            <Title>Conheça mais sobre a sua escola</Title>
            <SubTitle>
              Nesta página você encontra os principais dados <br /> referentes à
              sua escola.
            </SubTitle>
          </HeaderContent>
        </ContainerText>
        <HeaderSectionIcon icon={IconInf} title="Informações Gerais" />
        <Box>
          <DisplayField
            question="Nome da escola"
            answer={textOrEmpty(currentSchool?.nome)}
          />
          <DisplayField
            question="Telefone de contato"
            answer={phoneFormatted(currentSchool?.ddd, currentSchool?.telefone)}
          />
          <DisplayField
            question="E-mail de contato"
            answer={textOrEmpty(currentSchool?.email)}
          />
          {/* <DisplayField
            question="Nº da gerência regional"
            answer={textOrEmpty(undefined)}
          /> */}
        </Box>
        <HeaderSectionIcon icon={IconLocation} title="Endereço" />
        <Box>
          <DisplayField
            question="CEP"
            answer={textOrEmpty(currentSchool?.addressSEE?.cep)}
          />
          <DisplayField
            question="Rua"
            answer={textOrEmpty(currentSchool?.addressSEE?.rua)}
          />
          <DisplayField
            question="Nº"
            answer={textOrEmpty(currentSchool?.addressSEE?.numero)}
          />
          <DisplayField
            question="Bairro"
            answer={textOrEmpty(currentSchool?.addressSEE?.bairro)}
          />
          <DisplayField
            question="Município"
            answer={textOrEmpty(
              currentSchool?.addressSEE?.districtSEE?.citySEE?.nome,
            )}
          />
          <DisplayField
            question="Distrito"
            answer={textOrEmpty(currentSchool?.addressSEE?.districtSEE?.nome)}
          />
        </Box>
        {/* <HeaderSectionIcon icon={IconHome} title="Infraestrutura" />

        <Box>
          <TagContainer>
            <TagTitle>Espaço Físico</TagTitle>
            <TagList>
              <Tag>Pátio coberto</Tag>
              <Tag>Biblioteca</Tag>
              <Tag>Biblioteca</Tag>
              <Tag>Biblioteca</Tag>
            </TagList>
          </TagContainer>

          <TagContainer>
            <TagTitle>Acessibilidade</TagTitle>
            <TagList>
              <Tag>Piso tátil</Tag>
              <Tag>Sinalização sonora</Tag>
            </TagList>
          </TagContainer>
        </Box> */}
        <HeaderSectionIcon icon={IconHat} title="Ensino" />
        <Box>
          <DisplayField
            question="Modalidade de ensino"
            answer={textOrEmpty(educationTypeForm.modalityOption?.label)}
          />
          <DisplayField
            question="Etapa de ensino"
            answer={textOrEmpty(educationTypeForm.stepOption?.label)}
          />
        </Box>
      </Content>
    </Container>
  );
};

export default MySchool;
