import styled from 'styled-components';

import { BodyRegular, H2 } from 'components/StyledTypography/styles';

export const Container = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 32px;

  min-width: 607px;
  min-height: 533px;
  background-color: ${({ theme }) => theme.palette.white};
  box-shadow: 0px 2px 14px -2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

export const Title = styled(H2)`
  margin-top: 24px;
  color: ${({ theme }) => theme.palette.black};
`;

export const SubTitle = styled(BodyRegular)`
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 56px;
  color: ${({ theme }) => theme.palette.black};
`;
