import { isNil, omitBy } from 'lodash';

import { birthCertificateOptions } from 'assets/json';
import { Option } from 'components/Select';
import { DocumentsStudentData } from 'models/student';
import {
  changeFieldsFromUndefinedToNull,
  changeFieldsEmptyToUndefined,
} from 'utils/objectUtils';
import {
  unwrapDate,
  unwrapOptionValue,
  wrapOptionValue,
} from 'utils/wrapperUtils';

import {
  CreateDocumentStudentRequestData,
  FetchDocumentsStudentResponseData,
} from './models';

export function mapToCreateDocumentStudentRequest(
  data: DocumentsStudentData,
): CreateDocumentStudentRequestData {
  return changeFieldsFromUndefinedToNull(
    changeFieldsEmptyToUndefined({
      birthCertificateType: unwrapOptionValue(
        data.birthCertificateTypeOption || ({} as Option),
      ),
      birthCertificateNumber: data.birthCertificateNumber,
      birthCertificateBook: data.birthCertificateBook,
      birthCertificatePage: data.birthCertificatePage,
      birthCertificateTerm: data.birthCertificateTerm || null,
      rg: data.rg || null,
      rgStateId: data.rgStateId
        ? (unwrapOptionValue(data.rgStateId) as number)
        : null,
      issuer: data.issuer || null,
      birthdate: data.birthdate,
      issueDate: data.issueDate || null,
      cpf: data.cpf || null,
      nis: data.nis || null,
      sus: data.sus,
      covidVaccinationFirstDose: unwrapDate(
        data.covidVaccinationFirstDose || '',
      ),
      covidVaccinationSecondDose: unwrapDate(
        data.covidVaccinationSecondDose || '',
      ),
      covidVaccinationUniqueDose: data.covidVaccinationUniqueDose || false,
      vaccineUnavailableToAgeGroup: data.vaccineUnavailableToAgeGroup || false,
      covidVaccinationRejected: data.covidVaccinationRejected || false,
    }),
  );
}

export function mapToDocumentsStudentDataToDocumentsStudentForm(
  requestData: FetchDocumentsStudentResponseData,
): Partial<DocumentsStudentData> {
  const stateOptions: Option[] = [
    {
      label: requestData.state && requestData.state.name,
      value: requestData.state && requestData.state.id,
    },
  ];

  return omitBy(
    {
      ...requestData,
      birthCertificateTypeOption: wrapOptionValue(
        birthCertificateOptions,
        requestData.birthCertificateType,
      ),
      rgStateId:
        requestData.rgStateId &&
        wrapOptionValue(stateOptions, requestData.rgStateId),
    },
    isNil,
  );
}
