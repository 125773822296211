import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 32px;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  max-width: 583px;
`;

export const ContainerCard = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  margin-top: 16px;
`;
