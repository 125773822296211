import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import styled, { css } from 'styled-components';

import { Caption, LabelRegular } from 'components/StyledTypography/styles';

interface Error {
  error?: boolean;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: '5px',
      marginBottom: '48px',
      position: 'relative',
      width: '100%',
      '& .MuiInput-underline:after': {
        border: 'none',
      },
      '& .MuiInput-underline:before': {
        border: 'none',
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        border: 'none',
      },
      '& .MuiInputBase-input::placeholder': {
        color: '#888888',
      },
      '& .MuiFormControl-root MuiTextField-root': {
        border: 'none',
      },
      '& MuiButtonBase-root MuiIconButton-root': {
        color: '#FFFaaa',
      },
      '& .MuiInputBase-input': {
        font: 'inherit',
        color: 'currentColor',
        width: '100%',
        border: 'none',
        padding: '1px 30px 0px 8px',
        letterSpacing: 'inherit',
        animationDuration: '10ms',
      },
      '& .MuiFormHelperText-root': {
        paddingTop: '8px',
        display: 'none',
      },
    },
  }),
);

export const Container = styled.div<Error>`
  width: 100%;
  height: 38px;
  border: 1px solid red;
  border-color: ${({ error, theme }) =>
    error ? theme.palette.negative : theme.palette.grey1};
  border-radius: 4px;

  :hover {
    border: 1px solid ${props => props.theme.palette.primary};
  }
  ${({ error }) =>
    error &&
    css`
      border-color: ${props => props.theme.palette.negative};
      background-color: ${props => props.theme.palette.errorBackground};

      :hover {
        border-color: ${props => props.theme.palette.negative};
      }
    `}
`;

export const ContainerRoot = styled.div<Error>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  #error {
    color: ${({ theme }) => theme.palette.negative};
  }
`;

export const Label = styled(Caption)`
  color: ${({ theme }) => theme.palette.black};
`;

export const Message = styled(LabelRegular)<Error>`
  color: ${props => props.theme.palette.grey1};
  ${({ error }) =>
    error &&
    css`
      color: ${props => props.theme.palette.negative};
    `}
`;
