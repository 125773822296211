import styled from 'styled-components';

import { Caption } from 'components/StyledTypography/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;

  span {
    margin: 4px 8px;
  }

  & + div {
    margin-top: 24px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AttachmentContainer = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: none;
  }
`;

export const CaptionLabel = styled(Caption)`
  word-break: break-all;
`;
