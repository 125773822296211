import { useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { DoingIcon, DoneIcon, ToDoIcon } from 'assets/icons';
import { RecaptchaProvider } from 'hooks/ReCaptchaContext';
import { RootState } from 'store';
import { StepNavigationCreators } from 'store/ducks/stepNavigation';

import {
  AttachmentsForm,
  GeneralInfoForm,
  ResidentialAreaForm,
  ResponsibleForm,
  StudentDocumentsForm,
  ReviewForm,
} from './Form';
import {
  Container,
  Content,
  CurrentStepLabel,
  FormContainer,
  FormTitle,
  FormSubtitle,
  SideStepsContainer,
  StepContainer,
  StepIcon,
  StepLabel,
  StepsTitle,
  ProgressContainer,
  ProgressContent,
  ContainerForm,
} from './styles';

const ENROLLMENT_STEPS = [
  'Dados Gerais',
  'Dados dos responsáveis',
  'Documentos do estudante',
  'Área residencial',
  'Anexos',
  'Revisão dos Dados Cadastrados',
];

export enum EnrollmentStep {
  GENERAL_DATA,
  RESPONSIBLE,
  DOCUMENTS,
  RESIDENTIAL,
  ATTACHMENTS,
  REVIEW,
}

interface StepProps {
  current: number;
}

const Step = ({ current }: StepProps) => {
  const renderStepLabel = (step: string, index: number) => {
    if (index < current) {
      return (
        <>
          <StepIcon src={DoneIcon} />
          <StepLabel>{step}</StepLabel>
        </>
      );
    }

    if (current === index) {
      return (
        <>
          <StepIcon src={DoingIcon} />
          <CurrentStepLabel>{step}</CurrentStepLabel>
        </>
      );
    }

    return (
      <>
        <StepIcon src={ToDoIcon} />
        <StepLabel>{step}</StepLabel>
      </>
    );
  };

  return (
    <>
      {ENROLLMENT_STEPS.map((step, index) => (
        <StepContainer>{renderStepLabel(step, index)}</StepContainer>
      ))}
    </>
  );
};

const StudentEnrollment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [currentStepIndex, setCurrentStepIndex] = useState(
    state?.currentStep ?? EnrollmentStep.GENERAL_DATA,
  );
  const { isCompleted } = useSelector(
    ({ stepNavigation }: RootState) => stepNavigation,
  );

  const onNextFormButtonTap = useCallback(() => {
    dispatch(StepNavigationCreators.isCompleted(false));

    setCurrentStepIndex(
      Math.min(ENROLLMENT_STEPS.length - 1, currentStepIndex + 1),
    );

    if (currentStepIndex === ENROLLMENT_STEPS.length - 1) {
      navigate('/home/studentEnrollmentConfirmation');
    }
  }, [currentStepIndex, navigate, dispatch]);

  const onBackFormButtonTap = useCallback(() => {
    setCurrentStepIndex(Math.max(0, currentStepIndex - 1));
  }, [currentStepIndex]);

  const onGoToStep = useCallback((step: EnrollmentStep) => {
    setCurrentStepIndex(step);
  }, []);

  // Dispara quando a request de um form é finalizada
  // NOTA: Seu saga deve setar o isCompleted pra `true`
  // [MA]
  useEffect(() => {
    if (isCompleted) {
      onNextFormButtonTap();
    }
  }, [isCompleted, onNextFormButtonTap]);

  const CurrentForm = useMemo(() => {
    switch (currentStepIndex) {
      case EnrollmentStep.GENERAL_DATA:
        return (
          <GeneralInfoForm
            onBack={() => false}
            onNext={onNextFormButtonTap}
            editing={false}
          />
        );
      case EnrollmentStep.RESPONSIBLE:
        return (
          <ResponsibleForm
            onBack={onBackFormButtonTap}
            onNext={onNextFormButtonTap}
            editing={false}
          />
        );
      case EnrollmentStep.DOCUMENTS:
        return (
          <StudentDocumentsForm
            onBack={onBackFormButtonTap}
            onNext={onNextFormButtonTap}
            editing={false}
          />
        );
      case EnrollmentStep.RESIDENTIAL:
        return (
          <ResidentialAreaForm
            onBack={onBackFormButtonTap}
            onNext={onNextFormButtonTap}
            editing={false}
          />
        );
      case EnrollmentStep.ATTACHMENTS:
        return (
          <AttachmentsForm
            onBack={onBackFormButtonTap}
            onNext={onNextFormButtonTap}
            editing={false}
          />
        );
      case EnrollmentStep.REVIEW:
        return (
          <ReviewForm
            onBack={onBackFormButtonTap}
            onNext={onNextFormButtonTap}
            onGoToStep={onGoToStep}
            description="Confira se os dados foram preenchidos corretamente"
            editable={state?.reviewEditable ? 'redirect' : 'none'}
          />
        );
      default:
        return null;
    }
  }, [
    currentStepIndex,
    onNextFormButtonTap,
    onBackFormButtonTap,
    onGoToStep,
    state?.reviewEditable,
  ]);

  return (
    <RecaptchaProvider>
      <Container>
        <ProgressContainer>
          <ProgressContent
            progress={(currentStepIndex * 100) / ENROLLMENT_STEPS.length}
          />
        </ProgressContainer>
        <ContainerForm>
          <Content>
            <SideStepsContainer>
              <StepsTitle>Etapas</StepsTitle>
              <Step current={currentStepIndex} />
            </SideStepsContainer>
            <FormContainer>
              <FormTitle>{ENROLLMENT_STEPS[currentStepIndex]}</FormTitle>
              <FormSubtitle>Os campos com * são obrigatórios</FormSubtitle>
              {CurrentForm}
            </FormContainer>
          </Content>
        </ContainerForm>
      </Container>
    </RecaptchaProvider>
  );
};

export default StudentEnrollment;
