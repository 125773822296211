import styled from 'styled-components';

import { BodySemiBold, Caption } from 'components/StyledTypography/styles';

interface TextProps {
  isActived: boolean;
}

type DotProps = TextProps;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const DotContainer = styled(Column)`
  justify-content: flex-start;
  align-items: center;
  background-color: red;
  width: 0;
`;

export const DateContainer = styled(Column)`
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: none;
  }
  margin-top: -6px;
`;

export const DescriptionContainer = styled(Column)`
  margin: -6px 0px 40px 18px;
  width: 100%;
`;

export const TextDate = styled(Caption)<TextProps>`
  width: 100px;
  color: ${({ isActived }) => (isActived ? '#000' : '#888')};
  margin-top: 3px;
`;

export const TextDateHidden = styled(TextDate)`
  visibility: hidden;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    visibility: visible;
  }
`;

export const Title = styled(BodySemiBold)<TextProps>`
  color: ${({ isActived }) => (isActived ? '#000' : '#888')};
`;

export const Description = styled(Caption)<TextProps>`
  margin: 16px 0;
  color: ${({ isActived }) => (isActived ? '#000' : '#888')};
`;

export const Dot = styled.div<DotProps>`
  height: ${({ isActived }) => (isActived ? 16 : 12)}px;
  width: ${({ isActived }) => (isActived ? 16 : 12)}px;
  border-radius: ${({ isActived }) => (isActived ? 8 : 6)}px;
  background-color: ${({ isActived }) => (isActived ? '#fff' : '#1a4f75')};
  border: 4px solid #1a4f75;
`;

export const Line = styled.div`
  background-color: #1a4f75;
  width: 2px;
  height: 100%;
`;
