import styled from 'styled-components';

import { Caption, H2, LabelRegular } from 'components/StyledTypography/styles';

interface DisabledProps {
  disabled: boolean;
}

export const Container = styled.div<DisabledProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme, disabled }) =>
    disabled ? 'transparent' : theme.palette.white};
  padding: 16px 24px;
  box-sizing: border-box;
  border: 0.5px solid ${({ theme }) => theme.palette.grey2};
  border-radius: 6px;
  width: 100%;
  flex: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 16px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Step = styled(Caption)<DisabledProps>`
  color: ${({ theme, disabled }) =>
    disabled ? theme.palette.grey2 : theme.palette.primary};
`;

export const Title = styled(H2)<DisabledProps>`
  color: ${({ theme, disabled }) =>
    disabled ? theme.palette.grey2 : theme.palette.black};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    margin-top: 10px;
  }
`;

export const Divider = styled.div`
  width: 1px;
  height: 50px;
  border: 1px solid ${({ theme }) => theme.palette.grey3};
  margin: 0px 24px;
`;

export const DisabledText = styled(LabelRegular)`
  color: ${({ theme }) => theme.palette.grey1};
`;

export const ContainerIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: none;
  }
`;

export const Icon = styled.img<DisabledProps>`
  width: 91px;
  height: 91px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;
