const defaultEnv = {
  API_URL: process.env.REACT_APP_API_URL,
  API_KEY: process.env.REACT_APP_API_KEY,
  RECAPTCHA_KEY: process.env.REACT_APP_RECAPTCHA_KEY,
};

let environment = (window as any).$environment;

if (environment.API_URL === '') {
  environment = defaultEnv;
}

export const SERVER_URL = environment.API_URL || '';
export const SERVER_KEY = environment.API_KEY || '';
export const RECAPTCHA_CLIENT_KEY = environment.RECAPTCHA_KEY || '';
