import { GrayLine } from 'pages/StudentEnrollment/styles';

import { Container, Header, IconImage, Text } from './styles';

interface HeaderSectionProps {
  icon: string;
  title: string;
}

const HeaderSectionIcon = ({ icon, title }: HeaderSectionProps) => (
  <Container>
    <GrayLine />
    <Header>
      <IconImage src={icon} />
      <Text>{title}</Text>
    </Header>
  </Container>
);

export default HeaderSectionIcon;
