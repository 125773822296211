/* eslint-disable jsx-a11y/label-has-associated-control */
import { useCallback } from 'react';

import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { Button, Input } from 'components';
import { AuthCreators } from 'store/ducks/auth';
import { emailSchema, textRequiredSchema } from 'utils/validations';

import {
  Container,
  Content,
  Title,
  RecoveryPassword,
  Form,
  ContainerButtons,
  ForgotPassword,
  Subtitle,
  OrContainer,
  OrLine,
  OrText,
  SignUpButton,
  SignInButton,
} from './styles';

const schema = Yup.object().shape({
  email: emailSchema,
  password: textRequiredSchema,
});

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = useCallback(
    formData => {
      dispatch(AuthCreators.signIn.request(formData));
    },
    [dispatch],
  );

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: schema,
    onSubmit,
  });

  return (
    <Container>
      <Content>
        <Title>Sistema de matrícula</Title>
        <Subtitle>Acessar conta</Subtitle>
        <Form id="authForm" onSubmit={formik.handleSubmit}>
          <Input
            label="E-mail"
            type="email"
            placeholder="Digite seu email"
            id="email"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            onBlur={formik.handleBlur}
            messageError={formik.touched.email ? formik.errors.email : ''}
          />
          <Input
            label="Senha"
            type="password"
            placeholder="Digite sua senha"
            id="password"
            name="password"
            onChange={formik.handleChange}
            value={formik.values.password}
            onBlur={formik.handleBlur}
            messageError={formik.touched.password ? formik.errors.password : ''}
          />
        </Form>
        <RecoveryPassword>
          <ForgotPassword to="/auth/forgotPassword">
            Esqueceu sua senha? <text>Recuperar senha</text>
          </ForgotPassword>
        </RecoveryPassword>
        <ContainerButtons>
          <SignInButton
            type="submit"
            form="authForm"
            title="Acessar"
            disabled={!formik.dirty || !formik.isValid}
          />
          <OrContainer>
            <OrLine />
            <OrText>ou</OrText>
            <OrLine />
          </OrContainer>
          <SignUpButton
            title="Criar conta"
            onClick={() => navigate('/auth/signup')}
            styled="secondary"
          />
        </ContainerButtons>
      </Content>
    </Container>
  );
};

export default Login;
