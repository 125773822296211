import { useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Etapa1, Etapa2, Etapa3 } from 'assets/images';
import Card from 'components/Card';
import { StudentType } from 'models/student';
import { NewEnrollmentStep } from 'pages/NewEnrollment';
import { EnrollmentStep } from 'pages/StudentEnrollment';
import { ReEnrollmentStep } from 'pages/StudentReEnroll';
import { ActionCodeFollowUp } from 'services/api/followUp/models';
import { RootState } from 'store';
import { DropdownHeaderActions } from 'store/ducks/dropdownHeader';
import { EnrollmentCreators } from 'store/ducks/enrollment';
import { FollowUpCreators } from 'store/ducks/followUp';
import { NewEnrollmentCreators } from 'store/ducks/newEnrollment';
import { DocumentsStudentCreators } from 'store/ducks/students/documentsStudent';
import { GeneralStudentCreators } from 'store/ducks/students/general';
import { ResidentialAreaCreators } from 'store/ducks/students/residentialArea';
import { StudentsCreators } from 'store/ducks/students/responsableData';
import { UploadStudentFileCreators } from 'store/ducks/students/UploadFiles';
import { TransferCreators } from 'store/ducks/transfer';

import { Container, Content, ContainerCard } from './styles';

const UserProfile = () => {
  const navigate = useNavigate();

  const { loading: loadingGeneralInfo, studentFormData } = useSelector(
    ({ studentGeneralInfo }: RootState) => studentGeneralInfo,
  );
  const loadingResidentialArea = useSelector(
    ({ residentialArea }: RootState) => residentialArea.loading,
  );
  const loadingResponsableData = useSelector(
    ({ responsableData }: RootState) => responsableData.loading,
  );
  const loadingEnrollment = useSelector(
    ({ enrollment }: RootState) => enrollment.loading,
  );
  const loadingNewEnrollment = useSelector(
    ({ newEnrollment }: RootState) => newEnrollment.loading,
  );
  const loadingDocuments = useSelector(
    ({ documentsStudent }: RootState) => documentsStudent.loading,
  );
  const loadingAttachments = useSelector(
    ({ uploadStudentFile }: RootState) => uploadStudentFile.loading,
  );
  const loadingFollowUp = useSelector(
    ({ followUp }: RootState) => followUp.loading,
  );
  const followUpRecords = useSelector(
    ({ followUp }: RootState) => followUp.followUp,
  );
  const currentAction = useSelector(
    ({ dropdownHeader }: RootState) => dropdownHeader.currentAction,
  );
  const hasStateTransfer = useSelector(
    ({ transfer }: RootState) => transfer.hasStateTransfer,
  );

  const dispatch = useDispatch();

  const isLoading =
    loadingResponsableData ||
    loadingAttachments ||
    loadingResidentialArea ||
    loadingEnrollment ||
    loadingDocuments ||
    loadingGeneralInfo ||
    loadingNewEnrollment ||
    loadingFollowUp;

  useEffect(() => {
    dispatch(GeneralStudentCreators.fetchGeneralStudent.request());
    dispatch(StudentsCreators.fetchResponsableData.request());
    dispatch(DocumentsStudentCreators.fetchDocumentsStudent.request());
    dispatch(ResidentialAreaCreators.fetchResidentialArea.request());
    dispatch(EnrollmentCreators.fetchEnrollment.request());
    dispatch(NewEnrollmentCreators.fetchNewEnrollment.request());
    dispatch(UploadStudentFileCreators.fetchUploadStudentFile.request());
    dispatch(FollowUpCreators.getFollowUp.request());
    dispatch(TransferCreators.fetchTransfers.request());
  }, [dispatch]);

  const registrationState = useMemo(() => {
    if (followUpRecords.length === 0) {
      return 'active';
    }

    const lastFollowUp = followUpRecords[followUpRecords.length - 1];

    if (lastFollowUp.action.code === ActionCodeFollowUp.TRANSFER_REQUESTED) {
      return 'closed';
    }

    if (
      lastFollowUp.action.code === ActionCodeFollowUp.WAITING_SCHOOL ||
      lastFollowUp.action.code === ActionCodeFollowUp.WAITING_CLASSES ||
      lastFollowUp.action.code === ActionCodeFollowUp.PRE_ENROLLMENT_ACCEPTED ||
      lastFollowUp.action.code === ActionCodeFollowUp.PRE_ENROLLMENT_COMPLETED
    ) {
      // return 'closed';
      return 'view'; // to be able to review with no edit
    }

    if (
      lastFollowUp.action.code ===
        ActionCodeFollowUp.REQUESTING_CHANGES_WITHOUT_RESERVATION ||
      lastFollowUp.action.code === ActionCodeFollowUp.REQUESTING_CHANGES ||
      lastFollowUp.action.code === ActionCodeFollowUp.REGISTER_IN_EDITION
    ) {
      return 'continue';
    }

    if (
      lastFollowUp.action.code === ActionCodeFollowUp.REGISTER_COMPLETED ||
      lastFollowUp.action.code === ActionCodeFollowUp.PRE_ENROLLMENT_IN_EDITION
    ) {
      return 'update';
    }

    return 'active';
  }, [followUpRecords]);

  const isRegisterOpen = useMemo(() => {
    if (studentFormData.type) {
      return currentAction === DropdownHeaderActions.NEW_ENROLLMENT
        ? studentFormData.type === StudentType.BEGINNER
        : studentFormData.type === StudentType.VETERAN;
    }
    return true;
  }, [studentFormData, currentAction]);

  const isEnrollmentOpen = useMemo(() => {
    return currentAction === DropdownHeaderActions.NEW_ENROLLMENT
      ? studentFormData.type === StudentType.BEGINNER
      : studentFormData.type === StudentType.VETERAN;
  }, [studentFormData, currentAction]);

  const registrationCompleted = useMemo(() => {
    const completed = followUpRecords.find(
      item => item.action.code === ActionCodeFollowUp.REGISTER_COMPLETED,
    );

    return !!completed;
  }, [followUpRecords]);

  const enrollmentState = useMemo(() => {
    if (followUpRecords.length === 0) {
      return 'closed';
    }

    const lastFollowUp = followUpRecords[followUpRecords.length - 1];

    if (lastFollowUp.action.code === ActionCodeFollowUp.TRANSFER_REQUESTED) {
      return 'closed';
    }

    if (
      lastFollowUp.action.code === ActionCodeFollowUp.WAITING_SCHOOL ||
      lastFollowUp.action.code === ActionCodeFollowUp.WAITING_CLASSES ||
      lastFollowUp.action.code === ActionCodeFollowUp.PRE_ENROLLMENT_ACCEPTED ||
      lastFollowUp.action.code ===
        ActionCodeFollowUp.PRE_ENROLLMENT_COMPLETED ||
      lastFollowUp.action.code === ActionCodeFollowUp.REQUESTING_CHANGES
    ) {
      // return 'closed';
      return 'view'; // to be able to review with no edit
    }

    if (lastFollowUp.action.code === ActionCodeFollowUp.REGISTER_IN_EDITION) {
      return 'disabled';
    }

    if (
      lastFollowUp.action.code ===
      ActionCodeFollowUp.REQUESTING_CHANGES_WITHOUT_RESERVATION
    ) {
      return 'update';
    }

    if (lastFollowUp.action.code === ActionCodeFollowUp.REGISTER_COMPLETED) {
      // TODO: Change to active once the enrollment starts [MA]
      return hasStateTransfer || studentFormData.type === StudentType.BEGINNER
        ? 'active'
        : 'view';
    }

    if (
      lastFollowUp.action.code === ActionCodeFollowUp.PRE_ENROLLMENT_IN_EDITION
    ) {
      return 'continue';
    }

    return 'active';
  }, [followUpRecords]);

  const onSecondStepClick = () => {
    const isViewMode = enrollmentState === 'view';

    switch (currentAction) {
      case DropdownHeaderActions.RE_ENROLLMENT:
        if (hasStateTransfer) {
          navigate('/home/studentNewEnrollment', {
            state: {
              currentStep: isViewMode
                ? NewEnrollmentStep.REVIEW
                : NewEnrollmentStep.TEACHING_TYPE,
              reviewEditable: !isViewMode,
            },
          });
        } else {
          navigate('/home/studentReEnroll', {
            state: {
              currentStep: isViewMode
                ? ReEnrollmentStep.REVIEW
                : ReEnrollmentStep.TEACHING_TYPE,
              reviewEditable: !isViewMode,
            },
          });
        }
        break;
      case DropdownHeaderActions.NEW_ENROLLMENT:
        navigate('/home/studentNewEnrollment', {
          state: {
            currentStep: isViewMode
              ? NewEnrollmentStep.REVIEW
              : NewEnrollmentStep.TEACHING_TYPE,
            reviewEditable: !isViewMode,
          },
        });
        break;
      default:
        break;
    }
  };

  return (
    <>
      {/* <DropdownHeader disabled /> */}
      <Container>
        <Content>
          {/* <Banner
            message={`Atenção, você está na tela de matrícula para aluno ${
              isNewEnrollment ? 'NOVATO' : 'VETERANO'
            }. O prazo de renovação da matrícula dos ${
              isNewEnrollment ? 'novatos' : 'veteranos'
            } vai até o dia 09/12/2022`}
            variant="warning"
          /> */}
          <ContainerCard>
            <Card
              icon={Etapa1}
              step="1ª Etapa"
              title="Cadastro escolar"
              state={isRegisterOpen ? registrationState : 'closed'}
              onClick={() =>
                navigate('/home/registerStudent', {
                  state: {
                    currentStep:
                      registrationState === 'view' ||
                      registrationState === 'update'
                        ? EnrollmentStep.REVIEW
                        : EnrollmentStep.GENERAL_DATA,
                    reviewEditable: registrationState !== 'view',
                  },
                })
              }
              isLoading={isLoading}
            />
            <Card
              icon={Etapa2}
              step="2ª Etapa"
              title="Matrícula"
              state={
                // eslint-disable-next-line no-nested-ternary
                registrationCompleted
                  ? isEnrollmentOpen
                    ? enrollmentState
                    : 'closed'
                  : 'disabled'
              }
              onClick={onSecondStepClick}
              isLoading={isLoading}
            />
            <Card
              icon={Etapa3}
              step="3ª Etapa"
              title="Acompanhamento"
              state="view"
              onClick={() => navigate('/home/followUp')}
              isLoading={isLoading || followUpRecords.length === 0}
            />
          </ContainerCard>
        </Content>
      </Container>
    </>
  );
};

export default UserProfile;
