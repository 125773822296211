import React from 'react';

import AdapterDateFns from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ptBR } from 'date-fns/locale';

import { Container, ContainerRoot, Label, useStyles, Message } from './styles';

interface ownProps extends KeyboardDatePickerProps {
  id?: string;
  label?: string;
  placeholder?: string;
  messageError?: string | undefined;
  name?: string;
  value: Date | null;
  defaultValue?: string;
  hint?: string;
  minDate?: Date;
  disabled?: boolean;
  autofocus?: boolean;
  required?: boolean;
  onChange: (
    date: MaterialUiPickersDate,
    value: string | null | undefined,
  ) => void;
}

const InputKeyboardDate: React.FC<ownProps> = ({
  id,
  label,
  value,
  hint = '',
  messageError = '',
  placeholder = 'Selecione uma data',
  required = false,
  ...props
}): JSX.Element => {
  const classes = useStyles();
  const error = !!messageError;
  return (
    <MuiPickersUtilsProvider utils={AdapterDateFns} locale={ptBR}>
      <ContainerRoot error={error}>
        <Label>
          {label}
          {required ? '*' : ''}
        </Label>
        <Container id={id} error={error}>
          <KeyboardDatePicker
            {...props}
            placeholder={placeholder}
            className={classes.root}
            autoOk
            variant="inline"
            margin="dense"
            format="dd/MM/yyyy"
            value={value}
            KeyboardButtonProps={{
              style: { position: 'absolute', right: 0 },
            }}
            invalidDateMessage={false}
            minDate={new Date(1910, 1, 1)}
          />
        </Container>
        {!error ? (
          <Message error={error}>{hint}</Message>
        ) : (
          <Message error={error}>{messageError}</Message>
        )}
      </ContainerRoot>
    </MuiPickersUtilsProvider>
  );
};

export default InputKeyboardDate;
