import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

import { Button } from 'components';

import {
  Title,
  BodyText,
  BodyContainer,
  ButtonsContainer,
  InputContainer,
  InputText,
} from './styles';

interface SaberVerificationModalProps {
  isOpen: boolean;
  onClose: () => void;
  studentName: string;
  onAffirmative: () => void;
  onNegative: () => void;
}

const SaberVerificationModal = ({
  isOpen,
  onClose,
  studentName,
  onAffirmative,
  onNegative,
}: SaberVerificationModalProps) => (
  <>
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Title>Código Válido</Title>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <BodyContainer>
            <BodyText>Esse código pertence a:</BodyText>
            <InputContainer>
              <InputText>{studentName}</InputText>
            </InputContainer>
            <BodyText>
              Você deseja realizar o Cadastro e Matrícula 2023 para esse
              estudante?
            </BodyText>
          </BodyContainer>
        </ModalBody>

        <ModalFooter>
          <ButtonsContainer>
            <Button
              title="Não"
              size="mini"
              styled="secondary"
              onClick={onNegative}
            />
            <Button title="Sim" size="mini" onClick={onAffirmative} />
          </ButtonsContainer>
        </ModalFooter>
      </ModalContent>
    </Modal>
  </>
);

export default SaberVerificationModal;
