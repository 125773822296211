import { ActionCodeFollowUp } from 'services/api/followUp/models';

import StatusFollowUp, { StatusFollowUpType } from '../StatusFollowUp';
import {
  Container,
  TextDate,
  Title,
  Description,
  Dot,
  DateContainer,
  Line,
  DotContainer,
  DescriptionContainer,
  Row,
  TextDateHidden,
} from './styles';

export interface TimelineItemProps {
  date: string;
  title: string;
  description: string;
  code: ActionCodeFollowUp;
  isActived: boolean;
  isLastItem: boolean;
}

function getStatusByCode(
  code: ActionCodeFollowUp,
  isActived: boolean,
): StatusFollowUpType {
  if (!isActived) {
    return code === ActionCodeFollowUp.PRE_ENROLLMENT_ACCEPTED
      ? 'effective'
      : 'completed';
  }

  switch (code) {
    case ActionCodeFollowUp.REGISTER_IN_EDITION:
    case ActionCodeFollowUp.PRE_ENROLLMENT_IN_EDITION:
      return 'in_edition';
    case ActionCodeFollowUp.REGISTER_COMPLETED:
    case ActionCodeFollowUp.PRE_ENROLLMENT_COMPLETED:
      return 'completed';
    case ActionCodeFollowUp.PRE_ENROLLMENT_ACCEPTED:
      return 'effective';
    case ActionCodeFollowUp.REQUESTING_CHANGES:
    case ActionCodeFollowUp.REQUESTING_CHANGES_WITHOUT_RESERVATION:
      return 'request_changes';
    case ActionCodeFollowUp.WAITING_CLASSES:
    case ActionCodeFollowUp.WAITING_SCHOOL:
      return 'in_edition';
    default:
      return 'in_edition';
  }
}

const TimelineItem = ({
  date,
  title,
  description,
  code,
  isActived,
  isLastItem,
}: TimelineItemProps) => {
  return (
    <Container>
      <DateContainer>
        <TextDate isActived={isActived}>{date}</TextDate>
      </DateContainer>
      <DotContainer>
        <Dot isActived={isActived} />
        {!isLastItem && <Line />}
      </DotContainer>
      <DescriptionContainer>
        <Row>
          <Title isActived={isActived}>{title}</Title>
          <TextDateHidden isActived={isActived}>{date}</TextDateHidden>
        </Row>
        <Description isActived={isActived}>{description}</Description>
        <StatusFollowUp status={getStatusByCode(code, isActived)} />
      </DescriptionContainer>
    </Container>
  );
};

export default TimelineItem;
