import base from 'services/api/base';

import { ServiceEndpoints } from '../types';
import {
  TransferRequestData,
  TransferResponseData,
  GetTransferResponseData,
} from './models';

const Strings = {
  DEFAULT_ERROR: 'Erro ao recuperar transferência',
};

export const TransferEndpoints: ServiceEndpoints = {
  index: {
    path: 'transfers',
    errorMessages: {
      401: 'Não autorizado',
      403: 'Sem permissão para acessar',
      404: 'Matrícula não encontrada',
      default: Strings.DEFAULT_ERROR,
    },
  },
};

async function createTransfer(
  body: TransferRequestData,
): Promise<TransferResponseData> {
  const endpoint = TransferEndpoints.index;
  const response = await base.api.post<TransferResponseData>(
    endpoint.path,
    body,
  );

  return response.data;
}

async function fetchTransfers(): Promise<GetTransferResponseData> {
  const endpoint = TransferEndpoints.index;
  const response = await base.api.get<GetTransferResponseData>(endpoint.path, {
    params: {
      disablePagination: true,
    },
  });

  return response.data;
}

export const TransferService = { createTransfer, fetchTransfers };
