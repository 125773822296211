/* eslint-disable no-console */
import { createStore, compose, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { StateType } from 'typesafe-actions';

import { AUTH_PERSIST_REDUCER_KEY } from 'config/keys';

import reducers from './ducks';
import sagas from './sagas';

const persistConfig = {
  key: AUTH_PERSIST_REDUCER_KEY,
  storage,
  whiteList: ['auth'],
};

const middlewares = [];

const sagaMonitor =
  process.env.NODE_ENV === 'development'
    ? console.tron?.createSagaMonitor()
    : null;

const sagaMiddleware = createSagaMiddleware({ sagaMonitor });

middlewares.push(sagaMiddleware);

const composer =
  process.env.NODE_ENV === 'development'
    ? compose(applyMiddleware(...middlewares), console.tron?.createEnhancer())
    : compose(applyMiddleware(...middlewares));

const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(persistedReducer, composer);

const persistor = persistStore(store);

sagaMiddleware.run(sagas);

export type Store = StateType<typeof store>;

export type RootState = ReturnType<typeof reducers>;

export { store, persistor };
