import flatten from 'flat';
import { FormikProps } from 'formik';

export function checkFormikErrorsAndSetFieldTouched<FormData>(
  formik: FormikProps<FormData>,
) {
  const { errors, setFieldTouched } = formik;
  Object.keys(flatten(errors)).forEach(path => {
    setFieldTouched(path, true, false);
  });
}

export function getFormikMessageError<FormData>(
  formik: FormikProps<FormData>,
  keyField: keyof FormData | string,
) {
  const touched = formik.touched[keyField] as boolean;
  const error = formik.errors[keyField] as any;
  const errorLabelOrValue = error?.label ?? error?.value;
  const errorMessage: string = errorLabelOrValue || error;
  return touched ? errorMessage : '';
}
