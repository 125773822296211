import { isNil, omitBy } from 'lodash';

import { binaryOptions } from 'assets/json';
import { Option } from 'components/Select';
import { CURRENT_ENROLLMENT_YEAR } from 'config/constants';
import { SchoolOptionValue } from 'models/enrollment';
import { NewEnrollmentFormData } from 'models/newEnrollment';
import { SchoolFilters } from 'store/ducks/schoolFilters';
import { changeFieldsFromUndefinedToNull } from 'utils/objectUtils';
import { unwrapOptionValue, wrapOptionValue } from 'utils/wrapperUtils';

import {
  CreateNewEnrollmentRequestData,
  EducationFilterOptions,
  FetchNewEnrollmentResponseData,
} from './models';

export function mapNewEnrollmentFormToNewEnrollmentRequest(
  form: NewEnrollmentFormData,
): CreateNewEnrollmentRequestData {
  return changeFieldsFromUndefinedToNull({
    mediation: unwrapOptionValue(form.mediationOption),
    year: CURRENT_ENROLLMENT_YEAR,
    step: unwrapOptionValue(form.stepOption),
    modality: unwrapOptionValue(form.modalityOption),
    modalitySpecial: unwrapOptionValue(form.modalitySpecialOption),
    regime: 'DEPRECATED',
    temporality: unwrapOptionValue(form.temporalityOption),
    shift: unwrapOptionValue(form.shiftOption),
    previousSchoolId: (
      unwrapOptionValue(form.previousSchoolIdOption) as SchoolOptionValue
    )?.schoolId,
    previousSchool: form.previousSchool,
    disableGrade2020: unwrapOptionValue(form.disableGrade2020Option),
    portugueseGrade2020: String(form.portugueseGrade2020),
    mathGrade2020: String(form.mathGrade2020),
    disableGrade2021: unwrapOptionValue(form.disableGrade2021Option),
    portugueseGrade2021: String(form.portugueseGrade2021),
    mathGrade2021: String(form.mathGrade2021),
    publicTransport: unwrapOptionValue(form.publicTransportOption),
    siblingsToEnroll: unwrapOptionValue(form.siblingsToEnrollOption),
    siblings: form.siblings,
    schoolId1: (unwrapOptionValue(form.schoolId1Option) as SchoolOptionValue)
      ?.schoolId,
    schoolId2:
      form.schoolId2Option &&
      (unwrapOptionValue(form.schoolId2Option) as SchoolOptionValue)?.schoolId,
    schoolId3:
      form.schoolId3Option &&
      (unwrapOptionValue(form.schoolId3Option) as SchoolOptionValue)?.schoolId,
    schoolId4:
      form.schoolId4Option &&
      (unwrapOptionValue(form.schoolId4Option) as SchoolOptionValue)?.schoolId,
    schoolId5:
      form.schoolId5Option &&
      (unwrapOptionValue(form.schoolId5Option) as SchoolOptionValue)?.schoolId,
    courseId1:
      form.courseId1Option &&
      (unwrapOptionValue(form.courseId1Option) as string),
    courseId2:
      form.courseId2Option &&
      (unwrapOptionValue(form.courseId2Option) as string),
    courseId3:
      form.courseId3Option &&
      (unwrapOptionValue(form.courseId3Option) as string),
    courseId4:
      form.courseId4Option &&
      (unwrapOptionValue(form.courseId4Option) as string),
    courseId5:
      form.courseId5Option &&
      (unwrapOptionValue(form.courseId5Option) as string),
    cityId1: unwrapOptionValue(form.cityId1Option),
    cityId2:
      form.cityId2Option && (unwrapOptionValue(form.cityId2Option) as string),
    cityId3:
      form.cityId3Option && (unwrapOptionValue(form.cityId3Option) as string),
    cityId4:
      form.cityId4Option && (unwrapOptionValue(form.cityId4Option) as string),
    cityId5:
      form.cityId5Option && (unwrapOptionValue(form.cityId5Option) as string),
    eptOrFic1: unwrapOptionValue(form.eptOrFic1Option) as boolean,
    eptOrFic2:
      (form.eptOrFic2Option &&
        (unwrapOptionValue(form.eptOrFic2Option) as boolean)) ??
      false,
    eptOrFic3:
      (form.eptOrFic3Option &&
        (unwrapOptionValue(form.eptOrFic3Option) as boolean)) ??
      false,
    eptOrFic4:
      (form.eptOrFic4Option &&
        (unwrapOptionValue(form.eptOrFic4Option) as boolean)) ??
      false,
    eptOrFic5:
      (form.eptOrFic5Option &&
        (unwrapOptionValue(form.eptOrFic5Option) as boolean)) ??
      false,
    itineraryId1:
      form.itineraryId1Option &&
      (unwrapOptionValue(form.itineraryId1Option) as string),
    itineraryId2:
      form.itineraryId2Option &&
      (unwrapOptionValue(form.itineraryId2Option) as string),
    itineraryId3:
      form.itineraryId3Option &&
      (unwrapOptionValue(form.itineraryId3Option) as string),
    itineraryId4:
      form.itineraryId4Option &&
      (unwrapOptionValue(form.itineraryId4Option) as string),
    itineraryId5:
      form.itineraryId5Option &&
      (unwrapOptionValue(form.itineraryId5Option) as string),
  });
}

export interface CityOptions {
  cityId1Option: Option;
  cityId2Option?: Option;
  cityId3Option?: Option;
  cityId4Option?: Option;
  cityId5Option?: Option;
}

export interface SchoolOptions {
  schoolId1Option: Option;
  schoolId2Option?: Option;
  schoolId3Option?: Option;
  schoolId4Option?: Option;
  schoolId5Option?: Option;
}

export interface CourseIdOptions {
  courseId1Option?: Option;
  courseId2Option?: Option;
  courseId3Option?: Option;
  courseId4Option?: Option;
  courseId5Option?: Option;
}

export interface ItineraryIdOptions {
  itineraryId1Option?: Option;
  itineraryId2Option?: Option;
  itineraryId3Option?: Option;
  itineraryId4Option?: Option;
  itineraryId5Option?: Option;
}

export function mapNewEnrollmentResponseToNewEnrollmentFormData(
  responseData: FetchNewEnrollmentResponseData,
  options: EducationFilterOptions,
  previousSchoolIdOption: Option | undefined,
  schoolOptions: SchoolOptions,
  courseIdOptions: CourseIdOptions,
  cityOptions: CityOptions,
  itineraryOptions: ItineraryIdOptions,
): Partial<NewEnrollmentFormData> {
  return omitBy<Partial<NewEnrollmentFormData>>(
    {
      mediationOption: wrapOptionValue(
        options.mediation,
        responseData.mediation,
      ),
      stepOption: wrapOptionValue(options.step, responseData.step),
      modalityOption: wrapOptionValue(options.modality, responseData.modality),
      modalitySpecialOption: wrapOptionValue(
        options.educationOption,
        responseData.modalitySpecial,
      ),
      regimeOption: undefined,
      temporalityOption: wrapOptionValue(
        options.temporality,
        responseData.temporality,
      ),
      shiftOption: wrapOptionValue(options.shift, responseData.shift),
      disableGrade2020Option: wrapOptionValue(
        binaryOptions,
        responseData.disableGrade2020,
      ),
      portugueseGrade2020: responseData.portugueseGrade2020
        ? Number(responseData.portugueseGrade2020)
        : undefined,
      mathGrade2020: responseData.mathGrade2020
        ? Number(responseData.mathGrade2020)
        : undefined,
      disableGrade2021Option: wrapOptionValue(
        binaryOptions,
        responseData.disableGrade2021,
      ),
      portugueseGrade2021: responseData.portugueseGrade2021
        ? Number(responseData.portugueseGrade2021)
        : undefined,
      mathGrade2021: responseData.mathGrade2021
        ? Number(responseData.mathGrade2021)
        : undefined,
      ...schoolOptions,
      ...courseIdOptions,
      ...cityOptions,
      ...itineraryOptions,
      eptOrFic1Option: wrapOptionValue(binaryOptions, responseData.eptOrFic1),
      eptOrFic2Option: wrapOptionValue(binaryOptions, responseData.eptOrFic2),
      eptOrFic3Option: wrapOptionValue(binaryOptions, responseData.eptOrFic3),
      eptOrFic4Option: wrapOptionValue(binaryOptions, responseData.eptOrFic4),
      eptOrFic5Option: wrapOptionValue(binaryOptions, responseData.eptOrFic5),
      publicTransportOption: wrapOptionValue(
        binaryOptions,
        responseData.publicTransport,
      ),
      siblingsToEnrollOption: wrapOptionValue(
        binaryOptions,
        responseData.siblingsToEnroll,
      ),
      siblings: responseData.siblings,
      previousSchoolIdOption,
      previousSchool: responseData.previousSchool,
    },
    isNil,
  );
}
