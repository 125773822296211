import styled from 'styled-components';

import { LabelRegular } from 'components/StyledTypography/styles';

import { StatusFollowUpType, StatusFolloUpProps } from '.';

type TitleProps = StatusFolloUpProps;
type ContainerProps = StatusFolloUpProps;

interface TitleStyles {
  backgroundColor: string;
  textColor: string;
}

function getStylesByStatus(status: StatusFollowUpType): TitleStyles {
  switch (status) {
    case 'request_changes':
      return { backgroundColor: '#FDDBDB', textColor: '#DB4040' };
    case 'completed':
      return { backgroundColor: '#DEF0CF', textColor: '#679D5E' };
    case 'in_edition':
      return { backgroundColor: '#FEEBCB', textColor: '#DD6B20' };
    case 'effective':
      return { backgroundColor: '#E2F4FE', textColor: '#007BD1' };
    default:
      return { backgroundColor: '#fff', textColor: '#fff' };
  }
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  gap: 4px;
  padding: 6px 12px;
  border-radius: 4px;
  background-color: ${({ status }) =>
    getStylesByStatus(status).backgroundColor};
  width: fit-content;
  align-items: center;
`;

export const Title = styled(LabelRegular)<TitleProps>`
  font-weight: 500;
  line-height: 24px;
  color: ${({ status }) => getStylesByStatus(status).textColor};
`;
