import { IconCalendar } from 'assets/icons';

import { Container, Table, Title, Head, Icon, ColorDot } from './styles';

interface AttendanceProps {
  mes: string;
  presente: string;
  ausente: string;
  ausenteJustificado: string;
  naoRegistrado: string;
  frequenciaTotal: string;
  disabled?: boolean;
}

const AttendanceTable = ({
  mes,
  presente,
  ausente,
  ausenteJustificado,
  naoRegistrado,
  frequenciaTotal,
  disabled = false,
}: AttendanceProps) => {
  return (
    <Container>
      <Head>
        <Icon disabled={disabled} src={IconCalendar} />
        <Title disabled={disabled}>{mes}</Title>
      </Head>
      <Table disabled={disabled}>
        <tbody>
          <tr>
            <td>
              <ColorDot disabled={disabled} color="#B4CD6B" />
              Presente
            </td>
            <td align="right">{presente}</td>
          </tr>
          <tr>
            <td>
              <ColorDot disabled={disabled} color="#DB442F" />
              Ausente
            </td>
            <td align="right">{ausente}</td>
          </tr>
          <tr>
            <td>
              <ColorDot disabled={disabled} color="#F4B366" />
              Ausente justificado
            </td>
            <td align="right">{ausenteJustificado}</td>
          </tr>
          <tr>
            <td>
              <ColorDot disabled={disabled} color="#D9D9D9" />
              Não registrado
            </td>
            <td align="right">{naoRegistrado}</td>
          </tr>
          <tr>
            <td>
              <ColorDot disabled={disabled} color="0" />
              Frequência total
            </td>
            <td align="right">
              <b>{frequenciaTotal}</b>
            </td>
          </tr>
        </tbody>
      </Table>
    </Container>
  );
};

export default AttendanceTable;
