import React from 'react';

import ReCAPTCHA from 'react-google-recaptcha';

import { RECAPTCHA_CLIENT_KEY } from 'config/envs';

interface ReCaptchaProps {
  onChange: ((token: string | null) => void) | undefined;
}

const ReCaptcha = React.forwardRef<ReCAPTCHA, ReCaptchaProps>(
  ({ onChange }, ref) => (
    <ReCAPTCHA ref={ref} sitekey={RECAPTCHA_CLIENT_KEY} onChange={onChange} />
  ),
);

export default ReCaptcha;
