/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import styled from 'styled-components';

import { CoverDarkFigures } from 'assets/images';

export const Container = styled.main`
  background: ${({ theme }) => theme.palette.darkBlue2};
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid ${({ theme }) => theme.palette.grey3};
  mix-blend-mode: normal;
  position: relative;
  width: 100%;

  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
`;

export const Content = styled.main`
  flex: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  position: relative;
  max-width: ${({ theme }) => theme.breakpoints.xl}px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 71px 32px 48px 32px;
  }
`;

export const WelcomeArea = styled.div`
  flex: 0.27;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* margin-left: 80px; */
  /* margin-top: 71px; */
  gap: 41px;
  // background-color: purple;
  //margin: 0 auto

  h1 {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    white-space: nowrap;

    color: ${({ theme }) => theme.palette.white};
  }

  span {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 164%;
    /* or 30px */

    color: ${({ theme }) => theme.palette.white};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    flex: 0.5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 16px;

    h1 {
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 700;
      font-size: 31px;
      line-height: 39px;
      /* identical to box height */
      color: ${({ theme }) => theme.palette.white};
    }

    span {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 30px;
      /* or 30px */

      text-align: center;
      color: ${({ theme }) => theme.palette.white};
    }
  }
`;

export const CoverGirlDiv = styled.div`
  flex: 0.73;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    flex: 0.5;
    display: none;
  }
`;

export const CoverFiguresImage = styled.img`
  max-height: 647.49px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    position: relative;
  }
`;

export const CoverGirlImage = styled.img`
  height: 392px;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    display: none;
  }
`;

export const LogoPBGovImage = styled.img`
  height: 55px;
  margin-bottom: 34px;
  margin-right: 35px;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    display: none;
  }
`;

export const Header = styled.div`
  flex: 1;
  display: flex;
  position: absolute;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  max-width: ${({ theme }) => theme.breakpoints.xl}px;
  margin: 0 auto;
  width: 100%;
  padding: 16px;
  margin-bottom: 72px;

  img {
    height: 51px;
  }
`;

export const EnrollmentButton = styled.button`
  background: #f7d01b;
  box-shadow: 0px 7px 25px #1a3c55;
  border-radius: 100px;
  border: none;
  padding: 20px 28px;
  width: fit-content;
  z-index: 1;
  transition: filter 0.2s;
  position: relative;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: center;
  /* margin-bottom: 71px; */

  &:hover {
    filter: brightness(0.9);
  }

  span {
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 100%;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.darkBlue};
    white-space: nowrap;
  }

  img {
    margin-left: 10px;
  }
`;

export const Line = styled.div`
  background: ${({ theme }) => theme.palette.white};
  border: 4px solid rgba(43, 134, 199, 0.4);
  width: 100%;
  margin-top: 50px;
  z-index: -1;
  transform: matrix(0.82, -1.66, 0.24, 0.73, 0, 0);
`;

export const EnrollmentImage = styled.img`
  max-height: 270px;
`;

export const GirlImage = styled.img`
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    display: none;
  }
`;

export const ImageEnrollment = styled.img``;

export const ContainerBackground = styled.div`
  img {
    position: absolute;
    top: 0;
    right: 0;
    width: 60%;
    height: 100%;

    @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
      width: 100%;
      object-fit: cover;
    }
  }
`;

export const ContainerLogoGov = styled.div`
  position: absolute;
  bottom: 16;
  right: 0;
`;
