import styled from 'styled-components';

export const Container = styled.div`
  background: white;
  box-shadow: 0px 2px 14px -2px rgba(0, 0, 0, 0.15);
  padding: 16px;
  width: 100%;
  min-height: 400px;
  border-radius: 8px;
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  border: 0;

  thead tr {
    border-bottom: 10px solid white;
  }

  td,
  th {
    text-align: left;
    padding: 8px;
  }

  tbody {
    margin-top: 32px;
    tr:nth-child(odd) {
      background-color: ${({ theme }) => theme.palette.grey4};
    }
  }
`;
