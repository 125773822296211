import { Option } from 'components/Select';
import { SchoolFilters } from 'store/ducks/schoolFilters';

import {
  FetchSchoolsResponse,
  SchoolFilterContent,
  SchoolFiltersResponse,
  FetchSchoolResponse,
  FetchSchoolCoursesResponse,
  Course,
  Itinerary,
  FetchSchoolItineraiesResponse,
} from './models';

export function mapSchoolFilterContentArrayToOptionArray(
  filterContentArray: SchoolFilterContent[],
): Option[] {
  return filterContentArray.map(v => ({
    value: v.enumId,
    label: v.description,
  }));
}

export function mapSchoolFiltersResponseToSchoolFilters(
  response: SchoolFiltersResponse,
): SchoolFilters {
  return {
    gerencialRegionalEnsino: mapSchoolFilterContentArrayToOptionArray(
      response.gerencialRegionalEnsino,
    ),
    situacaoFuncionamento: mapSchoolFilterContentArrayToOptionArray(
      response.situacaoFuncionamento,
    ),
    autorizacaoFuncionamento: mapSchoolFilterContentArrayToOptionArray(
      response.autorizacaoFuncionamento,
    ),
    zonaEscolar: mapSchoolFilterContentArrayToOptionArray(response.zonaEscolar),
    localizacaoDiferenciada: mapSchoolFilterContentArrayToOptionArray(
      response.localizacaoDiferenciada,
    ),
    localFuncionamento: mapSchoolFilterContentArrayToOptionArray(
      response.localFuncionamento,
    ),
    tipoSalaUtilizada: mapSchoolFilterContentArrayToOptionArray(
      response.tipoSalaUtilizada,
    ),
    tipoMediacao: mapSchoolFilterContentArrayToOptionArray(
      response.tipoMediacao,
    ),
    modalidade: mapSchoolFilterContentArrayToOptionArray(response.modalidade),
    modalidadeExtra: mapSchoolFilterContentArrayToOptionArray(
      response.modalidadeExtra,
    ),
    etapa: mapSchoolFilterContentArrayToOptionArray(response.etapa),
    regime: mapSchoolFilterContentArrayToOptionArray(response.regime),
    turno: mapSchoolFilterContentArrayToOptionArray(response.turno),
    temporalidade: mapSchoolFilterContentArrayToOptionArray(
      response.temporalidade,
    ),
    temporalidadeEpt: mapSchoolFilterContentArrayToOptionArray(
      response.temporalidadeEpt,
    ),
    temporalidadeEptFic: mapSchoolFilterContentArrayToOptionArray(
      response.temporalidadeEptFic,
    ),
    ofertaEpt: mapSchoolFilterContentArrayToOptionArray(response.ofertaEpt),
    etapaEpt: mapSchoolFilterContentArrayToOptionArray(response.etapaEpt),
    tipoAtendimento: mapSchoolFilterContentArrayToOptionArray(
      response.tipoAtendimento,
    ),
    oferta: mapSchoolFilterContentArrayToOptionArray(response.oferta),
    turma: mapSchoolFilterContentArrayToOptionArray(response.turma),
    statusEscola: mapSchoolFilterContentArrayToOptionArray(
      response.statusEscola,
    ),
    localFuncionamentoDiferenciado: mapSchoolFilterContentArrayToOptionArray(
      response.localFuncionamentoDiferenciado,
    ),
  };
}

export function mapSchoolResponseToSchoolOption(
  response: FetchSchoolResponse,
): Option {
  return {
    label: response.nome,
    value: {
      schoolId: response.id,
      schoolInep: response.codigo_inep,
      ept: response.schoolOrganizationEPT?.coursesEPT ?? undefined,
      fic: response.schoolOrganizationEPT?.coursesFIC ?? undefined,
    },
  };
}

export function mapSchoolsResponseToSchoolOption(
  response: FetchSchoolsResponse,
): Option[] {
  const { records } = response;
  return records.map(school => mapSchoolResponseToSchoolOption(school));
}

export function mapSchoolCourseResponseToCourseIdOption(
  response: Course,
): Option {
  return {
    label: response.nome,
    value: response.id,
  };
}

export function mapSchoolItineraryResponseToItineraryIdOption(
  response: Itinerary,
): Option {
  return {
    label: response.itinerario_formativo,
    value: response.id,
  };
}

export function mapSchoolCoursesResponseToCourseIdOptions(
  response: FetchSchoolCoursesResponse,
): Option[] {
  const { records } = response;
  return records.map(course => mapSchoolCourseResponseToCourseIdOption(course));
}

export function mapSchoolItineraiesResponseToItineraryIdOptions(
  response: FetchSchoolItineraiesResponse,
): Option[] {
  const { records } = response;
  return records.map(itenerary =>
    mapSchoolItineraryResponseToItineraryIdOption(itenerary),
  );
}
