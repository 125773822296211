import { isNil, omitBy } from 'lodash';

import { ResponsablesData } from 'models/student';
import {
  changeFieldsFromUndefinedToNull,
  changeFieldsEmptyToUndefined,
} from 'utils/objectUtils';

import {
  CreateResponsableDataRequestData,
  FetchResponsableDataResponseData,
} from './models';

export function mapToCreateResponsableDataRequest(
  data: ResponsablesData,
): CreateResponsableDataRequestData {
  return changeFieldsFromUndefinedToNull(
    changeFieldsEmptyToUndefined({
      responsableBirthdate: data.responsableBirthdate,
      responsableEmail: data.responsableEmail,
      responsablePhone: data.responsablePhone,
      responsableCPF: data.responsableCPF,
      responsableName: data.responsableName,
      responsableOccupation: data.responsableOccupation,
      responsableProfession: data.responsableProfession,
      responsableRelation: data.responsableRelation,
      fatherName: data.fatherName,
      motherName: data.motherName,
      noAffiliation: data.noAffiliation,
    }),
  );
}

export function mapResponsableDataResponseToResponsableDataForm(
  requestData: FetchResponsableDataResponseData,
): Partial<ResponsablesData> {
  return omitBy(
    {
      ...requestData,
    },
    isNil,
  );
}
