export function changeFieldsFromUndefinedToNull<T>(obj: T): T {
  const cloneObjUpdate = { ...obj };

  Object.keys(obj).forEach(key => {
    if (
      (cloneObjUpdate as any)[key] === undefined ||
      (cloneObjUpdate as any)[key] === 'undefined'
    ) {
      (cloneObjUpdate as any)[key] = null;
    }
  });

  return cloneObjUpdate;
}

export function changeFieldsEmptyToUndefined<T>(obj: T): T {
  const cloneObjUpdate = { ...obj };

  Object.keys(obj).forEach(key => {
    if (
      (cloneObjUpdate as any)[key] === '' ||
      (cloneObjUpdate as any)[key] === 0
    ) {
      (cloneObjUpdate as any)[key] = undefined;
    }
  });

  return cloneObjUpdate;
}
