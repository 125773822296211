import { BodySemiBold, Caption } from 'components/StyledTypography/styles';

import { Container } from './styles';

interface DisplayFieldProps {
  question: string;
  answer: string | undefined | number;
}

const DisplayField = ({ question, answer }: DisplayFieldProps) => (
  <Container>
    <Caption>{question}</Caption>
    <BodySemiBold>{answer || 'Não Informado'}</BodySemiBold>
  </Container>
);

export default DisplayField;
