import styled from 'styled-components';

import { BodyRegular, H2 } from 'components/StyledTypography/styles';

export const Container = styled.div`
  display: flex;
  padding: 40px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0px 2px 14px -2px rgba(0, 0, 0, 0.15);
  width: 100%;
  min-height: 588px;
  border-radius: 8px;
  max-width: 786px;
  padding: 32px;
`;
export const ContainerText = styled.div`
  display: flex;
  gap: 40px;
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AttendenceImage = styled.img`
  height: 100px;
  width: 150px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: none;
  }
`;

export const Title = styled(H2)`
  color: ${({ theme }) => theme.palette.black};
`;

export const SubTitle = styled(BodyRegular)`
  color: ${({ theme }) => theme.palette.black};
`;

export const CardsBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 32px;
  align-items: center;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    gap: 24px;
  }
`;
