import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CharacterImg } from 'assets/images';
import { Button } from 'components';
import {
  Container,
  ContainerButtons,
  Content,
  Title,
  SubTitle,
} from 'pages/StudentEnrollmentConfirmation/styles';
import { FollowUpCreators } from 'store/ducks/followUp';

const ReEnrollConfirmation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(FollowUpCreators.getFollowUp.request());
  }, [dispatch]);

  return (
    <Container>
      <Content>
        <img src={CharacterImg} alt="" />
        <Title>Matrícula renovada!</Title>
        <SubTitle>
          Você optou por permanecer nesta escola. Sendo assim, o processo de
          renovação de matrícula será feito automaticamente.
          <br />
          <br />
          As informações sobre a sua nova turma serão exibidas na página de
          acompanhamento após o término do presente ano escolar.
        </SubTitle>
        <ContainerButtons>
          <Button
            size="medium"
            styled="secondary"
            title="Página inicial"
            onClick={() => navigate('/home')}
          />
        </ContainerButtons>
      </Content>
    </Container>
  );
};

export default ReEnrollConfirmation;
