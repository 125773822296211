import { isToday } from 'date-fns';
import { validate as validateCPF } from 'gerador-validador-cpf';
import * as Yup from 'yup';

import { parseDateString } from './dateUtils';

export enum Strings {
  REQUIRED_FIELD = 'Campo obrigatório',
  INVALID_EMAIL = 'E-mail inválido',
  INVALID_CPF_LENGTH = 'O CPF deve conter 11 dígitos',
  INVALID_CPF = 'CPF inválido',
  INVALID_RG = 'RG inválido',
}

export const saberCodeSchema = Yup.string()
  .matches(/^\d+$/, 'Apenas números são permitidos')
  .max(8, 'O código do Saber deve conter no máximo 8 números');

export const saberCodeSchemaRequired = saberCodeSchema.required(
  Strings.REQUIRED_FIELD,
);

export const personNameOptionalSchema = Yup.string()
  .transform(currentValue => currentValue.trim())
  .min(4, 'O nome deve conter no mínimo 4 caracteres')
  .matches(
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
    'O nome deve conter apenas letras',
  );

export const personNameRequiredSchema = personNameOptionalSchema.required(
  Strings.REQUIRED_FIELD,
);

export const arrayRequired = Yup.array().min(1, Strings.REQUIRED_FIELD);

export const emailSchemaOptional = Yup.string().email(Strings.INVALID_EMAIL);

export const emailSchema = emailSchemaOptional.required(Strings.REQUIRED_FIELD);

export const stringSelectOptionRequiredSchema = Yup.object()
  .shape({
    value: Yup.string().required(Strings.REQUIRED_FIELD),
    label: Yup.string().required(Strings.REQUIRED_FIELD),
  })
  .typeError(Strings.REQUIRED_FIELD);

export const schoolSelectOptionRequiredSchema = Yup.object().shape({
  value: Yup.object()
    .shape({
      schoolId: Yup.string(),
      schoolInep: Yup.string(),
    })
    .required(Strings.REQUIRED_FIELD),
  label: Yup.string().required(Strings.REQUIRED_FIELD),
});

export const schoolSelectOptionSchema = Yup.object().shape({
  value: Yup.object().shape({
    schoolId: Yup.string(),
    schoolInep: Yup.string(),
  }),
  label: Yup.string(),
});

export const numberSelectOptionRequiredSchema = Yup.object().shape({
  value: Yup.number().required(Strings.REQUIRED_FIELD),
  label: Yup.string().required(Strings.REQUIRED_FIELD),
});

export const numberSelectOptionSchema = Yup.object().shape({
  value: Yup.number(),
  label: Yup.string(),
});

export const stringSelectOptionSchema = Yup.object().shape({
  value: Yup.string(),
  label: Yup.string(),
});

export const booleanSelectOptionRequiredSchema = Yup.object().shape({
  value: Yup.bool().required(Strings.REQUIRED_FIELD),
  label: Yup.string().required(Strings.REQUIRED_FIELD),
});

export const booleanSelectOptionSchema = Yup.object().shape({
  value: Yup.bool(),
  label: Yup.string(),
});

export const textRequiredSchema = Yup.string().required(Strings.REQUIRED_FIELD);

export const numberRequiredSchema = Yup.number()
  .required(Strings.REQUIRED_FIELD)
  .typeError('Este campo deve conter apenas números');

export const gradeRequiredSchema = Yup.number()
  .required(Strings.REQUIRED_FIELD)
  .typeError('Este campo deve conter apenas números')
  .test(
    'Is positive?',
    'Média não pode ser menor que zero',
    value => (value ?? 0) >= 0,
  )
  .max(10, 'Média não pode ser maior que 10');

export const numberSchema = Yup.number().typeError(
  'Este campo deve conter apenas números',
);

export const studentIdSchema = Yup.number().required(Strings.REQUIRED_FIELD);

export const phoneSchemaOptional = Yup.string()
  .transform(currentValue => currentValue.replace(/\D/g, ''))
  .min(10, 'O telefone deve conter no mínimo 10 dígitos')
  .max(11, 'O telefone deve conter no máximo 11 dígitos');

export const phoneSchema = phoneSchemaOptional.required(Strings.REQUIRED_FIELD);

export const cepSchemaOptional = Yup.string()
  .transform(currentValue => currentValue.replace(/\D/g, ''))
  .min(8, 'O CEP deve conter 8 dígitos')
  .max(8, 'O CEP deve conter 8 dígitos');

export const cepSchema = cepSchemaOptional.required(Strings.REQUIRED_FIELD);

export const dateSchema = Yup.date()
  .test(
    'dateRequiredSchema',
    'A data de hoje não é permitida',
    value => !isToday(parseDateString(value)),
  )
  .min(new Date(1905, 1, 1), 'A data é muito antiga')
  .max(new Date(), 'A data não pode ser maior que hoje')
  .typeError('Data inválida');

export const dateRequiredSchema = dateSchema.required(Strings.REQUIRED_FIELD);

const somaPonderada = (cns: string) => {
  let sum = 0;

  for (let i = 0; i < cns.length; i += 1) {
    const element = cns[i];
    sum += Number(element) * (15 - i);
  }

  return sum;
};

const isCNSValid = (cns: string | undefined) => {
  if (!cns) {
    return false;
  }

  return cns.match(/[1-2]\d{10}00[0-1]\d/gm) || cns.match(/[7-9]\d{14}/gm)
    ? somaPonderada(cns) % 11 === 0
    : false;
};

export const cnsSchema = Yup.string()
  .transform(currentValue => currentValue.replace(/\D/g, ''))
  .max(15, 'Número do Cartão Nacional de Saúde deve ter 15 dígitos')
  .test('cns-validator', 'Número do Cartão Nacional de Saúde inválido', value =>
    value ? isCNSValid(value) : true,
  );

export const nisSchema = Yup.string()
  .transform(currentValue => currentValue.replace(/\D/g, ''))
  .max(11, 'O NIS deve conter 11 dígitos');

export const cpfSchema = Yup.string()
  .transform(currentValue => currentValue.replace(/\D/g, ''))
  .max(14, Strings.INVALID_CPF_LENGTH)
  .test('cpf', Strings.INVALID_CPF, (value: string | undefined) => {
    if (!value || value?.length < 1) {
      return true;
    }

    return validateCPF(value ?? '');
  });

export const cpfRequiredSchema = Yup.string()
  .transform(currentValue => currentValue.replace(/\D/g, ''))
  .min(11, Strings.INVALID_CPF_LENGTH)
  .max(14, Strings.INVALID_CPF_LENGTH)
  .test('cpf', Strings.INVALID_CPF, (value: string | undefined) =>
    validateCPF(value ?? ''),
  )
  .required(Strings.REQUIRED_FIELD);

export const requiredIfFieldExists = (field: string, schema: any) => {
  return field ? schema.required() : schema;
};
