import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { DoingIcon, DoneIcon, ToDoIcon } from 'assets/icons';
import {
  ProgressContainer,
  ProgressContent,
  Content,
  SideStepsContainer,
  StepsTitle,
  FormContainer,
  FormTitle,
  Container,
  StepIcon,
  StepLabel,
  CurrentStepLabel,
  StepContainer,
} from 'pages/StudentEnrollment/styles';
import { RootState } from 'store';
import { SchoolFiltersCreators } from 'store/ducks/schoolFilters';
import { StepNavigationCreators } from 'store/ducks/stepNavigation';

import EducationalData from './Form/EducationalData';
import ReviewForm from './Form/ReviewForm';
import SchoolOffers from './Form/SchoolOffers';
import TeachingType from './Form/TeachingType';

const NEW_ENROLLMENT_STEPS = [
  'Tipo de ensino',
  'Dados educacionais',
  'Oferta de Escolas',
  'Revisão de Dados de Pré matrícula ',
];

export enum NewEnrollmentStep {
  TEACHING_TYPE,
  EDUCATIONAL_DATA,
  SCHOOL_OFFERS,
  REVIEW,
}

interface StepProps {
  current: number;
}

const Step = ({ current }: StepProps) => {
  const renderStepLabel = (step: string, index: number) => {
    if (index < current) {
      return (
        <>
          <StepIcon src={DoneIcon} />
          <StepLabel>{step}</StepLabel>
        </>
      );
    }

    if (current === index) {
      return (
        <>
          <StepIcon src={DoingIcon} />
          <CurrentStepLabel>{step}</CurrentStepLabel>
        </>
      );
    }

    return (
      <>
        <StepIcon src={ToDoIcon} />
        <StepLabel>{step}</StepLabel>
      </>
    );
  };

  return (
    <>
      {NEW_ENROLLMENT_STEPS.map((step, index) => (
        <StepContainer>{renderStepLabel(step, index)}</StepContainer>
      ))}
    </>
  );
};

const NewEnrollment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [currentStepIndex, setCurrentStepIndex] = useState(
    state?.currentStep ?? NewEnrollmentStep.TEACHING_TYPE,
  );
  const { isCompleted } = useSelector(
    ({ stepNavigation }: RootState) => stepNavigation,
  );

  useEffect(() => {
    dispatch(SchoolFiltersCreators.getSchoolFilters.request());
  }, [dispatch]);

  const onNextFormButtonTap = useCallback(() => {
    dispatch(StepNavigationCreators.isCompleted(false));

    setCurrentStepIndex(
      Math.min(NEW_ENROLLMENT_STEPS.length - 1, currentStepIndex + 1),
    );

    if (currentStepIndex === NEW_ENROLLMENT_STEPS.length - 1) {
      navigate('/home/studentNewEnrollmentConfirmation');
    }
  }, [currentStepIndex, navigate, dispatch]);

  // Dispara quando a request de um form é finalizada
  // NOTA: Seu saga deve setar o isCompleted pra `true`
  // [MA]
  useEffect(() => {
    if (isCompleted) {
      onNextFormButtonTap();
    }
  }, [isCompleted, onNextFormButtonTap]);

  const onBackFormButtonTap = useCallback(() => {
    setCurrentStepIndex(Math.max(0, currentStepIndex - 1));
  }, [currentStepIndex]);

  const onGoToStep = useCallback((step: NewEnrollmentStep) => {
    setCurrentStepIndex(step);
  }, []);

  const CurrentForm = useMemo(() => {
    switch (currentStepIndex) {
      case NewEnrollmentStep.TEACHING_TYPE:
        return <TeachingType onNext={onNextFormButtonTap} />;
      case NewEnrollmentStep.EDUCATIONAL_DATA:
        return (
          <EducationalData
            onBack={onBackFormButtonTap}
            onNext={onNextFormButtonTap}
          />
        );
      case NewEnrollmentStep.REVIEW:
        return (
          <ReviewForm
            onBack={onBackFormButtonTap}
            onNext={onNextFormButtonTap}
            onGoToStep={onGoToStep}
            editable={state?.reviewEditable ?? true}
          />
        );
      default:
        return (
          <SchoolOffers
            onBack={onBackFormButtonTap}
            onNext={onNextFormButtonTap}
          />
        );
    }
  }, [currentStepIndex, onNextFormButtonTap, onBackFormButtonTap]);

  return (
    <Container>
      <ProgressContainer>
        <ProgressContent
          progress={(currentStepIndex * 100) / NEW_ENROLLMENT_STEPS.length}
        />
      </ProgressContainer>
      <Content>
        <SideStepsContainer>
          <StepsTitle>Etapas</StepsTitle>
          <Step current={currentStepIndex} />
        </SideStepsContainer>
        <FormContainer>
          <FormTitle>{NEW_ENROLLMENT_STEPS[currentStepIndex]}</FormTitle>
          {CurrentForm}
        </FormContainer>
      </Content>
    </Container>
  );
};

export default NewEnrollment;
