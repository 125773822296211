import { useEffect, useState } from 'react';

import { Radio, RadioGroup } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components';
import { Caption } from 'components/StyledTypography/styles';
import {
  DisplayField,
  HeaderSection,
  ReviewRow,
} from 'pages/StudentEnrollment/Form/ReviewForm/components';
import { StepsButtonContainer } from 'pages/StudentEnrollment/styles';
import { RootState } from 'store';
import { EnrollmentCreators } from 'store/ducks/enrollment';
import { TransferCreators } from 'store/ducks/transfer';
import { unwrapOptionLabel } from 'utils/wrapperUtils';

import {
  Container,
  Separator,
  FormSection,
  LabelForm,
  RadioRow,
} from './styles';

const transferOptions = {
  REENROLL: 'REENROLL',
  TRANSFER: 'TRANSFER',
};

const transferReasonOptions = {
  CHANGE_ADDRESS_OR_JUDICIAL: 'Mudança de endereço ou decisão judicial',
  PERSONAL: 'Preferência pessoal',
};

const transferToOptions = {
  FEDERAL: 'FEDERAL',
  STATE: 'STATE',
  MUNICIPAL: 'MUNICIPAL',
  PRIVATE: 'PRIVATE',
};

interface RenewEnrollmentProps {
  onBack: () => void;
  onNext: () => void;
}

const RenewEnrollment = ({ onBack, onNext }: RenewEnrollmentProps) => {
  const { educationDataForm, isRequestCompleted } = useSelector(
    ({ enrollment }: RootState) => enrollment,
  );

  const { isCompleted } = useSelector(({ transfer }: RootState) => transfer);

  const [transfer, setTransfer] = useState(transferOptions.REENROLL);
  const [transferReason, setTransferReason] = useState('');
  const [transferTo, setTransferTo] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isRequestCompleted && transfer === transferOptions.REENROLL) {
      navigate('/home/reEnrollConfirmation');
    }

    if (isCompleted && transfer === transferOptions.TRANSFER) {
      navigate('/home/transferConfirmation', {
        state: {
          isStateTransfer: transferTo === transferToOptions.STATE,
        },
      });
    }
  }, [isRequestCompleted, isCompleted, navigate, transfer, transferTo]);

  useEffect(() => {
    if (transfer === transferOptions.REENROLL) {
      setTransferReason('');
      setTransferTo('');
    }
  }, [transfer]);

  const submitForm = () => {
    if (transfer === transferOptions.REENROLL) {
      dispatch(EnrollmentCreators.repeatEnrollment.request());
    } else {
      dispatch(
        TransferCreators.createTransfer.request({
          reason: transferReason,
          to: transferTo,
        }),
      );
    }
  };

  return (
    <Container>
      <Caption>
        Consulte o que foi informado no ano anterior e escolha se quer
        permanecer na mesma escola ou solicitar uma transferência.
        <br />
        <br />
        Lembre-se, sua futura série e turma dependem de seu desempenho no atual
        ano escolar.
      </Caption>
      <HeaderSection
        title="Dados Gerais"
        onEditAction={() => false}
        editable={false}
      />
      <>
        <ReviewRow>
          <DisplayField
            question="Escola atual"
            answer={unwrapOptionLabel(educationDataForm.school)}
          />
          {educationDataForm.courseOption && (
            <DisplayField
              question="Curso EPT"
              answer={unwrapOptionLabel(educationDataForm.courseOption)}
            />
          )}
        </ReviewRow>
      </>
      <Separator />
      <FormSection>
        <LabelForm>Como quer prosseguir?*</LabelForm>
        <RadioGroup onChange={setTransfer} value={transfer}>
          <RadioRow>
            <Radio value={transferOptions.REENROLL}>
              Desejo <span style={{ fontWeight: 'bold' }}>permanecer</span> na
              mesma escola
            </Radio>
            <Radio value={transferOptions.TRANSFER}>
              Desejo{' '}
              <span style={{ fontWeight: 'bold' }}>
                solicitar transferência
              </span>{' '}
              para uma nova escola
            </Radio>
          </RadioRow>
        </RadioGroup>
      </FormSection>
      {transfer === transferOptions.TRANSFER && (
        <>
          <Separator />
          <FormSection>
            <LabelForm>Qual o motivo da transferência?*</LabelForm>
            <RadioGroup onChange={setTransferReason} value={transferReason}>
              <RadioRow>
                <Radio value={transferReasonOptions.CHANGE_ADDRESS_OR_JUDICIAL}>
                  Mudança de endereço ou decisão judicial
                </Radio>
                <Radio value={transferReasonOptions.PERSONAL}>
                  Preferência pessoal
                </Radio>
              </RadioRow>
            </RadioGroup>
          </FormSection>
          <Separator />
          <FormSection>
            <LabelForm>
              Para qual rede de ensino será feita a transferência?*
            </LabelForm>
            <RadioGroup onChange={setTransferTo} value={transferTo}>
              <RadioRow>
                <Radio value={transferToOptions.STATE}>
                  Estadual, porém para outra escola
                </Radio>
                <Radio value={transferToOptions.FEDERAL}>Federal</Radio>
                <Radio value={transferToOptions.MUNICIPAL}>Municipal</Radio>
                <Radio value={transferToOptions.PRIVATE}>Privada</Radio>
              </RadioRow>
            </RadioGroup>
          </FormSection>
        </>
      )}
      <StepsButtonContainer>
        <Button
          title="Anterior"
          type="button"
          styled="secondary"
          onClick={onBack}
          size="mini"
        />
        <Button
          type="button"
          size="mini"
          title="Finalizar"
          onClick={submitForm}
          disabled={
            transfer === transferOptions.TRANSFER &&
            (transferReason === '' || transferTo === '')
          }
        />
      </StepsButtonContainer>
    </Container>
  );
};

export default RenewEnrollment;
