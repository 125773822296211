import produce, { Draft } from 'immer';
import { Reducer } from 'redux';
import { ActionType, createAction } from 'typesafe-actions';

export enum Types {
  SET_IS_COMPLETED = '@stepNavigation/SET_IS_COMPLETED',
}

export interface StepNavigationState {
  isCompleted: boolean;
}

const INITIAL_STATE: StepNavigationState = {
  isCompleted: false,
};

export const StepNavigationCreators = {
  isCompleted: createAction(Types.SET_IS_COMPLETED)<boolean>(),
};

export type ActionTypes = ActionType<typeof StepNavigationCreators>;

const reducer: Reducer<StepNavigationState, ActionTypes> = (
  state = INITIAL_STATE,
  action: ActionTypes,
) => {
  const { type, payload } = action;

  return produce(state, (draft: Draft<StepNavigationState>) => {
    switch (type) {
      case Types.SET_IS_COMPLETED: {
        draft.isCompleted = payload;
        break;
      }

      default:
    }
  });
};

export default reducer;
