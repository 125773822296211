import { useSelector } from 'react-redux';

import { ContainerAcomp } from 'components';
import { RootState } from 'store';

import Timeline from './components/Timeline';
import { Container, Content } from './styles';

const FollowUp = () => {
  const records = useSelector(({ followUp }: RootState) => followUp.followUp);

  return (
    <Container>
      <Content>
        <ContainerAcomp>
          <Timeline data={records} />
        </ContainerAcomp>
      </Content>
    </Container>
  );
};

export default FollowUp;
