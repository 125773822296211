import { ChakraProvider } from '@chakra-ui/react';
import { createTheme } from '@material-ui/core';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';

import 'config/reactotron';
import 'react-toastify/dist/ReactToastify.css';
import { DialogProvider } from 'hooks/DialogContext';
import AppRoutes from 'routes';
import { store, persistor } from 'store';
import theme, { palette } from 'styles/theme';

import { GlobalStyle } from './styles/global';

const App = () => (
  <MuiThemeProvider
    theme={createTheme({ palette: { primary: { main: palette.primary } } })}
  >
    <ChakraProvider>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ToastContainer theme="colored" />
            <DialogProvider>
              <GlobalStyle />
              <AppRoutes />
            </DialogProvider>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </ChakraProvider>
  </MuiThemeProvider>
);

export default App;
