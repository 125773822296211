import { useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { IconArrowLeft } from 'assets/icons';
import {
  birthCertificateOptions,
  residentialAreaOptions,
  responsableRelation,
} from 'assets/json';
import { Button, ReCaptcha } from 'components';
import { Option } from 'components/Select';
import { Caption } from 'components/StyledTypography/styles';
import { useRecaptcha } from 'hooks/ReCaptchaContext';
import { StudentType } from 'models/student';
import { EnrollmentStep } from 'pages/StudentEnrollment';
import { StepsButtonContainer } from 'pages/StudentEnrollment/styles';
import { RootState } from 'store';
import { DropdownHeaderActions } from 'store/ducks/dropdownHeader';
import { GeneralStudentCreators } from 'store/ducks/students/general';
import { dateFormatted } from 'utils/dateUtils';
import { unwrapOptionLabel } from 'utils/wrapperUtils';

import AttachmentsForm, { documentTypes } from '../AttachmentsForm';
import GeneralInfoForm from '../GeneralInfoForm';
import ResidentialAreaForm from '../ResidentialAreaForm';
import ResponsibleForm from '../ResponsibleForm';
import StudentDocumentsForm from '../StudentDocumentsForm';
import {
  HeaderSection,
  DisplayField,
  DisplayAttachment,
  ReviewRow,
  SubHeader,
} from './components';
import { Container } from './styles';

// inpalce - render the form inside review form
// redirect - redirect to the page form
// none - edditing is not allowed
type EditMode = 'inplace' | 'redirect' | 'none';

interface ReviewForm {
  onBack: () => void;
  onNext: () => void;
  onGoToStep: (step: EnrollmentStep) => void;
  description: string;
  editable: EditMode;
}

const ReviewForm = ({
  onBack,
  onNext,
  onGoToStep,
  description,
  editable,
}: ReviewForm) => {
  const [isEditingGeneralInfo, setIsEditingGeneralInfo] = useState(false);
  const [isEditingResponsableStudent, setIsEditingResponsableStudent] =
    useState(false);
  const [isEditingDocument, setIsEditingDocument] = useState(false);
  const [isEditingResidentialArea, setIsEditingResidentialArea] =
    useState(false);
  const [isEditingStudentFiles, setIsEditingStudentFiles] = useState(false);

  const { studentFormData } = useSelector(
    ({ studentGeneralInfo }: RootState) => studentGeneralInfo,
  );
  const { responsableStudentData } = useSelector(
    ({ responsableData }: RootState) => responsableData,
  );
  const { data: documentStudentData } = useSelector(
    ({ documentsStudent }: RootState) => documentsStudent,
  );
  const { residentialArea: residentialAreaData } = useSelector(
    ({ residentialArea }: RootState) => residentialArea,
  );
  const { studentsFiles } = useSelector(
    ({ uploadStudentFile }: RootState) => uploadStudentFile,
  );
  const { verifyTokenOnChange, recaptchaRef, recaptchaSucceed } =
    useRecaptcha();

  const isEditable = editable !== 'none';
  const dispatch = useDispatch();

  const isNewBirthCertificate =
    documentStudentData.birthCertificateTypeOption ===
    birthCertificateOptions[0];

  const currentAction = useSelector(
    ({ dropdownHeader }: RootState) => dropdownHeader.currentAction,
  );
  const isNewEnrollment =
    currentAction === DropdownHeaderActions.NEW_ENROLLMENT;

  const onFinishStudentRegister = useCallback(() => {
    dispatch(
      GeneralStudentCreators.finishStudent.request({
        type: isNewEnrollment ? StudentType.BEGINNER : StudentType.VETERAN,
      }),
    );
  }, [dispatch, isNewEnrollment]);

  return (
    <Container>
      <Caption>{description}</Caption>
      <HeaderSection
        title="Dados Gerais"
        onEditAction={() =>
          editable === 'inplace'
            ? setIsEditingGeneralInfo(true)
            : onGoToStep(EnrollmentStep.GENERAL_DATA)
        }
        editable={isEditable && !isEditingGeneralInfo}
      />
      {isEditingGeneralInfo ? (
        <GeneralInfoForm
          onBack={() => setIsEditingGeneralInfo(false)}
          onNext={() => setIsEditingGeneralInfo(false)}
          editing
        />
      ) : (
        <>
          <ReviewRow>
            <DisplayField
              question="Código Estudante - Saber"
              answer={studentFormData.code}
            />
            <DisplayField
              question="Estudante Matriculado na Rede"
              answer={unwrapOptionLabel(studentFormData.networkOption)}
            />
          </ReviewRow>
          <ReviewRow>
            <DisplayField
              question="Nome Civil Completo"
              answer={studentFormData.fullname}
            />
            <DisplayField
              question="Possui Nome Social?"
              answer={unwrapOptionLabel(studentFormData.hasSocialNameOption)}
            />
          </ReviewRow>
          <ReviewRow>
            <DisplayField
              question="Nome Social"
              answer={studentFormData.socialName}
            />
            <DisplayField
              question="Sexo"
              answer={unwrapOptionLabel(studentFormData.sexOption)}
            />
          </ReviewRow>
          <ReviewRow>
            <DisplayField
              question="Nome afetivo"
              answer={studentFormData.affectiveName}
            />
          </ReviewRow>
          <ReviewRow>
            <DisplayField
              question="Cor-Raça"
              answer={unwrapOptionLabel(studentFormData.raceOption)}
            />
            <DisplayField
              question="Nacionalidade"
              answer={unwrapOptionLabel(studentFormData.nationalityOption)}
            />
          </ReviewRow>
          <ReviewRow>
            <DisplayField
              question="UF de Nascimento"
              answer={unwrapOptionLabel(studentFormData.birthStateOption)}
            />
            <DisplayField
              question="Município de Nascimento"
              answer={unwrapOptionLabel(studentFormData.birthCityOption)}
            />
          </ReviewRow>
          <ReviewRow>
            <DisplayField
              question="Contato Telefônico"
              answer={studentFormData.phone}
            />
            <DisplayField question="E-mail" answer={studentFormData.email} />
          </ReviewRow>
          <ReviewRow>
            <DisplayField
              question="Recursos para uso do(a) estudante em sala de aula e/ou para participação em avaliações"
              answer={studentFormData.extraResourceOption
                ?.map(item => unwrapOptionLabel(item))
                .join(',')}
            />
            <DisplayField
              question="Estudante em situação de itinerância?"
              answer={unwrapOptionLabel(studentFormData.itinerantOption)}
            />
          </ReviewRow>
          <ReviewRow>
            <DisplayField
              question="Pertence a povo ou comunidade tradicional?"
              answer={unwrapOptionLabel(studentFormData.specialCommunityOption)}
            />
            <DisplayField
              question="O(a) aluno(a) possui deficiência, transtorno do espectro autista ou altas habilidades/superdotação?"
              answer={unwrapOptionLabel(studentFormData.handicappedOption)}
            />
          </ReviewRow>
          <SubHeader>Fardamento</SubHeader>
          <ReviewRow>
            <DisplayField
              question="Tamanho Blusa"
              answer={unwrapOptionLabel(studentFormData.shirtSizeOption)}
            />
            <DisplayField
              question="Tamanho Bermuda"
              answer={unwrapOptionLabel(studentFormData.shortsSizeOption)}
            />
          </ReviewRow>
        </>
      )}
      <HeaderSection
        title="Dados dos Responsáveis"
        onEditAction={() =>
          editable === 'inplace'
            ? setIsEditingResponsableStudent(true)
            : onGoToStep(EnrollmentStep.RESPONSIBLE)
        }
        editable={isEditable && !isEditingResponsableStudent}
      />
      {isEditingResponsableStudent ? (
        <ResponsibleForm
          onBack={() => setIsEditingResponsableStudent(false)}
          onNext={() => setIsEditingResponsableStudent(false)}
          editing
        />
      ) : (
        <>
          {/* TODO: Não existe campo na interface para definir "Sem afiliação" [MA] */}
          <ReviewRow>
            <DisplayField
              question="Filiação - Pai"
              answer={responsableStudentData?.fatherName}
            />
            <DisplayField
              question="Filiação - Mãe"
              answer={responsableStudentData?.motherName}
            />
          </ReviewRow>
          <ReviewRow>
            <DisplayField
              question="Nome do Responsável"
              answer={responsableStudentData?.responsableName}
            />
            <DisplayField
              question="CPF"
              answer={responsableStudentData?.responsableCPF}
            />
          </ReviewRow>
          <ReviewRow>
            <DisplayField
              question="Data de Nascimento"
              answer={dateFormatted(
                responsableStudentData?.responsableBirthdate || '',
              )}
            />
            <DisplayField
              question="Parentesco"
              answer={
                responsableRelation.find(
                  item =>
                    item.value === responsableStudentData?.responsableRelation,
                )?.label
              }
            />
          </ReviewRow>
          <ReviewRow>
            <DisplayField
              question="Profissão"
              answer={responsableStudentData?.responsableProfession}
            />
            <DisplayField
              question="Ocupação"
              answer={responsableStudentData?.responsableOccupation}
            />
          </ReviewRow>
          <ReviewRow>
            <DisplayField
              question="Contato Telefônico"
              answer={responsableStudentData?.responsablePhone}
            />
            <DisplayField
              question="E-mail"
              answer={responsableStudentData?.responsableEmail}
            />
          </ReviewRow>
        </>
      )}
      <HeaderSection
        title="Documentos do Estudante"
        onEditAction={() =>
          editable === 'inplace'
            ? setIsEditingDocument(true)
            : onGoToStep(EnrollmentStep.DOCUMENTS)
        }
        editable={isEditable && !isEditingDocument}
      />
      {isEditingDocument ? (
        <StudentDocumentsForm
          onBack={() => setIsEditingDocument(false)}
          onNext={() => setIsEditingDocument(false)}
          editing
        />
      ) : (
        <>
          <ReviewRow>
            <DisplayField
              question="Modelo de Certidão de Nascimento"
              answer={unwrapOptionLabel(
                documentStudentData.birthCertificateTypeOption ||
                  ({} as Option),
              )}
            />
            <DisplayField
              question={isNewBirthCertificate ? 'Número' : 'Número do livro'}
              answer={
                isNewBirthCertificate
                  ? documentStudentData.birthCertificateNumber
                  : documentStudentData.birthCertificateBook
              }
            />
          </ReviewRow>
          {!isNewBirthCertificate && (
            <ReviewRow>
              <DisplayField
                question="Página"
                answer={documentStudentData.birthCertificatePage}
              />
              <DisplayField
                question="Termo"
                answer={documentStudentData.birthCertificateTerm}
              />
            </ReviewRow>
          )}
          <ReviewRow>
            <DisplayField question="RG" answer={documentStudentData.rg} />
            <DisplayField
              question="UF"
              answer={
                documentStudentData.rgStateId &&
                unwrapOptionLabel(documentStudentData.rgStateId)
              }
            />
          </ReviewRow>
          <ReviewRow>
            <DisplayField
              question="Órgão Emissor"
              answer={documentStudentData.issuer}
            />
            <DisplayField
              question="Data de expedição"
              answer={
                documentStudentData.issueDate &&
                dateFormatted(documentStudentData.issueDate)
              }
            />
          </ReviewRow>
          <ReviewRow>
            <DisplayField question="CPF" answer={documentStudentData.cpf} />
            <DisplayField
              question="Número de Identificação Social (NIS)"
              answer={documentStudentData.nis}
            />
          </ReviewRow>
          <SubHeader>Vacinação COVID-19</SubHeader>
          <ReviewRow>
            <DisplayField
              question="Data 1ª dose"
              answer={dateFormatted(
                documentStudentData.covidVaccinationFirstDose || '',
              )}
            />
            <DisplayField
              question="Vacina com dose única"
              answer={
                documentStudentData.covidVaccinationUniqueDose ? 'Sim' : 'Não'
              }
            />
          </ReviewRow>
          <ReviewRow>
            <DisplayField
              question="Data 2ª dose"
              answer={dateFormatted(
                documentStudentData.covidVaccinationSecondDose || '',
              )}
            />
            <DisplayField
              question="Declaro que, por opção, não tomei nenhuma dose de vacina para imunização do covid/19."
              answer={
                documentStudentData.covidVaccinationRejected ? 'Sim' : 'Não'
              }
            />
          </ReviewRow>
          <ReviewRow>
            <DisplayField
              question="O estudante faz parte de um público a ser vacinado"
              answer={
                documentStudentData.vaccineUnavailableToAgeGroup ? 'Sim' : 'Não'
              }
            />
          </ReviewRow>
        </>
      )}
      <HeaderSection
        title="Área Residencial do Estudante"
        onEditAction={() =>
          editable === 'inplace'
            ? setIsEditingResidentialArea(true)
            : onGoToStep(EnrollmentStep.RESIDENTIAL)
        }
        editable={isEditable && !isEditingResidentialArea}
      />
      {isEditingResidentialArea ? (
        <ResidentialAreaForm
          onBack={() => setIsEditingResidentialArea(false)}
          onNext={() => setIsEditingResidentialArea(false)}
          editing
        />
      ) : (
        <>
          <ReviewRow>
            <DisplayField
              question="País de Residência"
              answer={unwrapOptionLabel(
                residentialAreaData.country || ({} as Option),
              )}
            />
            <DisplayField question="CEP" answer={residentialAreaData.zipcode} />
          </ReviewRow>
          <ReviewRow>
            <DisplayField
              question="Estado"
              answer={unwrapOptionLabel(
                residentialAreaData.stateId || ({} as Option),
              )}
            />
            <DisplayField
              question="Município"
              answer={unwrapOptionLabel(
                residentialAreaData.cityId || ({} as Option),
              )}
            />
          </ReviewRow>
          <ReviewRow>
            <DisplayField
              question="Bairro"
              answer={residentialAreaData.neighborhood}
            />
            <DisplayField question="Rua" answer={residentialAreaData.street} />
          </ReviewRow>
          <ReviewRow>
            <DisplayField
              question="Número"
              answer={residentialAreaData.number}
            />
            <DisplayField
              question="Complemento"
              answer={residentialAreaData.complement}
            />
          </ReviewRow>
          <ReviewRow>
            <DisplayField
              question="Distrito"
              answer={residentialAreaData.district}
            />
            {/* TODO: Checar a razão de n ter usado zonaOption [MA] */}
            <DisplayField
              question="Zona de Residência"
              answer={
                residentialAreaOptions.find(
                  item => item.value === residentialAreaData.zone,
                )?.label
              }
            />
          </ReviewRow>
          <ReviewRow>
            <DisplayField
              question="Localização diferenciada de residência"
              answer={unwrapOptionLabel(
                residentialAreaData.differentiatedLocation || ({} as Option),
              )}
            />
          </ReviewRow>
        </>
      )}

      <HeaderSection
        title="Anexos"
        onEditAction={() =>
          editable === 'inplace'
            ? setIsEditingStudentFiles(true)
            : onGoToStep(EnrollmentStep.ATTACHMENTS)
        }
        editable={isEditable && !isEditingStudentFiles}
      />
      {isEditingStudentFiles ? (
        <AttachmentsForm
          onBack={() => setIsEditingStudentFiles(false)}
          onNext={() => setIsEditingStudentFiles(false)}
          editing
        />
      ) : (
        <>
          {studentsFiles.records.map(item => (
            <DisplayAttachment
              key={item.id}
              fileName={item.name || item.url}
              type={documentTypes[item.type]}
            />
          ))}
        </>
      )}

      {isEditable &&
        !isEditingGeneralInfo &&
        !isEditingDocument &&
        !isEditingResidentialArea &&
        !isEditingResponsableStudent &&
        !isEditingStudentFiles && (
          <ReCaptcha ref={recaptchaRef} onChange={verifyTokenOnChange} />
        )}

      {isEditable &&
        !isEditingGeneralInfo &&
        !isEditingDocument &&
        !isEditingResidentialArea &&
        !isEditingResponsableStudent &&
        !isEditingStudentFiles && (
          <StepsButtonContainer>
            <Button
              iconLeft={editable === 'inplace' ? undefined : IconArrowLeft}
              title={editable === 'inplace' ? 'Cancelar' : 'Anterior'}
              type="button"
              styled="secondary"
              onClick={onBack}
              size="mini"
            />
            <Button
              type="button"
              size="mini"
              title={editable === 'inplace' ? 'Continuar' : 'Finalizar'}
              onClick={
                editable === 'inplace' ? onNext : onFinishStudentRegister
              }
              disabled={!recaptchaSucceed}
            />
          </StepsButtonContainer>
        )}
    </Container>
  );
};

export default ReviewForm;
