import { toast } from 'react-toastify';
import {
  all,
  takeLatest,
  put,
  SagaReturnType,
  select,
} from 'redux-saga/effects';

import { Identifier } from 'models/identifier';
import { AuthService } from 'services/api/auth';
import { mapErrorToResponseError } from 'services/api/responseErrorHandler';
import { RootState } from 'store';
import { AuthCreators } from 'store/ducks/auth';

type SagaSignInResponse = SagaReturnType<typeof AuthService.signIn>;
export const getUserInfoId = ({ auth }: RootState) => auth.userPersonalInfo?.id;

function* signIn(
  action: ReturnType<typeof AuthCreators.signIn.request>,
): Generator<unknown, void, SagaSignInResponse> {
  try {
    const { email, password } = action.payload;
    const response: SagaSignInResponse = yield AuthService.signIn({
      login: email,
      password,
    });
    const { user, ...userAuthInfo } = response;

    yield put(AuthCreators.signIn.success(userAuthInfo));
    yield put(AuthCreators.setPersonalInfo(user));
  } catch (error) {
    const responseError = mapErrorToResponseError(error);
    toast.error(responseError.message);
    yield put(AuthCreators.signIn.failure(responseError.message));
  }
}

function* signUp(
  action: ReturnType<typeof AuthCreators.signUp.request>,
): Generator<unknown, void, Identifier> {
  try {
    const { email, password } = action.payload;
    const response = yield AuthService.signUp(action.payload);
    yield put(AuthCreators.signUp.success(response));

    yield put(AuthCreators.signIn.request({ email, password }));
  } catch (error) {
    const responseError = mapErrorToResponseError(error);
    toast.error(responseError.message);
    yield put(AuthCreators.signUp.failure(responseError.message));
  }
}

function* fetchUser(): Generator<unknown, void, Identifier & number> {
  try {
    const id: number = yield select(getUserInfoId);

    const response = yield AuthService.fetchUser({ id });
    yield put(AuthCreators.fetchUser.success(response));
  } catch (error) {
    const responseError = mapErrorToResponseError(error);
    toast.error(responseError.message);
    yield put(AuthCreators.fetchUser.failure(responseError.message));
  }
}

function* resendEmail(): Generator<unknown, void, Identifier & number> {
  try {
    const id: number = yield select(getUserInfoId);

    yield AuthService.resendEmail({ id });
    yield put(AuthCreators.resendEmail.success());
    toast.success('E-mail enviado com sucesso');
  } catch (error) {
    const responseError = mapErrorToResponseError(error);
    toast.error(responseError.message);
    yield put(AuthCreators.resendEmail.failure());
  }
}

export default all([
  takeLatest(AuthCreators.signIn.request, signIn),
  takeLatest(AuthCreators.signUp.request, signUp),
  takeLatest(AuthCreators.fetchUser.request, fetchUser),
  takeLatest(AuthCreators.resendEmail.request, resendEmail),
]);
