import produce, { Draft } from 'immer';
import { Reducer } from 'redux';
import { ActionType, createAction } from 'typesafe-actions';

export enum Types {
  SET_CURRENT_TAB = '@dropdownHeader/SET_CURRENT_TAB',
}

export enum DropdownHeaderActions {
  RE_ENROLLMENT = 'Renovar matrícula',
  NEW_ENROLLMENT = 'Nova matrícula',
}

export interface DropdownHeaderState {
  currentAction: DropdownHeaderActions;
}

const INITIAL_STATE: DropdownHeaderState = {
  currentAction: DropdownHeaderActions.RE_ENROLLMENT,
};

export const DropdownHeaderCreators = {
  setCurrentDropdownHeader: createAction(
    Types.SET_CURRENT_TAB,
  )<DropdownHeaderActions>(),
};

export type ActionTypes = ActionType<typeof DropdownHeaderCreators>;

const reducer: Reducer<DropdownHeaderState, ActionTypes> = (
  state = INITIAL_STATE,
  action: ActionTypes,
) => {
  const { type, payload } = action;

  return produce(state, (draft: Draft<DropdownHeaderState>) => {
    switch (type) {
      case Types.SET_CURRENT_TAB: {
        draft.currentAction = payload;
        break;
      }

      default:
    }
  });
};

export default reducer;
