import { toast } from 'react-toastify';
import { all, takeLatest, put, select } from 'redux-saga/effects';

import { FollowUpService } from 'services/api/followUp';
import { mapErrorToResponseError } from 'services/api/responseErrorHandler';
import { RootState } from 'store';
import { FollowUpCreators, FollowUp } from 'store/ducks/followUp';

export const getUserInfoId = ({ auth }: RootState) => auth.userPersonalInfo?.id;

function* fetchFollowUp(): Generator<unknown, void, number & FollowUp[]> {
  try {
    const id: number = yield select(getUserInfoId);
    const records = yield FollowUpService.fetchFollowUp({ id });
    yield put(FollowUpCreators.getFollowUp.success(records));
  } catch (error) {
    const responseError = mapErrorToResponseError(error);
    toast.error(responseError.message);
    yield put(FollowUpCreators.getFollowUp.failure(responseError.message));
  }
}

export default all([
  takeLatest(FollowUpCreators.getFollowUp.request, fetchFollowUp),
]);
