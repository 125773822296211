import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { IconArrowLeft, IconArrowRight } from 'assets/icons';
import {
  binaryOptions,
  publicTransportResponsableOptions,
  transportTypeDetailOptions,
  transportTypeOptions,
} from 'assets/json';
import { Select, Button, Input } from 'components';
import Banner from 'components/Banner';
import { Option } from 'components/Select';
import { useDialogConfirmation } from 'hooks/DialogContext';
import { EducationDataFormData, SchoolOptionValue } from 'models/enrollment';
import {
  ChildrenFormContainer,
  ChildrenFormRow,
  GrayLine,
  StepsButtonContainer,
} from 'pages/StudentEnrollment/styles';
import { SchoolSEEService } from 'services/api/schoolSEE';
import { mapSchoolCoursesResponseToCourseIdOptions } from 'services/api/schoolSEE/mapper';
import { FetchSchoolCoursesResponse } from 'services/api/schoolSEE/models';
import { RootState } from 'store';
import { EnrollmentCreators } from 'store/ducks/enrollment';
import { SchoolFiltersCreators } from 'store/ducks/schoolFilters';
import {
  checkFormikErrorsAndSetFieldTouched,
  getFormikMessageError,
} from 'utils/formikUtils';
import {
  stringSelectOptionRequiredSchema,
  booleanSelectOptionRequiredSchema,
  stringSelectOptionSchema,
  schoolSelectOptionRequiredSchema,
  Strings as ValidationsStrings,
} from 'utils/validations';

import {
  FormTitle,
  AddSiblingButton,
  RemoveSiblingButton,
  ChildrenFormRowSiblings,
} from './styles';

interface EducationDataProps {
  onBack: () => void;
  onNext: () => void;
}

const EducationData = ({ onBack, onNext }: EducationDataProps) => {
  const { schools } = useSelector(
    ({ schoolFilters }: RootState) => schoolFilters,
  );
  const { educationTypeForm, educationDataForm } = useSelector(
    ({ enrollment }: RootState) => enrollment,
  );
  const {
    studentFormData: { pplOption },
  } = useSelector(({ studentGeneralInfo }: RootState) => studentGeneralInfo);

  const [siblingsIndex, setSiblingsIndex] = useState(
    (educationDataForm.siblings && educationDataForm.siblings.length) || 1,
  );
  const [courses, setCourses] = useState<Option[]>([]);
  const dispatch = useDispatch();
  const modal = useDialogConfirmation();

  useEffect(() => {
    dispatch(SchoolFiltersCreators.getSchoolsSEE.request());
  }, [dispatch]);

  const onSubmit = useCallback(
    (formData: EducationDataFormData) => {
      // Checking if has empty siblings BEFORE submit [ML]
      if (
        formData.siblingsToEnroll &&
        formData.siblings &&
        formData.siblings.filter(e => !e).length !== 0
      ) {
        toast.error('Preencha o nome de todos os irmãos');
        return;
      }
      if (isEmpty(educationDataForm)) {
        dispatch(
          EnrollmentCreators.createEnrollment.request({
            ...formData,
            ...educationTypeForm,
          }),
        );
      } else {
        dispatch(
          EnrollmentCreators.updateEnrollment.request({
            ...formData,
            ...educationTypeForm,
          }),
        );
      }
    },
    [dispatch, educationDataForm, educationTypeForm],
  );

  const EDUCATION_DATA_SCHEMA = useMemo(
    () =>
      Yup.object().shape({
        school: schoolSelectOptionRequiredSchema,
        courseOption:
          educationTypeForm.modalityOption.value === 'EPT'
            ? stringSelectOptionRequiredSchema.nullable()
            : stringSelectOptionSchema.nullable(),
        publicTransport: booleanSelectOptionRequiredSchema,
        publicTransportResponsable: stringSelectOptionSchema.when(
          'publicTransport',
          {
            is: (option: Option) => option.value,
            then: stringSelectOptionRequiredSchema,
          },
        ),
        transportType: stringSelectOptionSchema.when('publicTransport', {
          is: (option: Option) => option.value,
          then: stringSelectOptionRequiredSchema,
        }),
        transportTypeDetail: stringSelectOptionSchema.when('publicTransport', {
          is: (option: Option) => option.value,
          then: stringSelectOptionRequiredSchema,
        }),
        siblingsToEnroll: booleanSelectOptionRequiredSchema,
        siblings: Yup.array()
          .of(Yup.string())
          .when('siblingsToEnroll', {
            is: (option: Option) => option.value,
            then: rule => rule.required(ValidationsStrings.REQUIRED_FIELD),
          }),
      }),
    [educationTypeForm.modalityOption?.value],
  );

  const formik = useFormik({
    initialValues: !isEmpty(educationDataForm)
      ? educationDataForm
      : ({
          publicTransport: pplOption.value ? binaryOptions[1] : undefined,
        } as EducationDataFormData),
    validationSchema: EDUCATION_DATA_SCHEMA,
    onSubmit,
  });

  useEffect(() => {
    checkFormikErrorsAndSetFieldTouched(formik);
  }, [formik.isSubmitting]);

  const fetchSchoolCourses = async (schoolId: string) => {
    const schoolCoursesResponse = await SchoolSEEService.fetchSchoolCourses({
      id: schoolId,
    });
    const coursesOptions = mapSchoolCoursesResponseToCourseIdOptions(
      schoolCoursesResponse,
    );

    setCourses(coursesOptions);
  };

  useEffect(() => {
    const schoolId = (formik.values.school?.value as SchoolOptionValue)
      ?.schoolId;

    if (schoolId) {
      fetchSchoolCourses(schoolId);
    }

    if (educationTypeForm.modalityOption.value !== 'EPT') {
      formik.setFieldValue('courseOption', undefined);
    }
  }, []);

  // Reseting dynamic values [ML]

  useEffect(() => {
    if (
      formik.values?.publicTransport !== undefined &&
      !formik.values?.publicTransport.value &&
      (formik.values?.publicTransportResponsable !== undefined ||
        formik.values?.transportType !== undefined ||
        formik.values?.transportTypeDetail !== undefined)
    ) {
      formik.setFieldValue('publicTransportResponsable', undefined);
      formik.setFieldValue('transportType', undefined);
      formik.setFieldValue('transportTypeDetail', undefined);
    }
  }, [formik, formik.values?.publicTransport]);

  useEffect(() => {
    if (
      formik.values?.siblingsToEnroll !== undefined &&
      !formik.values?.siblingsToEnroll.value &&
      formik.values?.siblings !== undefined
    ) {
      formik.setFieldValue('siblings', undefined);
      setSiblingsIndex(1);
    }
  }, [formik, formik.values?.siblingsToEnroll]);

  const handleClose = useCallback(() => {
    if (formik.dirty) {
      modal.openDialog(onBack, () => false);
    } else {
      onBack();
    }
  }, [onBack, modal, formik]);

  return (
    <ChildrenFormContainer>
      {schools && schools.length === 0 && (
        <Banner
          message={`Prezado Estudante!\nO processo de vinculação às turmas (matrícula) acontece gradativamente. Caso o perfil de sua escola não esteja visível neste momento aguarde que logo estará disponível, sendo assegurada a renovação da matrícula.`}
          variant="warning"
        />
      )}
      <ChildrenFormRow>
        <Select
          label="Escola atual"
          options={schools}
          required
          value={formik.values.school}
          onChange={(option, _) => {
            formik.setFieldValue('school', option);
            fetchSchoolCourses((option.value as SchoolOptionValue).schoolId);
          }}
          messageError={getFormikMessageError(formik, 'school')}
        />
        {educationTypeForm.modalityOption.value === 'EPT' && (
          <Select
            label="Curso EPT"
            options={courses}
            required
            value={formik.values.courseOption}
            onChange={(option, _) =>
              formik.setFieldValue('courseOption', option)
            }
            messageError={getFormikMessageError(formik, 'courseOption')}
          />
        )}
      </ChildrenFormRow>

      {!pplOption.value && (
        <>
          <GrayLine />
          <FormTitle>Transporte</FormTitle>
          <ChildrenFormRow>
            <Select
              label="Você utiliza transporte escolar público?"
              options={binaryOptions}
              required
              value={formik.values.publicTransport}
              onChange={(option, _) =>
                formik.setFieldValue('publicTransport', option)
              }
              messageError={getFormikMessageError(formik, 'publicTransport')}
            />
            {formik.values?.publicTransport?.value && (
              <Select
                label="Poder público responsável pelo transporte"
                options={publicTransportResponsableOptions}
                required
                value={formik.values.publicTransportResponsable}
                onChange={(option, _) =>
                  formik.setFieldValue('publicTransportResponsable', option)
                }
                messageError={getFormikMessageError(
                  formik,

                  'publicTransportResponsable',
                )}
              />
            )}
          </ChildrenFormRow>

          {formik.values?.publicTransport?.value && (
            <>
              <ChildrenFormRow>
                <Select
                  label="Tipo de veículo utilizado"
                  options={transportTypeOptions}
                  required
                  value={formik.values.transportType}
                  onChange={(option, _) => {
                    formik.setFieldValue('transportType', option);
                    formik.setFieldValue('transportTypeDetail', '');
                  }}
                  messageError={getFormikMessageError(formik, 'transportType')}
                />
                {formik.values?.transportType && (
                  <Select
                    label={formik.values?.transportType.label ?? ''}
                    options={
                      formik.values?.transportType === transportTypeOptions[0]
                        ? transportTypeDetailOptions.rodoviario
                        : transportTypeDetailOptions.aquaviario
                    }
                    required
                    value={formik.values.transportTypeDetail}
                    onChange={(option, _) =>
                      formik.setFieldValue('transportTypeDetail', option)
                    }
                    messageError={getFormikMessageError(
                      formik,
                      'transportTypeDetail',
                    )}
                  />
                )}
              </ChildrenFormRow>
              <ChildrenFormRow />
            </>
          )}

          <GrayLine />
        </>
      )}

      <ChildrenFormRow>
        <Select
          label="Possui irmãos a serem matriculados na mesma escola?"
          options={binaryOptions}
          required
          value={formik.values.siblingsToEnroll}
          onChange={(option, _) =>
            formik.setFieldValue('siblingsToEnroll', option)
          }
          messageError={getFormikMessageError(formik, 'siblingsToEnroll')}
        />
      </ChildrenFormRow>
      {formik.values?.siblingsToEnroll?.value &&
        Array.from({ length: siblingsIndex }, (_, key) => {
          return (
            <ChildrenFormRowSiblings>
              <Input
                label="Nome completo do irmão"
                placeholder="Digite o nome do irmão"
                required
                onChange={e => {
                  if (
                    key === 0 &&
                    e.target.value === '' &&
                    siblingsIndex === 1
                  ) {
                    return formik.setFieldValue('siblings', undefined);
                  }
                  return formik.setFieldValue(
                    `siblings[${key}]`,
                    e.target.value,
                  );
                }}
                onBlur={formik.handleBlur}
                value={formik.values?.siblings?.[key]}
                name="siblings"
                messageError={getFormikMessageError(formik, 'siblings')}
              />

              {key !== siblingsIndex - 1 ? (
                <RemoveSiblingButton
                  type="button"
                  onClick={() => {
                    const siblings = [...formik.values.siblings];
                    siblings.splice(key, 1);
                    formik.setFieldValue('siblings', siblings);
                    setSiblingsIndex(siblingsIndex - 1);
                  }}
                >
                  - Remover irmão
                </RemoveSiblingButton>
              ) : (
                <AddSiblingButton
                  type="button"
                  onClick={() => {
                    const siblings = [...formik.values.siblings];
                    siblings.push('');
                    formik.setFieldValue('siblings', siblings);
                    setSiblingsIndex(siblingsIndex + 1);
                  }}
                >
                  + Adicionar mais um irmão
                </AddSiblingButton>
              )}
            </ChildrenFormRowSiblings>
          );
        })}

      <StepsButtonContainer>
        <Button
          iconLeft={IconArrowLeft}
          title="Anterior"
          type="button"
          styled="secondary"
          size="mini"
          onClick={handleClose}
        />
        <Button
          iconRight={IconArrowRight}
          type="button"
          size="mini"
          title="Finalizar"
          onClick={formik.submitForm}
        />
      </StepsButtonContainer>
    </ChildrenFormContainer>
  );
};

export default EducationData;
