import base from 'services/api/base';
import { ServiceEndpoints } from 'services/api/types';

import { StudentSchoolResponse } from './models';

export const StudentSchoolEndpoints: ServiceEndpoints = {
  schools: {
    path: 'students/{id}/schools',
    errorMessages: {
      401: 'Não autorizado',
      403: 'Sem permissão para acessar',
      404: 'Escola não encontrada',
      default: 'Erro ao recuperar cursos',
    },
  },
};

async function fetchSchool(params: {
  id: string;
}): Promise<StudentSchoolResponse> {
  const endpoint = StudentSchoolEndpoints.schools;
  const response = await base.api.get<StudentSchoolResponse>(
    endpoint.path.replace('{id}', params.id),
  );
  return response.data;
}

export const StudentSchoolService = {
  fetchSchool,
};
