import React, { createContext, useState, useContext, useCallback } from 'react';

import { toast } from 'react-toastify';

import { BarSpinner } from 'components';
import { AuthService } from 'services/api/auth';
import { mapErrorToResponseError } from 'services/api/responseErrorHandler';

type RecoveryPasswordData = {
  email: string;
};

type ResetPasswordData = {
  token: string;
  newPassword: string;
};

export interface ForgotPasswordContextData {
  loading: boolean;
  onRecoveryPassword: (recoveryData: RecoveryPasswordData) => Promise<boolean>;
  onResetPassword: (resetData: ResetPasswordData) => Promise<boolean>;
}

const ForgotPasswordContext = createContext<ForgotPasswordContextData>(
  {} as ForgotPasswordContextData,
);

const ForgotPasswordProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const onRecoveryPassword = useCallback(
    async ({ email }: RecoveryPasswordData) => {
      try {
        setLoading(true);
        await AuthService.recoveryPassword({ email });
        toast.success('Link enviado com sucesso! Verifique seu e-mail');
        return true;
      } catch (error) {
        const responseError = mapErrorToResponseError(error);
        toast.error(responseError.message);
      } finally {
        setLoading(false);
      }
      return false;
    },
    [],
  );

  const onResetPassword = useCallback(async ({ token, newPassword }) => {
    try {
      setLoading(true);
      await AuthService.resetPassword({ token, newPassword });
      return true;
    } catch (error) {
      const responseError = mapErrorToResponseError(error);
      toast.error(responseError.message);
    } finally {
      setLoading(false);
    }
    return false;
  }, []);

  return (
    <ForgotPasswordContext.Provider
      value={{ loading, onRecoveryPassword, onResetPassword }}
    >
      <BarSpinner loading={loading} />
      {children}
    </ForgotPasswordContext.Provider>
  );
};

function useForgotPassword(): ForgotPasswordContextData {
  const context = useContext(ForgotPasswordContext);

  if (!context) {
    throw new Error(
      'useForgotPassword must be used within a ForgotPasswordProvider',
    );
  }
  return context;
}

export { ForgotPasswordContext, ForgotPasswordProvider, useForgotPassword };
