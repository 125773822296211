import styled from 'styled-components';

export const AddOptionButton = styled.button`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 100%;

  width: fit-content;
  color: ${({ theme }) => theme.palette.primary};

  &:hover {
    filter: brightness(0.8);
  }
`;

export const RemoveOptionButton = styled.button`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 100%;

  width: fit-content;
  color: ${({ theme }) => theme.palette.negative};

  &:hover {
    filter: brightness(0.8);
  }
`;
