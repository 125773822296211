/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeEvent, useCallback, useEffect, useMemo, useRef } from 'react';

import { useFormik } from 'formik';
import { isEmpty, isEqual, debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { IconArrowRight } from 'assets/icons';
import {
  schoolRegionOptions,
  binaryOptions,
  countryOptions,
  sexOptions,
  racialOptions,
  nationalityOptions,
  studentAdditionalResourceOptions,
  communityOptions,
  clothingSize,
  disabilityOptions,
} from 'assets/json';
import { Input, Select, Button, SaberVerificationModal } from 'components';
import InputKeyboardDate from 'components/InputKeyboardDate';
import { Option } from 'components/Select';
import { BodySemiBold } from 'components/StyledTypography/styles';
import { useDialogConfirmation } from 'hooks/DialogContext';
import { StudentFormData } from 'models/student';
import {
  ChildrenFormContainer,
  ChildrenFormRow,
  StepsButtonContainer,
  GrayLine,
} from 'pages/StudentEnrollment/styles';
import { RootState } from 'store';
import { AuthCreators } from 'store/ducks/auth';
import { CitiesCreators } from 'store/ducks/cities';
import { DropdownHeaderActions } from 'store/ducks/dropdownHeader';
import { StatesCreators } from 'store/ducks/states';
import { GeneralStudentCreators } from 'store/ducks/students/general';
import { SaberCreators } from 'store/ducks/students/saber';
import { dateDashFormatted } from 'utils/dateUtils';
import {
  checkFormikErrorsAndSetFieldTouched,
  getFormikMessageError,
} from 'utils/formikUtils';
import { normalizeOnlyNumbers, normalizePhoneValue } from 'utils/maskUtils';
import {
  personNameRequiredSchema,
  stringSelectOptionRequiredSchema,
  stringSelectOptionSchema,
  booleanSelectOptionRequiredSchema,
  booleanSelectOptionSchema,
  Strings as ValidationStrings,
  phoneSchema,
  emailSchema,
  numberSelectOptionRequiredSchema,
  saberCodeSchema,
  numberSelectOptionSchema,
  arrayRequired,
  saberCodeSchemaRequired,
  dateRequiredSchema,
} from 'utils/validations';

import { OneLine, SelectLabel, SelectContainer } from './styles';

const TIME_DEBOUNCE_MS = 1000;

interface GeneralInfoFormProps {
  onBack: () => void;
  onNext: () => void;
  editing: boolean;
}

const GeneralInfoForm = ({ onNext, onBack, editing }: GeneralInfoFormProps) => {
  const dispatch = useDispatch();
  const { userPersonalInfo } = useSelector(({ auth }: RootState) => auth);
  const {
    studentName,
    loading: saberLoading,
    errorMessage: saberErrorMessage,
    modalOpen: saberModelOpen,
  } = useSelector(({ saber }: RootState) => saber);
  const { studentFormData, loading } = useSelector(
    ({ studentGeneralInfo }: RootState) => studentGeneralInfo,
  );
  const { loading: statesLoading, states: stateOptions } = useSelector(
    ({ states }: RootState) => states,
  );
  const { loading: citiesLoading, cityOptions } = useSelector(
    ({ cities }: RootState) => cities,
  );
  const currentAction = useSelector(
    ({ dropdownHeader }: RootState) => dropdownHeader.currentAction,
  );

  const isNewEnrollment =
    currentAction === DropdownHeaderActions.NEW_ENROLLMENT;

  const studentSchema = useMemo(
    () =>
      Yup.object().shape({
        code: saberCodeSchema, // isNewEnrollment ? saberCodeSchema : saberCodeSchemaRequired,
        networkOption: stringSelectOptionRequiredSchema,
        fullname: personNameRequiredSchema,
        hasSocialNameOption: booleanSelectOptionRequiredSchema,
        socialName: Yup.string().when('hasSocialNameOption', {
          is: (option: Option) => option.value,
          then: rule => rule.required(ValidationStrings.REQUIRED_FIELD),
        }),
        hasSocialNameInSchoolRecordsOption: booleanSelectOptionSchema.when(
          'hasSocialNameOption',
          {
            is: (option: Option) => option.value,
            then: booleanSelectOptionRequiredSchema,
          },
        ),
        birthdate: dateRequiredSchema,
        affectiveName: Yup.string(),
        sexOption: stringSelectOptionRequiredSchema,
        raceOption: stringSelectOptionRequiredSchema,
        nationalityOption: stringSelectOptionRequiredSchema,
        nationalityCountryOption: stringSelectOptionSchema.when(
          'nationalityOption',
          {
            is: (option: Option) =>
              option.value === nationalityOptions[2].value,
            then: stringSelectOptionRequiredSchema,
          },
        ),
        passport: Yup.string().when('nationalityOption', {
          is: (option: Option) => option.value === nationalityOptions[2].value,
          then: rule => rule.required(ValidationStrings.REQUIRED_FIELD),
        }),
        phone: phoneSchema,
        email: emailSchema,
        extraResourceOption: Yup.array().when('handicappedOption', {
          is: (option: Option) => option.value,
          then: arrayRequired,
        }),
        itinerantOption: booleanSelectOptionRequiredSchema,
        specialCommunityOption: stringSelectOptionRequiredSchema,
        shirtSizeOption: stringSelectOptionRequiredSchema,
        shortsSizeOption: stringSelectOptionRequiredSchema,
        birthStateOption: numberSelectOptionSchema.when('nationalityOption', {
          is: (option: Option) => option.value === nationalityOptions[2].value,
          then: numberSelectOptionSchema,
        }),
        birthCityOption: numberSelectOptionSchema.when('nationalityOption', {
          is: (option: Option) => option.value === nationalityOptions[2].value,
          then: numberSelectOptionSchema,
        }),
        pplOption: booleanSelectOptionRequiredSchema,
        handicappedOption: booleanSelectOptionRequiredSchema,
        handicappedTypeOption: stringSelectOptionSchema.when(
          'handicappedOption',
          {
            is: (option: Option) => option.value,
            then: stringSelectOptionRequiredSchema,
          },
        ),
      }),
    [isNewEnrollment],
  );

  const pplModal = useDialogConfirmation();

  useEffect(() => {
    dispatch(CitiesCreators.cleanCities());
    dispatch(StatesCreators.getStates.request());
  }, [dispatch]);

  const onSubmit = useCallback(
    formData => {
      dispatch(
        AuthCreators.setPersonalInfo({
          ...userPersonalInfo,
          birthdate: dateDashFormatted(formData.birthdate),
        }),
      );

      if (editing) {
        onNext();
      }

      if (isEqual(studentFormData, formData)) {
        onNext();
      } else if (isEmpty(studentFormData)) {
        dispatch(GeneralStudentCreators.createGeneralStudent.request(formData));
      } else {
        dispatch(GeneralStudentCreators.updateGeneralStudent.request(formData));
      }
    },
    [dispatch, onNext, studentFormData, userPersonalInfo],
  );

  const formik = useFormik({
    initialValues: isEmpty(studentFormData)
      ? ({
          fullname: userPersonalInfo?.fullname,
          email: userPersonalInfo?.email,
          networkOption: isNewEnrollment ? undefined : schoolRegionOptions[2],
          birthdate: userPersonalInfo?.birthdate,
        } as StudentFormData)
      : studentFormData,
    validationSchema: studentSchema,
    onSubmit,
  });

  const onSelectPPLOption = useCallback(() => {
    pplModal.openDialog(
      () => formik.setFieldValue('pplOption', binaryOptions[0]),
      () => formik.setFieldValue('pplOption', binaryOptions[1]),
      {
        title: 'Estudante PPL',
        description:
          'A modalidade PPL serve para estudantes que no momento se encontram cumprindo medidas socioeducativas.',
        extraInfo:
          'Você confirma que o(a) estudante em questão se encaixa neste perfil?',
      },
    );
  }, [pplModal, formik]);

  useEffect(() => {
    checkFormikErrorsAndSetFieldTouched(formik);
  }, [formik.isSubmitting]);

  useEffect(() => {
    // FIXME: Fix value city when the state changes [MA]
    if (formik.values.birthStateOption !== undefined) {
      dispatch(
        CitiesCreators.getCitiesByStateId.request({
          stateId: formik.values.birthStateOption.value as number,
        }),
      );
    }
  }, [dispatch, formik.values.birthStateOption]);

  // Here we are resetting the dynamic fields when needed to avoid sending old data to the backend [ML]
  useEffect(() => {
    if (
      formik.values.hasSocialNameOption !== undefined &&
      !formik.values.hasSocialNameOption.value &&
      (formik.values.socialName !== undefined ||
        formik.values.hasSocialNameInSchoolRecordsOption !== undefined)
    ) {
      formik.setFieldValue('socialName', undefined);
      formik.setFieldValue('hasSocialNameInSchoolRecordsOption', undefined);
    }
  }, [formik, formik.values.hasSocialNameOption]);

  useEffect(() => {
    if (
      formik.values.nationalityOption !== undefined &&
      formik.values.nationalityOption.value !== nationalityOptions[2].value &&
      (formik.values.nationalityCountryOption !== undefined ||
        formik.values.passport !== undefined)
    ) {
      formik.setFieldValue('nationalityCountryOption', undefined);
      formik.setFieldValue('passport', undefined);
    }
  }, [formik, formik.values.nationalityOption]);

  useEffect(() => {
    if (
      formik.values.nationalityOption !== undefined &&
      formik.values.nationalityOption.value === nationalityOptions[2].value &&
      (formik.values.birthStateOption !== undefined ||
        formik.values.birthCityOption !== undefined)
    ) {
      formik.setFieldValue('birthStateOption', undefined);
      formik.setFieldValue('birthCityOption', undefined);
    }
  }, [formik, formik.values.nationalityOption]);

  useEffect(() => {
    if (
      formik.values.handicappedOption !== undefined &&
      !formik.values.handicappedOption.value &&
      formik.values.handicappedTypeOption !== undefined
    ) {
      formik.setFieldValue('handicappedTypeOption', undefined);
    }
  }, [formik, formik.values.handicappedOption]);

  const oldState = useRef(studentFormData?.birthStateOption);

  useEffect(() => {
    if (oldState.current !== formik.values.birthStateOption) {
      formik.setFieldValue('birthCityOption', {
        label: 'Selecione uma cidade',
        value: '',
      });
    }
  }, [formik.values.birthStateOption]);

  const debouncedSearchSaberCode = useCallback(
    debounce(
      (saberCode: string) =>
        dispatch(SaberCreators.getStudent.request({ code: saberCode })),
      TIME_DEBOUNCE_MS,
    ),
    [dispatch],
  );

  const onSaberCodeChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      const saberCode = normalizeOnlyNumbers(evt.target.value);
      formik.setFieldValue('code', saberCode);
      if (saberCode) {
        debouncedSearchSaberCode(saberCode);
      }
    },
    [formik, debouncedSearchSaberCode],
  );

  return (
    <ChildrenFormContainer id="student-form-id">
      <SaberVerificationModal
        isOpen={saberModelOpen}
        onClose={() => dispatch(SaberCreators.setModalOpen(false))}
        studentName={studentName ?? ''}
        onAffirmative={() => {
          dispatch(SaberCreators.setModalOpen(false));

          if (studentName) {
            formik.setFieldValue('fullname', studentName);
          }
        }}
        onNegative={() => {
          dispatch(
            SaberCreators.setErrorMessage(
              'Verifique se o código foi digitado corretamente.',
            ),
          );
          dispatch(SaberCreators.setModalOpen(false));
        }}
      />
      <ChildrenFormRow>
        {isNewEnrollment ? (
          <Select
            label="Estudantes matriculados na rede (2021 ou anos anteriores)"
            options={schoolRegionOptions}
            required
            value={formik.values.networkOption}
            onChange={(option, _) =>
              formik.setFieldValue('networkOption', option)
            }
            messageError={getFormikMessageError(formik, 'networkOption')}
          />
        ) : (
          <Input
            label="Código do estudante - Saber"
            placeholder="Digite o código"
            hint={saberLoading ? 'Verificando código...' : ''}
            // required={!isNewEnrollment}
            name="code"
            hintSuccess={
              formik.values.code &&
              formik.values?.code?.length !== 0 &&
              !saberLoading &&
              !saberErrorMessage &&
              studentName
                ? 'Código verificado'
                : ''
            }
            hintFailure={
              formik.values.code &&
              formik.values?.code?.length !== 0 &&
              !saberLoading &&
              saberErrorMessage
                ? saberErrorMessage
                : ''
            }
            messageError={getFormikMessageError(formik, 'code')}
            onChange={onSaberCodeChange}
            onBlur={formik.handleBlur}
            value={formik.values.code}
            maxLength={8}
          />
        )}
      </ChildrenFormRow>
      <ChildrenFormRow>
        <Input
          label="Nome Civil Completo"
          placeholder="Digite seu nome"
          required
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.fullname}
          name="fullname"
          messageError={getFormikMessageError(formik, 'fullname')}
        />
        <Select
          label="Possui nome social?"
          options={binaryOptions}
          required
          value={formik.values.hasSocialNameOption}
          onChange={(option, _) =>
            formik.setFieldValue('hasSocialNameOption', option)
          }
          messageError={getFormikMessageError(formik, 'hasSocialNameOption')}
        />
      </ChildrenFormRow>
      {formik.values.hasSocialNameOption?.value && (
        <ChildrenFormRow>
          <Input
            label="Nome Social"
            placeholder="Digite seu nome social"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.socialName}
            name="socialName"
            required
            messageError={getFormikMessageError(formik, 'socialName')}
          />
          <Select
            label="Usar nome social nos registros escolares?"
            options={binaryOptions}
            required
            value={formik.values.hasSocialNameInSchoolRecordsOption}
            onChange={(option, _) =>
              formik.setFieldValue('hasSocialNameInSchoolRecordsOption', option)
            }
            messageError={getFormikMessageError(
              formik,
              'hasSocialNameInSchoolRecordsOption',
            )}
          />
        </ChildrenFormRow>
      )}

      <ChildrenFormRow>
        <Input
          info="O nome afetivo não é um apelido. É o nome utilizado e requerido para uma criança ou adolescente que está em processo de adoção."
          label="Nome afetivo"
          placeholder="Digite seu nome"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.affectiveName}
          name="affectiveName"
          messageError={getFormikMessageError(formik, 'affectiveName')}
        />
      </ChildrenFormRow>

      <GrayLine />

      <ChildrenFormRow>
        <InputKeyboardDate
          label="Data nascimento"
          name="birthdate"
          value={
            formik.values.birthdate
              ? new Date(`${formik.values.birthdate} 06:00`)
              : null
          }
          onChange={date =>
            formik.setFieldValue('birthdate', date?.toDateString() ?? undefined)
          }
          onBlur={formik.handleBlur}
          messageError={getFormikMessageError(formik, 'birthdate')}
          required
        />
        <Select
          label="Sexo"
          options={sexOptions}
          required
          value={formik.values.sexOption}
          onChange={(option, _) => formik.setFieldValue('sexOption', option)}
          messageError={getFormikMessageError(formik, 'sexOption')}
        />
      </ChildrenFormRow>
      <ChildrenFormRow>
        <Select
          label="Cor-Raça"
          options={racialOptions}
          required
          value={formik.values.raceOption}
          onChange={(option, _) => formik.setFieldValue('raceOption', option)}
          messageError={getFormikMessageError(formik, 'raceOption')}
        />
        <Select
          label="Nacionalidade"
          options={nationalityOptions}
          required
          value={formik.values.nationalityOption}
          onChange={(option, _) =>
            formik.setFieldValue('nationalityOption', option)
          }
          messageError={getFormikMessageError(formik, 'nationalityOption')}
        />
      </ChildrenFormRow>

      {formik.values.nationalityOption?.value ===
        nationalityOptions[2].value && (
        <ChildrenFormRow>
          <Select
            label="País de nacionalidade"
            options={countryOptions}
            required
            value={formik.values.nationalityCountryOption}
            onChange={(option, _) =>
              formik.setFieldValue('nationalityCountryOption', option)
            }
            messageError={getFormikMessageError(
              formik,
              'nationalityCountryOption',
            )}
          />
          <Input
            label="Passaporte"
            placeholder="Digite seu passaporte"
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.passport}
            name="passport"
            messageError={getFormikMessageError(formik, 'passport')}
          />
        </ChildrenFormRow>
      )}

      {formik.values.nationalityOption?.value !==
        nationalityOptions[2].value && (
        <>
          <GrayLine />
          <ChildrenFormRow>
            <Select
              label="UF de nascimento"
              options={stateOptions}
              required
              disabled={statesLoading}
              value={formik.values.birthStateOption}
              onChange={(option, _) =>
                formik.setFieldValue('birthStateOption', option)
              }
              messageError={getFormikMessageError(formik, 'birthStateOption')}
            />
            <Select
              label="Município de nascimento"
              options={cityOptions}
              required
              disabled={citiesLoading}
              value={formik.values.birthCityOption}
              onChange={(option, _) =>
                formik.setFieldValue('birthCityOption', option)
              }
              messageError={getFormikMessageError(formik, 'birthCityOption')}
            />
          </ChildrenFormRow>
        </>
      )}

      <GrayLine />

      <ChildrenFormRow>
        <Input
          label="Contato Telefônico"
          placeholder="(83) 0000-0000"
          required
          onBlur={formik.handleBlur}
          value={formik.values.phone}
          onChange={evt =>
            formik.setFieldValue('phone', normalizePhoneValue(evt.target.value))
          }
          name="phone"
          maxLength={15}
          messageError={getFormikMessageError(formik, 'phone')}
        />
        <Input
          label="E-mail"
          placeholder="Digite seu email"
          required
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          name="email"
          disabled={!!userPersonalInfo?.email || !!studentFormData?.email}
          messageError={getFormikMessageError(formik, 'email')}
        />
      </ChildrenFormRow>

      <GrayLine />

      <OneLine>
        <SelectLabel>
          O(a) aluno(a) possui deficiência, transtorno do espectro autista ou
          altas habilidades/superdotação?*
        </SelectLabel>
        <SelectContainer>
          <Select
            label=""
            options={binaryOptions}
            value={formik.values.handicappedOption}
            onChange={(option, _) =>
              formik.setFieldValue('handicappedOption', option)
            }
            messageError={getFormikMessageError(formik, 'handicappedOption')}
          />
        </SelectContainer>
      </OneLine>
      {formik.values.handicappedOption?.value && (
        <OneLine>
          <SelectLabel>
            Tipo de deficiência, transtorno do espectro autista ou altas
            habilidades/superdotação*
          </SelectLabel>
          <SelectContainer>
            <Select
              label=""
              options={disabilityOptions}
              value={formik.values.handicappedTypeOption}
              onChange={(option, _) =>
                formik.setFieldValue('handicappedTypeOption', option)
              }
              messageError={getFormikMessageError(
                formik,
                'handicappedTypeOption',
              )}
            />
          </SelectContainer>
        </OneLine>
      )}
      {formik.values.handicappedOption?.value && (
        <OneLine>
          <SelectLabel>
            Selecione os recursos para uso do(a) estudante em sala de aula e/ou
            para participação em avaliações*
          </SelectLabel>
          <SelectContainer>
            <Select
              label=""
              options={studentAdditionalResourceOptions}
              value={formik.values.extraResourceOption}
              onChange={(option, _) => {
                const enabled =
                  (option as Option[]).find(
                    (item, index) =>
                      item.value === 'Nenhum' && index === option.length - 1,
                  ) !== undefined;
                const optionFiltered = (option as Option[]).filter(
                  item => item.value !== 'Nenhum',
                );
                if (enabled) {
                  formik.setFieldValue('extraResourceOption', [
                    studentAdditionalResourceOptions[0],
                  ]);
                } else {
                  formik.setFieldValue('extraResourceOption', optionFiltered);
                }
              }}
              messageError={getFormikMessageError(
                formik,
                'extraResourceOption',
              )}
              isMulti
            />
          </SelectContainer>
        </OneLine>
      )}

      <GrayLine />

      <OneLine>
        <SelectLabel>Estudante em situação de itinerância?*</SelectLabel>
        <SelectContainer>
          <Select
            label=""
            options={binaryOptions}
            value={formik.values.itinerantOption}
            onChange={(option, _) =>
              formik.setFieldValue('itinerantOption', option)
            }
            messageError={getFormikMessageError(formik, 'itinerantOption')}
          />
        </SelectContainer>
      </OneLine>
      <OneLine>
        <SelectLabel>Pertence a povo ou comunidade tradicional?*</SelectLabel>
        <SelectContainer>
          <Select
            label=""
            options={communityOptions}
            value={formik.values.specialCommunityOption}
            onChange={(option, _) =>
              formik.setFieldValue('specialCommunityOption', option)
            }
            messageError={getFormikMessageError(
              formik,
              'specialCommunityOption',
            )}
          />
        </SelectContainer>
      </OneLine>

      <GrayLine />
      <OneLine>
        <SelectLabel>
          Estudante é uma Pessoa Privada de Liberdade (PPL)?*
        </SelectLabel>
        <SelectContainer>
          <Select
            label=""
            options={binaryOptions}
            value={formik.values.pplOption}
            onChange={(option, _) => {
              if (option && option.value) {
                onSelectPPLOption();
              } else {
                formik.setFieldValue('pplOption', option);
              }
            }}
            messageError={getFormikMessageError(formik, 'pplOption')}
          />
        </SelectContainer>
      </OneLine>
      <GrayLine />

      <BodySemiBold>Fardamento</BodySemiBold>

      <ChildrenFormRow>
        <Select
          label="Tamanho Blusa"
          options={clothingSize}
          required
          value={formik.values.shirtSizeOption}
          onChange={(option, _) =>
            formik.setFieldValue('shirtSizeOption', option)
          }
          messageError={getFormikMessageError(formik, 'shirtSizeOption')}
        />
      </ChildrenFormRow>
      <ChildrenFormRow>
        <Select
          label="Tamanho Bermuda"
          options={clothingSize}
          required
          value={formik.values.shortsSizeOption}
          onChange={(option, _) =>
            formik.setFieldValue('shortsSizeOption', option)
          }
          messageError={getFormikMessageError(formik, 'shortsSizeOption')}
        />
      </ChildrenFormRow>

      <StepsButtonContainer>
        {editing && (
          <Button
            title="Cancelar"
            type="button"
            styled="secondary"
            onClick={onBack}
            size="mini"
          />
        )}
        <Button
          iconRight={editing ? undefined : IconArrowRight}
          type="button"
          size="mini"
          title={editing ? 'Atualizar' : 'Próximo'}
          form="student-form-id"
          onClick={formik.submitForm}
          disabled={loading}
        />
      </StepsButtonContainer>
    </ChildrenFormContainer>
  );
};

export default GeneralInfoForm;
