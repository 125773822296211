import styled from 'styled-components';

import { BodySemiBold } from 'components/StyledTypography/styles';

export const Container = styled.div``;

export const ContentContainer = styled.div`
  margin-top: 27px;
  padding-bottom: 24px;
  width: 100%;
`;

export const FormTitle = styled(BodySemiBold)``;

export const ContainerCheckBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 26px;
`;

export const ContainerCheck = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 26px 0;
`;

export const ContainerLanguages = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 31px;
`;

export const Line = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey3};
  width: 100%;
  margin: 27px 0 24px 0;
`;

export const AddSiblingButton = styled.button`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 100%;

  align-self: center;
  color: ${({ theme }) => theme.palette.primaryLight};

  &:hover {
    filter: brightness(0.8);
  }
`;

export const RemoveSiblingButton = styled.button`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 100%;

  color: ${({ theme }) => theme.palette.negative};

  &:hover {
    filter: brightness(0.8);
  }
`;

export const ChildrenSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
  white-space: nowrap;
`;

export const ChildrenFormRowSiblings = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
  margin-top: 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
`;
