import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 22px;
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: 0fr 1fr;
  grid-gap: 6px;
  align-self: left;
`;

export const IconImage = styled.img`
  max-width: 30px;
`;

export const Text = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #888888;
  text-align: left;
`;
