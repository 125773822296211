import { all } from 'redux-saga/effects';

import auth from './auth';
import cities from './cities';
import enrollment from './enrollment';
import followUp from './followUp';
import newEnrollment from './newEnrollment';
import schoolFilters from './schoolFilters';
import states from './states';
import documentsStudent from './students/documentsStudent';
import studentGeneralInfo from './students/general';
import residentialArea from './students/residentialArea';
import responsibleData from './students/responsableData';
import saber from './students/saber';
import studentSchool from './students/school';
import uploadStudentFile from './students/uploadFiles';
import transfer from './transfer';

export default function* rootSaga() {
  yield all([
    auth,
    studentGeneralInfo,
    documentsStudent,
    states,
    cities,
    responsibleData,
    residentialArea,
    enrollment,
    uploadStudentFile,
    schoolFilters,
    followUp,
    newEnrollment,
    saber,
    transfer,
    studentSchool,
  ]);
}
