import { useNavigate } from 'react-router-dom';

import { ArrowLeft } from 'assets/icons';
import { ButtonLabel } from 'components/StyledTypography/styles';

import {
  Container,
  Title,
  ButtonContainer,
  ItemCenter,
  ItemSide,
  Content,
} from './styles';

interface HeaderNavigationProps {
  title: string;
  hidden: boolean;
  hiddenBackButton: boolean;
  navBackTitle: string;
  navigateTo: any;
}

const HeaderNavigation = ({
  title,
  navBackTitle,
  hiddenBackButton,
  hidden,
  navigateTo,
}: HeaderNavigationProps) => {
  const navigate = useNavigate();

  return hidden ? null : (
    <Container>
      <Content>
        <ItemSide>
          {!hiddenBackButton && (
            <ButtonContainer>
              <ArrowLeft />
              <ButtonLabel onClick={() => navigate(navigateTo)}>
                {navBackTitle}
              </ButtonLabel>
            </ButtonContainer>
          )}
        </ItemSide>
        <ItemCenter />
        <Title>{title}</Title>
      </Content>
    </Container>
  );
};

export default HeaderNavigation;
