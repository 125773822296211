import styled from 'styled-components';

export const H1 = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 100%;
`;

export const H2 = styled.h2`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 112%;
`;

export const H3 = styled.h3`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: 0.2px;
`;

export const BodyBold = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: 0.2px;
`;

export const BodySemiBold = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.2px;
`;

export const BodyRegular = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.2px;
`;

export const ButtonLabel = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0.2px;
`;

export const Caption = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 114%;
  letter-spacing: 0.2px;
`;

export const LabelExtraBold = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.2px;
`;

export const LabelSemiBold = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.2px;
`;

export const LabelRegular = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.2px;
`;
