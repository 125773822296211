/* eslint-disable react/require-default-props */
import React from 'react';

import { Container, Content, Message } from './styles';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  messageError?: string;
  hint?: string;
}

const Checkbox = ({
  label,
  messageError = '',
  hint = '',
  disabled,
  ...rest
}: CheckboxProps) => {
  const error = !!messageError;
  return (
    <Container>
      <Content>
        <div id="text">{label}</div>
        <input type="checkbox" {...rest} disabled={disabled} />
        <span />
      </Content>
      {!error ? (
        <Message error={error}>{hint}</Message>
      ) : (
        <Message error={error}>{messageError}</Message>
      )}
    </Container>
  );
};
export default Checkbox;
