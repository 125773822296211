/* eslint-disable import/order */
import { SERVER_KEY } from 'config/envs';
import { ResidentalArea } from 'models/student';
import { useSelector } from 'react-redux';
import base from 'services/api/base';
import { store } from 'store';

import {
  CreateResidentialAreaRequestData,
  UpdateResidentialAreaRequestData,
  UpdateResidentialAreaResponseData,
  FetchResidentialAreaResponseData,
} from './models';
import { ServiceEndpoints } from 'services/api/types';

const Strings = {
  DEFAULT_ERROR_RESIDENTIAL_AREA: 'Não foi possível salvar o endereço',
};

export const ResidentialAreaDataEndpoints: ServiceEndpoints = {
  createResidentialArea: {
    path: `students`,
    errorMessages: {
      204: 'Dados do estudante atualizado',
      400: 'Problema nos dados',
      401: 'Alteração não permitida',
      403: 'Você não tem permissão para alterar os dados',
      default: Strings.DEFAULT_ERROR_RESIDENTIAL_AREA,
    },
  },
  updateResidentialArea: {
    path: 'students',
    errorMessages: {
      204: 'Dados do estudante atualizado',
      400: 'Problema nos dados',
      401: 'Alteração não permitida',
      403: 'Você não tem permissão para alterar os dados',
      default: Strings.DEFAULT_ERROR_RESIDENTIAL_AREA,
    },
  },
  fetchResidentialArea: {
    path: 'students',
    errorMessages: {
      204: 'Dados do estudante atualizado',
      400: 'Problema nos dados',
      401: 'Alteração não permitida',
      403: 'Você não tem permissão para alterar os dados',
      default: Strings.DEFAULT_ERROR_RESIDENTIAL_AREA,
    },
  },
};

async function createResidentialArea(
  studentId: number,
  residentialArea: CreateResidentialAreaRequestData,
): Promise<CreateResidentialAreaRequestData> {
  const endpoint = ResidentialAreaDataEndpoints.createResidentialArea;
  const response = await base.api.post<CreateResidentialAreaRequestData>(
    `${endpoint.path}/${studentId}/addresses`,
    residentialArea,
  );

  return response.data;
}

async function updateResidentialArea(
  studentId: number,
  residentialArea: UpdateResidentialAreaRequestData,
): Promise<UpdateResidentialAreaResponseData> {
  const endpoint = ResidentialAreaDataEndpoints.updateResidentialArea;
  const response = await base.api.patch<UpdateResidentialAreaResponseData>(
    `${endpoint.path}/${studentId}/addresses`,
    residentialArea,
  );

  return response.data;
}

async function fetchResidentialArea(
  studentId: number,
): Promise<FetchResidentialAreaResponseData> {
  const endpoint = ResidentialAreaDataEndpoints.fetchResidentialArea;
  const response = await base.api.get<FetchResidentialAreaResponseData>(
    `${endpoint.path}/${studentId}/addresses`,
  );

  return response.data;
}

export const StudentService = {
  createResidentialArea,
  updateResidentialArea,
  fetchResidentialArea,
};
