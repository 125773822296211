/* eslint-disable import/order */
import { toast } from 'react-toastify';
import { all, put, takeLatest, select } from 'redux-saga/effects';

import { mapErrorToResponseError } from 'services/api/responseErrorHandler';
import {
  ResidentialAreaDataEndpoints,
  StudentService,
} from 'services/api/students/residentialArea';
import { RootState, store } from 'store';
import { ResidentialAreaCreators } from 'store/ducks/students/residentialArea';
import { StepNavigationCreators } from 'store/ducks/stepNavigation';
import { Identifier } from 'models/identifier';
import { ResidentalArea } from 'models/student';
import {
  mapToCreateResidentialAreaRequestData,
  mapToResidentialAreaRequestToResidentialAreaForm,
} from 'services/api/students/residentialArea/mapper';
import { FetchResidentialAreaRequestData } from 'services/api/students/residentialArea/models';

export const getUserInfoId = ({ auth }: RootState) => auth.userPersonalInfo?.id;

function* createResidentialArea(
  action: ReturnType<
    typeof ResidentialAreaCreators.createResidentialArea.request
  >,
): Generator<unknown, void, ResidentalArea & number> {
  try {
    const userId = yield select(getUserInfoId);

    const data = action.payload;
    const response = yield StudentService.createResidentialArea(
      userId,
      mapToCreateResidentialAreaRequestData(data),
    );
    yield put(ResidentialAreaCreators.createResidentialArea.success(data));
    yield put(StepNavigationCreators.isCompleted(true));
  } catch (error) {
    const responseError = mapErrorToResponseError(error);
    toast.error(responseError.message);
    yield put(
      ResidentialAreaCreators.createResidentialArea.failure(
        responseError.message,
      ),
    );
  }
}

function* fetchResidentialArea(): Generator<
  unknown,
  void,
  FetchResidentialAreaRequestData & number
> {
  try {
    const id: number = yield select(getUserInfoId);

    const response = yield StudentService.fetchResidentialArea(id);
    yield put(
      ResidentialAreaCreators.fetchResidentialArea.success(
        mapToResidentialAreaRequestToResidentialAreaForm(
          response,
        ) as ResidentalArea,
      ),
    );
  } catch (error) {
    const responseError = mapErrorToResponseError(error);
    yield put(
      ResidentialAreaCreators.fetchResidentialArea.failure(
        responseError.message,
      ),
    );
  }
}

function* updateResidentialArea(
  action: ReturnType<
    typeof ResidentialAreaCreators.updateResidentialArea.request
  >,
): Generator<unknown, void, ResidentalArea & number> {
  try {
    const id = yield select(getUserInfoId);

    yield StudentService.updateResidentialArea(
      id,
      mapToCreateResidentialAreaRequestData(action.payload),
    );
    yield put(
      ResidentialAreaCreators.updateResidentialArea.success(action.payload),
    );

    yield put(StepNavigationCreators.isCompleted(true));
  } catch (error) {
    const responseError = mapErrorToResponseError(error);
    toast.error(responseError.message);
    yield put(
      ResidentialAreaCreators.updateResidentialArea.failure(
        responseError.message,
      ),
    );
  }
}

export default all([
  takeLatest(
    ResidentialAreaCreators.createResidentialArea.request,
    createResidentialArea,
  ),
  takeLatest(
    ResidentialAreaCreators.updateResidentialArea.request,
    updateResidentialArea,
  ),
  takeLatest(
    ResidentialAreaCreators.fetchResidentialArea.request,
    fetchResidentialArea,
  ),
]);
