import styled from 'styled-components';

import {
  ButtonLabel,
  Caption,
  H2,
  H3,
} from 'components/StyledTypography/styles';

interface ProgressContentProps {
  progress: number;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.palette.grey4};
  min-height: 100vh;
`;

export const ContainerForm = styled.div`
  padding: 0 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ProgressContainer = styled.div`
  width: 100%;
  height: 8px;
  background: ${({ theme }) => theme.palette.grey3};
`;

export const ProgressContent = styled.div<ProgressContentProps>`
  width: ${({ progress }) => progress}%;
  height: 8px;
  background: ${({ theme }) => theme.palette.accent};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 48px 0 105px 0;

  max-width: 980px;
  width: 100%;
  background: ${({ theme }) => theme.palette.white};
  border-radius: 4px;
  box-shadow: 0px 2px 14px -2px rgba(0, 0, 0, 0.15);
`;

export const FormContainer = styled.div`
  border-left: 1px solid #d5d5d5;
  padding: 32px;
  width: 100%;
  hr {
    margin: 24px 0px;
    border: 1px solid #e0e0e0;
    width: 100%;
  }
`;

export const SideStepsContainer = styled.div`
  padding: 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    display: none;
  }
`;

export const StepsTitle = styled(H3)`
  margin-bottom: 24px;
`;

export const FormTitle = styled(H2)`
  margin-bottom: 20px;
`;

export const FormSubtitle = styled(Caption)`
  color: ${({ theme }) => theme.palette.grey1};
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 0px;
  min-width: 230px;
`;

export const CurrentStepLabel = styled(ButtonLabel)``;

export const StepLabel = styled(Caption)``;

export const StepIcon = styled.img`
  margin-right: 8px;
  width: 16px;
  height: 16px;
`;

export const CurrentStepIcon = styled.img``;

export const ChildrenFormContainer = styled.form`
  height: 100%;
  width: 100%;
`;

export const ChildrenFormRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
  margin-top: 24px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    grid-template-columns: 1fr;
  }
`;

export const GrayLine = styled.div`
  margin: 24px 0px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey3};
`;

export const StepsButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
  margin-top: 24px;
`;
