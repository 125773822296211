import produce, { Draft } from 'immer';
import { ActionType, createAsyncAction, Reducer } from 'typesafe-actions';

import { FollowUpGetResponseData } from 'services/api/followUp/models';

export enum Types {
  FOLLOW_UP_REQUEST = '@followUp/FOLLOW_UP_REQUEST',
  FOLLOW_UP_SUCCESS = '@followUp/FOLLOW_UP_SUCCESS',
  FOLLOW_UP_FAILURE = '@followUp/FOLLOW_UP_FAILURE',
}

export type FollowUp = FollowUpGetResponseData;

export interface FollowUpState {
  loading: boolean;
  followUp: FollowUp[];
}

const INITIAL_STATE: FollowUpState = {
  loading: false,
  followUp: [],
};

export const FollowUpCreators = {
  getFollowUp: createAsyncAction(
    Types.FOLLOW_UP_REQUEST,
    Types.FOLLOW_UP_SUCCESS,
    Types.FOLLOW_UP_FAILURE,
  )<void, FollowUp[], string>(),
};

export type ActionTypes = ActionType<typeof FollowUpCreators>;

const reducer: Reducer<FollowUpState, ActionTypes> = (
  state = INITIAL_STATE,
  action: ActionTypes,
) => {
  const { type, payload } = action;

  return produce(state, (draft: Draft<FollowUpState>) => {
    switch (type) {
      case Types.FOLLOW_UP_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.FOLLOW_UP_SUCCESS: {
        draft.loading = false;
        draft.followUp = payload as FollowUp[];
        break;
      }
      case Types.FOLLOW_UP_FAILURE: {
        draft.loading = false;
        break;
      }

      default:
    }
  });
};

export default reducer;
