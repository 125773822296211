/* eslint-disable import/order */
import produce, { Draft } from 'immer';
import { Reducer } from 'redux';
import { ActionType, createAsyncAction } from 'typesafe-actions';
import { Identifier } from 'models/identifier';
import { UploadStudentFiles, Keys as FileTypes } from 'models/student';
import { FetchUploadStudentFilesResponse } from 'services/api/students/uploadStudentFile/models';

export enum Types {
  CREATE_UPLOAD_FILE_REQUEST = '@uploadFile/CREATE_UPLOAD_FILE_REQUEST',
  CREATE_UPLOAD_FILE_SUCCESS = '@uploadFile/CREATE_UPLOAD_FILE_SUCCESS',
  CREATE_UPLOAD_FILE_FAILURE = '@uploadFile/CREATE_UPLOAD_FILE_FAILURE',
  UPDATE_UPLOAD_FILE_REQUEST = '@uploadFile/UPDATE_UPLOAD_FILE_REQUEST',
  UPDATE_UPLOAD_FILE_SUCCESS = '@uploadFile/UPDATE_UPLOAD_FILE_SUCCESS',
  UPDATE_UPLOAD_FILE_FAILURE = '@uploadFile/UPDATE_UPLOAD_FILE_FAILURE',
  FETCH_UPLOAD_FILE_REQUEST = '@uploadFile/FETCH_UPLOAD_FILE_REQUEST',
  FETCH_UPLOAD_FILE_SUCCESS = '@uploadFile/FETCH_UPLOAD_FILE_SUCCESS',
  FETCH_UPLOAD_FILE_FAILURE = '@uploadFile/FETCH_UPLOAD_FILE_FAILURE',
  DELETE_UPLOAD_FILE_REQUEST = '@uploadFile/DELETE_UPLOAD_FILE_REQUEST',
  DELETE_UPLOAD_FILE_SUCCESS = '@uploadFile/DELETE_UPLOAD_FILE_SUCCESS',
  DELETE_UPLOAD_FILE_FAILURE = '@uploadFile/DELETE_UPLOAD_FILE_FAILURE',
}

export type UploadStudentFilesResponse = UploadStudentFiles;
export interface UpdateUploadStudentFilesResponse
  extends Omit<UploadStudentFiles, 'type'> {
  attachmentsId: number;
}

export interface UpdateUploadStudentFilesDeleteRequest {
  attachmentsId: number;
  type: FileTypes;
}

export interface UploadStudentFileState {
  loading: boolean;
  studentsFiles: FetchUploadStudentFilesResponse;
  studentFile: UploadStudentFilesResponse;
  studentId: number;
}

const INITIAL_STATE: UploadStudentFileState = {
  loading: false,
  studentFile: {} as UploadStudentFilesResponse,
  studentsFiles: { records: [] } as FetchUploadStudentFilesResponse,
  studentId: -1,
};

export const UploadStudentFileCreators = {
  createUploadStudentFile: createAsyncAction(
    Types.CREATE_UPLOAD_FILE_REQUEST,
    Types.CREATE_UPLOAD_FILE_SUCCESS,
    Types.CREATE_UPLOAD_FILE_FAILURE,
  )<UploadStudentFilesResponse, Identifier, string>(),
  updateUploadStudentFile: createAsyncAction(
    Types.UPDATE_UPLOAD_FILE_REQUEST,
    Types.UPDATE_UPLOAD_FILE_SUCCESS,
    Types.UPDATE_UPLOAD_FILE_FAILURE,
  )<UpdateUploadStudentFilesResponse, void, string>(),
  fetchUploadStudentFile: createAsyncAction(
    Types.FETCH_UPLOAD_FILE_REQUEST,
    Types.FETCH_UPLOAD_FILE_SUCCESS,
    Types.FETCH_UPLOAD_FILE_FAILURE,
  )<void, FetchUploadStudentFilesResponse, string>(),
  deleteUploadStudentFile: createAsyncAction(
    Types.DELETE_UPLOAD_FILE_REQUEST,
    Types.DELETE_UPLOAD_FILE_SUCCESS,
    Types.DELETE_UPLOAD_FILE_FAILURE,
  )<UpdateUploadStudentFilesDeleteRequest, void, string>(),
};

export type ActionTypes = ActionType<typeof UploadStudentFileCreators>;

const reducer: Reducer<UploadStudentFileState, ActionTypes> = (
  state = INITIAL_STATE,
  action: ActionTypes,
) => {
  const { type, payload } = action;

  return produce(state, (draft: Draft<UploadStudentFileState>) => {
    switch (type) {
      case Types.FETCH_UPLOAD_FILE_REQUEST:
      case Types.DELETE_UPLOAD_FILE_REQUEST:
        draft.loading = true;
        break;
      case Types.FETCH_UPLOAD_FILE_SUCCESS:
        draft.loading = false;
        draft.studentsFiles = payload as FetchUploadStudentFilesResponse;
        break;
      case Types.CREATE_UPLOAD_FILE_REQUEST:
      case Types.UPDATE_UPLOAD_FILE_REQUEST:
        draft.loading = true;
        draft.studentFile = payload as UploadStudentFilesResponse;
        break;

      case Types.CREATE_UPLOAD_FILE_SUCCESS:
        draft.loading = false;
        draft.studentId = (payload as unknown as Identifier).id as number;
        break;
      case Types.CREATE_UPLOAD_FILE_FAILURE:
      case Types.UPDATE_UPLOAD_FILE_SUCCESS:
      case Types.DELETE_UPLOAD_FILE_SUCCESS:
      case Types.DELETE_UPLOAD_FILE_FAILURE:
      case Types.UPDATE_UPLOAD_FILE_FAILURE:
      case Types.FETCH_UPLOAD_FILE_FAILURE:
        draft.loading = false;
        break;
      default:
    }
  });
};

export default reducer;
