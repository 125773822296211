import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { PeriodoImg } from 'assets/images';
import { Button } from 'components';
import {
  Container,
  ContainerButtons,
  Content,
  Title,
  SubTitle,
} from 'pages/StudentEnrollmentConfirmation/styles';
import { FollowUpCreators } from 'store/ducks/followUp';

const TransferConfirmation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(FollowUpCreators.getFollowUp.request());
  }, [dispatch]);

  return (
    <Container>
      <Content>
        <img src={PeriodoImg} alt="" />
        <Title>Transferência solicitada</Title>
        <SubTitle>
          {location.state.isStateTransfer
            ? `Você precisa aguardar até que o sistema esteja aberto para novas
          matrículas para escolher uma outra escola.`
            : `Entendemos que você está migrando para outra rede de ensino.
          Informe-se com a rede de destino.`}
        </SubTitle>
        <ContainerButtons>
          <Button
            size="medium"
            styled="secondary"
            title="Página inicial"
            onClick={() => navigate('/home')}
          />
        </ContainerButtons>
      </Content>
    </Container>
  );
};

export default TransferConfirmation;
