import { IconEdit } from 'assets/icons';
import { Button } from 'components';
import { H3 } from 'components/StyledTypography/styles';
import { GrayLine } from 'pages/StudentEnrollment/styles';

import { Container, Header } from './styles';

interface HeaderSectionProps {
  title: string;
  onEditAction: () => void;
  editable: boolean;
}

const HeaderSection = ({
  title,
  onEditAction,
  editable,
}: HeaderSectionProps) => (
  <Container>
    <GrayLine />
    <Header>
      <H3>{title}</H3>
      {editable && (
        <Button
          iconLeft={IconEdit}
          title="Editar"
          type="button"
          styled="secondary"
          onClick={onEditAction}
          size="mini"
        />
      )}
    </Header>
  </Container>
);

export default HeaderSection;
