import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

import { Footer, Header } from 'components';
import { ForgotPasswordProvider } from 'hooks/ForgotPassword';
import { RecaptchaProvider } from 'hooks/ReCaptchaContext';
import { RootState } from 'store';

import { Container } from './styles';

const Auth = () => {
  const navigate = useNavigate();
  const from = '/home';
  const { userAuthInfo } = useSelector(({ auth }: RootState) => auth);

  useEffect(() => {
    if (userAuthInfo) {
      // Send them back to the page they tried to visit when they were
      // redirected to the login page. Use { replace: true } so we don't create
      // another entry in the history stack for the login page.  This means that
      // when they get to the protected page and click the back button, they
      // won't end up back on the login page, which is also really nice for the
      // user experience.
      navigate(from, { replace: true });
    }
  }, [userAuthInfo, navigate, from]);

  return (
    <ForgotPasswordProvider>
      <RecaptchaProvider>
        <Container>
          <Header menuItemVariant="none" title="Matrícula 2023 - Estudante" />
          <Outlet />
          <Footer />
        </Container>
      </RecaptchaProvider>
    </ForgotPasswordProvider>
  );
};

export default Auth;
