import styled from 'styled-components';

import {
  LabelRegular,
  LabelSemiBold,
} from 'components/StyledTypography/styles';

import { BannerProps } from './index';

// interface TypeBanner {
//   variant?: BannerProps.variant;
// }

export const BannerContainer = styled.div<BannerProps>`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  border-radius: 4px;
  margin-top: 16px;
  background: ${({ variant, theme }) =>
    variant === 'warning'
      ? theme.palette.warning
      : theme.palette.primaryLighter};
`;

export const BannerText = styled(LabelRegular)<BannerProps>`
  color: ${({ variant, theme }) =>
    variant === 'warning' ? theme.palette.black : theme.palette.primary};
  font-weight: ${({ variant }) => (variant === 'warning' ? 600 : LabelRegular)};
  flex: 1;
  margin-top: 3px;
  padding: 0px 14px;
`;

export const CloseButton = styled.div`
  cursor: pointer;
`;
