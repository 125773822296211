import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  flex: 1;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.white};
`;

export const Content = styled.div`
  display: flex;
  max-width: ${({ theme }) => theme.breakpoints.xl}px;
  flex-direction: row;
  width: 100%;
  max-width: 583px;
`;
