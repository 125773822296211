/* eslint-disable react/prop-types */
import { useState } from 'react';

import {
  IconCloseBlack,
  IconCloseBlue,
  IconWarningBlack,
  IconWarningBlue,
} from 'assets/icons';

import { BannerContainer, BannerText, CloseButton } from './styles';

export interface BannerProps {
  message?: string;
  variant?: 'warning' | 'info';
  onClick?: () => void;
}

const Banner: React.FC<BannerProps> = ({ message, variant, onClick }) => {
  const [showPopup, setShowPopup] = useState(false);
  const toggleShowInfoPopup = () => {
    setShowPopup(!showPopup);
  };
  return (
    <>
      {!showPopup === true && variant === 'warning' && (
        <BannerContainer variant="warning">
          <IconWarningBlack style={{ width: '17px', height: '17px' }} />
          <BannerText variant="warning">{message}</BannerText>
          {/* <CloseButton onClick={toggleShowInfoPopup}>
            <IconCloseBlack
              style={{ cursor: 'pointer', width: '13px', height: '13px' }}
            />
          </CloseButton> */}
        </BannerContainer>
      )}
      {!showPopup === true && variant === 'info' && (
        <BannerContainer>
          <IconWarningBlue style={{ width: '17px', height: '17px' }} />
          <BannerText>{message}</BannerText>
          <CloseButton onClick={toggleShowInfoPopup}>
            <IconCloseBlue
              style={{ cursor: 'pointer', width: '13px', height: '13px' }}
              onClick={onClick}
            />
          </CloseButton>
        </BannerContainer>
      )}
    </>
  );
};

export default Banner;
