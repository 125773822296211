import styled from 'styled-components';

import { Button } from 'components';
import { H2, BodyRegular } from 'components/StyledTypography/styles';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 30px;
  background: #ffffff;
  border: 1px solid #c1c1c1;
  border-radius: 6px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    align-items: flex-start;
  }
`;

export const HeaderImage = styled.img<DisabledProp>`
  min-height: 165px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: none;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const IconImage = styled.img`
  height: 18px;
  width: 18px;
  object-fit: contain;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: none;
  }
`;

interface DisabledProp {
  disabled: boolean;
}

export const Title = styled(H2)<DisabledProp>`
  color: ${({ theme, disabled }) =>
    disabled ? '#636161' : theme.palette.black};
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 112%;
`;

export const Description = styled(BodyRegular)<DisabledProp>`
  color: ${({ theme, disabled }) =>
    disabled ? '#636161' : theme.palette.black};
  margin-bottom: 20px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 135%;

  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    text-align: start;
    margin-bottom: 0;
  }
`;

export const FullButton = styled(Button)`
  width: 100%;
`;
