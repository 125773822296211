import { isNil, omitBy } from 'lodash';

import { countryOptions, differentiatedLocationOptions } from 'assets/json';
import { Option } from 'components/Select';
import { ResidentalArea } from 'models/student';
import { ResidentalAreaRequest } from 'store/ducks/students/residentialArea';
import {
  changeFieldsFromUndefinedToNull,
  changeFieldsEmptyToUndefined,
} from 'utils/objectUtils';
import { unwrapOptionValue, wrapOptionValue } from 'utils/wrapperUtils';

import {
  CreateResidentialAreaRequestData,
  FetchResidentialAreaResponseData,
} from './models';

export function mapToCreateResidentialAreaRequestData(
  residentialArea: ResidentalArea,
): CreateResidentialAreaRequestData {
  return changeFieldsFromUndefinedToNull(
    changeFieldsEmptyToUndefined({
      zipcode: residentialArea.zipcode,
      street: residentialArea.street,
      number: residentialArea.number,
      cityId: unwrapOptionValue(residentialArea.cityId || ({} as Option)),
      district: residentialArea.district,
      zone: residentialArea.zone,
      complement: residentialArea.complement,
      country: unwrapOptionValue(residentialArea.country || ({} as Option)),
      stateId: unwrapOptionValue(residentialArea.stateId || ({} as Option)),
      neighborhood: residentialArea.neighborhood,
      differentiatedLocation: unwrapOptionValue(
        residentialArea.differentiatedLocation || ({} as Option),
      ),
    }),
  );
}

export function mapToResidentialAreaRequestToResidentialAreaForm(
  requestData: FetchResidentialAreaResponseData,
): Partial<ResidentalAreaRequest> {
  const cityOption: Option[] = [
    {
      label: requestData.city && requestData.city.name,
      value: requestData.city && requestData.city.id,
    },
  ];
  const stateOption: Option[] = [
    {
      label:
        requestData.city &&
        requestData.city.state &&
        requestData.city.state.name,
      value:
        requestData.city && requestData.city.state && requestData.city.state.id,
    },
  ];

  return omitBy(
    {
      ...requestData,
      cityId: wrapOptionValue(cityOption, requestData.cityId),
      country: wrapOptionValue(countryOptions, requestData.country?.trim()),
      differentiatedLocation: wrapOptionValue(
        differentiatedLocationOptions,
        requestData.differentiatedLocation,
      ),
      neighborhood: requestData.neighborhood,
      stateId: wrapOptionValue(stateOption, requestData.stateId),
      zone: requestData.zone,
    },
    isNil,
  );
}
