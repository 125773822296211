import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { all, takeLatest, put, select } from 'redux-saga/effects';

import { LAST_ENROLLMENT_YEAR } from 'config/constants';
import { StudentFormData, StudentType } from 'models/student';
import { EnrollmentService } from 'services/api/enrollment';
import { FetchOverviewEnrollmentResponseData } from 'services/api/enrollment/models';
import { mapErrorToResponseError } from 'services/api/responseErrorHandler';
import { StudentGeneralService } from 'services/api/students/general';
import {
  mapGeneralStudentFormToGeneralStudentRequest,
  mapGeneralStudentResponseToGeneralStudentForm,
} from 'services/api/students/general/mapper';
import { FetchGeneralStudentRequestData } from 'services/api/students/general/models';
import { RootState } from 'store';
import { StepNavigationCreators } from 'store/ducks/stepNavigation';
import { GeneralStudentCreators } from 'store/ducks/students/general';

export const getUserInfoId = ({ auth }: RootState) => auth.userPersonalInfo?.id;

function* createGeneralStudent(
  action: ReturnType<
    typeof GeneralStudentCreators.createGeneralStudent.request
  >,
): Generator<unknown, void, StudentFormData & number> {
  try {
    const id: number = yield select(getUserInfoId);

    yield StudentGeneralService.createGeneralStudent(
      id,
      mapGeneralStudentFormToGeneralStudentRequest(action.payload),
    );
    yield put(
      GeneralStudentCreators.createGeneralStudent.success(action.payload),
    );

    yield put(StepNavigationCreators.isCompleted(true));
  } catch (error) {
    const responseError = mapErrorToResponseError(error);
    toast.error(responseError.message);
    yield put(
      GeneralStudentCreators.createGeneralStudent.failure(
        responseError.message,
      ),
    );
  }
}

function* fetchGeneralStudent(): Generator<
  unknown,
  void,
  FetchGeneralStudentRequestData & number
> {
  try {
    const id: number = yield select(getUserInfoId);
    const response = yield StudentGeneralService.fetchGeneralStudent(id);
    const enrollments = (yield EnrollmentService.fetchOverviewEnrollment(
      id,
    )) as FetchOverviewEnrollmentResponseData[];
    let responseData = mapGeneralStudentResponseToGeneralStudentForm(
      response,
    ) as StudentFormData;

    const overviewLastEnrollment = enrollments.find(
      item => item.year === LAST_ENROLLMENT_YEAR,
    );

    if (
      responseData.type === StudentType.VETERAN &&
      !overviewLastEnrollment?.newEnrollment &&
      !overviewLastEnrollment?.enrollment
    ) {
      yield StudentGeneralService.updateGeneralStudent(
        id,
        mapGeneralStudentFormToGeneralStudentRequest({
          ...responseData,
          type: StudentType.BEGINNER,
        }),
      );
      responseData = { ...responseData, type: StudentType.BEGINNER };
    } else if (
      responseData.type === StudentType.BEGINNER &&
      (overviewLastEnrollment?.newEnrollment === true ||
        overviewLastEnrollment?.enrollment === true)
    ) {
      yield StudentGeneralService.updateGeneralStudent(
        id,
        mapGeneralStudentFormToGeneralStudentRequest({
          ...responseData,
          type: StudentType.VETERAN,
        }),
      );
      responseData = { ...responseData, type: StudentType.VETERAN };
    }

    yield put(GeneralStudentCreators.fetchGeneralStudent.success(responseData));
  } catch (error) {
    const responseError = mapErrorToResponseError(error);

    yield put(
      GeneralStudentCreators.fetchGeneralStudent.failure(responseError.message),
    );
  }
}

function* updateGeneralStudent(
  action: ReturnType<
    typeof GeneralStudentCreators.updateGeneralStudent.request
  >,
): Generator<unknown, void, StudentFormData & number> {
  try {
    const id: number = yield select(getUserInfoId);

    yield StudentGeneralService.updateGeneralStudent(
      id,
      mapGeneralStudentFormToGeneralStudentRequest(action.payload),
    );
    yield put(
      GeneralStudentCreators.updateGeneralStudent.success(action.payload),
    );

    yield put(StepNavigationCreators.isCompleted(true));
  } catch (error) {
    const responseError = mapErrorToResponseError(error);
    toast.error(responseError.message);
    yield put(
      GeneralStudentCreators.updateGeneralStudent.failure(
        responseError.message,
      ),
    );
  }
}

function* finishStudent(
  action: ReturnType<typeof GeneralStudentCreators.finishStudent.request>,
): Generator<unknown, void, number & { type: StudentType }> {
  try {
    const id: number = yield select(getUserInfoId);

    yield StudentGeneralService.finishStudent({
      id,
      type: action.payload.type,
    });

    yield put(GeneralStudentCreators.finishStudent.success());

    yield put(StepNavigationCreators.isCompleted(true));
  } catch (error) {
    const responseError = mapErrorToResponseError(error);
    toast.error(responseError.message);
    yield put(GeneralStudentCreators.finishStudent.failure());
  }
}

export default all([
  takeLatest(GeneralStudentCreators.finishStudent.request, finishStudent),
  takeLatest(
    GeneralStudentCreators.createGeneralStudent.request,
    createGeneralStudent,
  ),
  takeLatest(
    GeneralStudentCreators.fetchGeneralStudent.request,
    fetchGeneralStudent,
  ),
  takeLatest(
    GeneralStudentCreators.updateGeneralStudent.request,
    updateGeneralStudent,
  ),
]);
