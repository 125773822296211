import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';

import { ArrowDownBlackIcon, ArrowDownIcon } from 'assets/icons';

import { MenuItemText, ContainerMenu } from './styles';

interface MenuItemProps {
  title: string;
  handler: () => void;
}

interface DropdownMenuProps {
  title: string;
  items: MenuItemProps[];
  isDark?: boolean;
}

const DropdownMenu = ({ title, items, isDark = false }: DropdownMenuProps) => (
  <Menu>
    <MenuButton>
      <ContainerMenu>
        <MenuItemText isDark={isDark}>{title}</MenuItemText>
        {isDark ? <ArrowDownBlackIcon /> : <ArrowDownIcon />}
      </ContainerMenu>
    </MenuButton>
    <MenuList>
      {items.map(item => (
        <MenuItem key={item.title} onClick={item.handler}>
          {item.title}
        </MenuItem>
      ))}
    </MenuList>
  </Menu>
);

export default DropdownMenu;
