import produce, { Draft } from 'immer';
import {
  ActionType,
  createAction,
  createAsyncAction,
  Reducer,
} from 'typesafe-actions';

import {
  EnrollmentData,
  EducationDataFormData,
  EnrollmentFormData,
  TeachingTypeFormData,
  EnrollmentOverview,
} from 'models/enrollment';

export enum Types {
  SET_EDUCATION_TYPES = '@enrollment/SET_EDUCATION_TYPES',
  FETCH_ENROLLMENT_REQUEST = '@enrollment/FETCH_ENROLLMENT_REQUEST',
  FETCH_ENROLLMENT_SUCCESS = '@enrollment/FETCH_ENROLLMENT_SUCCESS',
  FETCH_ENROLLMENT_FAILURE = '@enrollment/FETCH_ENROLLMENT_FAILURE',
  CREATE_ENROLLMENT_REQUEST = '@enrollment/CREATE_ENROLLMENT_REQUEST',
  CREATE_ENROLLMENT_SUCCESS = '@enrollment/CREATE_ENROLLMENT_SUCCESS',
  CREATE_ENROLLMENT_FAILURE = '@enrollment/CREATE_ENROLLMENT_FAILURE',
  UPDATE_ENROLLMENT_REQUEST = '@enrollment/UPDATE_ENROLLMENT_REQUEST',
  UPDATE_ENROLLMENT_SUCCESS = '@enrollment/UPDATE_ENROLLMENT_SUCCESS',
  UPDATE_ENROLLMENT_FAILURE = '@enrollment/UPDATE_ENROLLMENT_FAILURE',
  REPEAT_ENROLLMENT_REQUEST = '@enrollment/REPEAT_ENROLLMENT_REQUEST',
  REPEAT_ENROLLMENT_SUCCESS = '@enrollment/REPEAT_ENROLLMENT_SUCCESS',
  REPEAT_ENROLLMENT_FAILURE = '@enrollment/REPEAT_ENROLLMENT_FAILURE',
  OVERVIEW_ENROLLMENT_REQUEST = '@enrollment/OVERVIEW_ENROLLMENT_REQUEST',
  OVERVIEW_ENROLLMENT_SUCCESS = '@enrollment/OVERVIEW_ENROLLMENT_SUCCESS',
  OVERVIEW_ENROLLMENT_FAILURE = '@enrollment/OVERVIEW_ENROLLMENT_FAILURE',
}

export interface EnrollmentState {
  loading: boolean;
  overview: EnrollmentOverview[];
  isRequestCompleted: boolean;
  educationTypeForm: TeachingTypeFormData;
  educationDataForm: EducationDataFormData;
}

const INITIAL_STATE: EnrollmentState = {
  loading: false,
  overview: [],
  isRequestCompleted: false,
  educationTypeForm: {} as TeachingTypeFormData,
  educationDataForm: {} as EducationDataFormData,
};

export const EnrollmentCreators = {
  setEnrollmentEducationTypeForm: createAction(
    Types.SET_EDUCATION_TYPES,
  )<TeachingTypeFormData>(),
  createEnrollment: createAsyncAction(
    Types.CREATE_ENROLLMENT_REQUEST,
    Types.CREATE_ENROLLMENT_SUCCESS,
    Types.CREATE_ENROLLMENT_FAILURE,
  )<EnrollmentFormData, EnrollmentFormData, string>(),
  updateEnrollment: createAsyncAction(
    Types.UPDATE_ENROLLMENT_REQUEST,
    Types.UPDATE_ENROLLMENT_SUCCESS,
    Types.UPDATE_ENROLLMENT_FAILURE,
  )<EnrollmentFormData, EnrollmentFormData, string>(),
  fetchEnrollment: createAsyncAction(
    Types.FETCH_ENROLLMENT_REQUEST,
    Types.FETCH_ENROLLMENT_SUCCESS,
    Types.FETCH_ENROLLMENT_FAILURE,
  )<void, EnrollmentData, string>(),
  repeatEnrollment: createAsyncAction(
    Types.REPEAT_ENROLLMENT_REQUEST,
    Types.REPEAT_ENROLLMENT_SUCCESS,
    Types.REPEAT_ENROLLMENT_FAILURE,
  )<void, void, string>(),
  fetchOverviewEnrollment: createAsyncAction(
    Types.OVERVIEW_ENROLLMENT_REQUEST,
    Types.OVERVIEW_ENROLLMENT_SUCCESS,
    Types.OVERVIEW_ENROLLMENT_FAILURE,
  )<void, EnrollmentOverview[], string>(),
};

export type ActionTypes = ActionType<typeof EnrollmentCreators>;

function unwrapEducationTypeFromEnrollmentForm(
  enroll: EnrollmentFormData,
): TeachingTypeFormData {
  const {
    mediationOption,
    stepOption,
    modalityOption,
    modalitySpecialOption,
    regimeOption,
    temporalityOption,
    shiftOption,
  } = enroll;
  return {
    mediationOption,
    stepOption,
    modalityOption,
    modalitySpecialOption,
    regimeOption,
    temporalityOption,
    shiftOption,
  };
}

function unwrapEducationDataFromEnrollmentForm(
  enroll: EnrollmentFormData,
): EducationDataFormData {
  const {
    school,
    courseOption,
    publicTransport,
    publicTransportResponsable,
    transportType,
    transportTypeDetail,
    siblingsToEnroll,
    siblings,
  } = enroll;
  return {
    school,
    courseOption,
    publicTransport,
    publicTransportResponsable,
    transportType,
    transportTypeDetail,
    siblingsToEnroll,
    siblings,
  };
}

const reducer: Reducer<EnrollmentState, ActionTypes> = (
  state = INITIAL_STATE,
  action: ActionTypes,
) => {
  const { type, payload } = action;

  return produce(state, (draft: Draft<EnrollmentState>) => {
    switch (type) {
      case Types.SET_EDUCATION_TYPES: {
        draft.educationTypeForm = payload as TeachingTypeFormData;
        break;
      }
      case Types.OVERVIEW_ENROLLMENT_REQUEST:
      case Types.REPEAT_ENROLLMENT_REQUEST:
      case Types.CREATE_ENROLLMENT_REQUEST:
      case Types.UPDATE_ENROLLMENT_REQUEST: {
        draft.loading = true;
        draft.isRequestCompleted = false;
        break;
      }
      case Types.FETCH_ENROLLMENT_REQUEST: {
        draft.educationDataForm = {} as EducationDataFormData;
        draft.educationTypeForm = {} as TeachingTypeFormData;
        draft.loading = true;
        break;
      }
      case Types.OVERVIEW_ENROLLMENT_FAILURE:
      case Types.REPEAT_ENROLLMENT_FAILURE:
      case Types.CREATE_ENROLLMENT_FAILURE:
      case Types.UPDATE_ENROLLMENT_FAILURE:
      case Types.FETCH_ENROLLMENT_FAILURE: {
        draft.loading = false;
        draft.isRequestCompleted = false;
        break;
      }
      case Types.REPEAT_ENROLLMENT_SUCCESS: {
        draft.isRequestCompleted = true;
        draft.loading = false;
        break;
      }
      case Types.OVERVIEW_ENROLLMENT_SUCCESS: {
        draft.overview = payload as EnrollmentOverview[];
        draft.loading = false;
        break;
      }
      case Types.CREATE_ENROLLMENT_SUCCESS:
      case Types.UPDATE_ENROLLMENT_SUCCESS:
      case Types.FETCH_ENROLLMENT_SUCCESS: {
        const enrollmentData = payload as EnrollmentData;
        draft.educationDataForm =
          unwrapEducationDataFromEnrollmentForm(enrollmentData);
        draft.educationTypeForm =
          unwrapEducationTypeFromEnrollmentForm(enrollmentData);
        draft.loading = false;
        break;
      }

      default:
    }
  });
};

export default reducer;
