import { IconNotas } from 'assets/icons';
import { MinhasNotasIllustration } from 'assets/images';
import { CardNotas } from 'components';

import { Container, Text, TextBox, CardsBox, Line, Image } from './styles';

const Grades = () => {
  return (
    <Container>
      <TextBox>
        <Image src={MinhasNotasIllustration} alt="" />
        <Text>
          <h1>Acompanhe seu desempenho escolar</h1>
          <p>
            Visualize abaixo suas notas dos bimestres de acordo com cada
            disciplina.
          </p>
        </Text>
      </TextBox>
      <Line />
      <CardsBox>
        <CardNotas
          icon={IconNotas}
          materia="Português"
          nota1="0,0"
          nota2="0,0"
          nota3="0,0"
          nota4="0,0"
          mediaNotas="0,0"
          aproved={false}
        />
        <CardNotas
          icon={IconNotas}
          materia="Matemática"
          nota1="0,0"
          nota2="0,0"
          nota3="0,0"
          nota4="0,0"
          mediaNotas="0,0"
          aproved
        />
        <CardNotas
          icon={IconNotas}
          materia="Biologia"
          nota1="0,0"
          nota2="0,0"
          nota3="0,0"
          nota4="0,0"
          mediaNotas="0,0"
          aproved={false}
        />
        <CardNotas
          icon={IconNotas}
          materia="História"
          nota1="0,0"
          nota2="0,0"
          nota3="0,0"
          nota4="0,0"
          mediaNotas="0,0"
          aproved={false}
        />
        <CardNotas
          icon={IconNotas}
          materia="Geografia"
          nota1="0,0"
          nota2="0,0"
          nota3="0,0"
          nota4="0,0"
          mediaNotas="0,0"
          aproved={false}
        />
        <CardNotas
          icon={IconNotas}
          materia="Português de novo"
          nota1="0,0"
          nota2="0,0"
          nota3="0,0"
          nota4="0,0"
          mediaNotas="0,0"
          aproved={false}
        />
        <CardNotas
          icon={IconNotas}
          materia="Física"
          nota1="0,0"
          nota2="0,0"
          nota3="0,0"
          nota4="0,0"
          mediaNotas="0,0"
          aproved={false}
        />
        <CardNotas
          icon={IconNotas}
          materia="Química"
          nota1="0,0"
          nota2="0,0"
          nota3="0,0"
          nota4="0,0"
          mediaNotas="0,0"
          aproved={false}
        />
      </CardsBox>
    </Container>
  );
};

export default Grades;
