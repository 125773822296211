import { useState, useCallback, useMemo, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { DoingIcon, DoneIcon, ToDoIcon } from 'assets/icons';
import { ContainerForm } from 'pages/StudentEnrollment/styles';
import { RootState } from 'store';
import { SchoolFiltersCreators } from 'store/ducks/schoolFilters';
import { StepNavigationCreators } from 'store/ducks/stepNavigation';

import { EducationData, TeachingType } from './Form';
import ReviewForm from './Form/ReviewForm';
import {
  Container,
  Content,
  CurrentStepLabel,
  FormContainer,
  FormTitle,
  SideStepsContainer,
  StepContainer,
  StepIcon,
  StepLabel,
  StepsTitle,
  ProgressContainer,
  ProgressContent,
} from './styles';

const ENROLLMENT_STEPS = [
  'Tipos de ensino',
  'Dados educacionais',
  'Revisão de Dados de Pré matrícula ',
];

export enum ReEnrollmentStep {
  TEACHING_TYPE,
  EDUCATION_DATA,
  REVIEW,
}

interface StepProps {
  current: number;
}

const Step = ({ current }: StepProps) => {
  const renderStepLabel = (step: string, index: number) => {
    if (index < current) {
      return (
        <>
          <StepIcon src={DoneIcon} />
          <StepLabel>{step}</StepLabel>
        </>
      );
    }

    if (current === index) {
      return (
        <>
          <StepIcon src={DoingIcon} />
          <CurrentStepLabel>{step}</CurrentStepLabel>
        </>
      );
    }

    return (
      <>
        <StepIcon src={ToDoIcon} />
        <StepLabel>{step}</StepLabel>
      </>
    );
  };

  return (
    <>
      {ENROLLMENT_STEPS.map((step, index) => (
        <StepContainer>{renderStepLabel(step, index)}</StepContainer>
      ))}
    </>
  );
};

const StudentReEnroll = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [currentStepIndex, setCurrentStepIndex] = useState(
    state?.currentStep ?? ReEnrollmentStep.TEACHING_TYPE,
  );

  const { isCompleted } = useSelector(
    ({ stepNavigation }: RootState) => stepNavigation,
  );

  useEffect(() => {
    dispatch(SchoolFiltersCreators.getSchoolFilters.request());
  }, [dispatch]);

  const onNextFormButtonTap = useCallback(() => {
    dispatch(StepNavigationCreators.isCompleted(false));

    setCurrentStepIndex(
      Math.min(ENROLLMENT_STEPS.length - 1, currentStepIndex + 1),
    );

    if (currentStepIndex === ENROLLMENT_STEPS.length - 1) {
      navigate('/home/studentReEnrollmentConfirmation');
    }
  }, [currentStepIndex, navigate, dispatch]);

  const onBackFormButtonTap = useCallback(() => {
    setCurrentStepIndex(Math.max(0, currentStepIndex - 1));
  }, [currentStepIndex]);

  // Dispara quando a request de um form é finalizada
  // NOTA: Seu saga deve setar o isCompleted pra `true`
  // [MA]
  useEffect(() => {
    if (isCompleted) {
      onNextFormButtonTap();
    }
  }, [isCompleted, onNextFormButtonTap]);

  const onGoToStep = useCallback((step: ReEnrollmentStep) => {
    setCurrentStepIndex(step);
  }, []);

  const CurrentForm = useMemo(() => {
    switch (currentStepIndex) {
      case ReEnrollmentStep.TEACHING_TYPE:
        return <TeachingType onNext={onNextFormButtonTap} />;
      case ReEnrollmentStep.EDUCATION_DATA:
        return (
          <EducationData
            onBack={onBackFormButtonTap}
            onNext={onNextFormButtonTap}
          />
        );
      case ReEnrollmentStep.REVIEW:
        return (
          <ReviewForm
            onBack={onBackFormButtonTap}
            onNext={onNextFormButtonTap}
            onGoToStep={onGoToStep}
            editable={state?.reviewEditable ?? true}
          />
        );
      default:
        return <TeachingType onNext={onNextFormButtonTap} />;
    }
  }, [
    currentStepIndex,
    onNextFormButtonTap,
    onBackFormButtonTap,
    onGoToStep,
    state?.reviewEditable,
  ]);

  return (
    <Container>
      <ProgressContainer>
        <ProgressContent
          progress={(currentStepIndex * 100) / ENROLLMENT_STEPS.length}
        />
      </ProgressContainer>
      <ContainerForm>
        <Content>
          <SideStepsContainer>
            <StepsTitle>Etapas</StepsTitle>
            <Step current={currentStepIndex} />
          </SideStepsContainer>
          <FormContainer>
            <FormTitle>{ENROLLMENT_STEPS[currentStepIndex]}</FormTitle>
            {CurrentForm}
          </FormContainer>
        </Content>
      </ContainerForm>
    </Container>
  );
};

export default StudentReEnroll;
