import { Option } from 'components/Select';

export enum StudentType {
  BEGINNER = 'BEGINNER',
  VETERAN = 'VETERAN',
}

export type StudentClothingSizes =
  | 'CHILDISH_PP'
  | 'CHILDISH_P'
  | 'CHILDISH_M'
  | 'CHILDISH_G'
  | 'CHILDISH_GG'
  | 'ADULT_PP'
  | 'ADULT_P'
  | 'ADULT_M'
  | 'ADULT_G'
  | 'ADULT_GG';

export type BirthCetificateTypes = 'OLD' | 'NEW';

export type StudentFormData = {
  birthStateOption: Option;
  birthCityOption: Option;
  code: string;
  networkOption: Option;
  fullname: string;
  hasSocialNameOption: Option;
  socialName: string;
  hasSocialNameInSchoolRecordsOption: Option;
  affectiveName: string;
  sexOption: Option;
  raceOption: Option;
  nationalityOption: Option;
  nationalityCountryOption: Option;
  handicappedOption: Option;
  handicappedTypeOption: Option;
  pplOption: Option;
  passport: string;
  phone: string;
  email: string;
  extraResourceOption: Option[];
  itinerantOption: Option;
  specialCommunityOption: Option;
  shirtSizeOption: Option;
  shortsSizeOption: Option;
  birthdate: string;
  type?: StudentType;
};

export type ResponsablesData = {
  fatherName?: string;
  motherName?: string;
  responsableName?: string;
  responsableRelation?:
    | 'GRANDMOTHER'
    | 'GRANDFATHER'
    | 'SISTER'
    | 'BROTHER'
    | 'MOTHER'
    | 'FATHER'
    | 'UNCLE'
    | 'AUNT'
    | 'OTHER';
  responsableCPF?: string;
  responsableBirthdate?: string;
  responsableProfession?: string;
  responsableOccupation?: string;
  responsablePhone?: string;
  responsableEmail?: string;
  noAffiliation?: boolean;
};

export type DocumentsStudentData = {
  rgStateId?: Option;
  birthCertificateTypeOption?: Option;
  birthCertificateNumber?: string;
  birthCertificateBook?: string;
  birthCertificatePage?: string;
  birthCertificateTerm?: string;
  rg?: string;
  issuer?: string;
  birthdate?: string;
  issueDate?: string;
  cpf?: string;
  nis?: string;
  sus?: string;
  covidVaccinationFirstDose?: string;
  covidVaccinationSecondDose?: string;
  covidVaccinationUniqueDose?: boolean;
  vaccineUnavailableToAgeGroup?: boolean;
  covidVaccinationRejected?: boolean;
};

export type ResidentalArea = {
  stateId?: Option;
  cityId?: Option;
  zipcode?: string;
  country?: Option;
  neighborhood?: string;
  street?: string;
  number?: string;
  district?: string;
  zone?: 'COUNTRYSIDE' | 'URBAN';
  differentiatedLocation?: Option;
  complement?: string;
};

export type UploadStudentFiles = {
  file: FormData;
  type: string;
};

export type UploadStudentFilesUpdateRequest = {
  file: FormData;
};

export type Keys =
  | 'CPF'
  | 'PHOTO'
  | 'SCHOOL_RECORDS'
  | 'BIRTH_CERTIFICATE'
  | 'SCHOLING_STATEMENT'
  | 'ADDRESS'
  | 'SUS'
  | 'VACCINATION_CARD'
  | 'SOCIALNAME'
  | 'ELECTORAL_DISCHARGE'
  | 'MILITARY_DISCHARGE';

export type Status = 'uploaded' | 'uploading' | 'error' | 'idle';
