import { useCallback, useEffect, useMemo } from 'react';

import { useFormik } from 'formik';
import { isEqual, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { IconArrowLeft, IconArrowRight } from 'assets/icons';
import { birthCertificateOptions } from 'assets/json';
import { Input, CheckBox, Select, Button } from 'components';
import Banner from 'components/Banner';
import InputKeyboardDate from 'components/InputKeyboardDate';
import { Option } from 'components/Select';
import { DocumentsStudentData } from 'models/student';
import {
  ChildrenFormContainer,
  StepsButtonContainer,
  ChildrenFormRow,
  GrayLine,
} from 'pages/StudentEnrollment/styles';
import { RootState } from 'store';
import { DocumentsStudentCreators } from 'store/ducks/students/documentsStudent';
import {
  checkFormikErrorsAndSetFieldTouched,
  getFormikMessageError,
} from 'utils/formikUtils';
import {
  normalizeCpfValue,
  normalizeNisValue,
  normalizeOnlyNumbers,
} from 'utils/maskUtils';
import {
  nisSchema,
  cnsSchema,
  stringSelectOptionRequiredSchema,
  dateRequiredSchema,
  cpfSchema,
  dateSchema,
  numberSelectOptionSchema,
  stringSelectOptionSchema,
} from 'utils/validations';

import { Vacination, Declaration, CheckboxContainer } from './styles';

const STUDENT_DOCS_FORM_SCHEMA = Yup.object().shape({
  rgStateId: numberSelectOptionSchema,
  birthCertificateTypeOption: stringSelectOptionRequiredSchema,
  birthCertificateNumber: Yup.string()
    .max(255, 'Valor inválido')
    .when('birthCertificateTypeOption', {
      is: (option: Option) => option.value === birthCertificateOptions[0].value,
      then: Yup.string().required('Campo obrigatório'),
      otherwise: Yup.string().notRequired(),
    }),
  birthCertificateBook: Yup.string()
    .max(255, 'Valor inválido')
    .when('birthCertificateTypeOption', {
      is: (option: Option) => option.value === birthCertificateOptions[1].value,
      then: Yup.string().required('Campo obrigatório'),
      otherwise: Yup.string().notRequired(),
    }),
  birthCertificatePage: Yup.string()
    .max(255, 'Valor inválido')
    .when('birthCertificateTypeOption', {
      is: (option: Option) => option.value === birthCertificateOptions[1].value,
      then: Yup.string().notRequired(),
      otherwise: Yup.string().notRequired(),
    }),
  birthCertificateTerm: Yup.string().max(255, 'Valor inválido'),
  rg: Yup.string(),
  issuer: Yup.string(),
  issueDate: dateSchema,
  cpf: cpfSchema,
  nis: nisSchema,
  sus: cnsSchema,
  covidVaccinationUniqueDose: Yup.boolean(),
  vaccineUnavailableToAgeGroup: Yup.boolean(),
  covidVaccinationRejected: Yup.boolean(),
  covidVaccinationFirstDose: Yup.date().when(
    ['covidVaccinationRejected', 'vaccineUnavailableToAgeGroup'],
    {
      is: (
        covidVaccinationRejected: boolean,
        vaccineUnavailableToAgeGroup: boolean,
      ) => !covidVaccinationRejected && !vaccineUnavailableToAgeGroup,
      then: dateRequiredSchema,
    },
  ),
  covidVaccinationSecondDose: Yup.date()
    .when(
      [
        'covidVaccinationUniqueDose',
        'covidVaccinationRejected',
        'vaccineUnavailableToAgeGroup',
      ],
      {
        is: (
          covidVaccinationUniqueDose: boolean,
          covidVaccinationRejected: boolean,
          vaccineUnavailableToAgeGroup: boolean,
        ) =>
          !covidVaccinationRejected &&
          !covidVaccinationUniqueDose &&
          !vaccineUnavailableToAgeGroup,
        then: Yup.date().optional(),
      },
    )
    .test(
      'covidVaccinationSecondDose',
      'Data da segunda dose deve ser maior',
      function testValidation() {
        if (!this.parent.covidVaccinationSecondDose) {
          return true;
        }

        return (
          this.parent.covidVaccinationRejected ||
          this.parent.covidVaccinationUniqueDose ||
          this.parent.vaccineUnavailableToAgeGroup ||
          this.parent.covidVaccinationSecondDose >
            this.parent.covidVaccinationFirstDose
        );
      },
    ),
});

const PPL_STUDENT_DOCS_FORM_SCHEMA = Yup.object().shape({
  rgStateId: numberSelectOptionSchema,
  birthCertificateTypeOption: stringSelectOptionSchema,
  birthCertificateNumber: Yup.string().max(255, 'Valor inválido'),
  birthCertificateBook: Yup.string().max(255, 'Valor inválido'),
  birthCertificatePage: Yup.string().max(255, 'Valor inválido'),
  birthCertificateTerm: Yup.string().max(255, 'Valor inválido'),
  rg: Yup.string(),
  issuer: Yup.string(),
  issueDate: dateSchema,
  cpf: cpfSchema,
  nis: nisSchema,
  sus: cnsSchema,
  covidVaccinationUniqueDose: Yup.boolean(),
  vaccineUnavailableToAgeGroup: Yup.boolean(),
  covidVaccinationRejected: Yup.boolean(),
  covidVaccinationFirstDose: Yup.date().when(
    ['covidVaccinationRejected', 'vaccineUnavailableToAgeGroup'],
    {
      is: (
        covidVaccinationRejected: boolean,
        vaccineUnavailableToAgeGroup: boolean,
      ) => !covidVaccinationRejected && !vaccineUnavailableToAgeGroup,
      then: dateSchema,
    },
  ),
  covidVaccinationSecondDose: Yup.date()
    .when(
      [
        'covidVaccinationUniqueDose',
        'covidVaccinationRejected',
        'vaccineUnavailableToAgeGroup',
      ],
      {
        is: (
          covidVaccinationUniqueDose: boolean,
          covidVaccinationRejected: boolean,
          vaccineUnavailableToAgeGroup: boolean,
        ) =>
          !covidVaccinationRejected &&
          !covidVaccinationUniqueDose &&
          !vaccineUnavailableToAgeGroup,
        then: Yup.date().optional(),
      },
    )
    .test(
      'covidVaccinationSecondDose',
      'Data da segunda dose deve ser maior',
      function testValidation() {
        if (!this.parent.covidVaccinationSecondDose) {
          return true;
        }

        return (
          this.parent.covidVaccinationRejected ||
          this.parent.covidVaccinationUniqueDose ||
          this.parent.vaccineUnavailableToAgeGroup ||
          this.parent.covidVaccinationSecondDose >
            this.parent.covidVaccinationFirstDose
        );
      },
    ),
});

interface StudentDocumentsForm {
  onBack: () => void;
  onNext: () => void;
  editing: boolean;
}

const StudentDocumentsForm = ({
  onBack,
  onNext,
  editing,
}: StudentDocumentsForm) => {
  const dispatch = useDispatch();
  const { loading, data } = useSelector(
    ({ documentsStudent }: RootState) => documentsStudent,
  );
  const { responsableStudentData } = useSelector(
    ({ responsableData }: RootState) => responsableData,
  );
  const { studentFormData } = useSelector(
    ({ studentGeneralInfo }: RootState) => studentGeneralInfo,
  );
  const isPPL = !!studentFormData?.pplOption?.value;

  const onSubmit = useCallback(
    (formData: DocumentsStudentData) => {
      if (editing) {
        onNext();
      }

      if (
        responsableStudentData &&
        responsableStudentData.responsableCPF &&
        formData.cpf &&
        responsableStudentData.responsableCPF.replace(/\D/g, '') ===
          formData.cpf?.replace(/\D/g, '')
      ) {
        toast.error('O CPF do estudante não pode ser igual ao do responsável');
        return;
      }

      if (isEqual(data, formData)) {
        onNext();
      } else if (isEmpty(data)) {
        dispatch(
          DocumentsStudentCreators.createDocumentsStudent.request(formData),
        );
      } else {
        dispatch(
          DocumentsStudentCreators.updateDocumentsStudent.request(formData),
        );
      }
    },
    [dispatch, data, onNext],
  );

  const schema = useMemo(
    () => (isPPL ? PPL_STUDENT_DOCS_FORM_SCHEMA : STUDENT_DOCS_FORM_SCHEMA),
    [isPPL],
  );

  const formik = useFormik({
    initialValues: data,
    validationSchema: schema,
    onSubmit,
  });

  const { states: stateOptions } = useSelector(
    (state: RootState) => state.states,
  );

  useEffect(() => {
    checkFormikErrorsAndSetFieldTouched(formik);
  }, [formik.isSubmitting]);

  useEffect(() => {
    if (
      formik.values.birthCertificateTypeOption !== undefined &&
      formik.values.birthCertificateTypeOption.value !==
        birthCertificateOptions[1].value &&
      (formik.values.birthCertificateBook !== undefined ||
        formik.values.birthCertificatePage !== undefined ||
        formik.values.birthCertificateTerm !== undefined)
    ) {
      formik.setFieldValue('birthCertificateBook', undefined);
      formik.setFieldValue('birthCertificatePage', undefined);
      formik.setFieldValue('birthCertificateTerm', undefined);
    }
  }, [formik, formik.values.birthCertificateTypeOption]);

  useEffect(() => {
    if (
      formik.values.birthCertificateTypeOption !== undefined &&
      formik.values.birthCertificateTypeOption.value !==
        birthCertificateOptions[0].value &&
      formik.values.birthCertificateNumber !== undefined
    ) {
      formik.setFieldValue('birthCertificateNumber', undefined);
    }
  }, [formik, formik.values.birthCertificateTypeOption]);

  useEffect(() => {
    if (
      formik.values.covidVaccinationRejected !== undefined &&
      formik.values.covidVaccinationRejected &&
      (formik.values.covidVaccinationFirstDose !== '' ||
        formik.values.covidVaccinationSecondDose !== '' ||
        formik.values.covidVaccinationUniqueDose ||
        formik.values.vaccineUnavailableToAgeGroup)
    ) {
      formik.setFieldValue('covidVaccinationFirstDose', '');
      formik.setFieldValue('covidVaccinationSecondDose', '');
      formik.setFieldValue('covidVaccinationUniqueDose', undefined);
      formik.setFieldValue('vaccineUnavailableToAgeGroup', undefined);
    }
  }, [formik, formik.values.covidVaccinationRejected]);

  useEffect(() => {
    if (
      formik.values.covidVaccinationUniqueDose !== undefined &&
      formik.values.covidVaccinationUniqueDose &&
      formik.values.covidVaccinationSecondDose !== ''
    ) {
      formik.setFieldValue('covidVaccinationSecondDose', '');
    }
  }, [formik, formik.values.covidVaccinationUniqueDose]);

  useEffect(() => {
    if (
      formik.values.vaccineUnavailableToAgeGroup !== undefined &&
      formik.values.vaccineUnavailableToAgeGroup &&
      (formik.values.covidVaccinationSecondDose !== '' ||
        formik.values.covidVaccinationFirstDose !== '')
    ) {
      formik.setFieldValue('covidVaccinationFirstDose', '');
      formik.setFieldValue('covidVaccinationSecondDose', '');
    }
  }, [formik, formik.values.vaccineUnavailableToAgeGroup]);

  return (
    <ChildrenFormContainer id="documents-student-form">
      <ChildrenFormRow>
        <Select
          label="Modelo de certidão de nascimento"
          placeholder="Selecione uma opção"
          required={!isPPL}
          options={birthCertificateOptions}
          onChange={(option: Option, _) =>
            formik.setFieldValue('birthCertificateTypeOption', option)
          }
          value={formik.values.birthCertificateTypeOption}
          messageError={getFormikMessageError(
            formik,
            'birthCertificateTypeOption',
          )}
        />
        {formik.values.birthCertificateTypeOption?.value ===
          birthCertificateOptions[1].value && (
          <Input
            label="Número do livro"
            placeholder="Digite o número do livro"
            required={!isPPL}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.birthCertificateBook}
            name="birthCertificateBook"
            messageError={getFormikMessageError(formik, 'birthCertificateBook')}
          />
        )}
        {formik.values.birthCertificateTypeOption?.value ===
          birthCertificateOptions[0].value && (
          <Input
            label="Matrícula da certidão de nascimento"
            placeholder="Digite o número"
            required={!isPPL}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.birthCertificateNumber}
            name="birthCertificateNumber"
            messageError={getFormikMessageError(
              formik,
              'birthCertificateNumber',
            )}
          />
        )}
      </ChildrenFormRow>

      {formik.values.birthCertificateTypeOption?.value ===
        birthCertificateOptions[1].value && (
        <ChildrenFormRow>
          <Input
            label="Página"
            placeholder="Digite a página"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.birthCertificatePage}
            name="birthCertificatePage"
            messageError={getFormikMessageError(formik, 'birthCertificatePage')}
            required={!isPPL}
          />
          <Input
            type="text"
            label="Termo"
            placeholder="Digite o termo"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.birthCertificateTerm}
            name="birthCertificateTerm"
            messageError={getFormikMessageError(formik, 'birthCertificateTerm')}
          />
        </ChildrenFormRow>
      )}

      <GrayLine />

      <ChildrenFormRow>
        <Input
          label="RG"
          placeholder="Digite um número"
          name="rg"
          value={formik.values.rg}
          onChange={evt => {
            const rg = evt.target.value;
            formik.setFieldValue('rg', normalizeOnlyNumbers(rg));
            if (!rg) {
              formik.setFieldValue('rgStateId', undefined);
            }
          }}
          onBlur={formik.handleBlur}
          messageError={getFormikMessageError(formik, 'rg')}
        />

        <Select
          label="UF"
          placeholder="Selecione uma opção"
          options={stateOptions}
          onChange={(option, _) => formik.setFieldValue('rgStateId', option)}
          messageError={getFormikMessageError(formik, 'rgStateId')}
          value={formik.values.rgStateId || null}
        />
      </ChildrenFormRow>
      <ChildrenFormRow>
        <Input
          label="Orgão Emissor"
          placeholder="Digite o nome do orgão"
          name="issuer"
          value={formik.values.issuer}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          messageError={getFormikMessageError(formik, 'issuer')}
        />

        <InputKeyboardDate
          label="Data de expedição"
          name="issueDate"
          value={
            formik.values.issueDate === undefined
              ? null
              : new Date(formik.values.issueDate)
          }
          onChange={date =>
            formik.setFieldValue('issueDate', date ?? undefined)
          }
          onBlur={formik.handleBlur}
          messageError={getFormikMessageError(formik, 'issueDate')}
        />
      </ChildrenFormRow>

      <GrayLine />

      <ChildrenFormRow>
        <Input
          label="CPF"
          placeholder="Digite o CPF"
          name="cpf"
          value={formik.values.cpf}
          onChange={evt =>
            formik.setFieldValue('cpf', normalizeCpfValue(evt.target.value))
          }
          onBlur={formik.handleBlur}
          maxLength={14}
          messageError={getFormikMessageError(formik, 'cpf')}
        />
        <Input
          label="Número de identificação Social (NIS)"
          placeholder="Digite o NIS"
          name="nis"
          value={formik.values.nis}
          onChange={evt =>
            formik.setFieldValue('nis', normalizeNisValue(evt.target.value))
          }
          onBlur={formik.handleBlur}
          messageError={getFormikMessageError(formik, 'nis')}
          maxLength={14}
        />
      </ChildrenFormRow>
      <ChildrenFormRow>
        <Input
          label="Número do Cartão Nacional de Saúde"
          placeholder="Digite o número"
          name="sus"
          value={formik.values.sus}
          onChange={evt =>
            formik.setFieldValue('sus', normalizeOnlyNumbers(evt.target.value))
          }
          onBlur={formik.handleBlur}
          messageError={getFormikMessageError(formik, 'sus')}
        />
      </ChildrenFormRow>

      <GrayLine />

      <Vacination>Vacinação COVID-19</Vacination>

      <ChildrenFormRow style={{ alignItems: 'center' }}>
        <InputKeyboardDate
          label="Data 1º dose"
          disabled={
            formik.values.covidVaccinationRejected ||
            formik.values.vaccineUnavailableToAgeGroup
          }
          name="covidVaccinationFirstDose"
          value={
            formik.values.covidVaccinationFirstDose
              ? new Date(`${formik.values.covidVaccinationFirstDose} 06:00`)
              : null
          }
          onChange={date =>
            formik.setFieldValue(
              'covidVaccinationFirstDose',
              date?.toDateString() ?? undefined,
            )
          }
          onBlur={formik.handleBlur}
          messageError={getFormikMessageError(
            formik,
            'covidVaccinationFirstDose',
          )}
        />
        <InputKeyboardDate
          label="Data 2º dose"
          disabled={
            formik.values.covidVaccinationUniqueDose ||
            formik.values.vaccineUnavailableToAgeGroup ||
            formik.values.covidVaccinationRejected
          }
          name="covidVaccinationSecondDose"
          value={
            formik.values.covidVaccinationSecondDose
              ? new Date(`${formik.values.covidVaccinationSecondDose} 06:00`)
              : null
          }
          onChange={date =>
            formik.setFieldValue(
              'covidVaccinationSecondDose',
              date?.toDateString() ?? undefined,
            )
          }
          onBlur={formik.handleBlur}
          messageError={getFormikMessageError(
            formik,
            'covidVaccinationSecondDose',
          )}
        />
      </ChildrenFormRow>
      <CheckboxContainer>
        <CheckBox
          label="Vacina com dose única"
          name="covidVaccinationUniqueDose"
          checked={formik.values.covidVaccinationUniqueDose || false}
          disabled={formik.values.covidVaccinationRejected}
          onChange={evt => {
            formik.setFieldValue(
              'covidVaccinationUniqueDose',
              evt.target.checked,
            );
            if (evt.target.checked) {
              formik.setFieldValue('vaccineUnavailableToAgeGroup', false);
            }
          }}
        />
      </CheckboxContainer>
      <CheckboxContainer>
        <CheckBox
          label="A vacinação ainda não disponível para a minha faixa etária"
          name="vaccineUnavailableToAgeGroup"
          checked={formik.values.vaccineUnavailableToAgeGroup || false}
          disabled={formik.values.covidVaccinationRejected}
          onChange={evt => {
            formik.setFieldValue(
              'vaccineUnavailableToAgeGroup',
              evt.target.checked,
            );
            if (evt.target.checked) {
              formik.setFieldValue('covidVaccinationUniqueDose', false);
            }
          }}
        />
      </CheckboxContainer>

      <Banner
        message="Caso tenha optado por não tomar a vacina você deve marcar o campo
        abaixo."
        variant="info"
      />

      <Declaration>
        <CheckBox
          label="Declaro que, por opção, não tomei nenhuma dose de vacina para
          imunização do covid-19."
          name="covidVaccinationRejected"
          checked={formik.values.covidVaccinationRejected || false}
          onChange={evt =>
            formik.setFieldValue('covidVaccinationRejected', evt.target.checked)
          }
        />
      </Declaration>

      <StepsButtonContainer>
        <Button
          iconLeft={editing ? undefined : IconArrowLeft}
          title={editing ? 'Cancelar' : 'Anterior'}
          type="button"
          styled="secondary"
          size="mini"
          onClick={onBack}
        />
        <Button
          iconRight={editing ? undefined : IconArrowRight}
          type="button"
          size="mini"
          title={editing ? 'Atualizar' : 'Próximo'}
          onClick={formik.submitForm}
          form="documents-student-form"
          disabled={loading}
        />
      </StepsButtonContainer>
    </ChildrenFormContainer>
  );
};

export default StudentDocumentsForm;
